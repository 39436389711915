@charset "UTF-8";
/**
* This file is for Bootstrap 4 styles specifically for utilities and helper classes the react app
* e.g. row, col, mx-auto
*/
.sb-show-main {
  font-size: 14px;
}

/* Unsets - Start */
.react-app, .multiple-react-app, .bs4-popover, .bs4-modal, .bs4 {
  /**
  * This file is for setting custom Bootstrap 4 variables
  * It should only override relevant variables from node_modules/bootstrap/scss/_variables.scss
  * https://getbootstrap.com/docs/4.3/getting-started/theming/
  */
  /*  Bootstrap spacing variables  */
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700");
  @import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700");
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #1d2126;
  /* Style categories mapped to (font weight, font family) */
  /** This file is intended to house SCSS rules for utility classes that step around known
  * browser behavior. When adding a rule to this file, please provide a comment giving a clear explanation
  * of purpose and do try to include a link to relevant web documentation when possible */
  @charset "UTF-8";
  /* NOTE: When pasting in a new styles.css file here, you're going to see :before added to the first two
  selectors below this line (the ones related to [data-icon] and [class^="icon-")
  Please remove those as it will cause some icons on the site to be offset.
  (This comment will also be deleted: please preserve this comment for the next developer!)
  */
  /* begin icons */
  /* end icons */
  /*

  This is for importing the styles inside the react-app-styles.scss too.
  If we don't do this and rely on Angular classes, some of the styles in
  React froala gets overidded by bootstrap overrides in react-app-styles.

  The mixins in this files are copied from app/styles/global_defaults/_typography.scss

  */
}
.react-app :root, .multiple-react-app :root, .bs4-popover :root, .bs4-modal :root, .bs4 :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0E72ED;
  --secondary: #6c757d;
  --success: #00843C;
  --info: #eaf7f7;
  --warning: #fa6625;
  --danger: #eb0c3b;
  --light: #f8f9fa;
  --dark: #343a40;
  --logo: #467b8c;
  --black: #1d2126;
  --gray-1: #3a424d;
  --gray-2: #576373;
  --gray-3: #748499;
  --gray-4: #acb5c2;
  --gray-5: #d4dae1;
  --gray-6: #e4e7ec;
  --gray-7: #f7f8fa;
  --yellow: #fdce00;
  --kelp: #03a569;
  --high-tide: #0b4dc2;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Open Sans", Helvetica, Arial, sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.react-app h1, .react-app h2, .react-app h3, .react-app h4, .react-app h5, .react-app h6,
.react-app .h1, .react-app .h2, .react-app .h3, .react-app .h4, .react-app .h5, .react-app .h6, .multiple-react-app h1, .multiple-react-app h2, .multiple-react-app h3, .multiple-react-app h4, .multiple-react-app h5, .multiple-react-app h6,
.multiple-react-app .h1, .multiple-react-app .h2, .multiple-react-app .h3, .multiple-react-app .h4, .multiple-react-app .h5, .multiple-react-app .h6, .bs4-popover h1, .bs4-popover h2, .bs4-popover h3, .bs4-popover h4, .bs4-popover h5, .bs4-popover h6,
.bs4-popover .h1, .bs4-popover .h2, .bs4-popover .h3, .bs4-popover .h4, .bs4-popover .h5, .bs4-popover .h6, .bs4-modal h1, .bs4-modal h2, .bs4-modal h3, .bs4-modal h4, .bs4-modal h5, .bs4-modal h6,
.bs4-modal .h1, .bs4-modal .h2, .bs4-modal .h3, .bs4-modal .h4, .bs4-modal .h5, .bs4-modal .h6, .bs4 h1, .bs4 h2, .bs4 h3, .bs4 h4, .bs4 h5, .bs4 h6,
.bs4 .h1, .bs4 .h2, .bs4 .h3, .bs4 .h4, .bs4 .h5, .bs4 .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.react-app h1, .react-app .h1, .multiple-react-app h1, .multiple-react-app .h1, .bs4-popover h1, .bs4-popover .h1, .bs4-modal h1, .bs4-modal .h1, .bs4 h1, .bs4 .h1 {
  font-size: 3.5rem;
}
.react-app h2, .react-app .h2, .multiple-react-app h2, .multiple-react-app .h2, .bs4-popover h2, .bs4-popover .h2, .bs4-modal h2, .bs4-modal .h2, .bs4 h2, .bs4 .h2 {
  font-size: 2.8rem;
}
.react-app h3, .react-app .h3, .multiple-react-app h3, .multiple-react-app .h3, .bs4-popover h3, .bs4-popover .h3, .bs4-modal h3, .bs4-modal .h3, .bs4 h3, .bs4 .h3 {
  font-size: 2.45rem;
}
.react-app h4, .react-app .h4, .multiple-react-app h4, .multiple-react-app .h4, .bs4-popover h4, .bs4-popover .h4, .bs4-modal h4, .bs4-modal .h4, .bs4 h4, .bs4 .h4 {
  font-size: 2.1rem;
}
.react-app h5, .react-app .h5, .multiple-react-app h5, .multiple-react-app .h5, .bs4-popover h5, .bs4-popover .h5, .bs4-modal h5, .bs4-modal .h5, .bs4 h5, .bs4 .h5 {
  font-size: 1.75rem;
}
.react-app h6, .react-app .h6, .multiple-react-app h6, .multiple-react-app .h6, .bs4-popover h6, .bs4-popover .h6, .bs4-modal h6, .bs4-modal .h6, .bs4 h6, .bs4 .h6 {
  font-size: 1.4rem;
}
.react-app .lead, .multiple-react-app .lead, .bs4-popover .lead, .bs4-modal .lead, .bs4 .lead {
  font-size: 1.75rem;
  font-weight: 300;
}
.react-app .display-1, .multiple-react-app .display-1, .bs4-popover .display-1, .bs4-modal .display-1, .bs4 .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.react-app .display-2, .multiple-react-app .display-2, .bs4-popover .display-2, .bs4-modal .display-2, .bs4 .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.react-app .display-3, .multiple-react-app .display-3, .bs4-popover .display-3, .bs4-modal .display-3, .bs4 .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.react-app .display-4, .multiple-react-app .display-4, .bs4-popover .display-4, .bs4-modal .display-4, .bs4 .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.react-app hr, .multiple-react-app hr, .bs4-popover hr, .bs4-modal hr, .bs4 hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.react-app small,
.react-app .small, .multiple-react-app small,
.multiple-react-app .small, .bs4-popover small,
.bs4-popover .small, .bs4-modal small,
.bs4-modal .small, .bs4 small,
.bs4 .small {
  font-size: 0.875em;
  font-weight: 400;
}
.react-app mark,
.react-app .mark, .multiple-react-app mark,
.multiple-react-app .mark, .bs4-popover mark,
.bs4-popover .mark, .bs4-modal mark,
.bs4-modal .mark, .bs4 mark,
.bs4 .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.react-app .list-unstyled, .multiple-react-app .list-unstyled, .bs4-popover .list-unstyled, .bs4-modal .list-unstyled, .bs4 .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.react-app .list-inline, .multiple-react-app .list-inline, .bs4-popover .list-inline, .bs4-modal .list-inline, .bs4 .list-inline {
  padding-left: 0;
  list-style: none;
}
.react-app .list-inline-item, .multiple-react-app .list-inline-item, .bs4-popover .list-inline-item, .bs4-modal .list-inline-item, .bs4 .list-inline-item {
  display: inline-block;
}
.react-app .list-inline-item:not(:last-child), .multiple-react-app .list-inline-item:not(:last-child), .bs4-popover .list-inline-item:not(:last-child), .bs4-modal .list-inline-item:not(:last-child), .bs4 .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.react-app .initialism, .multiple-react-app .initialism, .bs4-popover .initialism, .bs4-modal .initialism, .bs4 .initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.react-app .blockquote, .multiple-react-app .blockquote, .bs4-popover .blockquote, .bs4-modal .blockquote, .bs4 .blockquote {
  margin-bottom: 1rem;
  font-size: 1.75rem;
}
.react-app .blockquote-footer, .multiple-react-app .blockquote-footer, .bs4-popover .blockquote-footer, .bs4-modal .blockquote-footer, .bs4 .blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.react-app .blockquote-footer::before, .multiple-react-app .blockquote-footer::before, .bs4-popover .blockquote-footer::before, .bs4-modal .blockquote-footer::before, .bs4 .blockquote-footer::before {
  content: "— ";
}
.react-app .align-baseline, .multiple-react-app .align-baseline, .bs4-popover .align-baseline, .bs4-modal .align-baseline, .bs4 .align-baseline {
  vertical-align: baseline !important;
}
.react-app .align-top, .multiple-react-app .align-top, .bs4-popover .align-top, .bs4-modal .align-top, .bs4 .align-top {
  vertical-align: top !important;
}
.react-app .align-middle, .multiple-react-app .align-middle, .bs4-popover .align-middle, .bs4-modal .align-middle, .bs4 .align-middle {
  vertical-align: middle !important;
}
.react-app .align-bottom, .multiple-react-app .align-bottom, .bs4-popover .align-bottom, .bs4-modal .align-bottom, .bs4 .align-bottom {
  vertical-align: bottom !important;
}
.react-app .align-text-bottom, .multiple-react-app .align-text-bottom, .bs4-popover .align-text-bottom, .bs4-modal .align-text-bottom, .bs4 .align-text-bottom {
  vertical-align: text-bottom !important;
}
.react-app .align-text-top, .multiple-react-app .align-text-top, .bs4-popover .align-text-top, .bs4-modal .align-text-top, .bs4 .align-text-top {
  vertical-align: text-top !important;
}
.react-app .bg-primary, .multiple-react-app .bg-primary, .bs4-popover .bg-primary, .bs4-modal .bg-primary, .bs4 .bg-primary {
  background-color: #0E72ED !important;
}
.react-app a.bg-primary:hover, .react-app a.bg-primary:focus,
.react-app button.bg-primary:hover,
.react-app button.bg-primary:focus, .multiple-react-app a.bg-primary:hover, .multiple-react-app a.bg-primary:focus,
.multiple-react-app button.bg-primary:hover,
.multiple-react-app button.bg-primary:focus, .bs4-popover a.bg-primary:hover, .bs4-popover a.bg-primary:focus,
.bs4-popover button.bg-primary:hover,
.bs4-popover button.bg-primary:focus, .bs4-modal a.bg-primary:hover, .bs4-modal a.bg-primary:focus,
.bs4-modal button.bg-primary:hover,
.bs4-modal button.bg-primary:focus, .bs4 a.bg-primary:hover, .bs4 a.bg-primary:focus,
.bs4 button.bg-primary:hover,
.bs4 button.bg-primary:focus {
  background-color: #0b5bbd !important;
}
.react-app .bg-secondary, .multiple-react-app .bg-secondary, .bs4-popover .bg-secondary, .bs4-modal .bg-secondary, .bs4 .bg-secondary {
  background-color: #6c757d !important;
}
.react-app a.bg-secondary:hover, .react-app a.bg-secondary:focus,
.react-app button.bg-secondary:hover,
.react-app button.bg-secondary:focus, .multiple-react-app a.bg-secondary:hover, .multiple-react-app a.bg-secondary:focus,
.multiple-react-app button.bg-secondary:hover,
.multiple-react-app button.bg-secondary:focus, .bs4-popover a.bg-secondary:hover, .bs4-popover a.bg-secondary:focus,
.bs4-popover button.bg-secondary:hover,
.bs4-popover button.bg-secondary:focus, .bs4-modal a.bg-secondary:hover, .bs4-modal a.bg-secondary:focus,
.bs4-modal button.bg-secondary:hover,
.bs4-modal button.bg-secondary:focus, .bs4 a.bg-secondary:hover, .bs4 a.bg-secondary:focus,
.bs4 button.bg-secondary:hover,
.bs4 button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.react-app .bg-success, .multiple-react-app .bg-success, .bs4-popover .bg-success, .bs4-modal .bg-success, .bs4 .bg-success {
  background-color: #00843C !important;
}
.react-app a.bg-success:hover, .react-app a.bg-success:focus,
.react-app button.bg-success:hover,
.react-app button.bg-success:focus, .multiple-react-app a.bg-success:hover, .multiple-react-app a.bg-success:focus,
.multiple-react-app button.bg-success:hover,
.multiple-react-app button.bg-success:focus, .bs4-popover a.bg-success:hover, .bs4-popover a.bg-success:focus,
.bs4-popover button.bg-success:hover,
.bs4-popover button.bg-success:focus, .bs4-modal a.bg-success:hover, .bs4-modal a.bg-success:focus,
.bs4-modal button.bg-success:hover,
.bs4-modal button.bg-success:focus, .bs4 a.bg-success:hover, .bs4 a.bg-success:focus,
.bs4 button.bg-success:hover,
.bs4 button.bg-success:focus {
  background-color: #005125 !important;
}
.react-app .bg-info, .multiple-react-app .bg-info, .bs4-popover .bg-info, .bs4-modal .bg-info, .bs4 .bg-info {
  background-color: #eaf7f7 !important;
}
.react-app a.bg-info:hover, .react-app a.bg-info:focus,
.react-app button.bg-info:hover,
.react-app button.bg-info:focus, .multiple-react-app a.bg-info:hover, .multiple-react-app a.bg-info:focus,
.multiple-react-app button.bg-info:hover,
.multiple-react-app button.bg-info:focus, .bs4-popover a.bg-info:hover, .bs4-popover a.bg-info:focus,
.bs4-popover button.bg-info:hover,
.bs4-popover button.bg-info:focus, .bs4-modal a.bg-info:hover, .bs4-modal a.bg-info:focus,
.bs4-modal button.bg-info:hover,
.bs4-modal button.bg-info:focus, .bs4 a.bg-info:hover, .bs4 a.bg-info:focus,
.bs4 button.bg-info:hover,
.bs4 button.bg-info:focus {
  background-color: #c5e9e9 !important;
}
.react-app .bg-warning, .multiple-react-app .bg-warning, .bs4-popover .bg-warning, .bs4-modal .bg-warning, .bs4 .bg-warning {
  background-color: #fa6625 !important;
}
.react-app a.bg-warning:hover, .react-app a.bg-warning:focus,
.react-app button.bg-warning:hover,
.react-app button.bg-warning:focus, .multiple-react-app a.bg-warning:hover, .multiple-react-app a.bg-warning:focus,
.multiple-react-app button.bg-warning:hover,
.multiple-react-app button.bg-warning:focus, .bs4-popover a.bg-warning:hover, .bs4-popover a.bg-warning:focus,
.bs4-popover button.bg-warning:hover,
.bs4-popover button.bg-warning:focus, .bs4-modal a.bg-warning:hover, .bs4-modal a.bg-warning:focus,
.bs4-modal button.bg-warning:hover,
.bs4-modal button.bg-warning:focus, .bs4 a.bg-warning:hover, .bs4 a.bg-warning:focus,
.bs4 button.bg-warning:hover,
.bs4 button.bg-warning:focus {
  background-color: #e74a05 !important;
}
.react-app .bg-danger, .multiple-react-app .bg-danger, .bs4-popover .bg-danger, .bs4-modal .bg-danger, .bs4 .bg-danger {
  background-color: #eb0c3b !important;
}
.react-app a.bg-danger:hover, .react-app a.bg-danger:focus,
.react-app button.bg-danger:hover,
.react-app button.bg-danger:focus, .multiple-react-app a.bg-danger:hover, .multiple-react-app a.bg-danger:focus,
.multiple-react-app button.bg-danger:hover,
.multiple-react-app button.bg-danger:focus, .bs4-popover a.bg-danger:hover, .bs4-popover a.bg-danger:focus,
.bs4-popover button.bg-danger:hover,
.bs4-popover button.bg-danger:focus, .bs4-modal a.bg-danger:hover, .bs4-modal a.bg-danger:focus,
.bs4-modal button.bg-danger:hover,
.bs4-modal button.bg-danger:focus, .bs4 a.bg-danger:hover, .bs4 a.bg-danger:focus,
.bs4 button.bg-danger:hover,
.bs4 button.bg-danger:focus {
  background-color: #ba0a2f !important;
}
.react-app .bg-light, .multiple-react-app .bg-light, .bs4-popover .bg-light, .bs4-modal .bg-light, .bs4 .bg-light {
  background-color: #f8f9fa !important;
}
.react-app a.bg-light:hover, .react-app a.bg-light:focus,
.react-app button.bg-light:hover,
.react-app button.bg-light:focus, .multiple-react-app a.bg-light:hover, .multiple-react-app a.bg-light:focus,
.multiple-react-app button.bg-light:hover,
.multiple-react-app button.bg-light:focus, .bs4-popover a.bg-light:hover, .bs4-popover a.bg-light:focus,
.bs4-popover button.bg-light:hover,
.bs4-popover button.bg-light:focus, .bs4-modal a.bg-light:hover, .bs4-modal a.bg-light:focus,
.bs4-modal button.bg-light:hover,
.bs4-modal button.bg-light:focus, .bs4 a.bg-light:hover, .bs4 a.bg-light:focus,
.bs4 button.bg-light:hover,
.bs4 button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.react-app .bg-dark, .multiple-react-app .bg-dark, .bs4-popover .bg-dark, .bs4-modal .bg-dark, .bs4 .bg-dark {
  background-color: #343a40 !important;
}
.react-app a.bg-dark:hover, .react-app a.bg-dark:focus,
.react-app button.bg-dark:hover,
.react-app button.bg-dark:focus, .multiple-react-app a.bg-dark:hover, .multiple-react-app a.bg-dark:focus,
.multiple-react-app button.bg-dark:hover,
.multiple-react-app button.bg-dark:focus, .bs4-popover a.bg-dark:hover, .bs4-popover a.bg-dark:focus,
.bs4-popover button.bg-dark:hover,
.bs4-popover button.bg-dark:focus, .bs4-modal a.bg-dark:hover, .bs4-modal a.bg-dark:focus,
.bs4-modal button.bg-dark:hover,
.bs4-modal button.bg-dark:focus, .bs4 a.bg-dark:hover, .bs4 a.bg-dark:focus,
.bs4 button.bg-dark:hover,
.bs4 button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.react-app .bg-logo, .multiple-react-app .bg-logo, .bs4-popover .bg-logo, .bs4-modal .bg-logo, .bs4 .bg-logo {
  background-color: #467b8c !important;
}
.react-app a.bg-logo:hover, .react-app a.bg-logo:focus,
.react-app button.bg-logo:hover,
.react-app button.bg-logo:focus, .multiple-react-app a.bg-logo:hover, .multiple-react-app a.bg-logo:focus,
.multiple-react-app button.bg-logo:hover,
.multiple-react-app button.bg-logo:focus, .bs4-popover a.bg-logo:hover, .bs4-popover a.bg-logo:focus,
.bs4-popover button.bg-logo:hover,
.bs4-popover button.bg-logo:focus, .bs4-modal a.bg-logo:hover, .bs4-modal a.bg-logo:focus,
.bs4-modal button.bg-logo:hover,
.bs4-modal button.bg-logo:focus, .bs4 a.bg-logo:hover, .bs4 a.bg-logo:focus,
.bs4 button.bg-logo:hover,
.bs4 button.bg-logo:focus {
  background-color: #355d6a !important;
}
.react-app .bg-black, .multiple-react-app .bg-black, .bs4-popover .bg-black, .bs4-modal .bg-black, .bs4 .bg-black {
  background-color: #1d2126 !important;
}
.react-app a.bg-black:hover, .react-app a.bg-black:focus,
.react-app button.bg-black:hover,
.react-app button.bg-black:focus, .multiple-react-app a.bg-black:hover, .multiple-react-app a.bg-black:focus,
.multiple-react-app button.bg-black:hover,
.multiple-react-app button.bg-black:focus, .bs4-popover a.bg-black:hover, .bs4-popover a.bg-black:focus,
.bs4-popover button.bg-black:hover,
.bs4-popover button.bg-black:focus, .bs4-modal a.bg-black:hover, .bs4-modal a.bg-black:focus,
.bs4-modal button.bg-black:hover,
.bs4-modal button.bg-black:focus, .bs4 a.bg-black:hover, .bs4 a.bg-black:focus,
.bs4 button.bg-black:hover,
.bs4 button.bg-black:focus {
  background-color: #070809 !important;
}
.react-app .bg-gray-1, .multiple-react-app .bg-gray-1, .bs4-popover .bg-gray-1, .bs4-modal .bg-gray-1, .bs4 .bg-gray-1 {
  background-color: #3a424d !important;
}
.react-app a.bg-gray-1:hover, .react-app a.bg-gray-1:focus,
.react-app button.bg-gray-1:hover,
.react-app button.bg-gray-1:focus, .multiple-react-app a.bg-gray-1:hover, .multiple-react-app a.bg-gray-1:focus,
.multiple-react-app button.bg-gray-1:hover,
.multiple-react-app button.bg-gray-1:focus, .bs4-popover a.bg-gray-1:hover, .bs4-popover a.bg-gray-1:focus,
.bs4-popover button.bg-gray-1:hover,
.bs4-popover button.bg-gray-1:focus, .bs4-modal a.bg-gray-1:hover, .bs4-modal a.bg-gray-1:focus,
.bs4-modal button.bg-gray-1:hover,
.bs4-modal button.bg-gray-1:focus, .bs4 a.bg-gray-1:hover, .bs4 a.bg-gray-1:focus,
.bs4 button.bg-gray-1:hover,
.bs4 button.bg-gray-1:focus {
  background-color: #242930 !important;
}
.react-app .bg-gray-2, .multiple-react-app .bg-gray-2, .bs4-popover .bg-gray-2, .bs4-modal .bg-gray-2, .bs4 .bg-gray-2 {
  background-color: #576373 !important;
}
.react-app a.bg-gray-2:hover, .react-app a.bg-gray-2:focus,
.react-app button.bg-gray-2:hover,
.react-app button.bg-gray-2:focus, .multiple-react-app a.bg-gray-2:hover, .multiple-react-app a.bg-gray-2:focus,
.multiple-react-app button.bg-gray-2:hover,
.multiple-react-app button.bg-gray-2:focus, .bs4-popover a.bg-gray-2:hover, .bs4-popover a.bg-gray-2:focus,
.bs4-popover button.bg-gray-2:hover,
.bs4-popover button.bg-gray-2:focus, .bs4-modal a.bg-gray-2:hover, .bs4-modal a.bg-gray-2:focus,
.bs4-modal button.bg-gray-2:hover,
.bs4-modal button.bg-gray-2:focus, .bs4 a.bg-gray-2:hover, .bs4 a.bg-gray-2:focus,
.bs4 button.bg-gray-2:hover,
.bs4 button.bg-gray-2:focus {
  background-color: #414a56 !important;
}
.react-app .bg-gray-3, .multiple-react-app .bg-gray-3, .bs4-popover .bg-gray-3, .bs4-modal .bg-gray-3, .bs4 .bg-gray-3 {
  background-color: #748499 !important;
}
.react-app a.bg-gray-3:hover, .react-app a.bg-gray-3:focus,
.react-app button.bg-gray-3:hover,
.react-app button.bg-gray-3:focus, .multiple-react-app a.bg-gray-3:hover, .multiple-react-app a.bg-gray-3:focus,
.multiple-react-app button.bg-gray-3:hover,
.multiple-react-app button.bg-gray-3:focus, .bs4-popover a.bg-gray-3:hover, .bs4-popover a.bg-gray-3:focus,
.bs4-popover button.bg-gray-3:hover,
.bs4-popover button.bg-gray-3:focus, .bs4-modal a.bg-gray-3:hover, .bs4-modal a.bg-gray-3:focus,
.bs4-modal button.bg-gray-3:hover,
.bs4-modal button.bg-gray-3:focus, .bs4 a.bg-gray-3:hover, .bs4 a.bg-gray-3:focus,
.bs4 button.bg-gray-3:hover,
.bs4 button.bg-gray-3:focus {
  background-color: #5c6b7e !important;
}
.react-app .bg-gray-4, .multiple-react-app .bg-gray-4, .bs4-popover .bg-gray-4, .bs4-modal .bg-gray-4, .bs4 .bg-gray-4 {
  background-color: #acb5c2 !important;
}
.react-app a.bg-gray-4:hover, .react-app a.bg-gray-4:focus,
.react-app button.bg-gray-4:hover,
.react-app button.bg-gray-4:focus, .multiple-react-app a.bg-gray-4:hover, .multiple-react-app a.bg-gray-4:focus,
.multiple-react-app button.bg-gray-4:hover,
.multiple-react-app button.bg-gray-4:focus, .bs4-popover a.bg-gray-4:hover, .bs4-popover a.bg-gray-4:focus,
.bs4-popover button.bg-gray-4:hover,
.bs4-popover button.bg-gray-4:focus, .bs4-modal a.bg-gray-4:hover, .bs4-modal a.bg-gray-4:focus,
.bs4-modal button.bg-gray-4:hover,
.bs4-modal button.bg-gray-4:focus, .bs4 a.bg-gray-4:hover, .bs4 a.bg-gray-4:focus,
.bs4 button.bg-gray-4:hover,
.bs4 button.bg-gray-4:focus {
  background-color: #8f9bac !important;
}
.react-app .bg-gray-5, .multiple-react-app .bg-gray-5, .bs4-popover .bg-gray-5, .bs4-modal .bg-gray-5, .bs4 .bg-gray-5 {
  background-color: #d4dae1 !important;
}
.react-app a.bg-gray-5:hover, .react-app a.bg-gray-5:focus,
.react-app button.bg-gray-5:hover,
.react-app button.bg-gray-5:focus, .multiple-react-app a.bg-gray-5:hover, .multiple-react-app a.bg-gray-5:focus,
.multiple-react-app button.bg-gray-5:hover,
.multiple-react-app button.bg-gray-5:focus, .bs4-popover a.bg-gray-5:hover, .bs4-popover a.bg-gray-5:focus,
.bs4-popover button.bg-gray-5:hover,
.bs4-popover button.bg-gray-5:focus, .bs4-modal a.bg-gray-5:hover, .bs4-modal a.bg-gray-5:focus,
.bs4-modal button.bg-gray-5:hover,
.bs4-modal button.bg-gray-5:focus, .bs4 a.bg-gray-5:hover, .bs4 a.bg-gray-5:focus,
.bs4 button.bg-gray-5:hover,
.bs4 button.bg-gray-5:focus {
  background-color: #b6c0cc !important;
}
.react-app .bg-gray-6, .multiple-react-app .bg-gray-6, .bs4-popover .bg-gray-6, .bs4-modal .bg-gray-6, .bs4 .bg-gray-6 {
  background-color: #e4e7ec !important;
}
.react-app a.bg-gray-6:hover, .react-app a.bg-gray-6:focus,
.react-app button.bg-gray-6:hover,
.react-app button.bg-gray-6:focus, .multiple-react-app a.bg-gray-6:hover, .multiple-react-app a.bg-gray-6:focus,
.multiple-react-app button.bg-gray-6:hover,
.multiple-react-app button.bg-gray-6:focus, .bs4-popover a.bg-gray-6:hover, .bs4-popover a.bg-gray-6:focus,
.bs4-popover button.bg-gray-6:hover,
.bs4-popover button.bg-gray-6:focus, .bs4-modal a.bg-gray-6:hover, .bs4-modal a.bg-gray-6:focus,
.bs4-modal button.bg-gray-6:hover,
.bs4-modal button.bg-gray-6:focus, .bs4 a.bg-gray-6:hover, .bs4 a.bg-gray-6:focus,
.bs4 button.bg-gray-6:hover,
.bs4 button.bg-gray-6:focus {
  background-color: #c6ccd7 !important;
}
.react-app .bg-gray-7, .multiple-react-app .bg-gray-7, .bs4-popover .bg-gray-7, .bs4-modal .bg-gray-7, .bs4 .bg-gray-7 {
  background-color: #f7f8fa !important;
}
.react-app a.bg-gray-7:hover, .react-app a.bg-gray-7:focus,
.react-app button.bg-gray-7:hover,
.react-app button.bg-gray-7:focus, .multiple-react-app a.bg-gray-7:hover, .multiple-react-app a.bg-gray-7:focus,
.multiple-react-app button.bg-gray-7:hover,
.multiple-react-app button.bg-gray-7:focus, .bs4-popover a.bg-gray-7:hover, .bs4-popover a.bg-gray-7:focus,
.bs4-popover button.bg-gray-7:hover,
.bs4-popover button.bg-gray-7:focus, .bs4-modal a.bg-gray-7:hover, .bs4-modal a.bg-gray-7:focus,
.bs4-modal button.bg-gray-7:hover,
.bs4-modal button.bg-gray-7:focus, .bs4 a.bg-gray-7:hover, .bs4 a.bg-gray-7:focus,
.bs4 button.bg-gray-7:hover,
.bs4 button.bg-gray-7:focus {
  background-color: #d8dde6 !important;
}
.react-app .bg-yellow, .multiple-react-app .bg-yellow, .bs4-popover .bg-yellow, .bs4-modal .bg-yellow, .bs4 .bg-yellow {
  background-color: #fdce00 !important;
}
.react-app a.bg-yellow:hover, .react-app a.bg-yellow:focus,
.react-app button.bg-yellow:hover,
.react-app button.bg-yellow:focus, .multiple-react-app a.bg-yellow:hover, .multiple-react-app a.bg-yellow:focus,
.multiple-react-app button.bg-yellow:hover,
.multiple-react-app button.bg-yellow:focus, .bs4-popover a.bg-yellow:hover, .bs4-popover a.bg-yellow:focus,
.bs4-popover button.bg-yellow:hover,
.bs4-popover button.bg-yellow:focus, .bs4-modal a.bg-yellow:hover, .bs4-modal a.bg-yellow:focus,
.bs4-modal button.bg-yellow:hover,
.bs4-modal button.bg-yellow:focus, .bs4 a.bg-yellow:hover, .bs4 a.bg-yellow:focus,
.bs4 button.bg-yellow:hover,
.bs4 button.bg-yellow:focus {
  background-color: #caa400 !important;
}
.react-app .bg-kelp, .multiple-react-app .bg-kelp, .bs4-popover .bg-kelp, .bs4-modal .bg-kelp, .bs4 .bg-kelp {
  background-color: #03a569 !important;
}
.react-app a.bg-kelp:hover, .react-app a.bg-kelp:focus,
.react-app button.bg-kelp:hover,
.react-app button.bg-kelp:focus, .multiple-react-app a.bg-kelp:hover, .multiple-react-app a.bg-kelp:focus,
.multiple-react-app button.bg-kelp:hover,
.multiple-react-app button.bg-kelp:focus, .bs4-popover a.bg-kelp:hover, .bs4-popover a.bg-kelp:focus,
.bs4-popover button.bg-kelp:hover,
.bs4-popover button.bg-kelp:focus, .bs4-modal a.bg-kelp:hover, .bs4-modal a.bg-kelp:focus,
.bs4-modal button.bg-kelp:hover,
.bs4-modal button.bg-kelp:focus, .bs4 a.bg-kelp:hover, .bs4 a.bg-kelp:focus,
.bs4 button.bg-kelp:hover,
.bs4 button.bg-kelp:focus {
  background-color: #027349 !important;
}
.react-app .bg-high-tide, .multiple-react-app .bg-high-tide, .bs4-popover .bg-high-tide, .bs4-modal .bg-high-tide, .bs4 .bg-high-tide {
  background-color: #0b4dc2 !important;
}
.react-app a.bg-high-tide:hover, .react-app a.bg-high-tide:focus,
.react-app button.bg-high-tide:hover,
.react-app button.bg-high-tide:focus, .multiple-react-app a.bg-high-tide:hover, .multiple-react-app a.bg-high-tide:focus,
.multiple-react-app button.bg-high-tide:hover,
.multiple-react-app button.bg-high-tide:focus, .bs4-popover a.bg-high-tide:hover, .bs4-popover a.bg-high-tide:focus,
.bs4-popover button.bg-high-tide:hover,
.bs4-popover button.bg-high-tide:focus, .bs4-modal a.bg-high-tide:hover, .bs4-modal a.bg-high-tide:focus,
.bs4-modal button.bg-high-tide:hover,
.bs4-modal button.bg-high-tide:focus, .bs4 a.bg-high-tide:hover, .bs4 a.bg-high-tide:focus,
.bs4 button.bg-high-tide:hover,
.bs4 button.bg-high-tide:focus {
  background-color: #083a92 !important;
}
.react-app .bg-white, .multiple-react-app .bg-white, .bs4-popover .bg-white, .bs4-modal .bg-white, .bs4 .bg-white {
  background-color: #fff !important;
}
.react-app .bg-transparent, .multiple-react-app .bg-transparent, .bs4-popover .bg-transparent, .bs4-modal .bg-transparent, .bs4 .bg-transparent {
  background-color: transparent !important;
}
.react-app .border, .multiple-react-app .border, .bs4-popover .border, .bs4-modal .border, .bs4 .border {
  border: 1px solid #dee2e6 !important;
}
.react-app .border-top, .multiple-react-app .border-top, .bs4-popover .border-top, .bs4-modal .border-top, .bs4 .border-top {
  border-top: 1px solid #dee2e6 !important;
}
.react-app .border-right, .multiple-react-app .border-right, .bs4-popover .border-right, .bs4-modal .border-right, .bs4 .border-right {
  border-right: 1px solid #dee2e6 !important;
}
.react-app .border-bottom, .multiple-react-app .border-bottom, .bs4-popover .border-bottom, .bs4-modal .border-bottom, .bs4 .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.react-app .border-left, .multiple-react-app .border-left, .bs4-popover .border-left, .bs4-modal .border-left, .bs4 .border-left {
  border-left: 1px solid #dee2e6 !important;
}
.react-app .border-0, .multiple-react-app .border-0, .bs4-popover .border-0, .bs4-modal .border-0, .bs4 .border-0 {
  border: 0 !important;
}
.react-app .border-top-0, .multiple-react-app .border-top-0, .bs4-popover .border-top-0, .bs4-modal .border-top-0, .bs4 .border-top-0 {
  border-top: 0 !important;
}
.react-app .border-right-0, .multiple-react-app .border-right-0, .bs4-popover .border-right-0, .bs4-modal .border-right-0, .bs4 .border-right-0 {
  border-right: 0 !important;
}
.react-app .border-bottom-0, .multiple-react-app .border-bottom-0, .bs4-popover .border-bottom-0, .bs4-modal .border-bottom-0, .bs4 .border-bottom-0 {
  border-bottom: 0 !important;
}
.react-app .border-left-0, .multiple-react-app .border-left-0, .bs4-popover .border-left-0, .bs4-modal .border-left-0, .bs4 .border-left-0 {
  border-left: 0 !important;
}
.react-app .border-primary, .multiple-react-app .border-primary, .bs4-popover .border-primary, .bs4-modal .border-primary, .bs4 .border-primary {
  border-color: #0E72ED !important;
}
.react-app .border-secondary, .multiple-react-app .border-secondary, .bs4-popover .border-secondary, .bs4-modal .border-secondary, .bs4 .border-secondary {
  border-color: #6c757d !important;
}
.react-app .border-success, .multiple-react-app .border-success, .bs4-popover .border-success, .bs4-modal .border-success, .bs4 .border-success {
  border-color: #00843C !important;
}
.react-app .border-info, .multiple-react-app .border-info, .bs4-popover .border-info, .bs4-modal .border-info, .bs4 .border-info {
  border-color: #eaf7f7 !important;
}
.react-app .border-warning, .multiple-react-app .border-warning, .bs4-popover .border-warning, .bs4-modal .border-warning, .bs4 .border-warning {
  border-color: #fa6625 !important;
}
.react-app .border-danger, .multiple-react-app .border-danger, .bs4-popover .border-danger, .bs4-modal .border-danger, .bs4 .border-danger {
  border-color: #eb0c3b !important;
}
.react-app .border-light, .multiple-react-app .border-light, .bs4-popover .border-light, .bs4-modal .border-light, .bs4 .border-light {
  border-color: #f8f9fa !important;
}
.react-app .border-dark, .multiple-react-app .border-dark, .bs4-popover .border-dark, .bs4-modal .border-dark, .bs4 .border-dark {
  border-color: #343a40 !important;
}
.react-app .border-logo, .multiple-react-app .border-logo, .bs4-popover .border-logo, .bs4-modal .border-logo, .bs4 .border-logo {
  border-color: #467b8c !important;
}
.react-app .border-black, .multiple-react-app .border-black, .bs4-popover .border-black, .bs4-modal .border-black, .bs4 .border-black {
  border-color: #1d2126 !important;
}
.react-app .border-gray-1, .multiple-react-app .border-gray-1, .bs4-popover .border-gray-1, .bs4-modal .border-gray-1, .bs4 .border-gray-1 {
  border-color: #3a424d !important;
}
.react-app .border-gray-2, .multiple-react-app .border-gray-2, .bs4-popover .border-gray-2, .bs4-modal .border-gray-2, .bs4 .border-gray-2 {
  border-color: #576373 !important;
}
.react-app .border-gray-3, .multiple-react-app .border-gray-3, .bs4-popover .border-gray-3, .bs4-modal .border-gray-3, .bs4 .border-gray-3 {
  border-color: #748499 !important;
}
.react-app .border-gray-4, .multiple-react-app .border-gray-4, .bs4-popover .border-gray-4, .bs4-modal .border-gray-4, .bs4 .border-gray-4 {
  border-color: #acb5c2 !important;
}
.react-app .border-gray-5, .multiple-react-app .border-gray-5, .bs4-popover .border-gray-5, .bs4-modal .border-gray-5, .bs4 .border-gray-5 {
  border-color: #d4dae1 !important;
}
.react-app .border-gray-6, .multiple-react-app .border-gray-6, .bs4-popover .border-gray-6, .bs4-modal .border-gray-6, .bs4 .border-gray-6 {
  border-color: #e4e7ec !important;
}
.react-app .border-gray-7, .multiple-react-app .border-gray-7, .bs4-popover .border-gray-7, .bs4-modal .border-gray-7, .bs4 .border-gray-7 {
  border-color: #f7f8fa !important;
}
.react-app .border-yellow, .multiple-react-app .border-yellow, .bs4-popover .border-yellow, .bs4-modal .border-yellow, .bs4 .border-yellow {
  border-color: #fdce00 !important;
}
.react-app .border-kelp, .multiple-react-app .border-kelp, .bs4-popover .border-kelp, .bs4-modal .border-kelp, .bs4 .border-kelp {
  border-color: #03a569 !important;
}
.react-app .border-high-tide, .multiple-react-app .border-high-tide, .bs4-popover .border-high-tide, .bs4-modal .border-high-tide, .bs4 .border-high-tide {
  border-color: #0b4dc2 !important;
}
.react-app .border-white, .multiple-react-app .border-white, .bs4-popover .border-white, .bs4-modal .border-white, .bs4 .border-white {
  border-color: #fff !important;
}
.react-app .rounded-sm, .multiple-react-app .rounded-sm, .bs4-popover .rounded-sm, .bs4-modal .rounded-sm, .bs4 .rounded-sm {
  border-radius: 0.2rem !important;
}
.react-app .rounded, .multiple-react-app .rounded, .bs4-popover .rounded, .bs4-modal .rounded, .bs4 .rounded {
  border-radius: 4px !important;
}
.react-app .rounded-top, .multiple-react-app .rounded-top, .bs4-popover .rounded-top, .bs4-modal .rounded-top, .bs4 .rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.react-app .rounded-right, .multiple-react-app .rounded-right, .bs4-popover .rounded-right, .bs4-modal .rounded-right, .bs4 .rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.react-app .rounded-bottom, .multiple-react-app .rounded-bottom, .bs4-popover .rounded-bottom, .bs4-modal .rounded-bottom, .bs4 .rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.react-app .rounded-left, .multiple-react-app .rounded-left, .bs4-popover .rounded-left, .bs4-modal .rounded-left, .bs4 .rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}
.react-app .rounded-lg, .multiple-react-app .rounded-lg, .bs4-popover .rounded-lg, .bs4-modal .rounded-lg, .bs4 .rounded-lg {
  border-radius: 0.3rem !important;
}
.react-app .rounded-circle, .multiple-react-app .rounded-circle, .bs4-popover .rounded-circle, .bs4-modal .rounded-circle, .bs4 .rounded-circle {
  border-radius: 50% !important;
}
.react-app .rounded-pill, .multiple-react-app .rounded-pill, .bs4-popover .rounded-pill, .bs4-modal .rounded-pill, .bs4 .rounded-pill {
  border-radius: 50rem !important;
}
.react-app .rounded-0, .multiple-react-app .rounded-0, .bs4-popover .rounded-0, .bs4-modal .rounded-0, .bs4 .rounded-0 {
  border-radius: 0 !important;
}
.react-app .clearfix::after, .multiple-react-app .clearfix::after, .bs4-popover .clearfix::after, .bs4-modal .clearfix::after, .bs4 .clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.react-app .d-none, .multiple-react-app .d-none, .bs4-popover .d-none, .bs4-modal .d-none, .bs4 .d-none {
  display: none !important;
}
.react-app .d-inline, .multiple-react-app .d-inline, .bs4-popover .d-inline, .bs4-modal .d-inline, .bs4 .d-inline {
  display: inline !important;
}
.react-app .d-inline-block, .multiple-react-app .d-inline-block, .bs4-popover .d-inline-block, .bs4-modal .d-inline-block, .bs4 .d-inline-block {
  display: inline-block !important;
}
.react-app .d-block, .multiple-react-app .d-block, .bs4-popover .d-block, .bs4-modal .d-block, .bs4 .d-block {
  display: block !important;
}
.react-app .d-table, .multiple-react-app .d-table, .bs4-popover .d-table, .bs4-modal .d-table, .bs4 .d-table {
  display: table !important;
}
.react-app .d-table-row, .multiple-react-app .d-table-row, .bs4-popover .d-table-row, .bs4-modal .d-table-row, .bs4 .d-table-row {
  display: table-row !important;
}
.react-app .d-table-cell, .multiple-react-app .d-table-cell, .bs4-popover .d-table-cell, .bs4-modal .d-table-cell, .bs4 .d-table-cell {
  display: table-cell !important;
}
.react-app .d-flex, .multiple-react-app .d-flex, .bs4-popover .d-flex, .bs4-modal .d-flex, .bs4 .d-flex {
  display: flex !important;
}
.react-app .d-inline-flex, .multiple-react-app .d-inline-flex, .bs4-popover .d-inline-flex, .bs4-modal .d-inline-flex, .bs4 .d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .react-app .d-sm-none, .multiple-react-app .d-sm-none, .bs4-popover .d-sm-none, .bs4-modal .d-sm-none, .bs4 .d-sm-none {
    display: none !important;
  }
  .react-app .d-sm-inline, .multiple-react-app .d-sm-inline, .bs4-popover .d-sm-inline, .bs4-modal .d-sm-inline, .bs4 .d-sm-inline {
    display: inline !important;
  }
  .react-app .d-sm-inline-block, .multiple-react-app .d-sm-inline-block, .bs4-popover .d-sm-inline-block, .bs4-modal .d-sm-inline-block, .bs4 .d-sm-inline-block {
    display: inline-block !important;
  }
  .react-app .d-sm-block, .multiple-react-app .d-sm-block, .bs4-popover .d-sm-block, .bs4-modal .d-sm-block, .bs4 .d-sm-block {
    display: block !important;
  }
  .react-app .d-sm-table, .multiple-react-app .d-sm-table, .bs4-popover .d-sm-table, .bs4-modal .d-sm-table, .bs4 .d-sm-table {
    display: table !important;
  }
  .react-app .d-sm-table-row, .multiple-react-app .d-sm-table-row, .bs4-popover .d-sm-table-row, .bs4-modal .d-sm-table-row, .bs4 .d-sm-table-row {
    display: table-row !important;
  }
  .react-app .d-sm-table-cell, .multiple-react-app .d-sm-table-cell, .bs4-popover .d-sm-table-cell, .bs4-modal .d-sm-table-cell, .bs4 .d-sm-table-cell {
    display: table-cell !important;
  }
  .react-app .d-sm-flex, .multiple-react-app .d-sm-flex, .bs4-popover .d-sm-flex, .bs4-modal .d-sm-flex, .bs4 .d-sm-flex {
    display: flex !important;
  }
  .react-app .d-sm-inline-flex, .multiple-react-app .d-sm-inline-flex, .bs4-popover .d-sm-inline-flex, .bs4-modal .d-sm-inline-flex, .bs4 .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .react-app .d-md-none, .multiple-react-app .d-md-none, .bs4-popover .d-md-none, .bs4-modal .d-md-none, .bs4 .d-md-none {
    display: none !important;
  }
  .react-app .d-md-inline, .multiple-react-app .d-md-inline, .bs4-popover .d-md-inline, .bs4-modal .d-md-inline, .bs4 .d-md-inline {
    display: inline !important;
  }
  .react-app .d-md-inline-block, .multiple-react-app .d-md-inline-block, .bs4-popover .d-md-inline-block, .bs4-modal .d-md-inline-block, .bs4 .d-md-inline-block {
    display: inline-block !important;
  }
  .react-app .d-md-block, .multiple-react-app .d-md-block, .bs4-popover .d-md-block, .bs4-modal .d-md-block, .bs4 .d-md-block {
    display: block !important;
  }
  .react-app .d-md-table, .multiple-react-app .d-md-table, .bs4-popover .d-md-table, .bs4-modal .d-md-table, .bs4 .d-md-table {
    display: table !important;
  }
  .react-app .d-md-table-row, .multiple-react-app .d-md-table-row, .bs4-popover .d-md-table-row, .bs4-modal .d-md-table-row, .bs4 .d-md-table-row {
    display: table-row !important;
  }
  .react-app .d-md-table-cell, .multiple-react-app .d-md-table-cell, .bs4-popover .d-md-table-cell, .bs4-modal .d-md-table-cell, .bs4 .d-md-table-cell {
    display: table-cell !important;
  }
  .react-app .d-md-flex, .multiple-react-app .d-md-flex, .bs4-popover .d-md-flex, .bs4-modal .d-md-flex, .bs4 .d-md-flex {
    display: flex !important;
  }
  .react-app .d-md-inline-flex, .multiple-react-app .d-md-inline-flex, .bs4-popover .d-md-inline-flex, .bs4-modal .d-md-inline-flex, .bs4 .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .react-app .d-lg-none, .multiple-react-app .d-lg-none, .bs4-popover .d-lg-none, .bs4-modal .d-lg-none, .bs4 .d-lg-none {
    display: none !important;
  }
  .react-app .d-lg-inline, .multiple-react-app .d-lg-inline, .bs4-popover .d-lg-inline, .bs4-modal .d-lg-inline, .bs4 .d-lg-inline {
    display: inline !important;
  }
  .react-app .d-lg-inline-block, .multiple-react-app .d-lg-inline-block, .bs4-popover .d-lg-inline-block, .bs4-modal .d-lg-inline-block, .bs4 .d-lg-inline-block {
    display: inline-block !important;
  }
  .react-app .d-lg-block, .multiple-react-app .d-lg-block, .bs4-popover .d-lg-block, .bs4-modal .d-lg-block, .bs4 .d-lg-block {
    display: block !important;
  }
  .react-app .d-lg-table, .multiple-react-app .d-lg-table, .bs4-popover .d-lg-table, .bs4-modal .d-lg-table, .bs4 .d-lg-table {
    display: table !important;
  }
  .react-app .d-lg-table-row, .multiple-react-app .d-lg-table-row, .bs4-popover .d-lg-table-row, .bs4-modal .d-lg-table-row, .bs4 .d-lg-table-row {
    display: table-row !important;
  }
  .react-app .d-lg-table-cell, .multiple-react-app .d-lg-table-cell, .bs4-popover .d-lg-table-cell, .bs4-modal .d-lg-table-cell, .bs4 .d-lg-table-cell {
    display: table-cell !important;
  }
  .react-app .d-lg-flex, .multiple-react-app .d-lg-flex, .bs4-popover .d-lg-flex, .bs4-modal .d-lg-flex, .bs4 .d-lg-flex {
    display: flex !important;
  }
  .react-app .d-lg-inline-flex, .multiple-react-app .d-lg-inline-flex, .bs4-popover .d-lg-inline-flex, .bs4-modal .d-lg-inline-flex, .bs4 .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .react-app .d-xl-none, .multiple-react-app .d-xl-none, .bs4-popover .d-xl-none, .bs4-modal .d-xl-none, .bs4 .d-xl-none {
    display: none !important;
  }
  .react-app .d-xl-inline, .multiple-react-app .d-xl-inline, .bs4-popover .d-xl-inline, .bs4-modal .d-xl-inline, .bs4 .d-xl-inline {
    display: inline !important;
  }
  .react-app .d-xl-inline-block, .multiple-react-app .d-xl-inline-block, .bs4-popover .d-xl-inline-block, .bs4-modal .d-xl-inline-block, .bs4 .d-xl-inline-block {
    display: inline-block !important;
  }
  .react-app .d-xl-block, .multiple-react-app .d-xl-block, .bs4-popover .d-xl-block, .bs4-modal .d-xl-block, .bs4 .d-xl-block {
    display: block !important;
  }
  .react-app .d-xl-table, .multiple-react-app .d-xl-table, .bs4-popover .d-xl-table, .bs4-modal .d-xl-table, .bs4 .d-xl-table {
    display: table !important;
  }
  .react-app .d-xl-table-row, .multiple-react-app .d-xl-table-row, .bs4-popover .d-xl-table-row, .bs4-modal .d-xl-table-row, .bs4 .d-xl-table-row {
    display: table-row !important;
  }
  .react-app .d-xl-table-cell, .multiple-react-app .d-xl-table-cell, .bs4-popover .d-xl-table-cell, .bs4-modal .d-xl-table-cell, .bs4 .d-xl-table-cell {
    display: table-cell !important;
  }
  .react-app .d-xl-flex, .multiple-react-app .d-xl-flex, .bs4-popover .d-xl-flex, .bs4-modal .d-xl-flex, .bs4 .d-xl-flex {
    display: flex !important;
  }
  .react-app .d-xl-inline-flex, .multiple-react-app .d-xl-inline-flex, .bs4-popover .d-xl-inline-flex, .bs4-modal .d-xl-inline-flex, .bs4 .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .react-app .d-print-none, .multiple-react-app .d-print-none, .bs4-popover .d-print-none, .bs4-modal .d-print-none, .bs4 .d-print-none {
    display: none !important;
  }
  .react-app .d-print-inline, .multiple-react-app .d-print-inline, .bs4-popover .d-print-inline, .bs4-modal .d-print-inline, .bs4 .d-print-inline {
    display: inline !important;
  }
  .react-app .d-print-inline-block, .multiple-react-app .d-print-inline-block, .bs4-popover .d-print-inline-block, .bs4-modal .d-print-inline-block, .bs4 .d-print-inline-block {
    display: inline-block !important;
  }
  .react-app .d-print-block, .multiple-react-app .d-print-block, .bs4-popover .d-print-block, .bs4-modal .d-print-block, .bs4 .d-print-block {
    display: block !important;
  }
  .react-app .d-print-table, .multiple-react-app .d-print-table, .bs4-popover .d-print-table, .bs4-modal .d-print-table, .bs4 .d-print-table {
    display: table !important;
  }
  .react-app .d-print-table-row, .multiple-react-app .d-print-table-row, .bs4-popover .d-print-table-row, .bs4-modal .d-print-table-row, .bs4 .d-print-table-row {
    display: table-row !important;
  }
  .react-app .d-print-table-cell, .multiple-react-app .d-print-table-cell, .bs4-popover .d-print-table-cell, .bs4-modal .d-print-table-cell, .bs4 .d-print-table-cell {
    display: table-cell !important;
  }
  .react-app .d-print-flex, .multiple-react-app .d-print-flex, .bs4-popover .d-print-flex, .bs4-modal .d-print-flex, .bs4 .d-print-flex {
    display: flex !important;
  }
  .react-app .d-print-inline-flex, .multiple-react-app .d-print-inline-flex, .bs4-popover .d-print-inline-flex, .bs4-modal .d-print-inline-flex, .bs4 .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.react-app .embed-responsive, .multiple-react-app .embed-responsive, .bs4-popover .embed-responsive, .bs4-modal .embed-responsive, .bs4 .embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.react-app .embed-responsive::before, .multiple-react-app .embed-responsive::before, .bs4-popover .embed-responsive::before, .bs4-modal .embed-responsive::before, .bs4 .embed-responsive::before {
  display: block;
  content: "";
}
.react-app .embed-responsive .embed-responsive-item,
.react-app .embed-responsive iframe,
.react-app .embed-responsive embed,
.react-app .embed-responsive object,
.react-app .embed-responsive video, .multiple-react-app .embed-responsive .embed-responsive-item,
.multiple-react-app .embed-responsive iframe,
.multiple-react-app .embed-responsive embed,
.multiple-react-app .embed-responsive object,
.multiple-react-app .embed-responsive video, .bs4-popover .embed-responsive .embed-responsive-item,
.bs4-popover .embed-responsive iframe,
.bs4-popover .embed-responsive embed,
.bs4-popover .embed-responsive object,
.bs4-popover .embed-responsive video, .bs4-modal .embed-responsive .embed-responsive-item,
.bs4-modal .embed-responsive iframe,
.bs4-modal .embed-responsive embed,
.bs4-modal .embed-responsive object,
.bs4-modal .embed-responsive video, .bs4 .embed-responsive .embed-responsive-item,
.bs4 .embed-responsive iframe,
.bs4 .embed-responsive embed,
.bs4 .embed-responsive object,
.bs4 .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.react-app .embed-responsive-21by9::before, .multiple-react-app .embed-responsive-21by9::before, .bs4-popover .embed-responsive-21by9::before, .bs4-modal .embed-responsive-21by9::before, .bs4 .embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}
.react-app .embed-responsive-16by9::before, .multiple-react-app .embed-responsive-16by9::before, .bs4-popover .embed-responsive-16by9::before, .bs4-modal .embed-responsive-16by9::before, .bs4 .embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.react-app .embed-responsive-4by3::before, .multiple-react-app .embed-responsive-4by3::before, .bs4-popover .embed-responsive-4by3::before, .bs4-modal .embed-responsive-4by3::before, .bs4 .embed-responsive-4by3::before {
  padding-top: 75%;
}
.react-app .embed-responsive-1by1::before, .multiple-react-app .embed-responsive-1by1::before, .bs4-popover .embed-responsive-1by1::before, .bs4-modal .embed-responsive-1by1::before, .bs4 .embed-responsive-1by1::before {
  padding-top: 100%;
}
.react-app .flex-row, .multiple-react-app .flex-row, .bs4-popover .flex-row, .bs4-modal .flex-row, .bs4 .flex-row {
  flex-direction: row !important;
}
.react-app .flex-column, .multiple-react-app .flex-column, .bs4-popover .flex-column, .bs4-modal .flex-column, .bs4 .flex-column {
  flex-direction: column !important;
}
.react-app .flex-row-reverse, .multiple-react-app .flex-row-reverse, .bs4-popover .flex-row-reverse, .bs4-modal .flex-row-reverse, .bs4 .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.react-app .flex-column-reverse, .multiple-react-app .flex-column-reverse, .bs4-popover .flex-column-reverse, .bs4-modal .flex-column-reverse, .bs4 .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.react-app .flex-wrap, .multiple-react-app .flex-wrap, .bs4-popover .flex-wrap, .bs4-modal .flex-wrap, .bs4 .flex-wrap {
  flex-wrap: wrap !important;
}
.react-app .flex-nowrap, .multiple-react-app .flex-nowrap, .bs4-popover .flex-nowrap, .bs4-modal .flex-nowrap, .bs4 .flex-nowrap {
  flex-wrap: nowrap !important;
}
.react-app .flex-wrap-reverse, .multiple-react-app .flex-wrap-reverse, .bs4-popover .flex-wrap-reverse, .bs4-modal .flex-wrap-reverse, .bs4 .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.react-app .flex-fill, .multiple-react-app .flex-fill, .bs4-popover .flex-fill, .bs4-modal .flex-fill, .bs4 .flex-fill {
  flex: 1 1 auto !important;
}
.react-app .flex-grow-0, .multiple-react-app .flex-grow-0, .bs4-popover .flex-grow-0, .bs4-modal .flex-grow-0, .bs4 .flex-grow-0 {
  flex-grow: 0 !important;
}
.react-app .flex-grow-1, .multiple-react-app .flex-grow-1, .bs4-popover .flex-grow-1, .bs4-modal .flex-grow-1, .bs4 .flex-grow-1 {
  flex-grow: 1 !important;
}
.react-app .flex-shrink-0, .multiple-react-app .flex-shrink-0, .bs4-popover .flex-shrink-0, .bs4-modal .flex-shrink-0, .bs4 .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.react-app .flex-shrink-1, .multiple-react-app .flex-shrink-1, .bs4-popover .flex-shrink-1, .bs4-modal .flex-shrink-1, .bs4 .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.react-app .justify-content-start, .multiple-react-app .justify-content-start, .bs4-popover .justify-content-start, .bs4-modal .justify-content-start, .bs4 .justify-content-start {
  justify-content: flex-start !important;
}
.react-app .justify-content-end, .multiple-react-app .justify-content-end, .bs4-popover .justify-content-end, .bs4-modal .justify-content-end, .bs4 .justify-content-end {
  justify-content: flex-end !important;
}
.react-app .justify-content-center, .multiple-react-app .justify-content-center, .bs4-popover .justify-content-center, .bs4-modal .justify-content-center, .bs4 .justify-content-center {
  justify-content: center !important;
}
.react-app .justify-content-between, .multiple-react-app .justify-content-between, .bs4-popover .justify-content-between, .bs4-modal .justify-content-between, .bs4 .justify-content-between {
  justify-content: space-between !important;
}
.react-app .justify-content-around, .multiple-react-app .justify-content-around, .bs4-popover .justify-content-around, .bs4-modal .justify-content-around, .bs4 .justify-content-around {
  justify-content: space-around !important;
}
.react-app .align-items-start, .multiple-react-app .align-items-start, .bs4-popover .align-items-start, .bs4-modal .align-items-start, .bs4 .align-items-start {
  align-items: flex-start !important;
}
.react-app .align-items-end, .multiple-react-app .align-items-end, .bs4-popover .align-items-end, .bs4-modal .align-items-end, .bs4 .align-items-end {
  align-items: flex-end !important;
}
.react-app .align-items-center, .multiple-react-app .align-items-center, .bs4-popover .align-items-center, .bs4-modal .align-items-center, .bs4 .align-items-center {
  align-items: center !important;
}
.react-app .align-items-baseline, .multiple-react-app .align-items-baseline, .bs4-popover .align-items-baseline, .bs4-modal .align-items-baseline, .bs4 .align-items-baseline {
  align-items: baseline !important;
}
.react-app .align-items-stretch, .multiple-react-app .align-items-stretch, .bs4-popover .align-items-stretch, .bs4-modal .align-items-stretch, .bs4 .align-items-stretch {
  align-items: stretch !important;
}
.react-app .align-content-start, .multiple-react-app .align-content-start, .bs4-popover .align-content-start, .bs4-modal .align-content-start, .bs4 .align-content-start {
  align-content: flex-start !important;
}
.react-app .align-content-end, .multiple-react-app .align-content-end, .bs4-popover .align-content-end, .bs4-modal .align-content-end, .bs4 .align-content-end {
  align-content: flex-end !important;
}
.react-app .align-content-center, .multiple-react-app .align-content-center, .bs4-popover .align-content-center, .bs4-modal .align-content-center, .bs4 .align-content-center {
  align-content: center !important;
}
.react-app .align-content-between, .multiple-react-app .align-content-between, .bs4-popover .align-content-between, .bs4-modal .align-content-between, .bs4 .align-content-between {
  align-content: space-between !important;
}
.react-app .align-content-around, .multiple-react-app .align-content-around, .bs4-popover .align-content-around, .bs4-modal .align-content-around, .bs4 .align-content-around {
  align-content: space-around !important;
}
.react-app .align-content-stretch, .multiple-react-app .align-content-stretch, .bs4-popover .align-content-stretch, .bs4-modal .align-content-stretch, .bs4 .align-content-stretch {
  align-content: stretch !important;
}
.react-app .align-self-auto, .multiple-react-app .align-self-auto, .bs4-popover .align-self-auto, .bs4-modal .align-self-auto, .bs4 .align-self-auto {
  align-self: auto !important;
}
.react-app .align-self-start, .multiple-react-app .align-self-start, .bs4-popover .align-self-start, .bs4-modal .align-self-start, .bs4 .align-self-start {
  align-self: flex-start !important;
}
.react-app .align-self-end, .multiple-react-app .align-self-end, .bs4-popover .align-self-end, .bs4-modal .align-self-end, .bs4 .align-self-end {
  align-self: flex-end !important;
}
.react-app .align-self-center, .multiple-react-app .align-self-center, .bs4-popover .align-self-center, .bs4-modal .align-self-center, .bs4 .align-self-center {
  align-self: center !important;
}
.react-app .align-self-baseline, .multiple-react-app .align-self-baseline, .bs4-popover .align-self-baseline, .bs4-modal .align-self-baseline, .bs4 .align-self-baseline {
  align-self: baseline !important;
}
.react-app .align-self-stretch, .multiple-react-app .align-self-stretch, .bs4-popover .align-self-stretch, .bs4-modal .align-self-stretch, .bs4 .align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .react-app .flex-sm-row, .multiple-react-app .flex-sm-row, .bs4-popover .flex-sm-row, .bs4-modal .flex-sm-row, .bs4 .flex-sm-row {
    flex-direction: row !important;
  }
  .react-app .flex-sm-column, .multiple-react-app .flex-sm-column, .bs4-popover .flex-sm-column, .bs4-modal .flex-sm-column, .bs4 .flex-sm-column {
    flex-direction: column !important;
  }
  .react-app .flex-sm-row-reverse, .multiple-react-app .flex-sm-row-reverse, .bs4-popover .flex-sm-row-reverse, .bs4-modal .flex-sm-row-reverse, .bs4 .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .react-app .flex-sm-column-reverse, .multiple-react-app .flex-sm-column-reverse, .bs4-popover .flex-sm-column-reverse, .bs4-modal .flex-sm-column-reverse, .bs4 .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .react-app .flex-sm-wrap, .multiple-react-app .flex-sm-wrap, .bs4-popover .flex-sm-wrap, .bs4-modal .flex-sm-wrap, .bs4 .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .react-app .flex-sm-nowrap, .multiple-react-app .flex-sm-nowrap, .bs4-popover .flex-sm-nowrap, .bs4-modal .flex-sm-nowrap, .bs4 .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .react-app .flex-sm-wrap-reverse, .multiple-react-app .flex-sm-wrap-reverse, .bs4-popover .flex-sm-wrap-reverse, .bs4-modal .flex-sm-wrap-reverse, .bs4 .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .react-app .flex-sm-fill, .multiple-react-app .flex-sm-fill, .bs4-popover .flex-sm-fill, .bs4-modal .flex-sm-fill, .bs4 .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .react-app .flex-sm-grow-0, .multiple-react-app .flex-sm-grow-0, .bs4-popover .flex-sm-grow-0, .bs4-modal .flex-sm-grow-0, .bs4 .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .react-app .flex-sm-grow-1, .multiple-react-app .flex-sm-grow-1, .bs4-popover .flex-sm-grow-1, .bs4-modal .flex-sm-grow-1, .bs4 .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .react-app .flex-sm-shrink-0, .multiple-react-app .flex-sm-shrink-0, .bs4-popover .flex-sm-shrink-0, .bs4-modal .flex-sm-shrink-0, .bs4 .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .react-app .flex-sm-shrink-1, .multiple-react-app .flex-sm-shrink-1, .bs4-popover .flex-sm-shrink-1, .bs4-modal .flex-sm-shrink-1, .bs4 .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .react-app .justify-content-sm-start, .multiple-react-app .justify-content-sm-start, .bs4-popover .justify-content-sm-start, .bs4-modal .justify-content-sm-start, .bs4 .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .react-app .justify-content-sm-end, .multiple-react-app .justify-content-sm-end, .bs4-popover .justify-content-sm-end, .bs4-modal .justify-content-sm-end, .bs4 .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .react-app .justify-content-sm-center, .multiple-react-app .justify-content-sm-center, .bs4-popover .justify-content-sm-center, .bs4-modal .justify-content-sm-center, .bs4 .justify-content-sm-center {
    justify-content: center !important;
  }
  .react-app .justify-content-sm-between, .multiple-react-app .justify-content-sm-between, .bs4-popover .justify-content-sm-between, .bs4-modal .justify-content-sm-between, .bs4 .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .react-app .justify-content-sm-around, .multiple-react-app .justify-content-sm-around, .bs4-popover .justify-content-sm-around, .bs4-modal .justify-content-sm-around, .bs4 .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .react-app .align-items-sm-start, .multiple-react-app .align-items-sm-start, .bs4-popover .align-items-sm-start, .bs4-modal .align-items-sm-start, .bs4 .align-items-sm-start {
    align-items: flex-start !important;
  }
  .react-app .align-items-sm-end, .multiple-react-app .align-items-sm-end, .bs4-popover .align-items-sm-end, .bs4-modal .align-items-sm-end, .bs4 .align-items-sm-end {
    align-items: flex-end !important;
  }
  .react-app .align-items-sm-center, .multiple-react-app .align-items-sm-center, .bs4-popover .align-items-sm-center, .bs4-modal .align-items-sm-center, .bs4 .align-items-sm-center {
    align-items: center !important;
  }
  .react-app .align-items-sm-baseline, .multiple-react-app .align-items-sm-baseline, .bs4-popover .align-items-sm-baseline, .bs4-modal .align-items-sm-baseline, .bs4 .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .react-app .align-items-sm-stretch, .multiple-react-app .align-items-sm-stretch, .bs4-popover .align-items-sm-stretch, .bs4-modal .align-items-sm-stretch, .bs4 .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .react-app .align-content-sm-start, .multiple-react-app .align-content-sm-start, .bs4-popover .align-content-sm-start, .bs4-modal .align-content-sm-start, .bs4 .align-content-sm-start {
    align-content: flex-start !important;
  }
  .react-app .align-content-sm-end, .multiple-react-app .align-content-sm-end, .bs4-popover .align-content-sm-end, .bs4-modal .align-content-sm-end, .bs4 .align-content-sm-end {
    align-content: flex-end !important;
  }
  .react-app .align-content-sm-center, .multiple-react-app .align-content-sm-center, .bs4-popover .align-content-sm-center, .bs4-modal .align-content-sm-center, .bs4 .align-content-sm-center {
    align-content: center !important;
  }
  .react-app .align-content-sm-between, .multiple-react-app .align-content-sm-between, .bs4-popover .align-content-sm-between, .bs4-modal .align-content-sm-between, .bs4 .align-content-sm-between {
    align-content: space-between !important;
  }
  .react-app .align-content-sm-around, .multiple-react-app .align-content-sm-around, .bs4-popover .align-content-sm-around, .bs4-modal .align-content-sm-around, .bs4 .align-content-sm-around {
    align-content: space-around !important;
  }
  .react-app .align-content-sm-stretch, .multiple-react-app .align-content-sm-stretch, .bs4-popover .align-content-sm-stretch, .bs4-modal .align-content-sm-stretch, .bs4 .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .react-app .align-self-sm-auto, .multiple-react-app .align-self-sm-auto, .bs4-popover .align-self-sm-auto, .bs4-modal .align-self-sm-auto, .bs4 .align-self-sm-auto {
    align-self: auto !important;
  }
  .react-app .align-self-sm-start, .multiple-react-app .align-self-sm-start, .bs4-popover .align-self-sm-start, .bs4-modal .align-self-sm-start, .bs4 .align-self-sm-start {
    align-self: flex-start !important;
  }
  .react-app .align-self-sm-end, .multiple-react-app .align-self-sm-end, .bs4-popover .align-self-sm-end, .bs4-modal .align-self-sm-end, .bs4 .align-self-sm-end {
    align-self: flex-end !important;
  }
  .react-app .align-self-sm-center, .multiple-react-app .align-self-sm-center, .bs4-popover .align-self-sm-center, .bs4-modal .align-self-sm-center, .bs4 .align-self-sm-center {
    align-self: center !important;
  }
  .react-app .align-self-sm-baseline, .multiple-react-app .align-self-sm-baseline, .bs4-popover .align-self-sm-baseline, .bs4-modal .align-self-sm-baseline, .bs4 .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .react-app .align-self-sm-stretch, .multiple-react-app .align-self-sm-stretch, .bs4-popover .align-self-sm-stretch, .bs4-modal .align-self-sm-stretch, .bs4 .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .react-app .flex-md-row, .multiple-react-app .flex-md-row, .bs4-popover .flex-md-row, .bs4-modal .flex-md-row, .bs4 .flex-md-row {
    flex-direction: row !important;
  }
  .react-app .flex-md-column, .multiple-react-app .flex-md-column, .bs4-popover .flex-md-column, .bs4-modal .flex-md-column, .bs4 .flex-md-column {
    flex-direction: column !important;
  }
  .react-app .flex-md-row-reverse, .multiple-react-app .flex-md-row-reverse, .bs4-popover .flex-md-row-reverse, .bs4-modal .flex-md-row-reverse, .bs4 .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .react-app .flex-md-column-reverse, .multiple-react-app .flex-md-column-reverse, .bs4-popover .flex-md-column-reverse, .bs4-modal .flex-md-column-reverse, .bs4 .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .react-app .flex-md-wrap, .multiple-react-app .flex-md-wrap, .bs4-popover .flex-md-wrap, .bs4-modal .flex-md-wrap, .bs4 .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .react-app .flex-md-nowrap, .multiple-react-app .flex-md-nowrap, .bs4-popover .flex-md-nowrap, .bs4-modal .flex-md-nowrap, .bs4 .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .react-app .flex-md-wrap-reverse, .multiple-react-app .flex-md-wrap-reverse, .bs4-popover .flex-md-wrap-reverse, .bs4-modal .flex-md-wrap-reverse, .bs4 .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .react-app .flex-md-fill, .multiple-react-app .flex-md-fill, .bs4-popover .flex-md-fill, .bs4-modal .flex-md-fill, .bs4 .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .react-app .flex-md-grow-0, .multiple-react-app .flex-md-grow-0, .bs4-popover .flex-md-grow-0, .bs4-modal .flex-md-grow-0, .bs4 .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .react-app .flex-md-grow-1, .multiple-react-app .flex-md-grow-1, .bs4-popover .flex-md-grow-1, .bs4-modal .flex-md-grow-1, .bs4 .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .react-app .flex-md-shrink-0, .multiple-react-app .flex-md-shrink-0, .bs4-popover .flex-md-shrink-0, .bs4-modal .flex-md-shrink-0, .bs4 .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .react-app .flex-md-shrink-1, .multiple-react-app .flex-md-shrink-1, .bs4-popover .flex-md-shrink-1, .bs4-modal .flex-md-shrink-1, .bs4 .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .react-app .justify-content-md-start, .multiple-react-app .justify-content-md-start, .bs4-popover .justify-content-md-start, .bs4-modal .justify-content-md-start, .bs4 .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .react-app .justify-content-md-end, .multiple-react-app .justify-content-md-end, .bs4-popover .justify-content-md-end, .bs4-modal .justify-content-md-end, .bs4 .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .react-app .justify-content-md-center, .multiple-react-app .justify-content-md-center, .bs4-popover .justify-content-md-center, .bs4-modal .justify-content-md-center, .bs4 .justify-content-md-center {
    justify-content: center !important;
  }
  .react-app .justify-content-md-between, .multiple-react-app .justify-content-md-between, .bs4-popover .justify-content-md-between, .bs4-modal .justify-content-md-between, .bs4 .justify-content-md-between {
    justify-content: space-between !important;
  }
  .react-app .justify-content-md-around, .multiple-react-app .justify-content-md-around, .bs4-popover .justify-content-md-around, .bs4-modal .justify-content-md-around, .bs4 .justify-content-md-around {
    justify-content: space-around !important;
  }
  .react-app .align-items-md-start, .multiple-react-app .align-items-md-start, .bs4-popover .align-items-md-start, .bs4-modal .align-items-md-start, .bs4 .align-items-md-start {
    align-items: flex-start !important;
  }
  .react-app .align-items-md-end, .multiple-react-app .align-items-md-end, .bs4-popover .align-items-md-end, .bs4-modal .align-items-md-end, .bs4 .align-items-md-end {
    align-items: flex-end !important;
  }
  .react-app .align-items-md-center, .multiple-react-app .align-items-md-center, .bs4-popover .align-items-md-center, .bs4-modal .align-items-md-center, .bs4 .align-items-md-center {
    align-items: center !important;
  }
  .react-app .align-items-md-baseline, .multiple-react-app .align-items-md-baseline, .bs4-popover .align-items-md-baseline, .bs4-modal .align-items-md-baseline, .bs4 .align-items-md-baseline {
    align-items: baseline !important;
  }
  .react-app .align-items-md-stretch, .multiple-react-app .align-items-md-stretch, .bs4-popover .align-items-md-stretch, .bs4-modal .align-items-md-stretch, .bs4 .align-items-md-stretch {
    align-items: stretch !important;
  }
  .react-app .align-content-md-start, .multiple-react-app .align-content-md-start, .bs4-popover .align-content-md-start, .bs4-modal .align-content-md-start, .bs4 .align-content-md-start {
    align-content: flex-start !important;
  }
  .react-app .align-content-md-end, .multiple-react-app .align-content-md-end, .bs4-popover .align-content-md-end, .bs4-modal .align-content-md-end, .bs4 .align-content-md-end {
    align-content: flex-end !important;
  }
  .react-app .align-content-md-center, .multiple-react-app .align-content-md-center, .bs4-popover .align-content-md-center, .bs4-modal .align-content-md-center, .bs4 .align-content-md-center {
    align-content: center !important;
  }
  .react-app .align-content-md-between, .multiple-react-app .align-content-md-between, .bs4-popover .align-content-md-between, .bs4-modal .align-content-md-between, .bs4 .align-content-md-between {
    align-content: space-between !important;
  }
  .react-app .align-content-md-around, .multiple-react-app .align-content-md-around, .bs4-popover .align-content-md-around, .bs4-modal .align-content-md-around, .bs4 .align-content-md-around {
    align-content: space-around !important;
  }
  .react-app .align-content-md-stretch, .multiple-react-app .align-content-md-stretch, .bs4-popover .align-content-md-stretch, .bs4-modal .align-content-md-stretch, .bs4 .align-content-md-stretch {
    align-content: stretch !important;
  }
  .react-app .align-self-md-auto, .multiple-react-app .align-self-md-auto, .bs4-popover .align-self-md-auto, .bs4-modal .align-self-md-auto, .bs4 .align-self-md-auto {
    align-self: auto !important;
  }
  .react-app .align-self-md-start, .multiple-react-app .align-self-md-start, .bs4-popover .align-self-md-start, .bs4-modal .align-self-md-start, .bs4 .align-self-md-start {
    align-self: flex-start !important;
  }
  .react-app .align-self-md-end, .multiple-react-app .align-self-md-end, .bs4-popover .align-self-md-end, .bs4-modal .align-self-md-end, .bs4 .align-self-md-end {
    align-self: flex-end !important;
  }
  .react-app .align-self-md-center, .multiple-react-app .align-self-md-center, .bs4-popover .align-self-md-center, .bs4-modal .align-self-md-center, .bs4 .align-self-md-center {
    align-self: center !important;
  }
  .react-app .align-self-md-baseline, .multiple-react-app .align-self-md-baseline, .bs4-popover .align-self-md-baseline, .bs4-modal .align-self-md-baseline, .bs4 .align-self-md-baseline {
    align-self: baseline !important;
  }
  .react-app .align-self-md-stretch, .multiple-react-app .align-self-md-stretch, .bs4-popover .align-self-md-stretch, .bs4-modal .align-self-md-stretch, .bs4 .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .react-app .flex-lg-row, .multiple-react-app .flex-lg-row, .bs4-popover .flex-lg-row, .bs4-modal .flex-lg-row, .bs4 .flex-lg-row {
    flex-direction: row !important;
  }
  .react-app .flex-lg-column, .multiple-react-app .flex-lg-column, .bs4-popover .flex-lg-column, .bs4-modal .flex-lg-column, .bs4 .flex-lg-column {
    flex-direction: column !important;
  }
  .react-app .flex-lg-row-reverse, .multiple-react-app .flex-lg-row-reverse, .bs4-popover .flex-lg-row-reverse, .bs4-modal .flex-lg-row-reverse, .bs4 .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .react-app .flex-lg-column-reverse, .multiple-react-app .flex-lg-column-reverse, .bs4-popover .flex-lg-column-reverse, .bs4-modal .flex-lg-column-reverse, .bs4 .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .react-app .flex-lg-wrap, .multiple-react-app .flex-lg-wrap, .bs4-popover .flex-lg-wrap, .bs4-modal .flex-lg-wrap, .bs4 .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .react-app .flex-lg-nowrap, .multiple-react-app .flex-lg-nowrap, .bs4-popover .flex-lg-nowrap, .bs4-modal .flex-lg-nowrap, .bs4 .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .react-app .flex-lg-wrap-reverse, .multiple-react-app .flex-lg-wrap-reverse, .bs4-popover .flex-lg-wrap-reverse, .bs4-modal .flex-lg-wrap-reverse, .bs4 .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .react-app .flex-lg-fill, .multiple-react-app .flex-lg-fill, .bs4-popover .flex-lg-fill, .bs4-modal .flex-lg-fill, .bs4 .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .react-app .flex-lg-grow-0, .multiple-react-app .flex-lg-grow-0, .bs4-popover .flex-lg-grow-0, .bs4-modal .flex-lg-grow-0, .bs4 .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .react-app .flex-lg-grow-1, .multiple-react-app .flex-lg-grow-1, .bs4-popover .flex-lg-grow-1, .bs4-modal .flex-lg-grow-1, .bs4 .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .react-app .flex-lg-shrink-0, .multiple-react-app .flex-lg-shrink-0, .bs4-popover .flex-lg-shrink-0, .bs4-modal .flex-lg-shrink-0, .bs4 .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .react-app .flex-lg-shrink-1, .multiple-react-app .flex-lg-shrink-1, .bs4-popover .flex-lg-shrink-1, .bs4-modal .flex-lg-shrink-1, .bs4 .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .react-app .justify-content-lg-start, .multiple-react-app .justify-content-lg-start, .bs4-popover .justify-content-lg-start, .bs4-modal .justify-content-lg-start, .bs4 .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .react-app .justify-content-lg-end, .multiple-react-app .justify-content-lg-end, .bs4-popover .justify-content-lg-end, .bs4-modal .justify-content-lg-end, .bs4 .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .react-app .justify-content-lg-center, .multiple-react-app .justify-content-lg-center, .bs4-popover .justify-content-lg-center, .bs4-modal .justify-content-lg-center, .bs4 .justify-content-lg-center {
    justify-content: center !important;
  }
  .react-app .justify-content-lg-between, .multiple-react-app .justify-content-lg-between, .bs4-popover .justify-content-lg-between, .bs4-modal .justify-content-lg-between, .bs4 .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .react-app .justify-content-lg-around, .multiple-react-app .justify-content-lg-around, .bs4-popover .justify-content-lg-around, .bs4-modal .justify-content-lg-around, .bs4 .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .react-app .align-items-lg-start, .multiple-react-app .align-items-lg-start, .bs4-popover .align-items-lg-start, .bs4-modal .align-items-lg-start, .bs4 .align-items-lg-start {
    align-items: flex-start !important;
  }
  .react-app .align-items-lg-end, .multiple-react-app .align-items-lg-end, .bs4-popover .align-items-lg-end, .bs4-modal .align-items-lg-end, .bs4 .align-items-lg-end {
    align-items: flex-end !important;
  }
  .react-app .align-items-lg-center, .multiple-react-app .align-items-lg-center, .bs4-popover .align-items-lg-center, .bs4-modal .align-items-lg-center, .bs4 .align-items-lg-center {
    align-items: center !important;
  }
  .react-app .align-items-lg-baseline, .multiple-react-app .align-items-lg-baseline, .bs4-popover .align-items-lg-baseline, .bs4-modal .align-items-lg-baseline, .bs4 .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .react-app .align-items-lg-stretch, .multiple-react-app .align-items-lg-stretch, .bs4-popover .align-items-lg-stretch, .bs4-modal .align-items-lg-stretch, .bs4 .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .react-app .align-content-lg-start, .multiple-react-app .align-content-lg-start, .bs4-popover .align-content-lg-start, .bs4-modal .align-content-lg-start, .bs4 .align-content-lg-start {
    align-content: flex-start !important;
  }
  .react-app .align-content-lg-end, .multiple-react-app .align-content-lg-end, .bs4-popover .align-content-lg-end, .bs4-modal .align-content-lg-end, .bs4 .align-content-lg-end {
    align-content: flex-end !important;
  }
  .react-app .align-content-lg-center, .multiple-react-app .align-content-lg-center, .bs4-popover .align-content-lg-center, .bs4-modal .align-content-lg-center, .bs4 .align-content-lg-center {
    align-content: center !important;
  }
  .react-app .align-content-lg-between, .multiple-react-app .align-content-lg-between, .bs4-popover .align-content-lg-between, .bs4-modal .align-content-lg-between, .bs4 .align-content-lg-between {
    align-content: space-between !important;
  }
  .react-app .align-content-lg-around, .multiple-react-app .align-content-lg-around, .bs4-popover .align-content-lg-around, .bs4-modal .align-content-lg-around, .bs4 .align-content-lg-around {
    align-content: space-around !important;
  }
  .react-app .align-content-lg-stretch, .multiple-react-app .align-content-lg-stretch, .bs4-popover .align-content-lg-stretch, .bs4-modal .align-content-lg-stretch, .bs4 .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .react-app .align-self-lg-auto, .multiple-react-app .align-self-lg-auto, .bs4-popover .align-self-lg-auto, .bs4-modal .align-self-lg-auto, .bs4 .align-self-lg-auto {
    align-self: auto !important;
  }
  .react-app .align-self-lg-start, .multiple-react-app .align-self-lg-start, .bs4-popover .align-self-lg-start, .bs4-modal .align-self-lg-start, .bs4 .align-self-lg-start {
    align-self: flex-start !important;
  }
  .react-app .align-self-lg-end, .multiple-react-app .align-self-lg-end, .bs4-popover .align-self-lg-end, .bs4-modal .align-self-lg-end, .bs4 .align-self-lg-end {
    align-self: flex-end !important;
  }
  .react-app .align-self-lg-center, .multiple-react-app .align-self-lg-center, .bs4-popover .align-self-lg-center, .bs4-modal .align-self-lg-center, .bs4 .align-self-lg-center {
    align-self: center !important;
  }
  .react-app .align-self-lg-baseline, .multiple-react-app .align-self-lg-baseline, .bs4-popover .align-self-lg-baseline, .bs4-modal .align-self-lg-baseline, .bs4 .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .react-app .align-self-lg-stretch, .multiple-react-app .align-self-lg-stretch, .bs4-popover .align-self-lg-stretch, .bs4-modal .align-self-lg-stretch, .bs4 .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .react-app .flex-xl-row, .multiple-react-app .flex-xl-row, .bs4-popover .flex-xl-row, .bs4-modal .flex-xl-row, .bs4 .flex-xl-row {
    flex-direction: row !important;
  }
  .react-app .flex-xl-column, .multiple-react-app .flex-xl-column, .bs4-popover .flex-xl-column, .bs4-modal .flex-xl-column, .bs4 .flex-xl-column {
    flex-direction: column !important;
  }
  .react-app .flex-xl-row-reverse, .multiple-react-app .flex-xl-row-reverse, .bs4-popover .flex-xl-row-reverse, .bs4-modal .flex-xl-row-reverse, .bs4 .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .react-app .flex-xl-column-reverse, .multiple-react-app .flex-xl-column-reverse, .bs4-popover .flex-xl-column-reverse, .bs4-modal .flex-xl-column-reverse, .bs4 .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .react-app .flex-xl-wrap, .multiple-react-app .flex-xl-wrap, .bs4-popover .flex-xl-wrap, .bs4-modal .flex-xl-wrap, .bs4 .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .react-app .flex-xl-nowrap, .multiple-react-app .flex-xl-nowrap, .bs4-popover .flex-xl-nowrap, .bs4-modal .flex-xl-nowrap, .bs4 .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .react-app .flex-xl-wrap-reverse, .multiple-react-app .flex-xl-wrap-reverse, .bs4-popover .flex-xl-wrap-reverse, .bs4-modal .flex-xl-wrap-reverse, .bs4 .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .react-app .flex-xl-fill, .multiple-react-app .flex-xl-fill, .bs4-popover .flex-xl-fill, .bs4-modal .flex-xl-fill, .bs4 .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .react-app .flex-xl-grow-0, .multiple-react-app .flex-xl-grow-0, .bs4-popover .flex-xl-grow-0, .bs4-modal .flex-xl-grow-0, .bs4 .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .react-app .flex-xl-grow-1, .multiple-react-app .flex-xl-grow-1, .bs4-popover .flex-xl-grow-1, .bs4-modal .flex-xl-grow-1, .bs4 .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .react-app .flex-xl-shrink-0, .multiple-react-app .flex-xl-shrink-0, .bs4-popover .flex-xl-shrink-0, .bs4-modal .flex-xl-shrink-0, .bs4 .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .react-app .flex-xl-shrink-1, .multiple-react-app .flex-xl-shrink-1, .bs4-popover .flex-xl-shrink-1, .bs4-modal .flex-xl-shrink-1, .bs4 .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .react-app .justify-content-xl-start, .multiple-react-app .justify-content-xl-start, .bs4-popover .justify-content-xl-start, .bs4-modal .justify-content-xl-start, .bs4 .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .react-app .justify-content-xl-end, .multiple-react-app .justify-content-xl-end, .bs4-popover .justify-content-xl-end, .bs4-modal .justify-content-xl-end, .bs4 .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .react-app .justify-content-xl-center, .multiple-react-app .justify-content-xl-center, .bs4-popover .justify-content-xl-center, .bs4-modal .justify-content-xl-center, .bs4 .justify-content-xl-center {
    justify-content: center !important;
  }
  .react-app .justify-content-xl-between, .multiple-react-app .justify-content-xl-between, .bs4-popover .justify-content-xl-between, .bs4-modal .justify-content-xl-between, .bs4 .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .react-app .justify-content-xl-around, .multiple-react-app .justify-content-xl-around, .bs4-popover .justify-content-xl-around, .bs4-modal .justify-content-xl-around, .bs4 .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .react-app .align-items-xl-start, .multiple-react-app .align-items-xl-start, .bs4-popover .align-items-xl-start, .bs4-modal .align-items-xl-start, .bs4 .align-items-xl-start {
    align-items: flex-start !important;
  }
  .react-app .align-items-xl-end, .multiple-react-app .align-items-xl-end, .bs4-popover .align-items-xl-end, .bs4-modal .align-items-xl-end, .bs4 .align-items-xl-end {
    align-items: flex-end !important;
  }
  .react-app .align-items-xl-center, .multiple-react-app .align-items-xl-center, .bs4-popover .align-items-xl-center, .bs4-modal .align-items-xl-center, .bs4 .align-items-xl-center {
    align-items: center !important;
  }
  .react-app .align-items-xl-baseline, .multiple-react-app .align-items-xl-baseline, .bs4-popover .align-items-xl-baseline, .bs4-modal .align-items-xl-baseline, .bs4 .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .react-app .align-items-xl-stretch, .multiple-react-app .align-items-xl-stretch, .bs4-popover .align-items-xl-stretch, .bs4-modal .align-items-xl-stretch, .bs4 .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .react-app .align-content-xl-start, .multiple-react-app .align-content-xl-start, .bs4-popover .align-content-xl-start, .bs4-modal .align-content-xl-start, .bs4 .align-content-xl-start {
    align-content: flex-start !important;
  }
  .react-app .align-content-xl-end, .multiple-react-app .align-content-xl-end, .bs4-popover .align-content-xl-end, .bs4-modal .align-content-xl-end, .bs4 .align-content-xl-end {
    align-content: flex-end !important;
  }
  .react-app .align-content-xl-center, .multiple-react-app .align-content-xl-center, .bs4-popover .align-content-xl-center, .bs4-modal .align-content-xl-center, .bs4 .align-content-xl-center {
    align-content: center !important;
  }
  .react-app .align-content-xl-between, .multiple-react-app .align-content-xl-between, .bs4-popover .align-content-xl-between, .bs4-modal .align-content-xl-between, .bs4 .align-content-xl-between {
    align-content: space-between !important;
  }
  .react-app .align-content-xl-around, .multiple-react-app .align-content-xl-around, .bs4-popover .align-content-xl-around, .bs4-modal .align-content-xl-around, .bs4 .align-content-xl-around {
    align-content: space-around !important;
  }
  .react-app .align-content-xl-stretch, .multiple-react-app .align-content-xl-stretch, .bs4-popover .align-content-xl-stretch, .bs4-modal .align-content-xl-stretch, .bs4 .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .react-app .align-self-xl-auto, .multiple-react-app .align-self-xl-auto, .bs4-popover .align-self-xl-auto, .bs4-modal .align-self-xl-auto, .bs4 .align-self-xl-auto {
    align-self: auto !important;
  }
  .react-app .align-self-xl-start, .multiple-react-app .align-self-xl-start, .bs4-popover .align-self-xl-start, .bs4-modal .align-self-xl-start, .bs4 .align-self-xl-start {
    align-self: flex-start !important;
  }
  .react-app .align-self-xl-end, .multiple-react-app .align-self-xl-end, .bs4-popover .align-self-xl-end, .bs4-modal .align-self-xl-end, .bs4 .align-self-xl-end {
    align-self: flex-end !important;
  }
  .react-app .align-self-xl-center, .multiple-react-app .align-self-xl-center, .bs4-popover .align-self-xl-center, .bs4-modal .align-self-xl-center, .bs4 .align-self-xl-center {
    align-self: center !important;
  }
  .react-app .align-self-xl-baseline, .multiple-react-app .align-self-xl-baseline, .bs4-popover .align-self-xl-baseline, .bs4-modal .align-self-xl-baseline, .bs4 .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .react-app .align-self-xl-stretch, .multiple-react-app .align-self-xl-stretch, .bs4-popover .align-self-xl-stretch, .bs4-modal .align-self-xl-stretch, .bs4 .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.react-app .float-left, .multiple-react-app .float-left, .bs4-popover .float-left, .bs4-modal .float-left, .bs4 .float-left {
  float: left !important;
}
.react-app .float-right, .multiple-react-app .float-right, .bs4-popover .float-right, .bs4-modal .float-right, .bs4 .float-right {
  float: right !important;
}
.react-app .float-none, .multiple-react-app .float-none, .bs4-popover .float-none, .bs4-modal .float-none, .bs4 .float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .react-app .float-sm-left, .multiple-react-app .float-sm-left, .bs4-popover .float-sm-left, .bs4-modal .float-sm-left, .bs4 .float-sm-left {
    float: left !important;
  }
  .react-app .float-sm-right, .multiple-react-app .float-sm-right, .bs4-popover .float-sm-right, .bs4-modal .float-sm-right, .bs4 .float-sm-right {
    float: right !important;
  }
  .react-app .float-sm-none, .multiple-react-app .float-sm-none, .bs4-popover .float-sm-none, .bs4-modal .float-sm-none, .bs4 .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .react-app .float-md-left, .multiple-react-app .float-md-left, .bs4-popover .float-md-left, .bs4-modal .float-md-left, .bs4 .float-md-left {
    float: left !important;
  }
  .react-app .float-md-right, .multiple-react-app .float-md-right, .bs4-popover .float-md-right, .bs4-modal .float-md-right, .bs4 .float-md-right {
    float: right !important;
  }
  .react-app .float-md-none, .multiple-react-app .float-md-none, .bs4-popover .float-md-none, .bs4-modal .float-md-none, .bs4 .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .react-app .float-lg-left, .multiple-react-app .float-lg-left, .bs4-popover .float-lg-left, .bs4-modal .float-lg-left, .bs4 .float-lg-left {
    float: left !important;
  }
  .react-app .float-lg-right, .multiple-react-app .float-lg-right, .bs4-popover .float-lg-right, .bs4-modal .float-lg-right, .bs4 .float-lg-right {
    float: right !important;
  }
  .react-app .float-lg-none, .multiple-react-app .float-lg-none, .bs4-popover .float-lg-none, .bs4-modal .float-lg-none, .bs4 .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .react-app .float-xl-left, .multiple-react-app .float-xl-left, .bs4-popover .float-xl-left, .bs4-modal .float-xl-left, .bs4 .float-xl-left {
    float: left !important;
  }
  .react-app .float-xl-right, .multiple-react-app .float-xl-right, .bs4-popover .float-xl-right, .bs4-modal .float-xl-right, .bs4 .float-xl-right {
    float: right !important;
  }
  .react-app .float-xl-none, .multiple-react-app .float-xl-none, .bs4-popover .float-xl-none, .bs4-modal .float-xl-none, .bs4 .float-xl-none {
    float: none !important;
  }
}
.react-app .user-select-all, .multiple-react-app .user-select-all, .bs4-popover .user-select-all, .bs4-modal .user-select-all, .bs4 .user-select-all {
  user-select: all !important;
}
.react-app .user-select-auto, .multiple-react-app .user-select-auto, .bs4-popover .user-select-auto, .bs4-modal .user-select-auto, .bs4 .user-select-auto {
  user-select: auto !important;
}
.react-app .user-select-none, .multiple-react-app .user-select-none, .bs4-popover .user-select-none, .bs4-modal .user-select-none, .bs4 .user-select-none {
  user-select: none !important;
}
.react-app .overflow-auto, .multiple-react-app .overflow-auto, .bs4-popover .overflow-auto, .bs4-modal .overflow-auto, .bs4 .overflow-auto {
  overflow: auto !important;
}
.react-app .overflow-hidden, .multiple-react-app .overflow-hidden, .bs4-popover .overflow-hidden, .bs4-modal .overflow-hidden, .bs4 .overflow-hidden {
  overflow: hidden !important;
}
.react-app .position-static, .multiple-react-app .position-static, .bs4-popover .position-static, .bs4-modal .position-static, .bs4 .position-static {
  position: static !important;
}
.react-app .position-relative, .multiple-react-app .position-relative, .bs4-popover .position-relative, .bs4-modal .position-relative, .bs4 .position-relative {
  position: relative !important;
}
.react-app .position-absolute, .multiple-react-app .position-absolute, .bs4-popover .position-absolute, .bs4-modal .position-absolute, .bs4 .position-absolute {
  position: absolute !important;
}
.react-app .position-fixed, .multiple-react-app .position-fixed, .bs4-popover .position-fixed, .bs4-modal .position-fixed, .bs4 .position-fixed {
  position: fixed !important;
}
.react-app .position-sticky, .multiple-react-app .position-sticky, .bs4-popover .position-sticky, .bs4-modal .position-sticky, .bs4 .position-sticky {
  position: sticky !important;
}
.react-app .fixed-top, .multiple-react-app .fixed-top, .bs4-popover .fixed-top, .bs4-modal .fixed-top, .bs4 .fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.react-app .fixed-bottom, .multiple-react-app .fixed-bottom, .bs4-popover .fixed-bottom, .bs4-modal .fixed-bottom, .bs4 .fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .react-app .sticky-top, .multiple-react-app .sticky-top, .bs4-popover .sticky-top, .bs4-modal .sticky-top, .bs4 .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.react-app .sr-only, .multiple-react-app .sr-only, .bs4-popover .sr-only, .bs4-modal .sr-only, .bs4 .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.react-app .sr-only-focusable:active, .react-app .sr-only-focusable:focus, .multiple-react-app .sr-only-focusable:active, .multiple-react-app .sr-only-focusable:focus, .bs4-popover .sr-only-focusable:active, .bs4-popover .sr-only-focusable:focus, .bs4-modal .sr-only-focusable:active, .bs4-modal .sr-only-focusable:focus, .bs4 .sr-only-focusable:active, .bs4 .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.react-app .shadow-sm, .multiple-react-app .shadow-sm, .bs4-popover .shadow-sm, .bs4-modal .shadow-sm, .bs4 .shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.react-app .shadow, .multiple-react-app .shadow, .bs4-popover .shadow, .bs4-modal .shadow, .bs4 .shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.react-app .shadow-lg, .multiple-react-app .shadow-lg, .bs4-popover .shadow-lg, .bs4-modal .shadow-lg, .bs4 .shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.react-app .shadow-none, .multiple-react-app .shadow-none, .bs4-popover .shadow-none, .bs4-modal .shadow-none, .bs4 .shadow-none {
  box-shadow: none !important;
}
.react-app .w-25, .multiple-react-app .w-25, .bs4-popover .w-25, .bs4-modal .w-25, .bs4 .w-25 {
  width: 25% !important;
}
.react-app .w-50, .multiple-react-app .w-50, .bs4-popover .w-50, .bs4-modal .w-50, .bs4 .w-50 {
  width: 50% !important;
}
.react-app .w-75, .multiple-react-app .w-75, .bs4-popover .w-75, .bs4-modal .w-75, .bs4 .w-75 {
  width: 75% !important;
}
.react-app .w-100, .multiple-react-app .w-100, .bs4-popover .w-100, .bs4-modal .w-100, .bs4 .w-100 {
  width: 100% !important;
}
.react-app .w-auto, .multiple-react-app .w-auto, .bs4-popover .w-auto, .bs4-modal .w-auto, .bs4 .w-auto {
  width: auto !important;
}
.react-app .h-25, .multiple-react-app .h-25, .bs4-popover .h-25, .bs4-modal .h-25, .bs4 .h-25 {
  height: 25% !important;
}
.react-app .h-50, .multiple-react-app .h-50, .bs4-popover .h-50, .bs4-modal .h-50, .bs4 .h-50 {
  height: 50% !important;
}
.react-app .h-75, .multiple-react-app .h-75, .bs4-popover .h-75, .bs4-modal .h-75, .bs4 .h-75 {
  height: 75% !important;
}
.react-app .h-100, .multiple-react-app .h-100, .bs4-popover .h-100, .bs4-modal .h-100, .bs4 .h-100 {
  height: 100% !important;
}
.react-app .h-auto, .multiple-react-app .h-auto, .bs4-popover .h-auto, .bs4-modal .h-auto, .bs4 .h-auto {
  height: auto !important;
}
.react-app .mw-100, .multiple-react-app .mw-100, .bs4-popover .mw-100, .bs4-modal .mw-100, .bs4 .mw-100 {
  max-width: 100% !important;
}
.react-app .mh-100, .multiple-react-app .mh-100, .bs4-popover .mh-100, .bs4-modal .mh-100, .bs4 .mh-100 {
  max-height: 100% !important;
}
.react-app .min-vw-100, .multiple-react-app .min-vw-100, .bs4-popover .min-vw-100, .bs4-modal .min-vw-100, .bs4 .min-vw-100 {
  min-width: 100vw !important;
}
.react-app .min-vh-100, .multiple-react-app .min-vh-100, .bs4-popover .min-vh-100, .bs4-modal .min-vh-100, .bs4 .min-vh-100 {
  min-height: 100vh !important;
}
.react-app .vw-100, .multiple-react-app .vw-100, .bs4-popover .vw-100, .bs4-modal .vw-100, .bs4 .vw-100 {
  width: 100vw !important;
}
.react-app .vh-100, .multiple-react-app .vh-100, .bs4-popover .vh-100, .bs4-modal .vh-100, .bs4 .vh-100 {
  height: 100vh !important;
}
.react-app .m-0, .multiple-react-app .m-0, .bs4-popover .m-0, .bs4-modal .m-0, .bs4 .m-0 {
  margin: 0 !important;
}
.react-app .mt-0,
.react-app .my-0, .multiple-react-app .mt-0,
.multiple-react-app .my-0, .bs4-popover .mt-0,
.bs4-popover .my-0, .bs4-modal .mt-0,
.bs4-modal .my-0, .bs4 .mt-0,
.bs4 .my-0 {
  margin-top: 0 !important;
}
.react-app .mr-0,
.react-app .mx-0, .multiple-react-app .mr-0,
.multiple-react-app .mx-0, .bs4-popover .mr-0,
.bs4-popover .mx-0, .bs4-modal .mr-0,
.bs4-modal .mx-0, .bs4 .mr-0,
.bs4 .mx-0 {
  margin-right: 0 !important;
}
.react-app .mb-0,
.react-app .my-0, .multiple-react-app .mb-0,
.multiple-react-app .my-0, .bs4-popover .mb-0,
.bs4-popover .my-0, .bs4-modal .mb-0,
.bs4-modal .my-0, .bs4 .mb-0,
.bs4 .my-0 {
  margin-bottom: 0 !important;
}
.react-app .ml-0,
.react-app .mx-0, .multiple-react-app .ml-0,
.multiple-react-app .mx-0, .bs4-popover .ml-0,
.bs4-popover .mx-0, .bs4-modal .ml-0,
.bs4-modal .mx-0, .bs4 .ml-0,
.bs4 .mx-0 {
  margin-left: 0 !important;
}
.react-app .m-1, .multiple-react-app .m-1, .bs4-popover .m-1, .bs4-modal .m-1, .bs4 .m-1 {
  margin: 0.5rem !important;
}
.react-app .mt-1,
.react-app .my-1, .multiple-react-app .mt-1,
.multiple-react-app .my-1, .bs4-popover .mt-1,
.bs4-popover .my-1, .bs4-modal .mt-1,
.bs4-modal .my-1, .bs4 .mt-1,
.bs4 .my-1 {
  margin-top: 0.5rem !important;
}
.react-app .mr-1,
.react-app .mx-1, .multiple-react-app .mr-1,
.multiple-react-app .mx-1, .bs4-popover .mr-1,
.bs4-popover .mx-1, .bs4-modal .mr-1,
.bs4-modal .mx-1, .bs4 .mr-1,
.bs4 .mx-1 {
  margin-right: 0.5rem !important;
}
.react-app .mb-1,
.react-app .my-1, .multiple-react-app .mb-1,
.multiple-react-app .my-1, .bs4-popover .mb-1,
.bs4-popover .my-1, .bs4-modal .mb-1,
.bs4-modal .my-1, .bs4 .mb-1,
.bs4 .my-1 {
  margin-bottom: 0.5rem !important;
}
.react-app .ml-1,
.react-app .mx-1, .multiple-react-app .ml-1,
.multiple-react-app .mx-1, .bs4-popover .ml-1,
.bs4-popover .mx-1, .bs4-modal .ml-1,
.bs4-modal .mx-1, .bs4 .ml-1,
.bs4 .mx-1 {
  margin-left: 0.5rem !important;
}
.react-app .m-2, .multiple-react-app .m-2, .bs4-popover .m-2, .bs4-modal .m-2, .bs4 .m-2 {
  margin: 1rem !important;
}
.react-app .mt-2,
.react-app .my-2, .multiple-react-app .mt-2,
.multiple-react-app .my-2, .bs4-popover .mt-2,
.bs4-popover .my-2, .bs4-modal .mt-2,
.bs4-modal .my-2, .bs4 .mt-2,
.bs4 .my-2 {
  margin-top: 1rem !important;
}
.react-app .mr-2,
.react-app .mx-2, .multiple-react-app .mr-2,
.multiple-react-app .mx-2, .bs4-popover .mr-2,
.bs4-popover .mx-2, .bs4-modal .mr-2,
.bs4-modal .mx-2, .bs4 .mr-2,
.bs4 .mx-2 {
  margin-right: 1rem !important;
}
.react-app .mb-2,
.react-app .my-2, .multiple-react-app .mb-2,
.multiple-react-app .my-2, .bs4-popover .mb-2,
.bs4-popover .my-2, .bs4-modal .mb-2,
.bs4-modal .my-2, .bs4 .mb-2,
.bs4 .my-2 {
  margin-bottom: 1rem !important;
}
.react-app .ml-2,
.react-app .mx-2, .multiple-react-app .ml-2,
.multiple-react-app .mx-2, .bs4-popover .ml-2,
.bs4-popover .mx-2, .bs4-modal .ml-2,
.bs4-modal .mx-2, .bs4 .ml-2,
.bs4 .mx-2 {
  margin-left: 1rem !important;
}
.react-app .m-3, .multiple-react-app .m-3, .bs4-popover .m-3, .bs4-modal .m-3, .bs4 .m-3 {
  margin: 1.5rem !important;
}
.react-app .mt-3,
.react-app .my-3, .multiple-react-app .mt-3,
.multiple-react-app .my-3, .bs4-popover .mt-3,
.bs4-popover .my-3, .bs4-modal .mt-3,
.bs4-modal .my-3, .bs4 .mt-3,
.bs4 .my-3 {
  margin-top: 1.5rem !important;
}
.react-app .mr-3,
.react-app .mx-3, .multiple-react-app .mr-3,
.multiple-react-app .mx-3, .bs4-popover .mr-3,
.bs4-popover .mx-3, .bs4-modal .mr-3,
.bs4-modal .mx-3, .bs4 .mr-3,
.bs4 .mx-3 {
  margin-right: 1.5rem !important;
}
.react-app .mb-3,
.react-app .my-3, .multiple-react-app .mb-3,
.multiple-react-app .my-3, .bs4-popover .mb-3,
.bs4-popover .my-3, .bs4-modal .mb-3,
.bs4-modal .my-3, .bs4 .mb-3,
.bs4 .my-3 {
  margin-bottom: 1.5rem !important;
}
.react-app .ml-3,
.react-app .mx-3, .multiple-react-app .ml-3,
.multiple-react-app .mx-3, .bs4-popover .ml-3,
.bs4-popover .mx-3, .bs4-modal .ml-3,
.bs4-modal .mx-3, .bs4 .ml-3,
.bs4 .mx-3 {
  margin-left: 1.5rem !important;
}
.react-app .m-4, .multiple-react-app .m-4, .bs4-popover .m-4, .bs4-modal .m-4, .bs4 .m-4 {
  margin: 2rem !important;
}
.react-app .mt-4,
.react-app .my-4, .multiple-react-app .mt-4,
.multiple-react-app .my-4, .bs4-popover .mt-4,
.bs4-popover .my-4, .bs4-modal .mt-4,
.bs4-modal .my-4, .bs4 .mt-4,
.bs4 .my-4 {
  margin-top: 2rem !important;
}
.react-app .mr-4,
.react-app .mx-4, .multiple-react-app .mr-4,
.multiple-react-app .mx-4, .bs4-popover .mr-4,
.bs4-popover .mx-4, .bs4-modal .mr-4,
.bs4-modal .mx-4, .bs4 .mr-4,
.bs4 .mx-4 {
  margin-right: 2rem !important;
}
.react-app .mb-4,
.react-app .my-4, .multiple-react-app .mb-4,
.multiple-react-app .my-4, .bs4-popover .mb-4,
.bs4-popover .my-4, .bs4-modal .mb-4,
.bs4-modal .my-4, .bs4 .mb-4,
.bs4 .my-4 {
  margin-bottom: 2rem !important;
}
.react-app .ml-4,
.react-app .mx-4, .multiple-react-app .ml-4,
.multiple-react-app .mx-4, .bs4-popover .ml-4,
.bs4-popover .mx-4, .bs4-modal .ml-4,
.bs4-modal .mx-4, .bs4 .ml-4,
.bs4 .mx-4 {
  margin-left: 2rem !important;
}
.react-app .m-5, .multiple-react-app .m-5, .bs4-popover .m-5, .bs4-modal .m-5, .bs4 .m-5 {
  margin: 3rem !important;
}
.react-app .mt-5,
.react-app .my-5, .multiple-react-app .mt-5,
.multiple-react-app .my-5, .bs4-popover .mt-5,
.bs4-popover .my-5, .bs4-modal .mt-5,
.bs4-modal .my-5, .bs4 .mt-5,
.bs4 .my-5 {
  margin-top: 3rem !important;
}
.react-app .mr-5,
.react-app .mx-5, .multiple-react-app .mr-5,
.multiple-react-app .mx-5, .bs4-popover .mr-5,
.bs4-popover .mx-5, .bs4-modal .mr-5,
.bs4-modal .mx-5, .bs4 .mr-5,
.bs4 .mx-5 {
  margin-right: 3rem !important;
}
.react-app .mb-5,
.react-app .my-5, .multiple-react-app .mb-5,
.multiple-react-app .my-5, .bs4-popover .mb-5,
.bs4-popover .my-5, .bs4-modal .mb-5,
.bs4-modal .my-5, .bs4 .mb-5,
.bs4 .my-5 {
  margin-bottom: 3rem !important;
}
.react-app .ml-5,
.react-app .mx-5, .multiple-react-app .ml-5,
.multiple-react-app .mx-5, .bs4-popover .ml-5,
.bs4-popover .mx-5, .bs4-modal .ml-5,
.bs4-modal .mx-5, .bs4 .ml-5,
.bs4 .mx-5 {
  margin-left: 3rem !important;
}
.react-app .m-6, .multiple-react-app .m-6, .bs4-popover .m-6, .bs4-modal .m-6, .bs4 .m-6 {
  margin: 4rem !important;
}
.react-app .mt-6,
.react-app .my-6, .multiple-react-app .mt-6,
.multiple-react-app .my-6, .bs4-popover .mt-6,
.bs4-popover .my-6, .bs4-modal .mt-6,
.bs4-modal .my-6, .bs4 .mt-6,
.bs4 .my-6 {
  margin-top: 4rem !important;
}
.react-app .mr-6,
.react-app .mx-6, .multiple-react-app .mr-6,
.multiple-react-app .mx-6, .bs4-popover .mr-6,
.bs4-popover .mx-6, .bs4-modal .mr-6,
.bs4-modal .mx-6, .bs4 .mr-6,
.bs4 .mx-6 {
  margin-right: 4rem !important;
}
.react-app .mb-6,
.react-app .my-6, .multiple-react-app .mb-6,
.multiple-react-app .my-6, .bs4-popover .mb-6,
.bs4-popover .my-6, .bs4-modal .mb-6,
.bs4-modal .my-6, .bs4 .mb-6,
.bs4 .my-6 {
  margin-bottom: 4rem !important;
}
.react-app .ml-6,
.react-app .mx-6, .multiple-react-app .ml-6,
.multiple-react-app .mx-6, .bs4-popover .ml-6,
.bs4-popover .mx-6, .bs4-modal .ml-6,
.bs4-modal .mx-6, .bs4 .ml-6,
.bs4 .mx-6 {
  margin-left: 4rem !important;
}
.react-app .m-7, .multiple-react-app .m-7, .bs4-popover .m-7, .bs4-modal .m-7, .bs4 .m-7 {
  margin: 5rem !important;
}
.react-app .mt-7,
.react-app .my-7, .multiple-react-app .mt-7,
.multiple-react-app .my-7, .bs4-popover .mt-7,
.bs4-popover .my-7, .bs4-modal .mt-7,
.bs4-modal .my-7, .bs4 .mt-7,
.bs4 .my-7 {
  margin-top: 5rem !important;
}
.react-app .mr-7,
.react-app .mx-7, .multiple-react-app .mr-7,
.multiple-react-app .mx-7, .bs4-popover .mr-7,
.bs4-popover .mx-7, .bs4-modal .mr-7,
.bs4-modal .mx-7, .bs4 .mr-7,
.bs4 .mx-7 {
  margin-right: 5rem !important;
}
.react-app .mb-7,
.react-app .my-7, .multiple-react-app .mb-7,
.multiple-react-app .my-7, .bs4-popover .mb-7,
.bs4-popover .my-7, .bs4-modal .mb-7,
.bs4-modal .my-7, .bs4 .mb-7,
.bs4 .my-7 {
  margin-bottom: 5rem !important;
}
.react-app .ml-7,
.react-app .mx-7, .multiple-react-app .ml-7,
.multiple-react-app .mx-7, .bs4-popover .ml-7,
.bs4-popover .mx-7, .bs4-modal .ml-7,
.bs4-modal .mx-7, .bs4 .ml-7,
.bs4 .mx-7 {
  margin-left: 5rem !important;
}
.react-app .m-8, .multiple-react-app .m-8, .bs4-popover .m-8, .bs4-modal .m-8, .bs4 .m-8 {
  margin: 6rem !important;
}
.react-app .mt-8,
.react-app .my-8, .multiple-react-app .mt-8,
.multiple-react-app .my-8, .bs4-popover .mt-8,
.bs4-popover .my-8, .bs4-modal .mt-8,
.bs4-modal .my-8, .bs4 .mt-8,
.bs4 .my-8 {
  margin-top: 6rem !important;
}
.react-app .mr-8,
.react-app .mx-8, .multiple-react-app .mr-8,
.multiple-react-app .mx-8, .bs4-popover .mr-8,
.bs4-popover .mx-8, .bs4-modal .mr-8,
.bs4-modal .mx-8, .bs4 .mr-8,
.bs4 .mx-8 {
  margin-right: 6rem !important;
}
.react-app .mb-8,
.react-app .my-8, .multiple-react-app .mb-8,
.multiple-react-app .my-8, .bs4-popover .mb-8,
.bs4-popover .my-8, .bs4-modal .mb-8,
.bs4-modal .my-8, .bs4 .mb-8,
.bs4 .my-8 {
  margin-bottom: 6rem !important;
}
.react-app .ml-8,
.react-app .mx-8, .multiple-react-app .ml-8,
.multiple-react-app .mx-8, .bs4-popover .ml-8,
.bs4-popover .mx-8, .bs4-modal .ml-8,
.bs4-modal .mx-8, .bs4 .ml-8,
.bs4 .mx-8 {
  margin-left: 6rem !important;
}
.react-app .p-0, .multiple-react-app .p-0, .bs4-popover .p-0, .bs4-modal .p-0, .bs4 .p-0 {
  padding: 0 !important;
}
.react-app .pt-0,
.react-app .py-0, .multiple-react-app .pt-0,
.multiple-react-app .py-0, .bs4-popover .pt-0,
.bs4-popover .py-0, .bs4-modal .pt-0,
.bs4-modal .py-0, .bs4 .pt-0,
.bs4 .py-0 {
  padding-top: 0 !important;
}
.react-app .pr-0,
.react-app .px-0, .multiple-react-app .pr-0,
.multiple-react-app .px-0, .bs4-popover .pr-0,
.bs4-popover .px-0, .bs4-modal .pr-0,
.bs4-modal .px-0, .bs4 .pr-0,
.bs4 .px-0 {
  padding-right: 0 !important;
}
.react-app .pb-0,
.react-app .py-0, .multiple-react-app .pb-0,
.multiple-react-app .py-0, .bs4-popover .pb-0,
.bs4-popover .py-0, .bs4-modal .pb-0,
.bs4-modal .py-0, .bs4 .pb-0,
.bs4 .py-0 {
  padding-bottom: 0 !important;
}
.react-app .pl-0,
.react-app .px-0, .multiple-react-app .pl-0,
.multiple-react-app .px-0, .bs4-popover .pl-0,
.bs4-popover .px-0, .bs4-modal .pl-0,
.bs4-modal .px-0, .bs4 .pl-0,
.bs4 .px-0 {
  padding-left: 0 !important;
}
.react-app .p-1, .multiple-react-app .p-1, .bs4-popover .p-1, .bs4-modal .p-1, .bs4 .p-1 {
  padding: 0.5rem !important;
}
.react-app .pt-1,
.react-app .py-1, .multiple-react-app .pt-1,
.multiple-react-app .py-1, .bs4-popover .pt-1,
.bs4-popover .py-1, .bs4-modal .pt-1,
.bs4-modal .py-1, .bs4 .pt-1,
.bs4 .py-1 {
  padding-top: 0.5rem !important;
}
.react-app .pr-1,
.react-app .px-1, .multiple-react-app .pr-1,
.multiple-react-app .px-1, .bs4-popover .pr-1,
.bs4-popover .px-1, .bs4-modal .pr-1,
.bs4-modal .px-1, .bs4 .pr-1,
.bs4 .px-1 {
  padding-right: 0.5rem !important;
}
.react-app .pb-1,
.react-app .py-1, .multiple-react-app .pb-1,
.multiple-react-app .py-1, .bs4-popover .pb-1,
.bs4-popover .py-1, .bs4-modal .pb-1,
.bs4-modal .py-1, .bs4 .pb-1,
.bs4 .py-1 {
  padding-bottom: 0.5rem !important;
}
.react-app .pl-1,
.react-app .px-1, .multiple-react-app .pl-1,
.multiple-react-app .px-1, .bs4-popover .pl-1,
.bs4-popover .px-1, .bs4-modal .pl-1,
.bs4-modal .px-1, .bs4 .pl-1,
.bs4 .px-1 {
  padding-left: 0.5rem !important;
}
.react-app .p-2, .multiple-react-app .p-2, .bs4-popover .p-2, .bs4-modal .p-2, .bs4 .p-2 {
  padding: 1rem !important;
}
.react-app .pt-2,
.react-app .py-2, .multiple-react-app .pt-2,
.multiple-react-app .py-2, .bs4-popover .pt-2,
.bs4-popover .py-2, .bs4-modal .pt-2,
.bs4-modal .py-2, .bs4 .pt-2,
.bs4 .py-2 {
  padding-top: 1rem !important;
}
.react-app .pr-2,
.react-app .px-2, .multiple-react-app .pr-2,
.multiple-react-app .px-2, .bs4-popover .pr-2,
.bs4-popover .px-2, .bs4-modal .pr-2,
.bs4-modal .px-2, .bs4 .pr-2,
.bs4 .px-2 {
  padding-right: 1rem !important;
}
.react-app .pb-2,
.react-app .py-2, .multiple-react-app .pb-2,
.multiple-react-app .py-2, .bs4-popover .pb-2,
.bs4-popover .py-2, .bs4-modal .pb-2,
.bs4-modal .py-2, .bs4 .pb-2,
.bs4 .py-2 {
  padding-bottom: 1rem !important;
}
.react-app .pl-2,
.react-app .px-2, .multiple-react-app .pl-2,
.multiple-react-app .px-2, .bs4-popover .pl-2,
.bs4-popover .px-2, .bs4-modal .pl-2,
.bs4-modal .px-2, .bs4 .pl-2,
.bs4 .px-2 {
  padding-left: 1rem !important;
}
.react-app .p-3, .multiple-react-app .p-3, .bs4-popover .p-3, .bs4-modal .p-3, .bs4 .p-3 {
  padding: 1.5rem !important;
}
.react-app .pt-3,
.react-app .py-3, .multiple-react-app .pt-3,
.multiple-react-app .py-3, .bs4-popover .pt-3,
.bs4-popover .py-3, .bs4-modal .pt-3,
.bs4-modal .py-3, .bs4 .pt-3,
.bs4 .py-3 {
  padding-top: 1.5rem !important;
}
.react-app .pr-3,
.react-app .px-3, .multiple-react-app .pr-3,
.multiple-react-app .px-3, .bs4-popover .pr-3,
.bs4-popover .px-3, .bs4-modal .pr-3,
.bs4-modal .px-3, .bs4 .pr-3,
.bs4 .px-3 {
  padding-right: 1.5rem !important;
}
.react-app .pb-3,
.react-app .py-3, .multiple-react-app .pb-3,
.multiple-react-app .py-3, .bs4-popover .pb-3,
.bs4-popover .py-3, .bs4-modal .pb-3,
.bs4-modal .py-3, .bs4 .pb-3,
.bs4 .py-3 {
  padding-bottom: 1.5rem !important;
}
.react-app .pl-3,
.react-app .px-3, .multiple-react-app .pl-3,
.multiple-react-app .px-3, .bs4-popover .pl-3,
.bs4-popover .px-3, .bs4-modal .pl-3,
.bs4-modal .px-3, .bs4 .pl-3,
.bs4 .px-3 {
  padding-left: 1.5rem !important;
}
.react-app .p-4, .multiple-react-app .p-4, .bs4-popover .p-4, .bs4-modal .p-4, .bs4 .p-4 {
  padding: 2rem !important;
}
.react-app .pt-4,
.react-app .py-4, .multiple-react-app .pt-4,
.multiple-react-app .py-4, .bs4-popover .pt-4,
.bs4-popover .py-4, .bs4-modal .pt-4,
.bs4-modal .py-4, .bs4 .pt-4,
.bs4 .py-4 {
  padding-top: 2rem !important;
}
.react-app .pr-4,
.react-app .px-4, .multiple-react-app .pr-4,
.multiple-react-app .px-4, .bs4-popover .pr-4,
.bs4-popover .px-4, .bs4-modal .pr-4,
.bs4-modal .px-4, .bs4 .pr-4,
.bs4 .px-4 {
  padding-right: 2rem !important;
}
.react-app .pb-4,
.react-app .py-4, .multiple-react-app .pb-4,
.multiple-react-app .py-4, .bs4-popover .pb-4,
.bs4-popover .py-4, .bs4-modal .pb-4,
.bs4-modal .py-4, .bs4 .pb-4,
.bs4 .py-4 {
  padding-bottom: 2rem !important;
}
.react-app .pl-4,
.react-app .px-4, .multiple-react-app .pl-4,
.multiple-react-app .px-4, .bs4-popover .pl-4,
.bs4-popover .px-4, .bs4-modal .pl-4,
.bs4-modal .px-4, .bs4 .pl-4,
.bs4 .px-4 {
  padding-left: 2rem !important;
}
.react-app .p-5, .multiple-react-app .p-5, .bs4-popover .p-5, .bs4-modal .p-5, .bs4 .p-5 {
  padding: 3rem !important;
}
.react-app .pt-5,
.react-app .py-5, .multiple-react-app .pt-5,
.multiple-react-app .py-5, .bs4-popover .pt-5,
.bs4-popover .py-5, .bs4-modal .pt-5,
.bs4-modal .py-5, .bs4 .pt-5,
.bs4 .py-5 {
  padding-top: 3rem !important;
}
.react-app .pr-5,
.react-app .px-5, .multiple-react-app .pr-5,
.multiple-react-app .px-5, .bs4-popover .pr-5,
.bs4-popover .px-5, .bs4-modal .pr-5,
.bs4-modal .px-5, .bs4 .pr-5,
.bs4 .px-5 {
  padding-right: 3rem !important;
}
.react-app .pb-5,
.react-app .py-5, .multiple-react-app .pb-5,
.multiple-react-app .py-5, .bs4-popover .pb-5,
.bs4-popover .py-5, .bs4-modal .pb-5,
.bs4-modal .py-5, .bs4 .pb-5,
.bs4 .py-5 {
  padding-bottom: 3rem !important;
}
.react-app .pl-5,
.react-app .px-5, .multiple-react-app .pl-5,
.multiple-react-app .px-5, .bs4-popover .pl-5,
.bs4-popover .px-5, .bs4-modal .pl-5,
.bs4-modal .px-5, .bs4 .pl-5,
.bs4 .px-5 {
  padding-left: 3rem !important;
}
.react-app .p-6, .multiple-react-app .p-6, .bs4-popover .p-6, .bs4-modal .p-6, .bs4 .p-6 {
  padding: 4rem !important;
}
.react-app .pt-6,
.react-app .py-6, .multiple-react-app .pt-6,
.multiple-react-app .py-6, .bs4-popover .pt-6,
.bs4-popover .py-6, .bs4-modal .pt-6,
.bs4-modal .py-6, .bs4 .pt-6,
.bs4 .py-6 {
  padding-top: 4rem !important;
}
.react-app .pr-6,
.react-app .px-6, .multiple-react-app .pr-6,
.multiple-react-app .px-6, .bs4-popover .pr-6,
.bs4-popover .px-6, .bs4-modal .pr-6,
.bs4-modal .px-6, .bs4 .pr-6,
.bs4 .px-6 {
  padding-right: 4rem !important;
}
.react-app .pb-6,
.react-app .py-6, .multiple-react-app .pb-6,
.multiple-react-app .py-6, .bs4-popover .pb-6,
.bs4-popover .py-6, .bs4-modal .pb-6,
.bs4-modal .py-6, .bs4 .pb-6,
.bs4 .py-6 {
  padding-bottom: 4rem !important;
}
.react-app .pl-6,
.react-app .px-6, .multiple-react-app .pl-6,
.multiple-react-app .px-6, .bs4-popover .pl-6,
.bs4-popover .px-6, .bs4-modal .pl-6,
.bs4-modal .px-6, .bs4 .pl-6,
.bs4 .px-6 {
  padding-left: 4rem !important;
}
.react-app .p-7, .multiple-react-app .p-7, .bs4-popover .p-7, .bs4-modal .p-7, .bs4 .p-7 {
  padding: 5rem !important;
}
.react-app .pt-7,
.react-app .py-7, .multiple-react-app .pt-7,
.multiple-react-app .py-7, .bs4-popover .pt-7,
.bs4-popover .py-7, .bs4-modal .pt-7,
.bs4-modal .py-7, .bs4 .pt-7,
.bs4 .py-7 {
  padding-top: 5rem !important;
}
.react-app .pr-7,
.react-app .px-7, .multiple-react-app .pr-7,
.multiple-react-app .px-7, .bs4-popover .pr-7,
.bs4-popover .px-7, .bs4-modal .pr-7,
.bs4-modal .px-7, .bs4 .pr-7,
.bs4 .px-7 {
  padding-right: 5rem !important;
}
.react-app .pb-7,
.react-app .py-7, .multiple-react-app .pb-7,
.multiple-react-app .py-7, .bs4-popover .pb-7,
.bs4-popover .py-7, .bs4-modal .pb-7,
.bs4-modal .py-7, .bs4 .pb-7,
.bs4 .py-7 {
  padding-bottom: 5rem !important;
}
.react-app .pl-7,
.react-app .px-7, .multiple-react-app .pl-7,
.multiple-react-app .px-7, .bs4-popover .pl-7,
.bs4-popover .px-7, .bs4-modal .pl-7,
.bs4-modal .px-7, .bs4 .pl-7,
.bs4 .px-7 {
  padding-left: 5rem !important;
}
.react-app .p-8, .multiple-react-app .p-8, .bs4-popover .p-8, .bs4-modal .p-8, .bs4 .p-8 {
  padding: 6rem !important;
}
.react-app .pt-8,
.react-app .py-8, .multiple-react-app .pt-8,
.multiple-react-app .py-8, .bs4-popover .pt-8,
.bs4-popover .py-8, .bs4-modal .pt-8,
.bs4-modal .py-8, .bs4 .pt-8,
.bs4 .py-8 {
  padding-top: 6rem !important;
}
.react-app .pr-8,
.react-app .px-8, .multiple-react-app .pr-8,
.multiple-react-app .px-8, .bs4-popover .pr-8,
.bs4-popover .px-8, .bs4-modal .pr-8,
.bs4-modal .px-8, .bs4 .pr-8,
.bs4 .px-8 {
  padding-right: 6rem !important;
}
.react-app .pb-8,
.react-app .py-8, .multiple-react-app .pb-8,
.multiple-react-app .py-8, .bs4-popover .pb-8,
.bs4-popover .py-8, .bs4-modal .pb-8,
.bs4-modal .py-8, .bs4 .pb-8,
.bs4 .py-8 {
  padding-bottom: 6rem !important;
}
.react-app .pl-8,
.react-app .px-8, .multiple-react-app .pl-8,
.multiple-react-app .px-8, .bs4-popover .pl-8,
.bs4-popover .px-8, .bs4-modal .pl-8,
.bs4-modal .px-8, .bs4 .pl-8,
.bs4 .px-8 {
  padding-left: 6rem !important;
}
.react-app .m-n1, .multiple-react-app .m-n1, .bs4-popover .m-n1, .bs4-modal .m-n1, .bs4 .m-n1 {
  margin: -0.5rem !important;
}
.react-app .mt-n1,
.react-app .my-n1, .multiple-react-app .mt-n1,
.multiple-react-app .my-n1, .bs4-popover .mt-n1,
.bs4-popover .my-n1, .bs4-modal .mt-n1,
.bs4-modal .my-n1, .bs4 .mt-n1,
.bs4 .my-n1 {
  margin-top: -0.5rem !important;
}
.react-app .mr-n1,
.react-app .mx-n1, .multiple-react-app .mr-n1,
.multiple-react-app .mx-n1, .bs4-popover .mr-n1,
.bs4-popover .mx-n1, .bs4-modal .mr-n1,
.bs4-modal .mx-n1, .bs4 .mr-n1,
.bs4 .mx-n1 {
  margin-right: -0.5rem !important;
}
.react-app .mb-n1,
.react-app .my-n1, .multiple-react-app .mb-n1,
.multiple-react-app .my-n1, .bs4-popover .mb-n1,
.bs4-popover .my-n1, .bs4-modal .mb-n1,
.bs4-modal .my-n1, .bs4 .mb-n1,
.bs4 .my-n1 {
  margin-bottom: -0.5rem !important;
}
.react-app .ml-n1,
.react-app .mx-n1, .multiple-react-app .ml-n1,
.multiple-react-app .mx-n1, .bs4-popover .ml-n1,
.bs4-popover .mx-n1, .bs4-modal .ml-n1,
.bs4-modal .mx-n1, .bs4 .ml-n1,
.bs4 .mx-n1 {
  margin-left: -0.5rem !important;
}
.react-app .m-n2, .multiple-react-app .m-n2, .bs4-popover .m-n2, .bs4-modal .m-n2, .bs4 .m-n2 {
  margin: -1rem !important;
}
.react-app .mt-n2,
.react-app .my-n2, .multiple-react-app .mt-n2,
.multiple-react-app .my-n2, .bs4-popover .mt-n2,
.bs4-popover .my-n2, .bs4-modal .mt-n2,
.bs4-modal .my-n2, .bs4 .mt-n2,
.bs4 .my-n2 {
  margin-top: -1rem !important;
}
.react-app .mr-n2,
.react-app .mx-n2, .multiple-react-app .mr-n2,
.multiple-react-app .mx-n2, .bs4-popover .mr-n2,
.bs4-popover .mx-n2, .bs4-modal .mr-n2,
.bs4-modal .mx-n2, .bs4 .mr-n2,
.bs4 .mx-n2 {
  margin-right: -1rem !important;
}
.react-app .mb-n2,
.react-app .my-n2, .multiple-react-app .mb-n2,
.multiple-react-app .my-n2, .bs4-popover .mb-n2,
.bs4-popover .my-n2, .bs4-modal .mb-n2,
.bs4-modal .my-n2, .bs4 .mb-n2,
.bs4 .my-n2 {
  margin-bottom: -1rem !important;
}
.react-app .ml-n2,
.react-app .mx-n2, .multiple-react-app .ml-n2,
.multiple-react-app .mx-n2, .bs4-popover .ml-n2,
.bs4-popover .mx-n2, .bs4-modal .ml-n2,
.bs4-modal .mx-n2, .bs4 .ml-n2,
.bs4 .mx-n2 {
  margin-left: -1rem !important;
}
.react-app .m-n3, .multiple-react-app .m-n3, .bs4-popover .m-n3, .bs4-modal .m-n3, .bs4 .m-n3 {
  margin: -1.5rem !important;
}
.react-app .mt-n3,
.react-app .my-n3, .multiple-react-app .mt-n3,
.multiple-react-app .my-n3, .bs4-popover .mt-n3,
.bs4-popover .my-n3, .bs4-modal .mt-n3,
.bs4-modal .my-n3, .bs4 .mt-n3,
.bs4 .my-n3 {
  margin-top: -1.5rem !important;
}
.react-app .mr-n3,
.react-app .mx-n3, .multiple-react-app .mr-n3,
.multiple-react-app .mx-n3, .bs4-popover .mr-n3,
.bs4-popover .mx-n3, .bs4-modal .mr-n3,
.bs4-modal .mx-n3, .bs4 .mr-n3,
.bs4 .mx-n3 {
  margin-right: -1.5rem !important;
}
.react-app .mb-n3,
.react-app .my-n3, .multiple-react-app .mb-n3,
.multiple-react-app .my-n3, .bs4-popover .mb-n3,
.bs4-popover .my-n3, .bs4-modal .mb-n3,
.bs4-modal .my-n3, .bs4 .mb-n3,
.bs4 .my-n3 {
  margin-bottom: -1.5rem !important;
}
.react-app .ml-n3,
.react-app .mx-n3, .multiple-react-app .ml-n3,
.multiple-react-app .mx-n3, .bs4-popover .ml-n3,
.bs4-popover .mx-n3, .bs4-modal .ml-n3,
.bs4-modal .mx-n3, .bs4 .ml-n3,
.bs4 .mx-n3 {
  margin-left: -1.5rem !important;
}
.react-app .m-n4, .multiple-react-app .m-n4, .bs4-popover .m-n4, .bs4-modal .m-n4, .bs4 .m-n4 {
  margin: -2rem !important;
}
.react-app .mt-n4,
.react-app .my-n4, .multiple-react-app .mt-n4,
.multiple-react-app .my-n4, .bs4-popover .mt-n4,
.bs4-popover .my-n4, .bs4-modal .mt-n4,
.bs4-modal .my-n4, .bs4 .mt-n4,
.bs4 .my-n4 {
  margin-top: -2rem !important;
}
.react-app .mr-n4,
.react-app .mx-n4, .multiple-react-app .mr-n4,
.multiple-react-app .mx-n4, .bs4-popover .mr-n4,
.bs4-popover .mx-n4, .bs4-modal .mr-n4,
.bs4-modal .mx-n4, .bs4 .mr-n4,
.bs4 .mx-n4 {
  margin-right: -2rem !important;
}
.react-app .mb-n4,
.react-app .my-n4, .multiple-react-app .mb-n4,
.multiple-react-app .my-n4, .bs4-popover .mb-n4,
.bs4-popover .my-n4, .bs4-modal .mb-n4,
.bs4-modal .my-n4, .bs4 .mb-n4,
.bs4 .my-n4 {
  margin-bottom: -2rem !important;
}
.react-app .ml-n4,
.react-app .mx-n4, .multiple-react-app .ml-n4,
.multiple-react-app .mx-n4, .bs4-popover .ml-n4,
.bs4-popover .mx-n4, .bs4-modal .ml-n4,
.bs4-modal .mx-n4, .bs4 .ml-n4,
.bs4 .mx-n4 {
  margin-left: -2rem !important;
}
.react-app .m-n5, .multiple-react-app .m-n5, .bs4-popover .m-n5, .bs4-modal .m-n5, .bs4 .m-n5 {
  margin: -3rem !important;
}
.react-app .mt-n5,
.react-app .my-n5, .multiple-react-app .mt-n5,
.multiple-react-app .my-n5, .bs4-popover .mt-n5,
.bs4-popover .my-n5, .bs4-modal .mt-n5,
.bs4-modal .my-n5, .bs4 .mt-n5,
.bs4 .my-n5 {
  margin-top: -3rem !important;
}
.react-app .mr-n5,
.react-app .mx-n5, .multiple-react-app .mr-n5,
.multiple-react-app .mx-n5, .bs4-popover .mr-n5,
.bs4-popover .mx-n5, .bs4-modal .mr-n5,
.bs4-modal .mx-n5, .bs4 .mr-n5,
.bs4 .mx-n5 {
  margin-right: -3rem !important;
}
.react-app .mb-n5,
.react-app .my-n5, .multiple-react-app .mb-n5,
.multiple-react-app .my-n5, .bs4-popover .mb-n5,
.bs4-popover .my-n5, .bs4-modal .mb-n5,
.bs4-modal .my-n5, .bs4 .mb-n5,
.bs4 .my-n5 {
  margin-bottom: -3rem !important;
}
.react-app .ml-n5,
.react-app .mx-n5, .multiple-react-app .ml-n5,
.multiple-react-app .mx-n5, .bs4-popover .ml-n5,
.bs4-popover .mx-n5, .bs4-modal .ml-n5,
.bs4-modal .mx-n5, .bs4 .ml-n5,
.bs4 .mx-n5 {
  margin-left: -3rem !important;
}
.react-app .m-n6, .multiple-react-app .m-n6, .bs4-popover .m-n6, .bs4-modal .m-n6, .bs4 .m-n6 {
  margin: -4rem !important;
}
.react-app .mt-n6,
.react-app .my-n6, .multiple-react-app .mt-n6,
.multiple-react-app .my-n6, .bs4-popover .mt-n6,
.bs4-popover .my-n6, .bs4-modal .mt-n6,
.bs4-modal .my-n6, .bs4 .mt-n6,
.bs4 .my-n6 {
  margin-top: -4rem !important;
}
.react-app .mr-n6,
.react-app .mx-n6, .multiple-react-app .mr-n6,
.multiple-react-app .mx-n6, .bs4-popover .mr-n6,
.bs4-popover .mx-n6, .bs4-modal .mr-n6,
.bs4-modal .mx-n6, .bs4 .mr-n6,
.bs4 .mx-n6 {
  margin-right: -4rem !important;
}
.react-app .mb-n6,
.react-app .my-n6, .multiple-react-app .mb-n6,
.multiple-react-app .my-n6, .bs4-popover .mb-n6,
.bs4-popover .my-n6, .bs4-modal .mb-n6,
.bs4-modal .my-n6, .bs4 .mb-n6,
.bs4 .my-n6 {
  margin-bottom: -4rem !important;
}
.react-app .ml-n6,
.react-app .mx-n6, .multiple-react-app .ml-n6,
.multiple-react-app .mx-n6, .bs4-popover .ml-n6,
.bs4-popover .mx-n6, .bs4-modal .ml-n6,
.bs4-modal .mx-n6, .bs4 .ml-n6,
.bs4 .mx-n6 {
  margin-left: -4rem !important;
}
.react-app .m-n7, .multiple-react-app .m-n7, .bs4-popover .m-n7, .bs4-modal .m-n7, .bs4 .m-n7 {
  margin: -5rem !important;
}
.react-app .mt-n7,
.react-app .my-n7, .multiple-react-app .mt-n7,
.multiple-react-app .my-n7, .bs4-popover .mt-n7,
.bs4-popover .my-n7, .bs4-modal .mt-n7,
.bs4-modal .my-n7, .bs4 .mt-n7,
.bs4 .my-n7 {
  margin-top: -5rem !important;
}
.react-app .mr-n7,
.react-app .mx-n7, .multiple-react-app .mr-n7,
.multiple-react-app .mx-n7, .bs4-popover .mr-n7,
.bs4-popover .mx-n7, .bs4-modal .mr-n7,
.bs4-modal .mx-n7, .bs4 .mr-n7,
.bs4 .mx-n7 {
  margin-right: -5rem !important;
}
.react-app .mb-n7,
.react-app .my-n7, .multiple-react-app .mb-n7,
.multiple-react-app .my-n7, .bs4-popover .mb-n7,
.bs4-popover .my-n7, .bs4-modal .mb-n7,
.bs4-modal .my-n7, .bs4 .mb-n7,
.bs4 .my-n7 {
  margin-bottom: -5rem !important;
}
.react-app .ml-n7,
.react-app .mx-n7, .multiple-react-app .ml-n7,
.multiple-react-app .mx-n7, .bs4-popover .ml-n7,
.bs4-popover .mx-n7, .bs4-modal .ml-n7,
.bs4-modal .mx-n7, .bs4 .ml-n7,
.bs4 .mx-n7 {
  margin-left: -5rem !important;
}
.react-app .m-n8, .multiple-react-app .m-n8, .bs4-popover .m-n8, .bs4-modal .m-n8, .bs4 .m-n8 {
  margin: -6rem !important;
}
.react-app .mt-n8,
.react-app .my-n8, .multiple-react-app .mt-n8,
.multiple-react-app .my-n8, .bs4-popover .mt-n8,
.bs4-popover .my-n8, .bs4-modal .mt-n8,
.bs4-modal .my-n8, .bs4 .mt-n8,
.bs4 .my-n8 {
  margin-top: -6rem !important;
}
.react-app .mr-n8,
.react-app .mx-n8, .multiple-react-app .mr-n8,
.multiple-react-app .mx-n8, .bs4-popover .mr-n8,
.bs4-popover .mx-n8, .bs4-modal .mr-n8,
.bs4-modal .mx-n8, .bs4 .mr-n8,
.bs4 .mx-n8 {
  margin-right: -6rem !important;
}
.react-app .mb-n8,
.react-app .my-n8, .multiple-react-app .mb-n8,
.multiple-react-app .my-n8, .bs4-popover .mb-n8,
.bs4-popover .my-n8, .bs4-modal .mb-n8,
.bs4-modal .my-n8, .bs4 .mb-n8,
.bs4 .my-n8 {
  margin-bottom: -6rem !important;
}
.react-app .ml-n8,
.react-app .mx-n8, .multiple-react-app .ml-n8,
.multiple-react-app .mx-n8, .bs4-popover .ml-n8,
.bs4-popover .mx-n8, .bs4-modal .ml-n8,
.bs4-modal .mx-n8, .bs4 .ml-n8,
.bs4 .mx-n8 {
  margin-left: -6rem !important;
}
.react-app .m-auto, .multiple-react-app .m-auto, .bs4-popover .m-auto, .bs4-modal .m-auto, .bs4 .m-auto {
  margin: auto !important;
}
.react-app .mt-auto,
.react-app .my-auto, .multiple-react-app .mt-auto,
.multiple-react-app .my-auto, .bs4-popover .mt-auto,
.bs4-popover .my-auto, .bs4-modal .mt-auto,
.bs4-modal .my-auto, .bs4 .mt-auto,
.bs4 .my-auto {
  margin-top: auto !important;
}
.react-app .mr-auto,
.react-app .mx-auto, .multiple-react-app .mr-auto,
.multiple-react-app .mx-auto, .bs4-popover .mr-auto,
.bs4-popover .mx-auto, .bs4-modal .mr-auto,
.bs4-modal .mx-auto, .bs4 .mr-auto,
.bs4 .mx-auto {
  margin-right: auto !important;
}
.react-app .mb-auto,
.react-app .my-auto, .multiple-react-app .mb-auto,
.multiple-react-app .my-auto, .bs4-popover .mb-auto,
.bs4-popover .my-auto, .bs4-modal .mb-auto,
.bs4-modal .my-auto, .bs4 .mb-auto,
.bs4 .my-auto {
  margin-bottom: auto !important;
}
.react-app .ml-auto,
.react-app .mx-auto, .multiple-react-app .ml-auto,
.multiple-react-app .mx-auto, .bs4-popover .ml-auto,
.bs4-popover .mx-auto, .bs4-modal .ml-auto,
.bs4-modal .mx-auto, .bs4 .ml-auto,
.bs4 .mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .react-app .m-sm-0, .multiple-react-app .m-sm-0, .bs4-popover .m-sm-0, .bs4-modal .m-sm-0, .bs4 .m-sm-0 {
    margin: 0 !important;
  }
  .react-app .mt-sm-0,
  .react-app .my-sm-0, .multiple-react-app .mt-sm-0,
  .multiple-react-app .my-sm-0, .bs4-popover .mt-sm-0,
  .bs4-popover .my-sm-0, .bs4-modal .mt-sm-0,
  .bs4-modal .my-sm-0, .bs4 .mt-sm-0,
  .bs4 .my-sm-0 {
    margin-top: 0 !important;
  }
  .react-app .mr-sm-0,
  .react-app .mx-sm-0, .multiple-react-app .mr-sm-0,
  .multiple-react-app .mx-sm-0, .bs4-popover .mr-sm-0,
  .bs4-popover .mx-sm-0, .bs4-modal .mr-sm-0,
  .bs4-modal .mx-sm-0, .bs4 .mr-sm-0,
  .bs4 .mx-sm-0 {
    margin-right: 0 !important;
  }
  .react-app .mb-sm-0,
  .react-app .my-sm-0, .multiple-react-app .mb-sm-0,
  .multiple-react-app .my-sm-0, .bs4-popover .mb-sm-0,
  .bs4-popover .my-sm-0, .bs4-modal .mb-sm-0,
  .bs4-modal .my-sm-0, .bs4 .mb-sm-0,
  .bs4 .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .react-app .ml-sm-0,
  .react-app .mx-sm-0, .multiple-react-app .ml-sm-0,
  .multiple-react-app .mx-sm-0, .bs4-popover .ml-sm-0,
  .bs4-popover .mx-sm-0, .bs4-modal .ml-sm-0,
  .bs4-modal .mx-sm-0, .bs4 .ml-sm-0,
  .bs4 .mx-sm-0 {
    margin-left: 0 !important;
  }
  .react-app .m-sm-1, .multiple-react-app .m-sm-1, .bs4-popover .m-sm-1, .bs4-modal .m-sm-1, .bs4 .m-sm-1 {
    margin: 0.5rem !important;
  }
  .react-app .mt-sm-1,
  .react-app .my-sm-1, .multiple-react-app .mt-sm-1,
  .multiple-react-app .my-sm-1, .bs4-popover .mt-sm-1,
  .bs4-popover .my-sm-1, .bs4-modal .mt-sm-1,
  .bs4-modal .my-sm-1, .bs4 .mt-sm-1,
  .bs4 .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .react-app .mr-sm-1,
  .react-app .mx-sm-1, .multiple-react-app .mr-sm-1,
  .multiple-react-app .mx-sm-1, .bs4-popover .mr-sm-1,
  .bs4-popover .mx-sm-1, .bs4-modal .mr-sm-1,
  .bs4-modal .mx-sm-1, .bs4 .mr-sm-1,
  .bs4 .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .react-app .mb-sm-1,
  .react-app .my-sm-1, .multiple-react-app .mb-sm-1,
  .multiple-react-app .my-sm-1, .bs4-popover .mb-sm-1,
  .bs4-popover .my-sm-1, .bs4-modal .mb-sm-1,
  .bs4-modal .my-sm-1, .bs4 .mb-sm-1,
  .bs4 .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .react-app .ml-sm-1,
  .react-app .mx-sm-1, .multiple-react-app .ml-sm-1,
  .multiple-react-app .mx-sm-1, .bs4-popover .ml-sm-1,
  .bs4-popover .mx-sm-1, .bs4-modal .ml-sm-1,
  .bs4-modal .mx-sm-1, .bs4 .ml-sm-1,
  .bs4 .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .react-app .m-sm-2, .multiple-react-app .m-sm-2, .bs4-popover .m-sm-2, .bs4-modal .m-sm-2, .bs4 .m-sm-2 {
    margin: 1rem !important;
  }
  .react-app .mt-sm-2,
  .react-app .my-sm-2, .multiple-react-app .mt-sm-2,
  .multiple-react-app .my-sm-2, .bs4-popover .mt-sm-2,
  .bs4-popover .my-sm-2, .bs4-modal .mt-sm-2,
  .bs4-modal .my-sm-2, .bs4 .mt-sm-2,
  .bs4 .my-sm-2 {
    margin-top: 1rem !important;
  }
  .react-app .mr-sm-2,
  .react-app .mx-sm-2, .multiple-react-app .mr-sm-2,
  .multiple-react-app .mx-sm-2, .bs4-popover .mr-sm-2,
  .bs4-popover .mx-sm-2, .bs4-modal .mr-sm-2,
  .bs4-modal .mx-sm-2, .bs4 .mr-sm-2,
  .bs4 .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .react-app .mb-sm-2,
  .react-app .my-sm-2, .multiple-react-app .mb-sm-2,
  .multiple-react-app .my-sm-2, .bs4-popover .mb-sm-2,
  .bs4-popover .my-sm-2, .bs4-modal .mb-sm-2,
  .bs4-modal .my-sm-2, .bs4 .mb-sm-2,
  .bs4 .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .react-app .ml-sm-2,
  .react-app .mx-sm-2, .multiple-react-app .ml-sm-2,
  .multiple-react-app .mx-sm-2, .bs4-popover .ml-sm-2,
  .bs4-popover .mx-sm-2, .bs4-modal .ml-sm-2,
  .bs4-modal .mx-sm-2, .bs4 .ml-sm-2,
  .bs4 .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .react-app .m-sm-3, .multiple-react-app .m-sm-3, .bs4-popover .m-sm-3, .bs4-modal .m-sm-3, .bs4 .m-sm-3 {
    margin: 1.5rem !important;
  }
  .react-app .mt-sm-3,
  .react-app .my-sm-3, .multiple-react-app .mt-sm-3,
  .multiple-react-app .my-sm-3, .bs4-popover .mt-sm-3,
  .bs4-popover .my-sm-3, .bs4-modal .mt-sm-3,
  .bs4-modal .my-sm-3, .bs4 .mt-sm-3,
  .bs4 .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .react-app .mr-sm-3,
  .react-app .mx-sm-3, .multiple-react-app .mr-sm-3,
  .multiple-react-app .mx-sm-3, .bs4-popover .mr-sm-3,
  .bs4-popover .mx-sm-3, .bs4-modal .mr-sm-3,
  .bs4-modal .mx-sm-3, .bs4 .mr-sm-3,
  .bs4 .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .react-app .mb-sm-3,
  .react-app .my-sm-3, .multiple-react-app .mb-sm-3,
  .multiple-react-app .my-sm-3, .bs4-popover .mb-sm-3,
  .bs4-popover .my-sm-3, .bs4-modal .mb-sm-3,
  .bs4-modal .my-sm-3, .bs4 .mb-sm-3,
  .bs4 .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .react-app .ml-sm-3,
  .react-app .mx-sm-3, .multiple-react-app .ml-sm-3,
  .multiple-react-app .mx-sm-3, .bs4-popover .ml-sm-3,
  .bs4-popover .mx-sm-3, .bs4-modal .ml-sm-3,
  .bs4-modal .mx-sm-3, .bs4 .ml-sm-3,
  .bs4 .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .react-app .m-sm-4, .multiple-react-app .m-sm-4, .bs4-popover .m-sm-4, .bs4-modal .m-sm-4, .bs4 .m-sm-4 {
    margin: 2rem !important;
  }
  .react-app .mt-sm-4,
  .react-app .my-sm-4, .multiple-react-app .mt-sm-4,
  .multiple-react-app .my-sm-4, .bs4-popover .mt-sm-4,
  .bs4-popover .my-sm-4, .bs4-modal .mt-sm-4,
  .bs4-modal .my-sm-4, .bs4 .mt-sm-4,
  .bs4 .my-sm-4 {
    margin-top: 2rem !important;
  }
  .react-app .mr-sm-4,
  .react-app .mx-sm-4, .multiple-react-app .mr-sm-4,
  .multiple-react-app .mx-sm-4, .bs4-popover .mr-sm-4,
  .bs4-popover .mx-sm-4, .bs4-modal .mr-sm-4,
  .bs4-modal .mx-sm-4, .bs4 .mr-sm-4,
  .bs4 .mx-sm-4 {
    margin-right: 2rem !important;
  }
  .react-app .mb-sm-4,
  .react-app .my-sm-4, .multiple-react-app .mb-sm-4,
  .multiple-react-app .my-sm-4, .bs4-popover .mb-sm-4,
  .bs4-popover .my-sm-4, .bs4-modal .mb-sm-4,
  .bs4-modal .my-sm-4, .bs4 .mb-sm-4,
  .bs4 .my-sm-4 {
    margin-bottom: 2rem !important;
  }
  .react-app .ml-sm-4,
  .react-app .mx-sm-4, .multiple-react-app .ml-sm-4,
  .multiple-react-app .mx-sm-4, .bs4-popover .ml-sm-4,
  .bs4-popover .mx-sm-4, .bs4-modal .ml-sm-4,
  .bs4-modal .mx-sm-4, .bs4 .ml-sm-4,
  .bs4 .mx-sm-4 {
    margin-left: 2rem !important;
  }
  .react-app .m-sm-5, .multiple-react-app .m-sm-5, .bs4-popover .m-sm-5, .bs4-modal .m-sm-5, .bs4 .m-sm-5 {
    margin: 3rem !important;
  }
  .react-app .mt-sm-5,
  .react-app .my-sm-5, .multiple-react-app .mt-sm-5,
  .multiple-react-app .my-sm-5, .bs4-popover .mt-sm-5,
  .bs4-popover .my-sm-5, .bs4-modal .mt-sm-5,
  .bs4-modal .my-sm-5, .bs4 .mt-sm-5,
  .bs4 .my-sm-5 {
    margin-top: 3rem !important;
  }
  .react-app .mr-sm-5,
  .react-app .mx-sm-5, .multiple-react-app .mr-sm-5,
  .multiple-react-app .mx-sm-5, .bs4-popover .mr-sm-5,
  .bs4-popover .mx-sm-5, .bs4-modal .mr-sm-5,
  .bs4-modal .mx-sm-5, .bs4 .mr-sm-5,
  .bs4 .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .react-app .mb-sm-5,
  .react-app .my-sm-5, .multiple-react-app .mb-sm-5,
  .multiple-react-app .my-sm-5, .bs4-popover .mb-sm-5,
  .bs4-popover .my-sm-5, .bs4-modal .mb-sm-5,
  .bs4-modal .my-sm-5, .bs4 .mb-sm-5,
  .bs4 .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .react-app .ml-sm-5,
  .react-app .mx-sm-5, .multiple-react-app .ml-sm-5,
  .multiple-react-app .mx-sm-5, .bs4-popover .ml-sm-5,
  .bs4-popover .mx-sm-5, .bs4-modal .ml-sm-5,
  .bs4-modal .mx-sm-5, .bs4 .ml-sm-5,
  .bs4 .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .react-app .m-sm-6, .multiple-react-app .m-sm-6, .bs4-popover .m-sm-6, .bs4-modal .m-sm-6, .bs4 .m-sm-6 {
    margin: 4rem !important;
  }
  .react-app .mt-sm-6,
  .react-app .my-sm-6, .multiple-react-app .mt-sm-6,
  .multiple-react-app .my-sm-6, .bs4-popover .mt-sm-6,
  .bs4-popover .my-sm-6, .bs4-modal .mt-sm-6,
  .bs4-modal .my-sm-6, .bs4 .mt-sm-6,
  .bs4 .my-sm-6 {
    margin-top: 4rem !important;
  }
  .react-app .mr-sm-6,
  .react-app .mx-sm-6, .multiple-react-app .mr-sm-6,
  .multiple-react-app .mx-sm-6, .bs4-popover .mr-sm-6,
  .bs4-popover .mx-sm-6, .bs4-modal .mr-sm-6,
  .bs4-modal .mx-sm-6, .bs4 .mr-sm-6,
  .bs4 .mx-sm-6 {
    margin-right: 4rem !important;
  }
  .react-app .mb-sm-6,
  .react-app .my-sm-6, .multiple-react-app .mb-sm-6,
  .multiple-react-app .my-sm-6, .bs4-popover .mb-sm-6,
  .bs4-popover .my-sm-6, .bs4-modal .mb-sm-6,
  .bs4-modal .my-sm-6, .bs4 .mb-sm-6,
  .bs4 .my-sm-6 {
    margin-bottom: 4rem !important;
  }
  .react-app .ml-sm-6,
  .react-app .mx-sm-6, .multiple-react-app .ml-sm-6,
  .multiple-react-app .mx-sm-6, .bs4-popover .ml-sm-6,
  .bs4-popover .mx-sm-6, .bs4-modal .ml-sm-6,
  .bs4-modal .mx-sm-6, .bs4 .ml-sm-6,
  .bs4 .mx-sm-6 {
    margin-left: 4rem !important;
  }
  .react-app .m-sm-7, .multiple-react-app .m-sm-7, .bs4-popover .m-sm-7, .bs4-modal .m-sm-7, .bs4 .m-sm-7 {
    margin: 5rem !important;
  }
  .react-app .mt-sm-7,
  .react-app .my-sm-7, .multiple-react-app .mt-sm-7,
  .multiple-react-app .my-sm-7, .bs4-popover .mt-sm-7,
  .bs4-popover .my-sm-7, .bs4-modal .mt-sm-7,
  .bs4-modal .my-sm-7, .bs4 .mt-sm-7,
  .bs4 .my-sm-7 {
    margin-top: 5rem !important;
  }
  .react-app .mr-sm-7,
  .react-app .mx-sm-7, .multiple-react-app .mr-sm-7,
  .multiple-react-app .mx-sm-7, .bs4-popover .mr-sm-7,
  .bs4-popover .mx-sm-7, .bs4-modal .mr-sm-7,
  .bs4-modal .mx-sm-7, .bs4 .mr-sm-7,
  .bs4 .mx-sm-7 {
    margin-right: 5rem !important;
  }
  .react-app .mb-sm-7,
  .react-app .my-sm-7, .multiple-react-app .mb-sm-7,
  .multiple-react-app .my-sm-7, .bs4-popover .mb-sm-7,
  .bs4-popover .my-sm-7, .bs4-modal .mb-sm-7,
  .bs4-modal .my-sm-7, .bs4 .mb-sm-7,
  .bs4 .my-sm-7 {
    margin-bottom: 5rem !important;
  }
  .react-app .ml-sm-7,
  .react-app .mx-sm-7, .multiple-react-app .ml-sm-7,
  .multiple-react-app .mx-sm-7, .bs4-popover .ml-sm-7,
  .bs4-popover .mx-sm-7, .bs4-modal .ml-sm-7,
  .bs4-modal .mx-sm-7, .bs4 .ml-sm-7,
  .bs4 .mx-sm-7 {
    margin-left: 5rem !important;
  }
  .react-app .m-sm-8, .multiple-react-app .m-sm-8, .bs4-popover .m-sm-8, .bs4-modal .m-sm-8, .bs4 .m-sm-8 {
    margin: 6rem !important;
  }
  .react-app .mt-sm-8,
  .react-app .my-sm-8, .multiple-react-app .mt-sm-8,
  .multiple-react-app .my-sm-8, .bs4-popover .mt-sm-8,
  .bs4-popover .my-sm-8, .bs4-modal .mt-sm-8,
  .bs4-modal .my-sm-8, .bs4 .mt-sm-8,
  .bs4 .my-sm-8 {
    margin-top: 6rem !important;
  }
  .react-app .mr-sm-8,
  .react-app .mx-sm-8, .multiple-react-app .mr-sm-8,
  .multiple-react-app .mx-sm-8, .bs4-popover .mr-sm-8,
  .bs4-popover .mx-sm-8, .bs4-modal .mr-sm-8,
  .bs4-modal .mx-sm-8, .bs4 .mr-sm-8,
  .bs4 .mx-sm-8 {
    margin-right: 6rem !important;
  }
  .react-app .mb-sm-8,
  .react-app .my-sm-8, .multiple-react-app .mb-sm-8,
  .multiple-react-app .my-sm-8, .bs4-popover .mb-sm-8,
  .bs4-popover .my-sm-8, .bs4-modal .mb-sm-8,
  .bs4-modal .my-sm-8, .bs4 .mb-sm-8,
  .bs4 .my-sm-8 {
    margin-bottom: 6rem !important;
  }
  .react-app .ml-sm-8,
  .react-app .mx-sm-8, .multiple-react-app .ml-sm-8,
  .multiple-react-app .mx-sm-8, .bs4-popover .ml-sm-8,
  .bs4-popover .mx-sm-8, .bs4-modal .ml-sm-8,
  .bs4-modal .mx-sm-8, .bs4 .ml-sm-8,
  .bs4 .mx-sm-8 {
    margin-left: 6rem !important;
  }
  .react-app .p-sm-0, .multiple-react-app .p-sm-0, .bs4-popover .p-sm-0, .bs4-modal .p-sm-0, .bs4 .p-sm-0 {
    padding: 0 !important;
  }
  .react-app .pt-sm-0,
  .react-app .py-sm-0, .multiple-react-app .pt-sm-0,
  .multiple-react-app .py-sm-0, .bs4-popover .pt-sm-0,
  .bs4-popover .py-sm-0, .bs4-modal .pt-sm-0,
  .bs4-modal .py-sm-0, .bs4 .pt-sm-0,
  .bs4 .py-sm-0 {
    padding-top: 0 !important;
  }
  .react-app .pr-sm-0,
  .react-app .px-sm-0, .multiple-react-app .pr-sm-0,
  .multiple-react-app .px-sm-0, .bs4-popover .pr-sm-0,
  .bs4-popover .px-sm-0, .bs4-modal .pr-sm-0,
  .bs4-modal .px-sm-0, .bs4 .pr-sm-0,
  .bs4 .px-sm-0 {
    padding-right: 0 !important;
  }
  .react-app .pb-sm-0,
  .react-app .py-sm-0, .multiple-react-app .pb-sm-0,
  .multiple-react-app .py-sm-0, .bs4-popover .pb-sm-0,
  .bs4-popover .py-sm-0, .bs4-modal .pb-sm-0,
  .bs4-modal .py-sm-0, .bs4 .pb-sm-0,
  .bs4 .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .react-app .pl-sm-0,
  .react-app .px-sm-0, .multiple-react-app .pl-sm-0,
  .multiple-react-app .px-sm-0, .bs4-popover .pl-sm-0,
  .bs4-popover .px-sm-0, .bs4-modal .pl-sm-0,
  .bs4-modal .px-sm-0, .bs4 .pl-sm-0,
  .bs4 .px-sm-0 {
    padding-left: 0 !important;
  }
  .react-app .p-sm-1, .multiple-react-app .p-sm-1, .bs4-popover .p-sm-1, .bs4-modal .p-sm-1, .bs4 .p-sm-1 {
    padding: 0.5rem !important;
  }
  .react-app .pt-sm-1,
  .react-app .py-sm-1, .multiple-react-app .pt-sm-1,
  .multiple-react-app .py-sm-1, .bs4-popover .pt-sm-1,
  .bs4-popover .py-sm-1, .bs4-modal .pt-sm-1,
  .bs4-modal .py-sm-1, .bs4 .pt-sm-1,
  .bs4 .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .react-app .pr-sm-1,
  .react-app .px-sm-1, .multiple-react-app .pr-sm-1,
  .multiple-react-app .px-sm-1, .bs4-popover .pr-sm-1,
  .bs4-popover .px-sm-1, .bs4-modal .pr-sm-1,
  .bs4-modal .px-sm-1, .bs4 .pr-sm-1,
  .bs4 .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .react-app .pb-sm-1,
  .react-app .py-sm-1, .multiple-react-app .pb-sm-1,
  .multiple-react-app .py-sm-1, .bs4-popover .pb-sm-1,
  .bs4-popover .py-sm-1, .bs4-modal .pb-sm-1,
  .bs4-modal .py-sm-1, .bs4 .pb-sm-1,
  .bs4 .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .react-app .pl-sm-1,
  .react-app .px-sm-1, .multiple-react-app .pl-sm-1,
  .multiple-react-app .px-sm-1, .bs4-popover .pl-sm-1,
  .bs4-popover .px-sm-1, .bs4-modal .pl-sm-1,
  .bs4-modal .px-sm-1, .bs4 .pl-sm-1,
  .bs4 .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .react-app .p-sm-2, .multiple-react-app .p-sm-2, .bs4-popover .p-sm-2, .bs4-modal .p-sm-2, .bs4 .p-sm-2 {
    padding: 1rem !important;
  }
  .react-app .pt-sm-2,
  .react-app .py-sm-2, .multiple-react-app .pt-sm-2,
  .multiple-react-app .py-sm-2, .bs4-popover .pt-sm-2,
  .bs4-popover .py-sm-2, .bs4-modal .pt-sm-2,
  .bs4-modal .py-sm-2, .bs4 .pt-sm-2,
  .bs4 .py-sm-2 {
    padding-top: 1rem !important;
  }
  .react-app .pr-sm-2,
  .react-app .px-sm-2, .multiple-react-app .pr-sm-2,
  .multiple-react-app .px-sm-2, .bs4-popover .pr-sm-2,
  .bs4-popover .px-sm-2, .bs4-modal .pr-sm-2,
  .bs4-modal .px-sm-2, .bs4 .pr-sm-2,
  .bs4 .px-sm-2 {
    padding-right: 1rem !important;
  }
  .react-app .pb-sm-2,
  .react-app .py-sm-2, .multiple-react-app .pb-sm-2,
  .multiple-react-app .py-sm-2, .bs4-popover .pb-sm-2,
  .bs4-popover .py-sm-2, .bs4-modal .pb-sm-2,
  .bs4-modal .py-sm-2, .bs4 .pb-sm-2,
  .bs4 .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .react-app .pl-sm-2,
  .react-app .px-sm-2, .multiple-react-app .pl-sm-2,
  .multiple-react-app .px-sm-2, .bs4-popover .pl-sm-2,
  .bs4-popover .px-sm-2, .bs4-modal .pl-sm-2,
  .bs4-modal .px-sm-2, .bs4 .pl-sm-2,
  .bs4 .px-sm-2 {
    padding-left: 1rem !important;
  }
  .react-app .p-sm-3, .multiple-react-app .p-sm-3, .bs4-popover .p-sm-3, .bs4-modal .p-sm-3, .bs4 .p-sm-3 {
    padding: 1.5rem !important;
  }
  .react-app .pt-sm-3,
  .react-app .py-sm-3, .multiple-react-app .pt-sm-3,
  .multiple-react-app .py-sm-3, .bs4-popover .pt-sm-3,
  .bs4-popover .py-sm-3, .bs4-modal .pt-sm-3,
  .bs4-modal .py-sm-3, .bs4 .pt-sm-3,
  .bs4 .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .react-app .pr-sm-3,
  .react-app .px-sm-3, .multiple-react-app .pr-sm-3,
  .multiple-react-app .px-sm-3, .bs4-popover .pr-sm-3,
  .bs4-popover .px-sm-3, .bs4-modal .pr-sm-3,
  .bs4-modal .px-sm-3, .bs4 .pr-sm-3,
  .bs4 .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .react-app .pb-sm-3,
  .react-app .py-sm-3, .multiple-react-app .pb-sm-3,
  .multiple-react-app .py-sm-3, .bs4-popover .pb-sm-3,
  .bs4-popover .py-sm-3, .bs4-modal .pb-sm-3,
  .bs4-modal .py-sm-3, .bs4 .pb-sm-3,
  .bs4 .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .react-app .pl-sm-3,
  .react-app .px-sm-3, .multiple-react-app .pl-sm-3,
  .multiple-react-app .px-sm-3, .bs4-popover .pl-sm-3,
  .bs4-popover .px-sm-3, .bs4-modal .pl-sm-3,
  .bs4-modal .px-sm-3, .bs4 .pl-sm-3,
  .bs4 .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .react-app .p-sm-4, .multiple-react-app .p-sm-4, .bs4-popover .p-sm-4, .bs4-modal .p-sm-4, .bs4 .p-sm-4 {
    padding: 2rem !important;
  }
  .react-app .pt-sm-4,
  .react-app .py-sm-4, .multiple-react-app .pt-sm-4,
  .multiple-react-app .py-sm-4, .bs4-popover .pt-sm-4,
  .bs4-popover .py-sm-4, .bs4-modal .pt-sm-4,
  .bs4-modal .py-sm-4, .bs4 .pt-sm-4,
  .bs4 .py-sm-4 {
    padding-top: 2rem !important;
  }
  .react-app .pr-sm-4,
  .react-app .px-sm-4, .multiple-react-app .pr-sm-4,
  .multiple-react-app .px-sm-4, .bs4-popover .pr-sm-4,
  .bs4-popover .px-sm-4, .bs4-modal .pr-sm-4,
  .bs4-modal .px-sm-4, .bs4 .pr-sm-4,
  .bs4 .px-sm-4 {
    padding-right: 2rem !important;
  }
  .react-app .pb-sm-4,
  .react-app .py-sm-4, .multiple-react-app .pb-sm-4,
  .multiple-react-app .py-sm-4, .bs4-popover .pb-sm-4,
  .bs4-popover .py-sm-4, .bs4-modal .pb-sm-4,
  .bs4-modal .py-sm-4, .bs4 .pb-sm-4,
  .bs4 .py-sm-4 {
    padding-bottom: 2rem !important;
  }
  .react-app .pl-sm-4,
  .react-app .px-sm-4, .multiple-react-app .pl-sm-4,
  .multiple-react-app .px-sm-4, .bs4-popover .pl-sm-4,
  .bs4-popover .px-sm-4, .bs4-modal .pl-sm-4,
  .bs4-modal .px-sm-4, .bs4 .pl-sm-4,
  .bs4 .px-sm-4 {
    padding-left: 2rem !important;
  }
  .react-app .p-sm-5, .multiple-react-app .p-sm-5, .bs4-popover .p-sm-5, .bs4-modal .p-sm-5, .bs4 .p-sm-5 {
    padding: 3rem !important;
  }
  .react-app .pt-sm-5,
  .react-app .py-sm-5, .multiple-react-app .pt-sm-5,
  .multiple-react-app .py-sm-5, .bs4-popover .pt-sm-5,
  .bs4-popover .py-sm-5, .bs4-modal .pt-sm-5,
  .bs4-modal .py-sm-5, .bs4 .pt-sm-5,
  .bs4 .py-sm-5 {
    padding-top: 3rem !important;
  }
  .react-app .pr-sm-5,
  .react-app .px-sm-5, .multiple-react-app .pr-sm-5,
  .multiple-react-app .px-sm-5, .bs4-popover .pr-sm-5,
  .bs4-popover .px-sm-5, .bs4-modal .pr-sm-5,
  .bs4-modal .px-sm-5, .bs4 .pr-sm-5,
  .bs4 .px-sm-5 {
    padding-right: 3rem !important;
  }
  .react-app .pb-sm-5,
  .react-app .py-sm-5, .multiple-react-app .pb-sm-5,
  .multiple-react-app .py-sm-5, .bs4-popover .pb-sm-5,
  .bs4-popover .py-sm-5, .bs4-modal .pb-sm-5,
  .bs4-modal .py-sm-5, .bs4 .pb-sm-5,
  .bs4 .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .react-app .pl-sm-5,
  .react-app .px-sm-5, .multiple-react-app .pl-sm-5,
  .multiple-react-app .px-sm-5, .bs4-popover .pl-sm-5,
  .bs4-popover .px-sm-5, .bs4-modal .pl-sm-5,
  .bs4-modal .px-sm-5, .bs4 .pl-sm-5,
  .bs4 .px-sm-5 {
    padding-left: 3rem !important;
  }
  .react-app .p-sm-6, .multiple-react-app .p-sm-6, .bs4-popover .p-sm-6, .bs4-modal .p-sm-6, .bs4 .p-sm-6 {
    padding: 4rem !important;
  }
  .react-app .pt-sm-6,
  .react-app .py-sm-6, .multiple-react-app .pt-sm-6,
  .multiple-react-app .py-sm-6, .bs4-popover .pt-sm-6,
  .bs4-popover .py-sm-6, .bs4-modal .pt-sm-6,
  .bs4-modal .py-sm-6, .bs4 .pt-sm-6,
  .bs4 .py-sm-6 {
    padding-top: 4rem !important;
  }
  .react-app .pr-sm-6,
  .react-app .px-sm-6, .multiple-react-app .pr-sm-6,
  .multiple-react-app .px-sm-6, .bs4-popover .pr-sm-6,
  .bs4-popover .px-sm-6, .bs4-modal .pr-sm-6,
  .bs4-modal .px-sm-6, .bs4 .pr-sm-6,
  .bs4 .px-sm-6 {
    padding-right: 4rem !important;
  }
  .react-app .pb-sm-6,
  .react-app .py-sm-6, .multiple-react-app .pb-sm-6,
  .multiple-react-app .py-sm-6, .bs4-popover .pb-sm-6,
  .bs4-popover .py-sm-6, .bs4-modal .pb-sm-6,
  .bs4-modal .py-sm-6, .bs4 .pb-sm-6,
  .bs4 .py-sm-6 {
    padding-bottom: 4rem !important;
  }
  .react-app .pl-sm-6,
  .react-app .px-sm-6, .multiple-react-app .pl-sm-6,
  .multiple-react-app .px-sm-6, .bs4-popover .pl-sm-6,
  .bs4-popover .px-sm-6, .bs4-modal .pl-sm-6,
  .bs4-modal .px-sm-6, .bs4 .pl-sm-6,
  .bs4 .px-sm-6 {
    padding-left: 4rem !important;
  }
  .react-app .p-sm-7, .multiple-react-app .p-sm-7, .bs4-popover .p-sm-7, .bs4-modal .p-sm-7, .bs4 .p-sm-7 {
    padding: 5rem !important;
  }
  .react-app .pt-sm-7,
  .react-app .py-sm-7, .multiple-react-app .pt-sm-7,
  .multiple-react-app .py-sm-7, .bs4-popover .pt-sm-7,
  .bs4-popover .py-sm-7, .bs4-modal .pt-sm-7,
  .bs4-modal .py-sm-7, .bs4 .pt-sm-7,
  .bs4 .py-sm-7 {
    padding-top: 5rem !important;
  }
  .react-app .pr-sm-7,
  .react-app .px-sm-7, .multiple-react-app .pr-sm-7,
  .multiple-react-app .px-sm-7, .bs4-popover .pr-sm-7,
  .bs4-popover .px-sm-7, .bs4-modal .pr-sm-7,
  .bs4-modal .px-sm-7, .bs4 .pr-sm-7,
  .bs4 .px-sm-7 {
    padding-right: 5rem !important;
  }
  .react-app .pb-sm-7,
  .react-app .py-sm-7, .multiple-react-app .pb-sm-7,
  .multiple-react-app .py-sm-7, .bs4-popover .pb-sm-7,
  .bs4-popover .py-sm-7, .bs4-modal .pb-sm-7,
  .bs4-modal .py-sm-7, .bs4 .pb-sm-7,
  .bs4 .py-sm-7 {
    padding-bottom: 5rem !important;
  }
  .react-app .pl-sm-7,
  .react-app .px-sm-7, .multiple-react-app .pl-sm-7,
  .multiple-react-app .px-sm-7, .bs4-popover .pl-sm-7,
  .bs4-popover .px-sm-7, .bs4-modal .pl-sm-7,
  .bs4-modal .px-sm-7, .bs4 .pl-sm-7,
  .bs4 .px-sm-7 {
    padding-left: 5rem !important;
  }
  .react-app .p-sm-8, .multiple-react-app .p-sm-8, .bs4-popover .p-sm-8, .bs4-modal .p-sm-8, .bs4 .p-sm-8 {
    padding: 6rem !important;
  }
  .react-app .pt-sm-8,
  .react-app .py-sm-8, .multiple-react-app .pt-sm-8,
  .multiple-react-app .py-sm-8, .bs4-popover .pt-sm-8,
  .bs4-popover .py-sm-8, .bs4-modal .pt-sm-8,
  .bs4-modal .py-sm-8, .bs4 .pt-sm-8,
  .bs4 .py-sm-8 {
    padding-top: 6rem !important;
  }
  .react-app .pr-sm-8,
  .react-app .px-sm-8, .multiple-react-app .pr-sm-8,
  .multiple-react-app .px-sm-8, .bs4-popover .pr-sm-8,
  .bs4-popover .px-sm-8, .bs4-modal .pr-sm-8,
  .bs4-modal .px-sm-8, .bs4 .pr-sm-8,
  .bs4 .px-sm-8 {
    padding-right: 6rem !important;
  }
  .react-app .pb-sm-8,
  .react-app .py-sm-8, .multiple-react-app .pb-sm-8,
  .multiple-react-app .py-sm-8, .bs4-popover .pb-sm-8,
  .bs4-popover .py-sm-8, .bs4-modal .pb-sm-8,
  .bs4-modal .py-sm-8, .bs4 .pb-sm-8,
  .bs4 .py-sm-8 {
    padding-bottom: 6rem !important;
  }
  .react-app .pl-sm-8,
  .react-app .px-sm-8, .multiple-react-app .pl-sm-8,
  .multiple-react-app .px-sm-8, .bs4-popover .pl-sm-8,
  .bs4-popover .px-sm-8, .bs4-modal .pl-sm-8,
  .bs4-modal .px-sm-8, .bs4 .pl-sm-8,
  .bs4 .px-sm-8 {
    padding-left: 6rem !important;
  }
  .react-app .m-sm-n1, .multiple-react-app .m-sm-n1, .bs4-popover .m-sm-n1, .bs4-modal .m-sm-n1, .bs4 .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .react-app .mt-sm-n1,
  .react-app .my-sm-n1, .multiple-react-app .mt-sm-n1,
  .multiple-react-app .my-sm-n1, .bs4-popover .mt-sm-n1,
  .bs4-popover .my-sm-n1, .bs4-modal .mt-sm-n1,
  .bs4-modal .my-sm-n1, .bs4 .mt-sm-n1,
  .bs4 .my-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .react-app .mr-sm-n1,
  .react-app .mx-sm-n1, .multiple-react-app .mr-sm-n1,
  .multiple-react-app .mx-sm-n1, .bs4-popover .mr-sm-n1,
  .bs4-popover .mx-sm-n1, .bs4-modal .mr-sm-n1,
  .bs4-modal .mx-sm-n1, .bs4 .mr-sm-n1,
  .bs4 .mx-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .react-app .mb-sm-n1,
  .react-app .my-sm-n1, .multiple-react-app .mb-sm-n1,
  .multiple-react-app .my-sm-n1, .bs4-popover .mb-sm-n1,
  .bs4-popover .my-sm-n1, .bs4-modal .mb-sm-n1,
  .bs4-modal .my-sm-n1, .bs4 .mb-sm-n1,
  .bs4 .my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .react-app .ml-sm-n1,
  .react-app .mx-sm-n1, .multiple-react-app .ml-sm-n1,
  .multiple-react-app .mx-sm-n1, .bs4-popover .ml-sm-n1,
  .bs4-popover .mx-sm-n1, .bs4-modal .ml-sm-n1,
  .bs4-modal .mx-sm-n1, .bs4 .ml-sm-n1,
  .bs4 .mx-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .react-app .m-sm-n2, .multiple-react-app .m-sm-n2, .bs4-popover .m-sm-n2, .bs4-modal .m-sm-n2, .bs4 .m-sm-n2 {
    margin: -1rem !important;
  }
  .react-app .mt-sm-n2,
  .react-app .my-sm-n2, .multiple-react-app .mt-sm-n2,
  .multiple-react-app .my-sm-n2, .bs4-popover .mt-sm-n2,
  .bs4-popover .my-sm-n2, .bs4-modal .mt-sm-n2,
  .bs4-modal .my-sm-n2, .bs4 .mt-sm-n2,
  .bs4 .my-sm-n2 {
    margin-top: -1rem !important;
  }
  .react-app .mr-sm-n2,
  .react-app .mx-sm-n2, .multiple-react-app .mr-sm-n2,
  .multiple-react-app .mx-sm-n2, .bs4-popover .mr-sm-n2,
  .bs4-popover .mx-sm-n2, .bs4-modal .mr-sm-n2,
  .bs4-modal .mx-sm-n2, .bs4 .mr-sm-n2,
  .bs4 .mx-sm-n2 {
    margin-right: -1rem !important;
  }
  .react-app .mb-sm-n2,
  .react-app .my-sm-n2, .multiple-react-app .mb-sm-n2,
  .multiple-react-app .my-sm-n2, .bs4-popover .mb-sm-n2,
  .bs4-popover .my-sm-n2, .bs4-modal .mb-sm-n2,
  .bs4-modal .my-sm-n2, .bs4 .mb-sm-n2,
  .bs4 .my-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .react-app .ml-sm-n2,
  .react-app .mx-sm-n2, .multiple-react-app .ml-sm-n2,
  .multiple-react-app .mx-sm-n2, .bs4-popover .ml-sm-n2,
  .bs4-popover .mx-sm-n2, .bs4-modal .ml-sm-n2,
  .bs4-modal .mx-sm-n2, .bs4 .ml-sm-n2,
  .bs4 .mx-sm-n2 {
    margin-left: -1rem !important;
  }
  .react-app .m-sm-n3, .multiple-react-app .m-sm-n3, .bs4-popover .m-sm-n3, .bs4-modal .m-sm-n3, .bs4 .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .react-app .mt-sm-n3,
  .react-app .my-sm-n3, .multiple-react-app .mt-sm-n3,
  .multiple-react-app .my-sm-n3, .bs4-popover .mt-sm-n3,
  .bs4-popover .my-sm-n3, .bs4-modal .mt-sm-n3,
  .bs4-modal .my-sm-n3, .bs4 .mt-sm-n3,
  .bs4 .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .react-app .mr-sm-n3,
  .react-app .mx-sm-n3, .multiple-react-app .mr-sm-n3,
  .multiple-react-app .mx-sm-n3, .bs4-popover .mr-sm-n3,
  .bs4-popover .mx-sm-n3, .bs4-modal .mr-sm-n3,
  .bs4-modal .mx-sm-n3, .bs4 .mr-sm-n3,
  .bs4 .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .react-app .mb-sm-n3,
  .react-app .my-sm-n3, .multiple-react-app .mb-sm-n3,
  .multiple-react-app .my-sm-n3, .bs4-popover .mb-sm-n3,
  .bs4-popover .my-sm-n3, .bs4-modal .mb-sm-n3,
  .bs4-modal .my-sm-n3, .bs4 .mb-sm-n3,
  .bs4 .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .react-app .ml-sm-n3,
  .react-app .mx-sm-n3, .multiple-react-app .ml-sm-n3,
  .multiple-react-app .mx-sm-n3, .bs4-popover .ml-sm-n3,
  .bs4-popover .mx-sm-n3, .bs4-modal .ml-sm-n3,
  .bs4-modal .mx-sm-n3, .bs4 .ml-sm-n3,
  .bs4 .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .react-app .m-sm-n4, .multiple-react-app .m-sm-n4, .bs4-popover .m-sm-n4, .bs4-modal .m-sm-n4, .bs4 .m-sm-n4 {
    margin: -2rem !important;
  }
  .react-app .mt-sm-n4,
  .react-app .my-sm-n4, .multiple-react-app .mt-sm-n4,
  .multiple-react-app .my-sm-n4, .bs4-popover .mt-sm-n4,
  .bs4-popover .my-sm-n4, .bs4-modal .mt-sm-n4,
  .bs4-modal .my-sm-n4, .bs4 .mt-sm-n4,
  .bs4 .my-sm-n4 {
    margin-top: -2rem !important;
  }
  .react-app .mr-sm-n4,
  .react-app .mx-sm-n4, .multiple-react-app .mr-sm-n4,
  .multiple-react-app .mx-sm-n4, .bs4-popover .mr-sm-n4,
  .bs4-popover .mx-sm-n4, .bs4-modal .mr-sm-n4,
  .bs4-modal .mx-sm-n4, .bs4 .mr-sm-n4,
  .bs4 .mx-sm-n4 {
    margin-right: -2rem !important;
  }
  .react-app .mb-sm-n4,
  .react-app .my-sm-n4, .multiple-react-app .mb-sm-n4,
  .multiple-react-app .my-sm-n4, .bs4-popover .mb-sm-n4,
  .bs4-popover .my-sm-n4, .bs4-modal .mb-sm-n4,
  .bs4-modal .my-sm-n4, .bs4 .mb-sm-n4,
  .bs4 .my-sm-n4 {
    margin-bottom: -2rem !important;
  }
  .react-app .ml-sm-n4,
  .react-app .mx-sm-n4, .multiple-react-app .ml-sm-n4,
  .multiple-react-app .mx-sm-n4, .bs4-popover .ml-sm-n4,
  .bs4-popover .mx-sm-n4, .bs4-modal .ml-sm-n4,
  .bs4-modal .mx-sm-n4, .bs4 .ml-sm-n4,
  .bs4 .mx-sm-n4 {
    margin-left: -2rem !important;
  }
  .react-app .m-sm-n5, .multiple-react-app .m-sm-n5, .bs4-popover .m-sm-n5, .bs4-modal .m-sm-n5, .bs4 .m-sm-n5 {
    margin: -3rem !important;
  }
  .react-app .mt-sm-n5,
  .react-app .my-sm-n5, .multiple-react-app .mt-sm-n5,
  .multiple-react-app .my-sm-n5, .bs4-popover .mt-sm-n5,
  .bs4-popover .my-sm-n5, .bs4-modal .mt-sm-n5,
  .bs4-modal .my-sm-n5, .bs4 .mt-sm-n5,
  .bs4 .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .react-app .mr-sm-n5,
  .react-app .mx-sm-n5, .multiple-react-app .mr-sm-n5,
  .multiple-react-app .mx-sm-n5, .bs4-popover .mr-sm-n5,
  .bs4-popover .mx-sm-n5, .bs4-modal .mr-sm-n5,
  .bs4-modal .mx-sm-n5, .bs4 .mr-sm-n5,
  .bs4 .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .react-app .mb-sm-n5,
  .react-app .my-sm-n5, .multiple-react-app .mb-sm-n5,
  .multiple-react-app .my-sm-n5, .bs4-popover .mb-sm-n5,
  .bs4-popover .my-sm-n5, .bs4-modal .mb-sm-n5,
  .bs4-modal .my-sm-n5, .bs4 .mb-sm-n5,
  .bs4 .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .react-app .ml-sm-n5,
  .react-app .mx-sm-n5, .multiple-react-app .ml-sm-n5,
  .multiple-react-app .mx-sm-n5, .bs4-popover .ml-sm-n5,
  .bs4-popover .mx-sm-n5, .bs4-modal .ml-sm-n5,
  .bs4-modal .mx-sm-n5, .bs4 .ml-sm-n5,
  .bs4 .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .react-app .m-sm-n6, .multiple-react-app .m-sm-n6, .bs4-popover .m-sm-n6, .bs4-modal .m-sm-n6, .bs4 .m-sm-n6 {
    margin: -4rem !important;
  }
  .react-app .mt-sm-n6,
  .react-app .my-sm-n6, .multiple-react-app .mt-sm-n6,
  .multiple-react-app .my-sm-n6, .bs4-popover .mt-sm-n6,
  .bs4-popover .my-sm-n6, .bs4-modal .mt-sm-n6,
  .bs4-modal .my-sm-n6, .bs4 .mt-sm-n6,
  .bs4 .my-sm-n6 {
    margin-top: -4rem !important;
  }
  .react-app .mr-sm-n6,
  .react-app .mx-sm-n6, .multiple-react-app .mr-sm-n6,
  .multiple-react-app .mx-sm-n6, .bs4-popover .mr-sm-n6,
  .bs4-popover .mx-sm-n6, .bs4-modal .mr-sm-n6,
  .bs4-modal .mx-sm-n6, .bs4 .mr-sm-n6,
  .bs4 .mx-sm-n6 {
    margin-right: -4rem !important;
  }
  .react-app .mb-sm-n6,
  .react-app .my-sm-n6, .multiple-react-app .mb-sm-n6,
  .multiple-react-app .my-sm-n6, .bs4-popover .mb-sm-n6,
  .bs4-popover .my-sm-n6, .bs4-modal .mb-sm-n6,
  .bs4-modal .my-sm-n6, .bs4 .mb-sm-n6,
  .bs4 .my-sm-n6 {
    margin-bottom: -4rem !important;
  }
  .react-app .ml-sm-n6,
  .react-app .mx-sm-n6, .multiple-react-app .ml-sm-n6,
  .multiple-react-app .mx-sm-n6, .bs4-popover .ml-sm-n6,
  .bs4-popover .mx-sm-n6, .bs4-modal .ml-sm-n6,
  .bs4-modal .mx-sm-n6, .bs4 .ml-sm-n6,
  .bs4 .mx-sm-n6 {
    margin-left: -4rem !important;
  }
  .react-app .m-sm-n7, .multiple-react-app .m-sm-n7, .bs4-popover .m-sm-n7, .bs4-modal .m-sm-n7, .bs4 .m-sm-n7 {
    margin: -5rem !important;
  }
  .react-app .mt-sm-n7,
  .react-app .my-sm-n7, .multiple-react-app .mt-sm-n7,
  .multiple-react-app .my-sm-n7, .bs4-popover .mt-sm-n7,
  .bs4-popover .my-sm-n7, .bs4-modal .mt-sm-n7,
  .bs4-modal .my-sm-n7, .bs4 .mt-sm-n7,
  .bs4 .my-sm-n7 {
    margin-top: -5rem !important;
  }
  .react-app .mr-sm-n7,
  .react-app .mx-sm-n7, .multiple-react-app .mr-sm-n7,
  .multiple-react-app .mx-sm-n7, .bs4-popover .mr-sm-n7,
  .bs4-popover .mx-sm-n7, .bs4-modal .mr-sm-n7,
  .bs4-modal .mx-sm-n7, .bs4 .mr-sm-n7,
  .bs4 .mx-sm-n7 {
    margin-right: -5rem !important;
  }
  .react-app .mb-sm-n7,
  .react-app .my-sm-n7, .multiple-react-app .mb-sm-n7,
  .multiple-react-app .my-sm-n7, .bs4-popover .mb-sm-n7,
  .bs4-popover .my-sm-n7, .bs4-modal .mb-sm-n7,
  .bs4-modal .my-sm-n7, .bs4 .mb-sm-n7,
  .bs4 .my-sm-n7 {
    margin-bottom: -5rem !important;
  }
  .react-app .ml-sm-n7,
  .react-app .mx-sm-n7, .multiple-react-app .ml-sm-n7,
  .multiple-react-app .mx-sm-n7, .bs4-popover .ml-sm-n7,
  .bs4-popover .mx-sm-n7, .bs4-modal .ml-sm-n7,
  .bs4-modal .mx-sm-n7, .bs4 .ml-sm-n7,
  .bs4 .mx-sm-n7 {
    margin-left: -5rem !important;
  }
  .react-app .m-sm-n8, .multiple-react-app .m-sm-n8, .bs4-popover .m-sm-n8, .bs4-modal .m-sm-n8, .bs4 .m-sm-n8 {
    margin: -6rem !important;
  }
  .react-app .mt-sm-n8,
  .react-app .my-sm-n8, .multiple-react-app .mt-sm-n8,
  .multiple-react-app .my-sm-n8, .bs4-popover .mt-sm-n8,
  .bs4-popover .my-sm-n8, .bs4-modal .mt-sm-n8,
  .bs4-modal .my-sm-n8, .bs4 .mt-sm-n8,
  .bs4 .my-sm-n8 {
    margin-top: -6rem !important;
  }
  .react-app .mr-sm-n8,
  .react-app .mx-sm-n8, .multiple-react-app .mr-sm-n8,
  .multiple-react-app .mx-sm-n8, .bs4-popover .mr-sm-n8,
  .bs4-popover .mx-sm-n8, .bs4-modal .mr-sm-n8,
  .bs4-modal .mx-sm-n8, .bs4 .mr-sm-n8,
  .bs4 .mx-sm-n8 {
    margin-right: -6rem !important;
  }
  .react-app .mb-sm-n8,
  .react-app .my-sm-n8, .multiple-react-app .mb-sm-n8,
  .multiple-react-app .my-sm-n8, .bs4-popover .mb-sm-n8,
  .bs4-popover .my-sm-n8, .bs4-modal .mb-sm-n8,
  .bs4-modal .my-sm-n8, .bs4 .mb-sm-n8,
  .bs4 .my-sm-n8 {
    margin-bottom: -6rem !important;
  }
  .react-app .ml-sm-n8,
  .react-app .mx-sm-n8, .multiple-react-app .ml-sm-n8,
  .multiple-react-app .mx-sm-n8, .bs4-popover .ml-sm-n8,
  .bs4-popover .mx-sm-n8, .bs4-modal .ml-sm-n8,
  .bs4-modal .mx-sm-n8, .bs4 .ml-sm-n8,
  .bs4 .mx-sm-n8 {
    margin-left: -6rem !important;
  }
  .react-app .m-sm-auto, .multiple-react-app .m-sm-auto, .bs4-popover .m-sm-auto, .bs4-modal .m-sm-auto, .bs4 .m-sm-auto {
    margin: auto !important;
  }
  .react-app .mt-sm-auto,
  .react-app .my-sm-auto, .multiple-react-app .mt-sm-auto,
  .multiple-react-app .my-sm-auto, .bs4-popover .mt-sm-auto,
  .bs4-popover .my-sm-auto, .bs4-modal .mt-sm-auto,
  .bs4-modal .my-sm-auto, .bs4 .mt-sm-auto,
  .bs4 .my-sm-auto {
    margin-top: auto !important;
  }
  .react-app .mr-sm-auto,
  .react-app .mx-sm-auto, .multiple-react-app .mr-sm-auto,
  .multiple-react-app .mx-sm-auto, .bs4-popover .mr-sm-auto,
  .bs4-popover .mx-sm-auto, .bs4-modal .mr-sm-auto,
  .bs4-modal .mx-sm-auto, .bs4 .mr-sm-auto,
  .bs4 .mx-sm-auto {
    margin-right: auto !important;
  }
  .react-app .mb-sm-auto,
  .react-app .my-sm-auto, .multiple-react-app .mb-sm-auto,
  .multiple-react-app .my-sm-auto, .bs4-popover .mb-sm-auto,
  .bs4-popover .my-sm-auto, .bs4-modal .mb-sm-auto,
  .bs4-modal .my-sm-auto, .bs4 .mb-sm-auto,
  .bs4 .my-sm-auto {
    margin-bottom: auto !important;
  }
  .react-app .ml-sm-auto,
  .react-app .mx-sm-auto, .multiple-react-app .ml-sm-auto,
  .multiple-react-app .mx-sm-auto, .bs4-popover .ml-sm-auto,
  .bs4-popover .mx-sm-auto, .bs4-modal .ml-sm-auto,
  .bs4-modal .mx-sm-auto, .bs4 .ml-sm-auto,
  .bs4 .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .react-app .m-md-0, .multiple-react-app .m-md-0, .bs4-popover .m-md-0, .bs4-modal .m-md-0, .bs4 .m-md-0 {
    margin: 0 !important;
  }
  .react-app .mt-md-0,
  .react-app .my-md-0, .multiple-react-app .mt-md-0,
  .multiple-react-app .my-md-0, .bs4-popover .mt-md-0,
  .bs4-popover .my-md-0, .bs4-modal .mt-md-0,
  .bs4-modal .my-md-0, .bs4 .mt-md-0,
  .bs4 .my-md-0 {
    margin-top: 0 !important;
  }
  .react-app .mr-md-0,
  .react-app .mx-md-0, .multiple-react-app .mr-md-0,
  .multiple-react-app .mx-md-0, .bs4-popover .mr-md-0,
  .bs4-popover .mx-md-0, .bs4-modal .mr-md-0,
  .bs4-modal .mx-md-0, .bs4 .mr-md-0,
  .bs4 .mx-md-0 {
    margin-right: 0 !important;
  }
  .react-app .mb-md-0,
  .react-app .my-md-0, .multiple-react-app .mb-md-0,
  .multiple-react-app .my-md-0, .bs4-popover .mb-md-0,
  .bs4-popover .my-md-0, .bs4-modal .mb-md-0,
  .bs4-modal .my-md-0, .bs4 .mb-md-0,
  .bs4 .my-md-0 {
    margin-bottom: 0 !important;
  }
  .react-app .ml-md-0,
  .react-app .mx-md-0, .multiple-react-app .ml-md-0,
  .multiple-react-app .mx-md-0, .bs4-popover .ml-md-0,
  .bs4-popover .mx-md-0, .bs4-modal .ml-md-0,
  .bs4-modal .mx-md-0, .bs4 .ml-md-0,
  .bs4 .mx-md-0 {
    margin-left: 0 !important;
  }
  .react-app .m-md-1, .multiple-react-app .m-md-1, .bs4-popover .m-md-1, .bs4-modal .m-md-1, .bs4 .m-md-1 {
    margin: 0.5rem !important;
  }
  .react-app .mt-md-1,
  .react-app .my-md-1, .multiple-react-app .mt-md-1,
  .multiple-react-app .my-md-1, .bs4-popover .mt-md-1,
  .bs4-popover .my-md-1, .bs4-modal .mt-md-1,
  .bs4-modal .my-md-1, .bs4 .mt-md-1,
  .bs4 .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .react-app .mr-md-1,
  .react-app .mx-md-1, .multiple-react-app .mr-md-1,
  .multiple-react-app .mx-md-1, .bs4-popover .mr-md-1,
  .bs4-popover .mx-md-1, .bs4-modal .mr-md-1,
  .bs4-modal .mx-md-1, .bs4 .mr-md-1,
  .bs4 .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .react-app .mb-md-1,
  .react-app .my-md-1, .multiple-react-app .mb-md-1,
  .multiple-react-app .my-md-1, .bs4-popover .mb-md-1,
  .bs4-popover .my-md-1, .bs4-modal .mb-md-1,
  .bs4-modal .my-md-1, .bs4 .mb-md-1,
  .bs4 .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .react-app .ml-md-1,
  .react-app .mx-md-1, .multiple-react-app .ml-md-1,
  .multiple-react-app .mx-md-1, .bs4-popover .ml-md-1,
  .bs4-popover .mx-md-1, .bs4-modal .ml-md-1,
  .bs4-modal .mx-md-1, .bs4 .ml-md-1,
  .bs4 .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .react-app .m-md-2, .multiple-react-app .m-md-2, .bs4-popover .m-md-2, .bs4-modal .m-md-2, .bs4 .m-md-2 {
    margin: 1rem !important;
  }
  .react-app .mt-md-2,
  .react-app .my-md-2, .multiple-react-app .mt-md-2,
  .multiple-react-app .my-md-2, .bs4-popover .mt-md-2,
  .bs4-popover .my-md-2, .bs4-modal .mt-md-2,
  .bs4-modal .my-md-2, .bs4 .mt-md-2,
  .bs4 .my-md-2 {
    margin-top: 1rem !important;
  }
  .react-app .mr-md-2,
  .react-app .mx-md-2, .multiple-react-app .mr-md-2,
  .multiple-react-app .mx-md-2, .bs4-popover .mr-md-2,
  .bs4-popover .mx-md-2, .bs4-modal .mr-md-2,
  .bs4-modal .mx-md-2, .bs4 .mr-md-2,
  .bs4 .mx-md-2 {
    margin-right: 1rem !important;
  }
  .react-app .mb-md-2,
  .react-app .my-md-2, .multiple-react-app .mb-md-2,
  .multiple-react-app .my-md-2, .bs4-popover .mb-md-2,
  .bs4-popover .my-md-2, .bs4-modal .mb-md-2,
  .bs4-modal .my-md-2, .bs4 .mb-md-2,
  .bs4 .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .react-app .ml-md-2,
  .react-app .mx-md-2, .multiple-react-app .ml-md-2,
  .multiple-react-app .mx-md-2, .bs4-popover .ml-md-2,
  .bs4-popover .mx-md-2, .bs4-modal .ml-md-2,
  .bs4-modal .mx-md-2, .bs4 .ml-md-2,
  .bs4 .mx-md-2 {
    margin-left: 1rem !important;
  }
  .react-app .m-md-3, .multiple-react-app .m-md-3, .bs4-popover .m-md-3, .bs4-modal .m-md-3, .bs4 .m-md-3 {
    margin: 1.5rem !important;
  }
  .react-app .mt-md-3,
  .react-app .my-md-3, .multiple-react-app .mt-md-3,
  .multiple-react-app .my-md-3, .bs4-popover .mt-md-3,
  .bs4-popover .my-md-3, .bs4-modal .mt-md-3,
  .bs4-modal .my-md-3, .bs4 .mt-md-3,
  .bs4 .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .react-app .mr-md-3,
  .react-app .mx-md-3, .multiple-react-app .mr-md-3,
  .multiple-react-app .mx-md-3, .bs4-popover .mr-md-3,
  .bs4-popover .mx-md-3, .bs4-modal .mr-md-3,
  .bs4-modal .mx-md-3, .bs4 .mr-md-3,
  .bs4 .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .react-app .mb-md-3,
  .react-app .my-md-3, .multiple-react-app .mb-md-3,
  .multiple-react-app .my-md-3, .bs4-popover .mb-md-3,
  .bs4-popover .my-md-3, .bs4-modal .mb-md-3,
  .bs4-modal .my-md-3, .bs4 .mb-md-3,
  .bs4 .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .react-app .ml-md-3,
  .react-app .mx-md-3, .multiple-react-app .ml-md-3,
  .multiple-react-app .mx-md-3, .bs4-popover .ml-md-3,
  .bs4-popover .mx-md-3, .bs4-modal .ml-md-3,
  .bs4-modal .mx-md-3, .bs4 .ml-md-3,
  .bs4 .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .react-app .m-md-4, .multiple-react-app .m-md-4, .bs4-popover .m-md-4, .bs4-modal .m-md-4, .bs4 .m-md-4 {
    margin: 2rem !important;
  }
  .react-app .mt-md-4,
  .react-app .my-md-4, .multiple-react-app .mt-md-4,
  .multiple-react-app .my-md-4, .bs4-popover .mt-md-4,
  .bs4-popover .my-md-4, .bs4-modal .mt-md-4,
  .bs4-modal .my-md-4, .bs4 .mt-md-4,
  .bs4 .my-md-4 {
    margin-top: 2rem !important;
  }
  .react-app .mr-md-4,
  .react-app .mx-md-4, .multiple-react-app .mr-md-4,
  .multiple-react-app .mx-md-4, .bs4-popover .mr-md-4,
  .bs4-popover .mx-md-4, .bs4-modal .mr-md-4,
  .bs4-modal .mx-md-4, .bs4 .mr-md-4,
  .bs4 .mx-md-4 {
    margin-right: 2rem !important;
  }
  .react-app .mb-md-4,
  .react-app .my-md-4, .multiple-react-app .mb-md-4,
  .multiple-react-app .my-md-4, .bs4-popover .mb-md-4,
  .bs4-popover .my-md-4, .bs4-modal .mb-md-4,
  .bs4-modal .my-md-4, .bs4 .mb-md-4,
  .bs4 .my-md-4 {
    margin-bottom: 2rem !important;
  }
  .react-app .ml-md-4,
  .react-app .mx-md-4, .multiple-react-app .ml-md-4,
  .multiple-react-app .mx-md-4, .bs4-popover .ml-md-4,
  .bs4-popover .mx-md-4, .bs4-modal .ml-md-4,
  .bs4-modal .mx-md-4, .bs4 .ml-md-4,
  .bs4 .mx-md-4 {
    margin-left: 2rem !important;
  }
  .react-app .m-md-5, .multiple-react-app .m-md-5, .bs4-popover .m-md-5, .bs4-modal .m-md-5, .bs4 .m-md-5 {
    margin: 3rem !important;
  }
  .react-app .mt-md-5,
  .react-app .my-md-5, .multiple-react-app .mt-md-5,
  .multiple-react-app .my-md-5, .bs4-popover .mt-md-5,
  .bs4-popover .my-md-5, .bs4-modal .mt-md-5,
  .bs4-modal .my-md-5, .bs4 .mt-md-5,
  .bs4 .my-md-5 {
    margin-top: 3rem !important;
  }
  .react-app .mr-md-5,
  .react-app .mx-md-5, .multiple-react-app .mr-md-5,
  .multiple-react-app .mx-md-5, .bs4-popover .mr-md-5,
  .bs4-popover .mx-md-5, .bs4-modal .mr-md-5,
  .bs4-modal .mx-md-5, .bs4 .mr-md-5,
  .bs4 .mx-md-5 {
    margin-right: 3rem !important;
  }
  .react-app .mb-md-5,
  .react-app .my-md-5, .multiple-react-app .mb-md-5,
  .multiple-react-app .my-md-5, .bs4-popover .mb-md-5,
  .bs4-popover .my-md-5, .bs4-modal .mb-md-5,
  .bs4-modal .my-md-5, .bs4 .mb-md-5,
  .bs4 .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .react-app .ml-md-5,
  .react-app .mx-md-5, .multiple-react-app .ml-md-5,
  .multiple-react-app .mx-md-5, .bs4-popover .ml-md-5,
  .bs4-popover .mx-md-5, .bs4-modal .ml-md-5,
  .bs4-modal .mx-md-5, .bs4 .ml-md-5,
  .bs4 .mx-md-5 {
    margin-left: 3rem !important;
  }
  .react-app .m-md-6, .multiple-react-app .m-md-6, .bs4-popover .m-md-6, .bs4-modal .m-md-6, .bs4 .m-md-6 {
    margin: 4rem !important;
  }
  .react-app .mt-md-6,
  .react-app .my-md-6, .multiple-react-app .mt-md-6,
  .multiple-react-app .my-md-6, .bs4-popover .mt-md-6,
  .bs4-popover .my-md-6, .bs4-modal .mt-md-6,
  .bs4-modal .my-md-6, .bs4 .mt-md-6,
  .bs4 .my-md-6 {
    margin-top: 4rem !important;
  }
  .react-app .mr-md-6,
  .react-app .mx-md-6, .multiple-react-app .mr-md-6,
  .multiple-react-app .mx-md-6, .bs4-popover .mr-md-6,
  .bs4-popover .mx-md-6, .bs4-modal .mr-md-6,
  .bs4-modal .mx-md-6, .bs4 .mr-md-6,
  .bs4 .mx-md-6 {
    margin-right: 4rem !important;
  }
  .react-app .mb-md-6,
  .react-app .my-md-6, .multiple-react-app .mb-md-6,
  .multiple-react-app .my-md-6, .bs4-popover .mb-md-6,
  .bs4-popover .my-md-6, .bs4-modal .mb-md-6,
  .bs4-modal .my-md-6, .bs4 .mb-md-6,
  .bs4 .my-md-6 {
    margin-bottom: 4rem !important;
  }
  .react-app .ml-md-6,
  .react-app .mx-md-6, .multiple-react-app .ml-md-6,
  .multiple-react-app .mx-md-6, .bs4-popover .ml-md-6,
  .bs4-popover .mx-md-6, .bs4-modal .ml-md-6,
  .bs4-modal .mx-md-6, .bs4 .ml-md-6,
  .bs4 .mx-md-6 {
    margin-left: 4rem !important;
  }
  .react-app .m-md-7, .multiple-react-app .m-md-7, .bs4-popover .m-md-7, .bs4-modal .m-md-7, .bs4 .m-md-7 {
    margin: 5rem !important;
  }
  .react-app .mt-md-7,
  .react-app .my-md-7, .multiple-react-app .mt-md-7,
  .multiple-react-app .my-md-7, .bs4-popover .mt-md-7,
  .bs4-popover .my-md-7, .bs4-modal .mt-md-7,
  .bs4-modal .my-md-7, .bs4 .mt-md-7,
  .bs4 .my-md-7 {
    margin-top: 5rem !important;
  }
  .react-app .mr-md-7,
  .react-app .mx-md-7, .multiple-react-app .mr-md-7,
  .multiple-react-app .mx-md-7, .bs4-popover .mr-md-7,
  .bs4-popover .mx-md-7, .bs4-modal .mr-md-7,
  .bs4-modal .mx-md-7, .bs4 .mr-md-7,
  .bs4 .mx-md-7 {
    margin-right: 5rem !important;
  }
  .react-app .mb-md-7,
  .react-app .my-md-7, .multiple-react-app .mb-md-7,
  .multiple-react-app .my-md-7, .bs4-popover .mb-md-7,
  .bs4-popover .my-md-7, .bs4-modal .mb-md-7,
  .bs4-modal .my-md-7, .bs4 .mb-md-7,
  .bs4 .my-md-7 {
    margin-bottom: 5rem !important;
  }
  .react-app .ml-md-7,
  .react-app .mx-md-7, .multiple-react-app .ml-md-7,
  .multiple-react-app .mx-md-7, .bs4-popover .ml-md-7,
  .bs4-popover .mx-md-7, .bs4-modal .ml-md-7,
  .bs4-modal .mx-md-7, .bs4 .ml-md-7,
  .bs4 .mx-md-7 {
    margin-left: 5rem !important;
  }
  .react-app .m-md-8, .multiple-react-app .m-md-8, .bs4-popover .m-md-8, .bs4-modal .m-md-8, .bs4 .m-md-8 {
    margin: 6rem !important;
  }
  .react-app .mt-md-8,
  .react-app .my-md-8, .multiple-react-app .mt-md-8,
  .multiple-react-app .my-md-8, .bs4-popover .mt-md-8,
  .bs4-popover .my-md-8, .bs4-modal .mt-md-8,
  .bs4-modal .my-md-8, .bs4 .mt-md-8,
  .bs4 .my-md-8 {
    margin-top: 6rem !important;
  }
  .react-app .mr-md-8,
  .react-app .mx-md-8, .multiple-react-app .mr-md-8,
  .multiple-react-app .mx-md-8, .bs4-popover .mr-md-8,
  .bs4-popover .mx-md-8, .bs4-modal .mr-md-8,
  .bs4-modal .mx-md-8, .bs4 .mr-md-8,
  .bs4 .mx-md-8 {
    margin-right: 6rem !important;
  }
  .react-app .mb-md-8,
  .react-app .my-md-8, .multiple-react-app .mb-md-8,
  .multiple-react-app .my-md-8, .bs4-popover .mb-md-8,
  .bs4-popover .my-md-8, .bs4-modal .mb-md-8,
  .bs4-modal .my-md-8, .bs4 .mb-md-8,
  .bs4 .my-md-8 {
    margin-bottom: 6rem !important;
  }
  .react-app .ml-md-8,
  .react-app .mx-md-8, .multiple-react-app .ml-md-8,
  .multiple-react-app .mx-md-8, .bs4-popover .ml-md-8,
  .bs4-popover .mx-md-8, .bs4-modal .ml-md-8,
  .bs4-modal .mx-md-8, .bs4 .ml-md-8,
  .bs4 .mx-md-8 {
    margin-left: 6rem !important;
  }
  .react-app .p-md-0, .multiple-react-app .p-md-0, .bs4-popover .p-md-0, .bs4-modal .p-md-0, .bs4 .p-md-0 {
    padding: 0 !important;
  }
  .react-app .pt-md-0,
  .react-app .py-md-0, .multiple-react-app .pt-md-0,
  .multiple-react-app .py-md-0, .bs4-popover .pt-md-0,
  .bs4-popover .py-md-0, .bs4-modal .pt-md-0,
  .bs4-modal .py-md-0, .bs4 .pt-md-0,
  .bs4 .py-md-0 {
    padding-top: 0 !important;
  }
  .react-app .pr-md-0,
  .react-app .px-md-0, .multiple-react-app .pr-md-0,
  .multiple-react-app .px-md-0, .bs4-popover .pr-md-0,
  .bs4-popover .px-md-0, .bs4-modal .pr-md-0,
  .bs4-modal .px-md-0, .bs4 .pr-md-0,
  .bs4 .px-md-0 {
    padding-right: 0 !important;
  }
  .react-app .pb-md-0,
  .react-app .py-md-0, .multiple-react-app .pb-md-0,
  .multiple-react-app .py-md-0, .bs4-popover .pb-md-0,
  .bs4-popover .py-md-0, .bs4-modal .pb-md-0,
  .bs4-modal .py-md-0, .bs4 .pb-md-0,
  .bs4 .py-md-0 {
    padding-bottom: 0 !important;
  }
  .react-app .pl-md-0,
  .react-app .px-md-0, .multiple-react-app .pl-md-0,
  .multiple-react-app .px-md-0, .bs4-popover .pl-md-0,
  .bs4-popover .px-md-0, .bs4-modal .pl-md-0,
  .bs4-modal .px-md-0, .bs4 .pl-md-0,
  .bs4 .px-md-0 {
    padding-left: 0 !important;
  }
  .react-app .p-md-1, .multiple-react-app .p-md-1, .bs4-popover .p-md-1, .bs4-modal .p-md-1, .bs4 .p-md-1 {
    padding: 0.5rem !important;
  }
  .react-app .pt-md-1,
  .react-app .py-md-1, .multiple-react-app .pt-md-1,
  .multiple-react-app .py-md-1, .bs4-popover .pt-md-1,
  .bs4-popover .py-md-1, .bs4-modal .pt-md-1,
  .bs4-modal .py-md-1, .bs4 .pt-md-1,
  .bs4 .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .react-app .pr-md-1,
  .react-app .px-md-1, .multiple-react-app .pr-md-1,
  .multiple-react-app .px-md-1, .bs4-popover .pr-md-1,
  .bs4-popover .px-md-1, .bs4-modal .pr-md-1,
  .bs4-modal .px-md-1, .bs4 .pr-md-1,
  .bs4 .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .react-app .pb-md-1,
  .react-app .py-md-1, .multiple-react-app .pb-md-1,
  .multiple-react-app .py-md-1, .bs4-popover .pb-md-1,
  .bs4-popover .py-md-1, .bs4-modal .pb-md-1,
  .bs4-modal .py-md-1, .bs4 .pb-md-1,
  .bs4 .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .react-app .pl-md-1,
  .react-app .px-md-1, .multiple-react-app .pl-md-1,
  .multiple-react-app .px-md-1, .bs4-popover .pl-md-1,
  .bs4-popover .px-md-1, .bs4-modal .pl-md-1,
  .bs4-modal .px-md-1, .bs4 .pl-md-1,
  .bs4 .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .react-app .p-md-2, .multiple-react-app .p-md-2, .bs4-popover .p-md-2, .bs4-modal .p-md-2, .bs4 .p-md-2 {
    padding: 1rem !important;
  }
  .react-app .pt-md-2,
  .react-app .py-md-2, .multiple-react-app .pt-md-2,
  .multiple-react-app .py-md-2, .bs4-popover .pt-md-2,
  .bs4-popover .py-md-2, .bs4-modal .pt-md-2,
  .bs4-modal .py-md-2, .bs4 .pt-md-2,
  .bs4 .py-md-2 {
    padding-top: 1rem !important;
  }
  .react-app .pr-md-2,
  .react-app .px-md-2, .multiple-react-app .pr-md-2,
  .multiple-react-app .px-md-2, .bs4-popover .pr-md-2,
  .bs4-popover .px-md-2, .bs4-modal .pr-md-2,
  .bs4-modal .px-md-2, .bs4 .pr-md-2,
  .bs4 .px-md-2 {
    padding-right: 1rem !important;
  }
  .react-app .pb-md-2,
  .react-app .py-md-2, .multiple-react-app .pb-md-2,
  .multiple-react-app .py-md-2, .bs4-popover .pb-md-2,
  .bs4-popover .py-md-2, .bs4-modal .pb-md-2,
  .bs4-modal .py-md-2, .bs4 .pb-md-2,
  .bs4 .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .react-app .pl-md-2,
  .react-app .px-md-2, .multiple-react-app .pl-md-2,
  .multiple-react-app .px-md-2, .bs4-popover .pl-md-2,
  .bs4-popover .px-md-2, .bs4-modal .pl-md-2,
  .bs4-modal .px-md-2, .bs4 .pl-md-2,
  .bs4 .px-md-2 {
    padding-left: 1rem !important;
  }
  .react-app .p-md-3, .multiple-react-app .p-md-3, .bs4-popover .p-md-3, .bs4-modal .p-md-3, .bs4 .p-md-3 {
    padding: 1.5rem !important;
  }
  .react-app .pt-md-3,
  .react-app .py-md-3, .multiple-react-app .pt-md-3,
  .multiple-react-app .py-md-3, .bs4-popover .pt-md-3,
  .bs4-popover .py-md-3, .bs4-modal .pt-md-3,
  .bs4-modal .py-md-3, .bs4 .pt-md-3,
  .bs4 .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .react-app .pr-md-3,
  .react-app .px-md-3, .multiple-react-app .pr-md-3,
  .multiple-react-app .px-md-3, .bs4-popover .pr-md-3,
  .bs4-popover .px-md-3, .bs4-modal .pr-md-3,
  .bs4-modal .px-md-3, .bs4 .pr-md-3,
  .bs4 .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .react-app .pb-md-3,
  .react-app .py-md-3, .multiple-react-app .pb-md-3,
  .multiple-react-app .py-md-3, .bs4-popover .pb-md-3,
  .bs4-popover .py-md-3, .bs4-modal .pb-md-3,
  .bs4-modal .py-md-3, .bs4 .pb-md-3,
  .bs4 .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .react-app .pl-md-3,
  .react-app .px-md-3, .multiple-react-app .pl-md-3,
  .multiple-react-app .px-md-3, .bs4-popover .pl-md-3,
  .bs4-popover .px-md-3, .bs4-modal .pl-md-3,
  .bs4-modal .px-md-3, .bs4 .pl-md-3,
  .bs4 .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .react-app .p-md-4, .multiple-react-app .p-md-4, .bs4-popover .p-md-4, .bs4-modal .p-md-4, .bs4 .p-md-4 {
    padding: 2rem !important;
  }
  .react-app .pt-md-4,
  .react-app .py-md-4, .multiple-react-app .pt-md-4,
  .multiple-react-app .py-md-4, .bs4-popover .pt-md-4,
  .bs4-popover .py-md-4, .bs4-modal .pt-md-4,
  .bs4-modal .py-md-4, .bs4 .pt-md-4,
  .bs4 .py-md-4 {
    padding-top: 2rem !important;
  }
  .react-app .pr-md-4,
  .react-app .px-md-4, .multiple-react-app .pr-md-4,
  .multiple-react-app .px-md-4, .bs4-popover .pr-md-4,
  .bs4-popover .px-md-4, .bs4-modal .pr-md-4,
  .bs4-modal .px-md-4, .bs4 .pr-md-4,
  .bs4 .px-md-4 {
    padding-right: 2rem !important;
  }
  .react-app .pb-md-4,
  .react-app .py-md-4, .multiple-react-app .pb-md-4,
  .multiple-react-app .py-md-4, .bs4-popover .pb-md-4,
  .bs4-popover .py-md-4, .bs4-modal .pb-md-4,
  .bs4-modal .py-md-4, .bs4 .pb-md-4,
  .bs4 .py-md-4 {
    padding-bottom: 2rem !important;
  }
  .react-app .pl-md-4,
  .react-app .px-md-4, .multiple-react-app .pl-md-4,
  .multiple-react-app .px-md-4, .bs4-popover .pl-md-4,
  .bs4-popover .px-md-4, .bs4-modal .pl-md-4,
  .bs4-modal .px-md-4, .bs4 .pl-md-4,
  .bs4 .px-md-4 {
    padding-left: 2rem !important;
  }
  .react-app .p-md-5, .multiple-react-app .p-md-5, .bs4-popover .p-md-5, .bs4-modal .p-md-5, .bs4 .p-md-5 {
    padding: 3rem !important;
  }
  .react-app .pt-md-5,
  .react-app .py-md-5, .multiple-react-app .pt-md-5,
  .multiple-react-app .py-md-5, .bs4-popover .pt-md-5,
  .bs4-popover .py-md-5, .bs4-modal .pt-md-5,
  .bs4-modal .py-md-5, .bs4 .pt-md-5,
  .bs4 .py-md-5 {
    padding-top: 3rem !important;
  }
  .react-app .pr-md-5,
  .react-app .px-md-5, .multiple-react-app .pr-md-5,
  .multiple-react-app .px-md-5, .bs4-popover .pr-md-5,
  .bs4-popover .px-md-5, .bs4-modal .pr-md-5,
  .bs4-modal .px-md-5, .bs4 .pr-md-5,
  .bs4 .px-md-5 {
    padding-right: 3rem !important;
  }
  .react-app .pb-md-5,
  .react-app .py-md-5, .multiple-react-app .pb-md-5,
  .multiple-react-app .py-md-5, .bs4-popover .pb-md-5,
  .bs4-popover .py-md-5, .bs4-modal .pb-md-5,
  .bs4-modal .py-md-5, .bs4 .pb-md-5,
  .bs4 .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .react-app .pl-md-5,
  .react-app .px-md-5, .multiple-react-app .pl-md-5,
  .multiple-react-app .px-md-5, .bs4-popover .pl-md-5,
  .bs4-popover .px-md-5, .bs4-modal .pl-md-5,
  .bs4-modal .px-md-5, .bs4 .pl-md-5,
  .bs4 .px-md-5 {
    padding-left: 3rem !important;
  }
  .react-app .p-md-6, .multiple-react-app .p-md-6, .bs4-popover .p-md-6, .bs4-modal .p-md-6, .bs4 .p-md-6 {
    padding: 4rem !important;
  }
  .react-app .pt-md-6,
  .react-app .py-md-6, .multiple-react-app .pt-md-6,
  .multiple-react-app .py-md-6, .bs4-popover .pt-md-6,
  .bs4-popover .py-md-6, .bs4-modal .pt-md-6,
  .bs4-modal .py-md-6, .bs4 .pt-md-6,
  .bs4 .py-md-6 {
    padding-top: 4rem !important;
  }
  .react-app .pr-md-6,
  .react-app .px-md-6, .multiple-react-app .pr-md-6,
  .multiple-react-app .px-md-6, .bs4-popover .pr-md-6,
  .bs4-popover .px-md-6, .bs4-modal .pr-md-6,
  .bs4-modal .px-md-6, .bs4 .pr-md-6,
  .bs4 .px-md-6 {
    padding-right: 4rem !important;
  }
  .react-app .pb-md-6,
  .react-app .py-md-6, .multiple-react-app .pb-md-6,
  .multiple-react-app .py-md-6, .bs4-popover .pb-md-6,
  .bs4-popover .py-md-6, .bs4-modal .pb-md-6,
  .bs4-modal .py-md-6, .bs4 .pb-md-6,
  .bs4 .py-md-6 {
    padding-bottom: 4rem !important;
  }
  .react-app .pl-md-6,
  .react-app .px-md-6, .multiple-react-app .pl-md-6,
  .multiple-react-app .px-md-6, .bs4-popover .pl-md-6,
  .bs4-popover .px-md-6, .bs4-modal .pl-md-6,
  .bs4-modal .px-md-6, .bs4 .pl-md-6,
  .bs4 .px-md-6 {
    padding-left: 4rem !important;
  }
  .react-app .p-md-7, .multiple-react-app .p-md-7, .bs4-popover .p-md-7, .bs4-modal .p-md-7, .bs4 .p-md-7 {
    padding: 5rem !important;
  }
  .react-app .pt-md-7,
  .react-app .py-md-7, .multiple-react-app .pt-md-7,
  .multiple-react-app .py-md-7, .bs4-popover .pt-md-7,
  .bs4-popover .py-md-7, .bs4-modal .pt-md-7,
  .bs4-modal .py-md-7, .bs4 .pt-md-7,
  .bs4 .py-md-7 {
    padding-top: 5rem !important;
  }
  .react-app .pr-md-7,
  .react-app .px-md-7, .multiple-react-app .pr-md-7,
  .multiple-react-app .px-md-7, .bs4-popover .pr-md-7,
  .bs4-popover .px-md-7, .bs4-modal .pr-md-7,
  .bs4-modal .px-md-7, .bs4 .pr-md-7,
  .bs4 .px-md-7 {
    padding-right: 5rem !important;
  }
  .react-app .pb-md-7,
  .react-app .py-md-7, .multiple-react-app .pb-md-7,
  .multiple-react-app .py-md-7, .bs4-popover .pb-md-7,
  .bs4-popover .py-md-7, .bs4-modal .pb-md-7,
  .bs4-modal .py-md-7, .bs4 .pb-md-7,
  .bs4 .py-md-7 {
    padding-bottom: 5rem !important;
  }
  .react-app .pl-md-7,
  .react-app .px-md-7, .multiple-react-app .pl-md-7,
  .multiple-react-app .px-md-7, .bs4-popover .pl-md-7,
  .bs4-popover .px-md-7, .bs4-modal .pl-md-7,
  .bs4-modal .px-md-7, .bs4 .pl-md-7,
  .bs4 .px-md-7 {
    padding-left: 5rem !important;
  }
  .react-app .p-md-8, .multiple-react-app .p-md-8, .bs4-popover .p-md-8, .bs4-modal .p-md-8, .bs4 .p-md-8 {
    padding: 6rem !important;
  }
  .react-app .pt-md-8,
  .react-app .py-md-8, .multiple-react-app .pt-md-8,
  .multiple-react-app .py-md-8, .bs4-popover .pt-md-8,
  .bs4-popover .py-md-8, .bs4-modal .pt-md-8,
  .bs4-modal .py-md-8, .bs4 .pt-md-8,
  .bs4 .py-md-8 {
    padding-top: 6rem !important;
  }
  .react-app .pr-md-8,
  .react-app .px-md-8, .multiple-react-app .pr-md-8,
  .multiple-react-app .px-md-8, .bs4-popover .pr-md-8,
  .bs4-popover .px-md-8, .bs4-modal .pr-md-8,
  .bs4-modal .px-md-8, .bs4 .pr-md-8,
  .bs4 .px-md-8 {
    padding-right: 6rem !important;
  }
  .react-app .pb-md-8,
  .react-app .py-md-8, .multiple-react-app .pb-md-8,
  .multiple-react-app .py-md-8, .bs4-popover .pb-md-8,
  .bs4-popover .py-md-8, .bs4-modal .pb-md-8,
  .bs4-modal .py-md-8, .bs4 .pb-md-8,
  .bs4 .py-md-8 {
    padding-bottom: 6rem !important;
  }
  .react-app .pl-md-8,
  .react-app .px-md-8, .multiple-react-app .pl-md-8,
  .multiple-react-app .px-md-8, .bs4-popover .pl-md-8,
  .bs4-popover .px-md-8, .bs4-modal .pl-md-8,
  .bs4-modal .px-md-8, .bs4 .pl-md-8,
  .bs4 .px-md-8 {
    padding-left: 6rem !important;
  }
  .react-app .m-md-n1, .multiple-react-app .m-md-n1, .bs4-popover .m-md-n1, .bs4-modal .m-md-n1, .bs4 .m-md-n1 {
    margin: -0.5rem !important;
  }
  .react-app .mt-md-n1,
  .react-app .my-md-n1, .multiple-react-app .mt-md-n1,
  .multiple-react-app .my-md-n1, .bs4-popover .mt-md-n1,
  .bs4-popover .my-md-n1, .bs4-modal .mt-md-n1,
  .bs4-modal .my-md-n1, .bs4 .mt-md-n1,
  .bs4 .my-md-n1 {
    margin-top: -0.5rem !important;
  }
  .react-app .mr-md-n1,
  .react-app .mx-md-n1, .multiple-react-app .mr-md-n1,
  .multiple-react-app .mx-md-n1, .bs4-popover .mr-md-n1,
  .bs4-popover .mx-md-n1, .bs4-modal .mr-md-n1,
  .bs4-modal .mx-md-n1, .bs4 .mr-md-n1,
  .bs4 .mx-md-n1 {
    margin-right: -0.5rem !important;
  }
  .react-app .mb-md-n1,
  .react-app .my-md-n1, .multiple-react-app .mb-md-n1,
  .multiple-react-app .my-md-n1, .bs4-popover .mb-md-n1,
  .bs4-popover .my-md-n1, .bs4-modal .mb-md-n1,
  .bs4-modal .my-md-n1, .bs4 .mb-md-n1,
  .bs4 .my-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .react-app .ml-md-n1,
  .react-app .mx-md-n1, .multiple-react-app .ml-md-n1,
  .multiple-react-app .mx-md-n1, .bs4-popover .ml-md-n1,
  .bs4-popover .mx-md-n1, .bs4-modal .ml-md-n1,
  .bs4-modal .mx-md-n1, .bs4 .ml-md-n1,
  .bs4 .mx-md-n1 {
    margin-left: -0.5rem !important;
  }
  .react-app .m-md-n2, .multiple-react-app .m-md-n2, .bs4-popover .m-md-n2, .bs4-modal .m-md-n2, .bs4 .m-md-n2 {
    margin: -1rem !important;
  }
  .react-app .mt-md-n2,
  .react-app .my-md-n2, .multiple-react-app .mt-md-n2,
  .multiple-react-app .my-md-n2, .bs4-popover .mt-md-n2,
  .bs4-popover .my-md-n2, .bs4-modal .mt-md-n2,
  .bs4-modal .my-md-n2, .bs4 .mt-md-n2,
  .bs4 .my-md-n2 {
    margin-top: -1rem !important;
  }
  .react-app .mr-md-n2,
  .react-app .mx-md-n2, .multiple-react-app .mr-md-n2,
  .multiple-react-app .mx-md-n2, .bs4-popover .mr-md-n2,
  .bs4-popover .mx-md-n2, .bs4-modal .mr-md-n2,
  .bs4-modal .mx-md-n2, .bs4 .mr-md-n2,
  .bs4 .mx-md-n2 {
    margin-right: -1rem !important;
  }
  .react-app .mb-md-n2,
  .react-app .my-md-n2, .multiple-react-app .mb-md-n2,
  .multiple-react-app .my-md-n2, .bs4-popover .mb-md-n2,
  .bs4-popover .my-md-n2, .bs4-modal .mb-md-n2,
  .bs4-modal .my-md-n2, .bs4 .mb-md-n2,
  .bs4 .my-md-n2 {
    margin-bottom: -1rem !important;
  }
  .react-app .ml-md-n2,
  .react-app .mx-md-n2, .multiple-react-app .ml-md-n2,
  .multiple-react-app .mx-md-n2, .bs4-popover .ml-md-n2,
  .bs4-popover .mx-md-n2, .bs4-modal .ml-md-n2,
  .bs4-modal .mx-md-n2, .bs4 .ml-md-n2,
  .bs4 .mx-md-n2 {
    margin-left: -1rem !important;
  }
  .react-app .m-md-n3, .multiple-react-app .m-md-n3, .bs4-popover .m-md-n3, .bs4-modal .m-md-n3, .bs4 .m-md-n3 {
    margin: -1.5rem !important;
  }
  .react-app .mt-md-n3,
  .react-app .my-md-n3, .multiple-react-app .mt-md-n3,
  .multiple-react-app .my-md-n3, .bs4-popover .mt-md-n3,
  .bs4-popover .my-md-n3, .bs4-modal .mt-md-n3,
  .bs4-modal .my-md-n3, .bs4 .mt-md-n3,
  .bs4 .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .react-app .mr-md-n3,
  .react-app .mx-md-n3, .multiple-react-app .mr-md-n3,
  .multiple-react-app .mx-md-n3, .bs4-popover .mr-md-n3,
  .bs4-popover .mx-md-n3, .bs4-modal .mr-md-n3,
  .bs4-modal .mx-md-n3, .bs4 .mr-md-n3,
  .bs4 .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .react-app .mb-md-n3,
  .react-app .my-md-n3, .multiple-react-app .mb-md-n3,
  .multiple-react-app .my-md-n3, .bs4-popover .mb-md-n3,
  .bs4-popover .my-md-n3, .bs4-modal .mb-md-n3,
  .bs4-modal .my-md-n3, .bs4 .mb-md-n3,
  .bs4 .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .react-app .ml-md-n3,
  .react-app .mx-md-n3, .multiple-react-app .ml-md-n3,
  .multiple-react-app .mx-md-n3, .bs4-popover .ml-md-n3,
  .bs4-popover .mx-md-n3, .bs4-modal .ml-md-n3,
  .bs4-modal .mx-md-n3, .bs4 .ml-md-n3,
  .bs4 .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .react-app .m-md-n4, .multiple-react-app .m-md-n4, .bs4-popover .m-md-n4, .bs4-modal .m-md-n4, .bs4 .m-md-n4 {
    margin: -2rem !important;
  }
  .react-app .mt-md-n4,
  .react-app .my-md-n4, .multiple-react-app .mt-md-n4,
  .multiple-react-app .my-md-n4, .bs4-popover .mt-md-n4,
  .bs4-popover .my-md-n4, .bs4-modal .mt-md-n4,
  .bs4-modal .my-md-n4, .bs4 .mt-md-n4,
  .bs4 .my-md-n4 {
    margin-top: -2rem !important;
  }
  .react-app .mr-md-n4,
  .react-app .mx-md-n4, .multiple-react-app .mr-md-n4,
  .multiple-react-app .mx-md-n4, .bs4-popover .mr-md-n4,
  .bs4-popover .mx-md-n4, .bs4-modal .mr-md-n4,
  .bs4-modal .mx-md-n4, .bs4 .mr-md-n4,
  .bs4 .mx-md-n4 {
    margin-right: -2rem !important;
  }
  .react-app .mb-md-n4,
  .react-app .my-md-n4, .multiple-react-app .mb-md-n4,
  .multiple-react-app .my-md-n4, .bs4-popover .mb-md-n4,
  .bs4-popover .my-md-n4, .bs4-modal .mb-md-n4,
  .bs4-modal .my-md-n4, .bs4 .mb-md-n4,
  .bs4 .my-md-n4 {
    margin-bottom: -2rem !important;
  }
  .react-app .ml-md-n4,
  .react-app .mx-md-n4, .multiple-react-app .ml-md-n4,
  .multiple-react-app .mx-md-n4, .bs4-popover .ml-md-n4,
  .bs4-popover .mx-md-n4, .bs4-modal .ml-md-n4,
  .bs4-modal .mx-md-n4, .bs4 .ml-md-n4,
  .bs4 .mx-md-n4 {
    margin-left: -2rem !important;
  }
  .react-app .m-md-n5, .multiple-react-app .m-md-n5, .bs4-popover .m-md-n5, .bs4-modal .m-md-n5, .bs4 .m-md-n5 {
    margin: -3rem !important;
  }
  .react-app .mt-md-n5,
  .react-app .my-md-n5, .multiple-react-app .mt-md-n5,
  .multiple-react-app .my-md-n5, .bs4-popover .mt-md-n5,
  .bs4-popover .my-md-n5, .bs4-modal .mt-md-n5,
  .bs4-modal .my-md-n5, .bs4 .mt-md-n5,
  .bs4 .my-md-n5 {
    margin-top: -3rem !important;
  }
  .react-app .mr-md-n5,
  .react-app .mx-md-n5, .multiple-react-app .mr-md-n5,
  .multiple-react-app .mx-md-n5, .bs4-popover .mr-md-n5,
  .bs4-popover .mx-md-n5, .bs4-modal .mr-md-n5,
  .bs4-modal .mx-md-n5, .bs4 .mr-md-n5,
  .bs4 .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .react-app .mb-md-n5,
  .react-app .my-md-n5, .multiple-react-app .mb-md-n5,
  .multiple-react-app .my-md-n5, .bs4-popover .mb-md-n5,
  .bs4-popover .my-md-n5, .bs4-modal .mb-md-n5,
  .bs4-modal .my-md-n5, .bs4 .mb-md-n5,
  .bs4 .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .react-app .ml-md-n5,
  .react-app .mx-md-n5, .multiple-react-app .ml-md-n5,
  .multiple-react-app .mx-md-n5, .bs4-popover .ml-md-n5,
  .bs4-popover .mx-md-n5, .bs4-modal .ml-md-n5,
  .bs4-modal .mx-md-n5, .bs4 .ml-md-n5,
  .bs4 .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .react-app .m-md-n6, .multiple-react-app .m-md-n6, .bs4-popover .m-md-n6, .bs4-modal .m-md-n6, .bs4 .m-md-n6 {
    margin: -4rem !important;
  }
  .react-app .mt-md-n6,
  .react-app .my-md-n6, .multiple-react-app .mt-md-n6,
  .multiple-react-app .my-md-n6, .bs4-popover .mt-md-n6,
  .bs4-popover .my-md-n6, .bs4-modal .mt-md-n6,
  .bs4-modal .my-md-n6, .bs4 .mt-md-n6,
  .bs4 .my-md-n6 {
    margin-top: -4rem !important;
  }
  .react-app .mr-md-n6,
  .react-app .mx-md-n6, .multiple-react-app .mr-md-n6,
  .multiple-react-app .mx-md-n6, .bs4-popover .mr-md-n6,
  .bs4-popover .mx-md-n6, .bs4-modal .mr-md-n6,
  .bs4-modal .mx-md-n6, .bs4 .mr-md-n6,
  .bs4 .mx-md-n6 {
    margin-right: -4rem !important;
  }
  .react-app .mb-md-n6,
  .react-app .my-md-n6, .multiple-react-app .mb-md-n6,
  .multiple-react-app .my-md-n6, .bs4-popover .mb-md-n6,
  .bs4-popover .my-md-n6, .bs4-modal .mb-md-n6,
  .bs4-modal .my-md-n6, .bs4 .mb-md-n6,
  .bs4 .my-md-n6 {
    margin-bottom: -4rem !important;
  }
  .react-app .ml-md-n6,
  .react-app .mx-md-n6, .multiple-react-app .ml-md-n6,
  .multiple-react-app .mx-md-n6, .bs4-popover .ml-md-n6,
  .bs4-popover .mx-md-n6, .bs4-modal .ml-md-n6,
  .bs4-modal .mx-md-n6, .bs4 .ml-md-n6,
  .bs4 .mx-md-n6 {
    margin-left: -4rem !important;
  }
  .react-app .m-md-n7, .multiple-react-app .m-md-n7, .bs4-popover .m-md-n7, .bs4-modal .m-md-n7, .bs4 .m-md-n7 {
    margin: -5rem !important;
  }
  .react-app .mt-md-n7,
  .react-app .my-md-n7, .multiple-react-app .mt-md-n7,
  .multiple-react-app .my-md-n7, .bs4-popover .mt-md-n7,
  .bs4-popover .my-md-n7, .bs4-modal .mt-md-n7,
  .bs4-modal .my-md-n7, .bs4 .mt-md-n7,
  .bs4 .my-md-n7 {
    margin-top: -5rem !important;
  }
  .react-app .mr-md-n7,
  .react-app .mx-md-n7, .multiple-react-app .mr-md-n7,
  .multiple-react-app .mx-md-n7, .bs4-popover .mr-md-n7,
  .bs4-popover .mx-md-n7, .bs4-modal .mr-md-n7,
  .bs4-modal .mx-md-n7, .bs4 .mr-md-n7,
  .bs4 .mx-md-n7 {
    margin-right: -5rem !important;
  }
  .react-app .mb-md-n7,
  .react-app .my-md-n7, .multiple-react-app .mb-md-n7,
  .multiple-react-app .my-md-n7, .bs4-popover .mb-md-n7,
  .bs4-popover .my-md-n7, .bs4-modal .mb-md-n7,
  .bs4-modal .my-md-n7, .bs4 .mb-md-n7,
  .bs4 .my-md-n7 {
    margin-bottom: -5rem !important;
  }
  .react-app .ml-md-n7,
  .react-app .mx-md-n7, .multiple-react-app .ml-md-n7,
  .multiple-react-app .mx-md-n7, .bs4-popover .ml-md-n7,
  .bs4-popover .mx-md-n7, .bs4-modal .ml-md-n7,
  .bs4-modal .mx-md-n7, .bs4 .ml-md-n7,
  .bs4 .mx-md-n7 {
    margin-left: -5rem !important;
  }
  .react-app .m-md-n8, .multiple-react-app .m-md-n8, .bs4-popover .m-md-n8, .bs4-modal .m-md-n8, .bs4 .m-md-n8 {
    margin: -6rem !important;
  }
  .react-app .mt-md-n8,
  .react-app .my-md-n8, .multiple-react-app .mt-md-n8,
  .multiple-react-app .my-md-n8, .bs4-popover .mt-md-n8,
  .bs4-popover .my-md-n8, .bs4-modal .mt-md-n8,
  .bs4-modal .my-md-n8, .bs4 .mt-md-n8,
  .bs4 .my-md-n8 {
    margin-top: -6rem !important;
  }
  .react-app .mr-md-n8,
  .react-app .mx-md-n8, .multiple-react-app .mr-md-n8,
  .multiple-react-app .mx-md-n8, .bs4-popover .mr-md-n8,
  .bs4-popover .mx-md-n8, .bs4-modal .mr-md-n8,
  .bs4-modal .mx-md-n8, .bs4 .mr-md-n8,
  .bs4 .mx-md-n8 {
    margin-right: -6rem !important;
  }
  .react-app .mb-md-n8,
  .react-app .my-md-n8, .multiple-react-app .mb-md-n8,
  .multiple-react-app .my-md-n8, .bs4-popover .mb-md-n8,
  .bs4-popover .my-md-n8, .bs4-modal .mb-md-n8,
  .bs4-modal .my-md-n8, .bs4 .mb-md-n8,
  .bs4 .my-md-n8 {
    margin-bottom: -6rem !important;
  }
  .react-app .ml-md-n8,
  .react-app .mx-md-n8, .multiple-react-app .ml-md-n8,
  .multiple-react-app .mx-md-n8, .bs4-popover .ml-md-n8,
  .bs4-popover .mx-md-n8, .bs4-modal .ml-md-n8,
  .bs4-modal .mx-md-n8, .bs4 .ml-md-n8,
  .bs4 .mx-md-n8 {
    margin-left: -6rem !important;
  }
  .react-app .m-md-auto, .multiple-react-app .m-md-auto, .bs4-popover .m-md-auto, .bs4-modal .m-md-auto, .bs4 .m-md-auto {
    margin: auto !important;
  }
  .react-app .mt-md-auto,
  .react-app .my-md-auto, .multiple-react-app .mt-md-auto,
  .multiple-react-app .my-md-auto, .bs4-popover .mt-md-auto,
  .bs4-popover .my-md-auto, .bs4-modal .mt-md-auto,
  .bs4-modal .my-md-auto, .bs4 .mt-md-auto,
  .bs4 .my-md-auto {
    margin-top: auto !important;
  }
  .react-app .mr-md-auto,
  .react-app .mx-md-auto, .multiple-react-app .mr-md-auto,
  .multiple-react-app .mx-md-auto, .bs4-popover .mr-md-auto,
  .bs4-popover .mx-md-auto, .bs4-modal .mr-md-auto,
  .bs4-modal .mx-md-auto, .bs4 .mr-md-auto,
  .bs4 .mx-md-auto {
    margin-right: auto !important;
  }
  .react-app .mb-md-auto,
  .react-app .my-md-auto, .multiple-react-app .mb-md-auto,
  .multiple-react-app .my-md-auto, .bs4-popover .mb-md-auto,
  .bs4-popover .my-md-auto, .bs4-modal .mb-md-auto,
  .bs4-modal .my-md-auto, .bs4 .mb-md-auto,
  .bs4 .my-md-auto {
    margin-bottom: auto !important;
  }
  .react-app .ml-md-auto,
  .react-app .mx-md-auto, .multiple-react-app .ml-md-auto,
  .multiple-react-app .mx-md-auto, .bs4-popover .ml-md-auto,
  .bs4-popover .mx-md-auto, .bs4-modal .ml-md-auto,
  .bs4-modal .mx-md-auto, .bs4 .ml-md-auto,
  .bs4 .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .react-app .m-lg-0, .multiple-react-app .m-lg-0, .bs4-popover .m-lg-0, .bs4-modal .m-lg-0, .bs4 .m-lg-0 {
    margin: 0 !important;
  }
  .react-app .mt-lg-0,
  .react-app .my-lg-0, .multiple-react-app .mt-lg-0,
  .multiple-react-app .my-lg-0, .bs4-popover .mt-lg-0,
  .bs4-popover .my-lg-0, .bs4-modal .mt-lg-0,
  .bs4-modal .my-lg-0, .bs4 .mt-lg-0,
  .bs4 .my-lg-0 {
    margin-top: 0 !important;
  }
  .react-app .mr-lg-0,
  .react-app .mx-lg-0, .multiple-react-app .mr-lg-0,
  .multiple-react-app .mx-lg-0, .bs4-popover .mr-lg-0,
  .bs4-popover .mx-lg-0, .bs4-modal .mr-lg-0,
  .bs4-modal .mx-lg-0, .bs4 .mr-lg-0,
  .bs4 .mx-lg-0 {
    margin-right: 0 !important;
  }
  .react-app .mb-lg-0,
  .react-app .my-lg-0, .multiple-react-app .mb-lg-0,
  .multiple-react-app .my-lg-0, .bs4-popover .mb-lg-0,
  .bs4-popover .my-lg-0, .bs4-modal .mb-lg-0,
  .bs4-modal .my-lg-0, .bs4 .mb-lg-0,
  .bs4 .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .react-app .ml-lg-0,
  .react-app .mx-lg-0, .multiple-react-app .ml-lg-0,
  .multiple-react-app .mx-lg-0, .bs4-popover .ml-lg-0,
  .bs4-popover .mx-lg-0, .bs4-modal .ml-lg-0,
  .bs4-modal .mx-lg-0, .bs4 .ml-lg-0,
  .bs4 .mx-lg-0 {
    margin-left: 0 !important;
  }
  .react-app .m-lg-1, .multiple-react-app .m-lg-1, .bs4-popover .m-lg-1, .bs4-modal .m-lg-1, .bs4 .m-lg-1 {
    margin: 0.5rem !important;
  }
  .react-app .mt-lg-1,
  .react-app .my-lg-1, .multiple-react-app .mt-lg-1,
  .multiple-react-app .my-lg-1, .bs4-popover .mt-lg-1,
  .bs4-popover .my-lg-1, .bs4-modal .mt-lg-1,
  .bs4-modal .my-lg-1, .bs4 .mt-lg-1,
  .bs4 .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .react-app .mr-lg-1,
  .react-app .mx-lg-1, .multiple-react-app .mr-lg-1,
  .multiple-react-app .mx-lg-1, .bs4-popover .mr-lg-1,
  .bs4-popover .mx-lg-1, .bs4-modal .mr-lg-1,
  .bs4-modal .mx-lg-1, .bs4 .mr-lg-1,
  .bs4 .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .react-app .mb-lg-1,
  .react-app .my-lg-1, .multiple-react-app .mb-lg-1,
  .multiple-react-app .my-lg-1, .bs4-popover .mb-lg-1,
  .bs4-popover .my-lg-1, .bs4-modal .mb-lg-1,
  .bs4-modal .my-lg-1, .bs4 .mb-lg-1,
  .bs4 .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .react-app .ml-lg-1,
  .react-app .mx-lg-1, .multiple-react-app .ml-lg-1,
  .multiple-react-app .mx-lg-1, .bs4-popover .ml-lg-1,
  .bs4-popover .mx-lg-1, .bs4-modal .ml-lg-1,
  .bs4-modal .mx-lg-1, .bs4 .ml-lg-1,
  .bs4 .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .react-app .m-lg-2, .multiple-react-app .m-lg-2, .bs4-popover .m-lg-2, .bs4-modal .m-lg-2, .bs4 .m-lg-2 {
    margin: 1rem !important;
  }
  .react-app .mt-lg-2,
  .react-app .my-lg-2, .multiple-react-app .mt-lg-2,
  .multiple-react-app .my-lg-2, .bs4-popover .mt-lg-2,
  .bs4-popover .my-lg-2, .bs4-modal .mt-lg-2,
  .bs4-modal .my-lg-2, .bs4 .mt-lg-2,
  .bs4 .my-lg-2 {
    margin-top: 1rem !important;
  }
  .react-app .mr-lg-2,
  .react-app .mx-lg-2, .multiple-react-app .mr-lg-2,
  .multiple-react-app .mx-lg-2, .bs4-popover .mr-lg-2,
  .bs4-popover .mx-lg-2, .bs4-modal .mr-lg-2,
  .bs4-modal .mx-lg-2, .bs4 .mr-lg-2,
  .bs4 .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .react-app .mb-lg-2,
  .react-app .my-lg-2, .multiple-react-app .mb-lg-2,
  .multiple-react-app .my-lg-2, .bs4-popover .mb-lg-2,
  .bs4-popover .my-lg-2, .bs4-modal .mb-lg-2,
  .bs4-modal .my-lg-2, .bs4 .mb-lg-2,
  .bs4 .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .react-app .ml-lg-2,
  .react-app .mx-lg-2, .multiple-react-app .ml-lg-2,
  .multiple-react-app .mx-lg-2, .bs4-popover .ml-lg-2,
  .bs4-popover .mx-lg-2, .bs4-modal .ml-lg-2,
  .bs4-modal .mx-lg-2, .bs4 .ml-lg-2,
  .bs4 .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .react-app .m-lg-3, .multiple-react-app .m-lg-3, .bs4-popover .m-lg-3, .bs4-modal .m-lg-3, .bs4 .m-lg-3 {
    margin: 1.5rem !important;
  }
  .react-app .mt-lg-3,
  .react-app .my-lg-3, .multiple-react-app .mt-lg-3,
  .multiple-react-app .my-lg-3, .bs4-popover .mt-lg-3,
  .bs4-popover .my-lg-3, .bs4-modal .mt-lg-3,
  .bs4-modal .my-lg-3, .bs4 .mt-lg-3,
  .bs4 .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .react-app .mr-lg-3,
  .react-app .mx-lg-3, .multiple-react-app .mr-lg-3,
  .multiple-react-app .mx-lg-3, .bs4-popover .mr-lg-3,
  .bs4-popover .mx-lg-3, .bs4-modal .mr-lg-3,
  .bs4-modal .mx-lg-3, .bs4 .mr-lg-3,
  .bs4 .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .react-app .mb-lg-3,
  .react-app .my-lg-3, .multiple-react-app .mb-lg-3,
  .multiple-react-app .my-lg-3, .bs4-popover .mb-lg-3,
  .bs4-popover .my-lg-3, .bs4-modal .mb-lg-3,
  .bs4-modal .my-lg-3, .bs4 .mb-lg-3,
  .bs4 .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .react-app .ml-lg-3,
  .react-app .mx-lg-3, .multiple-react-app .ml-lg-3,
  .multiple-react-app .mx-lg-3, .bs4-popover .ml-lg-3,
  .bs4-popover .mx-lg-3, .bs4-modal .ml-lg-3,
  .bs4-modal .mx-lg-3, .bs4 .ml-lg-3,
  .bs4 .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .react-app .m-lg-4, .multiple-react-app .m-lg-4, .bs4-popover .m-lg-4, .bs4-modal .m-lg-4, .bs4 .m-lg-4 {
    margin: 2rem !important;
  }
  .react-app .mt-lg-4,
  .react-app .my-lg-4, .multiple-react-app .mt-lg-4,
  .multiple-react-app .my-lg-4, .bs4-popover .mt-lg-4,
  .bs4-popover .my-lg-4, .bs4-modal .mt-lg-4,
  .bs4-modal .my-lg-4, .bs4 .mt-lg-4,
  .bs4 .my-lg-4 {
    margin-top: 2rem !important;
  }
  .react-app .mr-lg-4,
  .react-app .mx-lg-4, .multiple-react-app .mr-lg-4,
  .multiple-react-app .mx-lg-4, .bs4-popover .mr-lg-4,
  .bs4-popover .mx-lg-4, .bs4-modal .mr-lg-4,
  .bs4-modal .mx-lg-4, .bs4 .mr-lg-4,
  .bs4 .mx-lg-4 {
    margin-right: 2rem !important;
  }
  .react-app .mb-lg-4,
  .react-app .my-lg-4, .multiple-react-app .mb-lg-4,
  .multiple-react-app .my-lg-4, .bs4-popover .mb-lg-4,
  .bs4-popover .my-lg-4, .bs4-modal .mb-lg-4,
  .bs4-modal .my-lg-4, .bs4 .mb-lg-4,
  .bs4 .my-lg-4 {
    margin-bottom: 2rem !important;
  }
  .react-app .ml-lg-4,
  .react-app .mx-lg-4, .multiple-react-app .ml-lg-4,
  .multiple-react-app .mx-lg-4, .bs4-popover .ml-lg-4,
  .bs4-popover .mx-lg-4, .bs4-modal .ml-lg-4,
  .bs4-modal .mx-lg-4, .bs4 .ml-lg-4,
  .bs4 .mx-lg-4 {
    margin-left: 2rem !important;
  }
  .react-app .m-lg-5, .multiple-react-app .m-lg-5, .bs4-popover .m-lg-5, .bs4-modal .m-lg-5, .bs4 .m-lg-5 {
    margin: 3rem !important;
  }
  .react-app .mt-lg-5,
  .react-app .my-lg-5, .multiple-react-app .mt-lg-5,
  .multiple-react-app .my-lg-5, .bs4-popover .mt-lg-5,
  .bs4-popover .my-lg-5, .bs4-modal .mt-lg-5,
  .bs4-modal .my-lg-5, .bs4 .mt-lg-5,
  .bs4 .my-lg-5 {
    margin-top: 3rem !important;
  }
  .react-app .mr-lg-5,
  .react-app .mx-lg-5, .multiple-react-app .mr-lg-5,
  .multiple-react-app .mx-lg-5, .bs4-popover .mr-lg-5,
  .bs4-popover .mx-lg-5, .bs4-modal .mr-lg-5,
  .bs4-modal .mx-lg-5, .bs4 .mr-lg-5,
  .bs4 .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .react-app .mb-lg-5,
  .react-app .my-lg-5, .multiple-react-app .mb-lg-5,
  .multiple-react-app .my-lg-5, .bs4-popover .mb-lg-5,
  .bs4-popover .my-lg-5, .bs4-modal .mb-lg-5,
  .bs4-modal .my-lg-5, .bs4 .mb-lg-5,
  .bs4 .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .react-app .ml-lg-5,
  .react-app .mx-lg-5, .multiple-react-app .ml-lg-5,
  .multiple-react-app .mx-lg-5, .bs4-popover .ml-lg-5,
  .bs4-popover .mx-lg-5, .bs4-modal .ml-lg-5,
  .bs4-modal .mx-lg-5, .bs4 .ml-lg-5,
  .bs4 .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .react-app .m-lg-6, .multiple-react-app .m-lg-6, .bs4-popover .m-lg-6, .bs4-modal .m-lg-6, .bs4 .m-lg-6 {
    margin: 4rem !important;
  }
  .react-app .mt-lg-6,
  .react-app .my-lg-6, .multiple-react-app .mt-lg-6,
  .multiple-react-app .my-lg-6, .bs4-popover .mt-lg-6,
  .bs4-popover .my-lg-6, .bs4-modal .mt-lg-6,
  .bs4-modal .my-lg-6, .bs4 .mt-lg-6,
  .bs4 .my-lg-6 {
    margin-top: 4rem !important;
  }
  .react-app .mr-lg-6,
  .react-app .mx-lg-6, .multiple-react-app .mr-lg-6,
  .multiple-react-app .mx-lg-6, .bs4-popover .mr-lg-6,
  .bs4-popover .mx-lg-6, .bs4-modal .mr-lg-6,
  .bs4-modal .mx-lg-6, .bs4 .mr-lg-6,
  .bs4 .mx-lg-6 {
    margin-right: 4rem !important;
  }
  .react-app .mb-lg-6,
  .react-app .my-lg-6, .multiple-react-app .mb-lg-6,
  .multiple-react-app .my-lg-6, .bs4-popover .mb-lg-6,
  .bs4-popover .my-lg-6, .bs4-modal .mb-lg-6,
  .bs4-modal .my-lg-6, .bs4 .mb-lg-6,
  .bs4 .my-lg-6 {
    margin-bottom: 4rem !important;
  }
  .react-app .ml-lg-6,
  .react-app .mx-lg-6, .multiple-react-app .ml-lg-6,
  .multiple-react-app .mx-lg-6, .bs4-popover .ml-lg-6,
  .bs4-popover .mx-lg-6, .bs4-modal .ml-lg-6,
  .bs4-modal .mx-lg-6, .bs4 .ml-lg-6,
  .bs4 .mx-lg-6 {
    margin-left: 4rem !important;
  }
  .react-app .m-lg-7, .multiple-react-app .m-lg-7, .bs4-popover .m-lg-7, .bs4-modal .m-lg-7, .bs4 .m-lg-7 {
    margin: 5rem !important;
  }
  .react-app .mt-lg-7,
  .react-app .my-lg-7, .multiple-react-app .mt-lg-7,
  .multiple-react-app .my-lg-7, .bs4-popover .mt-lg-7,
  .bs4-popover .my-lg-7, .bs4-modal .mt-lg-7,
  .bs4-modal .my-lg-7, .bs4 .mt-lg-7,
  .bs4 .my-lg-7 {
    margin-top: 5rem !important;
  }
  .react-app .mr-lg-7,
  .react-app .mx-lg-7, .multiple-react-app .mr-lg-7,
  .multiple-react-app .mx-lg-7, .bs4-popover .mr-lg-7,
  .bs4-popover .mx-lg-7, .bs4-modal .mr-lg-7,
  .bs4-modal .mx-lg-7, .bs4 .mr-lg-7,
  .bs4 .mx-lg-7 {
    margin-right: 5rem !important;
  }
  .react-app .mb-lg-7,
  .react-app .my-lg-7, .multiple-react-app .mb-lg-7,
  .multiple-react-app .my-lg-7, .bs4-popover .mb-lg-7,
  .bs4-popover .my-lg-7, .bs4-modal .mb-lg-7,
  .bs4-modal .my-lg-7, .bs4 .mb-lg-7,
  .bs4 .my-lg-7 {
    margin-bottom: 5rem !important;
  }
  .react-app .ml-lg-7,
  .react-app .mx-lg-7, .multiple-react-app .ml-lg-7,
  .multiple-react-app .mx-lg-7, .bs4-popover .ml-lg-7,
  .bs4-popover .mx-lg-7, .bs4-modal .ml-lg-7,
  .bs4-modal .mx-lg-7, .bs4 .ml-lg-7,
  .bs4 .mx-lg-7 {
    margin-left: 5rem !important;
  }
  .react-app .m-lg-8, .multiple-react-app .m-lg-8, .bs4-popover .m-lg-8, .bs4-modal .m-lg-8, .bs4 .m-lg-8 {
    margin: 6rem !important;
  }
  .react-app .mt-lg-8,
  .react-app .my-lg-8, .multiple-react-app .mt-lg-8,
  .multiple-react-app .my-lg-8, .bs4-popover .mt-lg-8,
  .bs4-popover .my-lg-8, .bs4-modal .mt-lg-8,
  .bs4-modal .my-lg-8, .bs4 .mt-lg-8,
  .bs4 .my-lg-8 {
    margin-top: 6rem !important;
  }
  .react-app .mr-lg-8,
  .react-app .mx-lg-8, .multiple-react-app .mr-lg-8,
  .multiple-react-app .mx-lg-8, .bs4-popover .mr-lg-8,
  .bs4-popover .mx-lg-8, .bs4-modal .mr-lg-8,
  .bs4-modal .mx-lg-8, .bs4 .mr-lg-8,
  .bs4 .mx-lg-8 {
    margin-right: 6rem !important;
  }
  .react-app .mb-lg-8,
  .react-app .my-lg-8, .multiple-react-app .mb-lg-8,
  .multiple-react-app .my-lg-8, .bs4-popover .mb-lg-8,
  .bs4-popover .my-lg-8, .bs4-modal .mb-lg-8,
  .bs4-modal .my-lg-8, .bs4 .mb-lg-8,
  .bs4 .my-lg-8 {
    margin-bottom: 6rem !important;
  }
  .react-app .ml-lg-8,
  .react-app .mx-lg-8, .multiple-react-app .ml-lg-8,
  .multiple-react-app .mx-lg-8, .bs4-popover .ml-lg-8,
  .bs4-popover .mx-lg-8, .bs4-modal .ml-lg-8,
  .bs4-modal .mx-lg-8, .bs4 .ml-lg-8,
  .bs4 .mx-lg-8 {
    margin-left: 6rem !important;
  }
  .react-app .p-lg-0, .multiple-react-app .p-lg-0, .bs4-popover .p-lg-0, .bs4-modal .p-lg-0, .bs4 .p-lg-0 {
    padding: 0 !important;
  }
  .react-app .pt-lg-0,
  .react-app .py-lg-0, .multiple-react-app .pt-lg-0,
  .multiple-react-app .py-lg-0, .bs4-popover .pt-lg-0,
  .bs4-popover .py-lg-0, .bs4-modal .pt-lg-0,
  .bs4-modal .py-lg-0, .bs4 .pt-lg-0,
  .bs4 .py-lg-0 {
    padding-top: 0 !important;
  }
  .react-app .pr-lg-0,
  .react-app .px-lg-0, .multiple-react-app .pr-lg-0,
  .multiple-react-app .px-lg-0, .bs4-popover .pr-lg-0,
  .bs4-popover .px-lg-0, .bs4-modal .pr-lg-0,
  .bs4-modal .px-lg-0, .bs4 .pr-lg-0,
  .bs4 .px-lg-0 {
    padding-right: 0 !important;
  }
  .react-app .pb-lg-0,
  .react-app .py-lg-0, .multiple-react-app .pb-lg-0,
  .multiple-react-app .py-lg-0, .bs4-popover .pb-lg-0,
  .bs4-popover .py-lg-0, .bs4-modal .pb-lg-0,
  .bs4-modal .py-lg-0, .bs4 .pb-lg-0,
  .bs4 .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .react-app .pl-lg-0,
  .react-app .px-lg-0, .multiple-react-app .pl-lg-0,
  .multiple-react-app .px-lg-0, .bs4-popover .pl-lg-0,
  .bs4-popover .px-lg-0, .bs4-modal .pl-lg-0,
  .bs4-modal .px-lg-0, .bs4 .pl-lg-0,
  .bs4 .px-lg-0 {
    padding-left: 0 !important;
  }
  .react-app .p-lg-1, .multiple-react-app .p-lg-1, .bs4-popover .p-lg-1, .bs4-modal .p-lg-1, .bs4 .p-lg-1 {
    padding: 0.5rem !important;
  }
  .react-app .pt-lg-1,
  .react-app .py-lg-1, .multiple-react-app .pt-lg-1,
  .multiple-react-app .py-lg-1, .bs4-popover .pt-lg-1,
  .bs4-popover .py-lg-1, .bs4-modal .pt-lg-1,
  .bs4-modal .py-lg-1, .bs4 .pt-lg-1,
  .bs4 .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .react-app .pr-lg-1,
  .react-app .px-lg-1, .multiple-react-app .pr-lg-1,
  .multiple-react-app .px-lg-1, .bs4-popover .pr-lg-1,
  .bs4-popover .px-lg-1, .bs4-modal .pr-lg-1,
  .bs4-modal .px-lg-1, .bs4 .pr-lg-1,
  .bs4 .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .react-app .pb-lg-1,
  .react-app .py-lg-1, .multiple-react-app .pb-lg-1,
  .multiple-react-app .py-lg-1, .bs4-popover .pb-lg-1,
  .bs4-popover .py-lg-1, .bs4-modal .pb-lg-1,
  .bs4-modal .py-lg-1, .bs4 .pb-lg-1,
  .bs4 .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .react-app .pl-lg-1,
  .react-app .px-lg-1, .multiple-react-app .pl-lg-1,
  .multiple-react-app .px-lg-1, .bs4-popover .pl-lg-1,
  .bs4-popover .px-lg-1, .bs4-modal .pl-lg-1,
  .bs4-modal .px-lg-1, .bs4 .pl-lg-1,
  .bs4 .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .react-app .p-lg-2, .multiple-react-app .p-lg-2, .bs4-popover .p-lg-2, .bs4-modal .p-lg-2, .bs4 .p-lg-2 {
    padding: 1rem !important;
  }
  .react-app .pt-lg-2,
  .react-app .py-lg-2, .multiple-react-app .pt-lg-2,
  .multiple-react-app .py-lg-2, .bs4-popover .pt-lg-2,
  .bs4-popover .py-lg-2, .bs4-modal .pt-lg-2,
  .bs4-modal .py-lg-2, .bs4 .pt-lg-2,
  .bs4 .py-lg-2 {
    padding-top: 1rem !important;
  }
  .react-app .pr-lg-2,
  .react-app .px-lg-2, .multiple-react-app .pr-lg-2,
  .multiple-react-app .px-lg-2, .bs4-popover .pr-lg-2,
  .bs4-popover .px-lg-2, .bs4-modal .pr-lg-2,
  .bs4-modal .px-lg-2, .bs4 .pr-lg-2,
  .bs4 .px-lg-2 {
    padding-right: 1rem !important;
  }
  .react-app .pb-lg-2,
  .react-app .py-lg-2, .multiple-react-app .pb-lg-2,
  .multiple-react-app .py-lg-2, .bs4-popover .pb-lg-2,
  .bs4-popover .py-lg-2, .bs4-modal .pb-lg-2,
  .bs4-modal .py-lg-2, .bs4 .pb-lg-2,
  .bs4 .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .react-app .pl-lg-2,
  .react-app .px-lg-2, .multiple-react-app .pl-lg-2,
  .multiple-react-app .px-lg-2, .bs4-popover .pl-lg-2,
  .bs4-popover .px-lg-2, .bs4-modal .pl-lg-2,
  .bs4-modal .px-lg-2, .bs4 .pl-lg-2,
  .bs4 .px-lg-2 {
    padding-left: 1rem !important;
  }
  .react-app .p-lg-3, .multiple-react-app .p-lg-3, .bs4-popover .p-lg-3, .bs4-modal .p-lg-3, .bs4 .p-lg-3 {
    padding: 1.5rem !important;
  }
  .react-app .pt-lg-3,
  .react-app .py-lg-3, .multiple-react-app .pt-lg-3,
  .multiple-react-app .py-lg-3, .bs4-popover .pt-lg-3,
  .bs4-popover .py-lg-3, .bs4-modal .pt-lg-3,
  .bs4-modal .py-lg-3, .bs4 .pt-lg-3,
  .bs4 .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .react-app .pr-lg-3,
  .react-app .px-lg-3, .multiple-react-app .pr-lg-3,
  .multiple-react-app .px-lg-3, .bs4-popover .pr-lg-3,
  .bs4-popover .px-lg-3, .bs4-modal .pr-lg-3,
  .bs4-modal .px-lg-3, .bs4 .pr-lg-3,
  .bs4 .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .react-app .pb-lg-3,
  .react-app .py-lg-3, .multiple-react-app .pb-lg-3,
  .multiple-react-app .py-lg-3, .bs4-popover .pb-lg-3,
  .bs4-popover .py-lg-3, .bs4-modal .pb-lg-3,
  .bs4-modal .py-lg-3, .bs4 .pb-lg-3,
  .bs4 .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .react-app .pl-lg-3,
  .react-app .px-lg-3, .multiple-react-app .pl-lg-3,
  .multiple-react-app .px-lg-3, .bs4-popover .pl-lg-3,
  .bs4-popover .px-lg-3, .bs4-modal .pl-lg-3,
  .bs4-modal .px-lg-3, .bs4 .pl-lg-3,
  .bs4 .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .react-app .p-lg-4, .multiple-react-app .p-lg-4, .bs4-popover .p-lg-4, .bs4-modal .p-lg-4, .bs4 .p-lg-4 {
    padding: 2rem !important;
  }
  .react-app .pt-lg-4,
  .react-app .py-lg-4, .multiple-react-app .pt-lg-4,
  .multiple-react-app .py-lg-4, .bs4-popover .pt-lg-4,
  .bs4-popover .py-lg-4, .bs4-modal .pt-lg-4,
  .bs4-modal .py-lg-4, .bs4 .pt-lg-4,
  .bs4 .py-lg-4 {
    padding-top: 2rem !important;
  }
  .react-app .pr-lg-4,
  .react-app .px-lg-4, .multiple-react-app .pr-lg-4,
  .multiple-react-app .px-lg-4, .bs4-popover .pr-lg-4,
  .bs4-popover .px-lg-4, .bs4-modal .pr-lg-4,
  .bs4-modal .px-lg-4, .bs4 .pr-lg-4,
  .bs4 .px-lg-4 {
    padding-right: 2rem !important;
  }
  .react-app .pb-lg-4,
  .react-app .py-lg-4, .multiple-react-app .pb-lg-4,
  .multiple-react-app .py-lg-4, .bs4-popover .pb-lg-4,
  .bs4-popover .py-lg-4, .bs4-modal .pb-lg-4,
  .bs4-modal .py-lg-4, .bs4 .pb-lg-4,
  .bs4 .py-lg-4 {
    padding-bottom: 2rem !important;
  }
  .react-app .pl-lg-4,
  .react-app .px-lg-4, .multiple-react-app .pl-lg-4,
  .multiple-react-app .px-lg-4, .bs4-popover .pl-lg-4,
  .bs4-popover .px-lg-4, .bs4-modal .pl-lg-4,
  .bs4-modal .px-lg-4, .bs4 .pl-lg-4,
  .bs4 .px-lg-4 {
    padding-left: 2rem !important;
  }
  .react-app .p-lg-5, .multiple-react-app .p-lg-5, .bs4-popover .p-lg-5, .bs4-modal .p-lg-5, .bs4 .p-lg-5 {
    padding: 3rem !important;
  }
  .react-app .pt-lg-5,
  .react-app .py-lg-5, .multiple-react-app .pt-lg-5,
  .multiple-react-app .py-lg-5, .bs4-popover .pt-lg-5,
  .bs4-popover .py-lg-5, .bs4-modal .pt-lg-5,
  .bs4-modal .py-lg-5, .bs4 .pt-lg-5,
  .bs4 .py-lg-5 {
    padding-top: 3rem !important;
  }
  .react-app .pr-lg-5,
  .react-app .px-lg-5, .multiple-react-app .pr-lg-5,
  .multiple-react-app .px-lg-5, .bs4-popover .pr-lg-5,
  .bs4-popover .px-lg-5, .bs4-modal .pr-lg-5,
  .bs4-modal .px-lg-5, .bs4 .pr-lg-5,
  .bs4 .px-lg-5 {
    padding-right: 3rem !important;
  }
  .react-app .pb-lg-5,
  .react-app .py-lg-5, .multiple-react-app .pb-lg-5,
  .multiple-react-app .py-lg-5, .bs4-popover .pb-lg-5,
  .bs4-popover .py-lg-5, .bs4-modal .pb-lg-5,
  .bs4-modal .py-lg-5, .bs4 .pb-lg-5,
  .bs4 .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .react-app .pl-lg-5,
  .react-app .px-lg-5, .multiple-react-app .pl-lg-5,
  .multiple-react-app .px-lg-5, .bs4-popover .pl-lg-5,
  .bs4-popover .px-lg-5, .bs4-modal .pl-lg-5,
  .bs4-modal .px-lg-5, .bs4 .pl-lg-5,
  .bs4 .px-lg-5 {
    padding-left: 3rem !important;
  }
  .react-app .p-lg-6, .multiple-react-app .p-lg-6, .bs4-popover .p-lg-6, .bs4-modal .p-lg-6, .bs4 .p-lg-6 {
    padding: 4rem !important;
  }
  .react-app .pt-lg-6,
  .react-app .py-lg-6, .multiple-react-app .pt-lg-6,
  .multiple-react-app .py-lg-6, .bs4-popover .pt-lg-6,
  .bs4-popover .py-lg-6, .bs4-modal .pt-lg-6,
  .bs4-modal .py-lg-6, .bs4 .pt-lg-6,
  .bs4 .py-lg-6 {
    padding-top: 4rem !important;
  }
  .react-app .pr-lg-6,
  .react-app .px-lg-6, .multiple-react-app .pr-lg-6,
  .multiple-react-app .px-lg-6, .bs4-popover .pr-lg-6,
  .bs4-popover .px-lg-6, .bs4-modal .pr-lg-6,
  .bs4-modal .px-lg-6, .bs4 .pr-lg-6,
  .bs4 .px-lg-6 {
    padding-right: 4rem !important;
  }
  .react-app .pb-lg-6,
  .react-app .py-lg-6, .multiple-react-app .pb-lg-6,
  .multiple-react-app .py-lg-6, .bs4-popover .pb-lg-6,
  .bs4-popover .py-lg-6, .bs4-modal .pb-lg-6,
  .bs4-modal .py-lg-6, .bs4 .pb-lg-6,
  .bs4 .py-lg-6 {
    padding-bottom: 4rem !important;
  }
  .react-app .pl-lg-6,
  .react-app .px-lg-6, .multiple-react-app .pl-lg-6,
  .multiple-react-app .px-lg-6, .bs4-popover .pl-lg-6,
  .bs4-popover .px-lg-6, .bs4-modal .pl-lg-6,
  .bs4-modal .px-lg-6, .bs4 .pl-lg-6,
  .bs4 .px-lg-6 {
    padding-left: 4rem !important;
  }
  .react-app .p-lg-7, .multiple-react-app .p-lg-7, .bs4-popover .p-lg-7, .bs4-modal .p-lg-7, .bs4 .p-lg-7 {
    padding: 5rem !important;
  }
  .react-app .pt-lg-7,
  .react-app .py-lg-7, .multiple-react-app .pt-lg-7,
  .multiple-react-app .py-lg-7, .bs4-popover .pt-lg-7,
  .bs4-popover .py-lg-7, .bs4-modal .pt-lg-7,
  .bs4-modal .py-lg-7, .bs4 .pt-lg-7,
  .bs4 .py-lg-7 {
    padding-top: 5rem !important;
  }
  .react-app .pr-lg-7,
  .react-app .px-lg-7, .multiple-react-app .pr-lg-7,
  .multiple-react-app .px-lg-7, .bs4-popover .pr-lg-7,
  .bs4-popover .px-lg-7, .bs4-modal .pr-lg-7,
  .bs4-modal .px-lg-7, .bs4 .pr-lg-7,
  .bs4 .px-lg-7 {
    padding-right: 5rem !important;
  }
  .react-app .pb-lg-7,
  .react-app .py-lg-7, .multiple-react-app .pb-lg-7,
  .multiple-react-app .py-lg-7, .bs4-popover .pb-lg-7,
  .bs4-popover .py-lg-7, .bs4-modal .pb-lg-7,
  .bs4-modal .py-lg-7, .bs4 .pb-lg-7,
  .bs4 .py-lg-7 {
    padding-bottom: 5rem !important;
  }
  .react-app .pl-lg-7,
  .react-app .px-lg-7, .multiple-react-app .pl-lg-7,
  .multiple-react-app .px-lg-7, .bs4-popover .pl-lg-7,
  .bs4-popover .px-lg-7, .bs4-modal .pl-lg-7,
  .bs4-modal .px-lg-7, .bs4 .pl-lg-7,
  .bs4 .px-lg-7 {
    padding-left: 5rem !important;
  }
  .react-app .p-lg-8, .multiple-react-app .p-lg-8, .bs4-popover .p-lg-8, .bs4-modal .p-lg-8, .bs4 .p-lg-8 {
    padding: 6rem !important;
  }
  .react-app .pt-lg-8,
  .react-app .py-lg-8, .multiple-react-app .pt-lg-8,
  .multiple-react-app .py-lg-8, .bs4-popover .pt-lg-8,
  .bs4-popover .py-lg-8, .bs4-modal .pt-lg-8,
  .bs4-modal .py-lg-8, .bs4 .pt-lg-8,
  .bs4 .py-lg-8 {
    padding-top: 6rem !important;
  }
  .react-app .pr-lg-8,
  .react-app .px-lg-8, .multiple-react-app .pr-lg-8,
  .multiple-react-app .px-lg-8, .bs4-popover .pr-lg-8,
  .bs4-popover .px-lg-8, .bs4-modal .pr-lg-8,
  .bs4-modal .px-lg-8, .bs4 .pr-lg-8,
  .bs4 .px-lg-8 {
    padding-right: 6rem !important;
  }
  .react-app .pb-lg-8,
  .react-app .py-lg-8, .multiple-react-app .pb-lg-8,
  .multiple-react-app .py-lg-8, .bs4-popover .pb-lg-8,
  .bs4-popover .py-lg-8, .bs4-modal .pb-lg-8,
  .bs4-modal .py-lg-8, .bs4 .pb-lg-8,
  .bs4 .py-lg-8 {
    padding-bottom: 6rem !important;
  }
  .react-app .pl-lg-8,
  .react-app .px-lg-8, .multiple-react-app .pl-lg-8,
  .multiple-react-app .px-lg-8, .bs4-popover .pl-lg-8,
  .bs4-popover .px-lg-8, .bs4-modal .pl-lg-8,
  .bs4-modal .px-lg-8, .bs4 .pl-lg-8,
  .bs4 .px-lg-8 {
    padding-left: 6rem !important;
  }
  .react-app .m-lg-n1, .multiple-react-app .m-lg-n1, .bs4-popover .m-lg-n1, .bs4-modal .m-lg-n1, .bs4 .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .react-app .mt-lg-n1,
  .react-app .my-lg-n1, .multiple-react-app .mt-lg-n1,
  .multiple-react-app .my-lg-n1, .bs4-popover .mt-lg-n1,
  .bs4-popover .my-lg-n1, .bs4-modal .mt-lg-n1,
  .bs4-modal .my-lg-n1, .bs4 .mt-lg-n1,
  .bs4 .my-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .react-app .mr-lg-n1,
  .react-app .mx-lg-n1, .multiple-react-app .mr-lg-n1,
  .multiple-react-app .mx-lg-n1, .bs4-popover .mr-lg-n1,
  .bs4-popover .mx-lg-n1, .bs4-modal .mr-lg-n1,
  .bs4-modal .mx-lg-n1, .bs4 .mr-lg-n1,
  .bs4 .mx-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .react-app .mb-lg-n1,
  .react-app .my-lg-n1, .multiple-react-app .mb-lg-n1,
  .multiple-react-app .my-lg-n1, .bs4-popover .mb-lg-n1,
  .bs4-popover .my-lg-n1, .bs4-modal .mb-lg-n1,
  .bs4-modal .my-lg-n1, .bs4 .mb-lg-n1,
  .bs4 .my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .react-app .ml-lg-n1,
  .react-app .mx-lg-n1, .multiple-react-app .ml-lg-n1,
  .multiple-react-app .mx-lg-n1, .bs4-popover .ml-lg-n1,
  .bs4-popover .mx-lg-n1, .bs4-modal .ml-lg-n1,
  .bs4-modal .mx-lg-n1, .bs4 .ml-lg-n1,
  .bs4 .mx-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .react-app .m-lg-n2, .multiple-react-app .m-lg-n2, .bs4-popover .m-lg-n2, .bs4-modal .m-lg-n2, .bs4 .m-lg-n2 {
    margin: -1rem !important;
  }
  .react-app .mt-lg-n2,
  .react-app .my-lg-n2, .multiple-react-app .mt-lg-n2,
  .multiple-react-app .my-lg-n2, .bs4-popover .mt-lg-n2,
  .bs4-popover .my-lg-n2, .bs4-modal .mt-lg-n2,
  .bs4-modal .my-lg-n2, .bs4 .mt-lg-n2,
  .bs4 .my-lg-n2 {
    margin-top: -1rem !important;
  }
  .react-app .mr-lg-n2,
  .react-app .mx-lg-n2, .multiple-react-app .mr-lg-n2,
  .multiple-react-app .mx-lg-n2, .bs4-popover .mr-lg-n2,
  .bs4-popover .mx-lg-n2, .bs4-modal .mr-lg-n2,
  .bs4-modal .mx-lg-n2, .bs4 .mr-lg-n2,
  .bs4 .mx-lg-n2 {
    margin-right: -1rem !important;
  }
  .react-app .mb-lg-n2,
  .react-app .my-lg-n2, .multiple-react-app .mb-lg-n2,
  .multiple-react-app .my-lg-n2, .bs4-popover .mb-lg-n2,
  .bs4-popover .my-lg-n2, .bs4-modal .mb-lg-n2,
  .bs4-modal .my-lg-n2, .bs4 .mb-lg-n2,
  .bs4 .my-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .react-app .ml-lg-n2,
  .react-app .mx-lg-n2, .multiple-react-app .ml-lg-n2,
  .multiple-react-app .mx-lg-n2, .bs4-popover .ml-lg-n2,
  .bs4-popover .mx-lg-n2, .bs4-modal .ml-lg-n2,
  .bs4-modal .mx-lg-n2, .bs4 .ml-lg-n2,
  .bs4 .mx-lg-n2 {
    margin-left: -1rem !important;
  }
  .react-app .m-lg-n3, .multiple-react-app .m-lg-n3, .bs4-popover .m-lg-n3, .bs4-modal .m-lg-n3, .bs4 .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .react-app .mt-lg-n3,
  .react-app .my-lg-n3, .multiple-react-app .mt-lg-n3,
  .multiple-react-app .my-lg-n3, .bs4-popover .mt-lg-n3,
  .bs4-popover .my-lg-n3, .bs4-modal .mt-lg-n3,
  .bs4-modal .my-lg-n3, .bs4 .mt-lg-n3,
  .bs4 .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .react-app .mr-lg-n3,
  .react-app .mx-lg-n3, .multiple-react-app .mr-lg-n3,
  .multiple-react-app .mx-lg-n3, .bs4-popover .mr-lg-n3,
  .bs4-popover .mx-lg-n3, .bs4-modal .mr-lg-n3,
  .bs4-modal .mx-lg-n3, .bs4 .mr-lg-n3,
  .bs4 .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .react-app .mb-lg-n3,
  .react-app .my-lg-n3, .multiple-react-app .mb-lg-n3,
  .multiple-react-app .my-lg-n3, .bs4-popover .mb-lg-n3,
  .bs4-popover .my-lg-n3, .bs4-modal .mb-lg-n3,
  .bs4-modal .my-lg-n3, .bs4 .mb-lg-n3,
  .bs4 .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .react-app .ml-lg-n3,
  .react-app .mx-lg-n3, .multiple-react-app .ml-lg-n3,
  .multiple-react-app .mx-lg-n3, .bs4-popover .ml-lg-n3,
  .bs4-popover .mx-lg-n3, .bs4-modal .ml-lg-n3,
  .bs4-modal .mx-lg-n3, .bs4 .ml-lg-n3,
  .bs4 .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .react-app .m-lg-n4, .multiple-react-app .m-lg-n4, .bs4-popover .m-lg-n4, .bs4-modal .m-lg-n4, .bs4 .m-lg-n4 {
    margin: -2rem !important;
  }
  .react-app .mt-lg-n4,
  .react-app .my-lg-n4, .multiple-react-app .mt-lg-n4,
  .multiple-react-app .my-lg-n4, .bs4-popover .mt-lg-n4,
  .bs4-popover .my-lg-n4, .bs4-modal .mt-lg-n4,
  .bs4-modal .my-lg-n4, .bs4 .mt-lg-n4,
  .bs4 .my-lg-n4 {
    margin-top: -2rem !important;
  }
  .react-app .mr-lg-n4,
  .react-app .mx-lg-n4, .multiple-react-app .mr-lg-n4,
  .multiple-react-app .mx-lg-n4, .bs4-popover .mr-lg-n4,
  .bs4-popover .mx-lg-n4, .bs4-modal .mr-lg-n4,
  .bs4-modal .mx-lg-n4, .bs4 .mr-lg-n4,
  .bs4 .mx-lg-n4 {
    margin-right: -2rem !important;
  }
  .react-app .mb-lg-n4,
  .react-app .my-lg-n4, .multiple-react-app .mb-lg-n4,
  .multiple-react-app .my-lg-n4, .bs4-popover .mb-lg-n4,
  .bs4-popover .my-lg-n4, .bs4-modal .mb-lg-n4,
  .bs4-modal .my-lg-n4, .bs4 .mb-lg-n4,
  .bs4 .my-lg-n4 {
    margin-bottom: -2rem !important;
  }
  .react-app .ml-lg-n4,
  .react-app .mx-lg-n4, .multiple-react-app .ml-lg-n4,
  .multiple-react-app .mx-lg-n4, .bs4-popover .ml-lg-n4,
  .bs4-popover .mx-lg-n4, .bs4-modal .ml-lg-n4,
  .bs4-modal .mx-lg-n4, .bs4 .ml-lg-n4,
  .bs4 .mx-lg-n4 {
    margin-left: -2rem !important;
  }
  .react-app .m-lg-n5, .multiple-react-app .m-lg-n5, .bs4-popover .m-lg-n5, .bs4-modal .m-lg-n5, .bs4 .m-lg-n5 {
    margin: -3rem !important;
  }
  .react-app .mt-lg-n5,
  .react-app .my-lg-n5, .multiple-react-app .mt-lg-n5,
  .multiple-react-app .my-lg-n5, .bs4-popover .mt-lg-n5,
  .bs4-popover .my-lg-n5, .bs4-modal .mt-lg-n5,
  .bs4-modal .my-lg-n5, .bs4 .mt-lg-n5,
  .bs4 .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .react-app .mr-lg-n5,
  .react-app .mx-lg-n5, .multiple-react-app .mr-lg-n5,
  .multiple-react-app .mx-lg-n5, .bs4-popover .mr-lg-n5,
  .bs4-popover .mx-lg-n5, .bs4-modal .mr-lg-n5,
  .bs4-modal .mx-lg-n5, .bs4 .mr-lg-n5,
  .bs4 .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .react-app .mb-lg-n5,
  .react-app .my-lg-n5, .multiple-react-app .mb-lg-n5,
  .multiple-react-app .my-lg-n5, .bs4-popover .mb-lg-n5,
  .bs4-popover .my-lg-n5, .bs4-modal .mb-lg-n5,
  .bs4-modal .my-lg-n5, .bs4 .mb-lg-n5,
  .bs4 .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .react-app .ml-lg-n5,
  .react-app .mx-lg-n5, .multiple-react-app .ml-lg-n5,
  .multiple-react-app .mx-lg-n5, .bs4-popover .ml-lg-n5,
  .bs4-popover .mx-lg-n5, .bs4-modal .ml-lg-n5,
  .bs4-modal .mx-lg-n5, .bs4 .ml-lg-n5,
  .bs4 .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .react-app .m-lg-n6, .multiple-react-app .m-lg-n6, .bs4-popover .m-lg-n6, .bs4-modal .m-lg-n6, .bs4 .m-lg-n6 {
    margin: -4rem !important;
  }
  .react-app .mt-lg-n6,
  .react-app .my-lg-n6, .multiple-react-app .mt-lg-n6,
  .multiple-react-app .my-lg-n6, .bs4-popover .mt-lg-n6,
  .bs4-popover .my-lg-n6, .bs4-modal .mt-lg-n6,
  .bs4-modal .my-lg-n6, .bs4 .mt-lg-n6,
  .bs4 .my-lg-n6 {
    margin-top: -4rem !important;
  }
  .react-app .mr-lg-n6,
  .react-app .mx-lg-n6, .multiple-react-app .mr-lg-n6,
  .multiple-react-app .mx-lg-n6, .bs4-popover .mr-lg-n6,
  .bs4-popover .mx-lg-n6, .bs4-modal .mr-lg-n6,
  .bs4-modal .mx-lg-n6, .bs4 .mr-lg-n6,
  .bs4 .mx-lg-n6 {
    margin-right: -4rem !important;
  }
  .react-app .mb-lg-n6,
  .react-app .my-lg-n6, .multiple-react-app .mb-lg-n6,
  .multiple-react-app .my-lg-n6, .bs4-popover .mb-lg-n6,
  .bs4-popover .my-lg-n6, .bs4-modal .mb-lg-n6,
  .bs4-modal .my-lg-n6, .bs4 .mb-lg-n6,
  .bs4 .my-lg-n6 {
    margin-bottom: -4rem !important;
  }
  .react-app .ml-lg-n6,
  .react-app .mx-lg-n6, .multiple-react-app .ml-lg-n6,
  .multiple-react-app .mx-lg-n6, .bs4-popover .ml-lg-n6,
  .bs4-popover .mx-lg-n6, .bs4-modal .ml-lg-n6,
  .bs4-modal .mx-lg-n6, .bs4 .ml-lg-n6,
  .bs4 .mx-lg-n6 {
    margin-left: -4rem !important;
  }
  .react-app .m-lg-n7, .multiple-react-app .m-lg-n7, .bs4-popover .m-lg-n7, .bs4-modal .m-lg-n7, .bs4 .m-lg-n7 {
    margin: -5rem !important;
  }
  .react-app .mt-lg-n7,
  .react-app .my-lg-n7, .multiple-react-app .mt-lg-n7,
  .multiple-react-app .my-lg-n7, .bs4-popover .mt-lg-n7,
  .bs4-popover .my-lg-n7, .bs4-modal .mt-lg-n7,
  .bs4-modal .my-lg-n7, .bs4 .mt-lg-n7,
  .bs4 .my-lg-n7 {
    margin-top: -5rem !important;
  }
  .react-app .mr-lg-n7,
  .react-app .mx-lg-n7, .multiple-react-app .mr-lg-n7,
  .multiple-react-app .mx-lg-n7, .bs4-popover .mr-lg-n7,
  .bs4-popover .mx-lg-n7, .bs4-modal .mr-lg-n7,
  .bs4-modal .mx-lg-n7, .bs4 .mr-lg-n7,
  .bs4 .mx-lg-n7 {
    margin-right: -5rem !important;
  }
  .react-app .mb-lg-n7,
  .react-app .my-lg-n7, .multiple-react-app .mb-lg-n7,
  .multiple-react-app .my-lg-n7, .bs4-popover .mb-lg-n7,
  .bs4-popover .my-lg-n7, .bs4-modal .mb-lg-n7,
  .bs4-modal .my-lg-n7, .bs4 .mb-lg-n7,
  .bs4 .my-lg-n7 {
    margin-bottom: -5rem !important;
  }
  .react-app .ml-lg-n7,
  .react-app .mx-lg-n7, .multiple-react-app .ml-lg-n7,
  .multiple-react-app .mx-lg-n7, .bs4-popover .ml-lg-n7,
  .bs4-popover .mx-lg-n7, .bs4-modal .ml-lg-n7,
  .bs4-modal .mx-lg-n7, .bs4 .ml-lg-n7,
  .bs4 .mx-lg-n7 {
    margin-left: -5rem !important;
  }
  .react-app .m-lg-n8, .multiple-react-app .m-lg-n8, .bs4-popover .m-lg-n8, .bs4-modal .m-lg-n8, .bs4 .m-lg-n8 {
    margin: -6rem !important;
  }
  .react-app .mt-lg-n8,
  .react-app .my-lg-n8, .multiple-react-app .mt-lg-n8,
  .multiple-react-app .my-lg-n8, .bs4-popover .mt-lg-n8,
  .bs4-popover .my-lg-n8, .bs4-modal .mt-lg-n8,
  .bs4-modal .my-lg-n8, .bs4 .mt-lg-n8,
  .bs4 .my-lg-n8 {
    margin-top: -6rem !important;
  }
  .react-app .mr-lg-n8,
  .react-app .mx-lg-n8, .multiple-react-app .mr-lg-n8,
  .multiple-react-app .mx-lg-n8, .bs4-popover .mr-lg-n8,
  .bs4-popover .mx-lg-n8, .bs4-modal .mr-lg-n8,
  .bs4-modal .mx-lg-n8, .bs4 .mr-lg-n8,
  .bs4 .mx-lg-n8 {
    margin-right: -6rem !important;
  }
  .react-app .mb-lg-n8,
  .react-app .my-lg-n8, .multiple-react-app .mb-lg-n8,
  .multiple-react-app .my-lg-n8, .bs4-popover .mb-lg-n8,
  .bs4-popover .my-lg-n8, .bs4-modal .mb-lg-n8,
  .bs4-modal .my-lg-n8, .bs4 .mb-lg-n8,
  .bs4 .my-lg-n8 {
    margin-bottom: -6rem !important;
  }
  .react-app .ml-lg-n8,
  .react-app .mx-lg-n8, .multiple-react-app .ml-lg-n8,
  .multiple-react-app .mx-lg-n8, .bs4-popover .ml-lg-n8,
  .bs4-popover .mx-lg-n8, .bs4-modal .ml-lg-n8,
  .bs4-modal .mx-lg-n8, .bs4 .ml-lg-n8,
  .bs4 .mx-lg-n8 {
    margin-left: -6rem !important;
  }
  .react-app .m-lg-auto, .multiple-react-app .m-lg-auto, .bs4-popover .m-lg-auto, .bs4-modal .m-lg-auto, .bs4 .m-lg-auto {
    margin: auto !important;
  }
  .react-app .mt-lg-auto,
  .react-app .my-lg-auto, .multiple-react-app .mt-lg-auto,
  .multiple-react-app .my-lg-auto, .bs4-popover .mt-lg-auto,
  .bs4-popover .my-lg-auto, .bs4-modal .mt-lg-auto,
  .bs4-modal .my-lg-auto, .bs4 .mt-lg-auto,
  .bs4 .my-lg-auto {
    margin-top: auto !important;
  }
  .react-app .mr-lg-auto,
  .react-app .mx-lg-auto, .multiple-react-app .mr-lg-auto,
  .multiple-react-app .mx-lg-auto, .bs4-popover .mr-lg-auto,
  .bs4-popover .mx-lg-auto, .bs4-modal .mr-lg-auto,
  .bs4-modal .mx-lg-auto, .bs4 .mr-lg-auto,
  .bs4 .mx-lg-auto {
    margin-right: auto !important;
  }
  .react-app .mb-lg-auto,
  .react-app .my-lg-auto, .multiple-react-app .mb-lg-auto,
  .multiple-react-app .my-lg-auto, .bs4-popover .mb-lg-auto,
  .bs4-popover .my-lg-auto, .bs4-modal .mb-lg-auto,
  .bs4-modal .my-lg-auto, .bs4 .mb-lg-auto,
  .bs4 .my-lg-auto {
    margin-bottom: auto !important;
  }
  .react-app .ml-lg-auto,
  .react-app .mx-lg-auto, .multiple-react-app .ml-lg-auto,
  .multiple-react-app .mx-lg-auto, .bs4-popover .ml-lg-auto,
  .bs4-popover .mx-lg-auto, .bs4-modal .ml-lg-auto,
  .bs4-modal .mx-lg-auto, .bs4 .ml-lg-auto,
  .bs4 .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .react-app .m-xl-0, .multiple-react-app .m-xl-0, .bs4-popover .m-xl-0, .bs4-modal .m-xl-0, .bs4 .m-xl-0 {
    margin: 0 !important;
  }
  .react-app .mt-xl-0,
  .react-app .my-xl-0, .multiple-react-app .mt-xl-0,
  .multiple-react-app .my-xl-0, .bs4-popover .mt-xl-0,
  .bs4-popover .my-xl-0, .bs4-modal .mt-xl-0,
  .bs4-modal .my-xl-0, .bs4 .mt-xl-0,
  .bs4 .my-xl-0 {
    margin-top: 0 !important;
  }
  .react-app .mr-xl-0,
  .react-app .mx-xl-0, .multiple-react-app .mr-xl-0,
  .multiple-react-app .mx-xl-0, .bs4-popover .mr-xl-0,
  .bs4-popover .mx-xl-0, .bs4-modal .mr-xl-0,
  .bs4-modal .mx-xl-0, .bs4 .mr-xl-0,
  .bs4 .mx-xl-0 {
    margin-right: 0 !important;
  }
  .react-app .mb-xl-0,
  .react-app .my-xl-0, .multiple-react-app .mb-xl-0,
  .multiple-react-app .my-xl-0, .bs4-popover .mb-xl-0,
  .bs4-popover .my-xl-0, .bs4-modal .mb-xl-0,
  .bs4-modal .my-xl-0, .bs4 .mb-xl-0,
  .bs4 .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .react-app .ml-xl-0,
  .react-app .mx-xl-0, .multiple-react-app .ml-xl-0,
  .multiple-react-app .mx-xl-0, .bs4-popover .ml-xl-0,
  .bs4-popover .mx-xl-0, .bs4-modal .ml-xl-0,
  .bs4-modal .mx-xl-0, .bs4 .ml-xl-0,
  .bs4 .mx-xl-0 {
    margin-left: 0 !important;
  }
  .react-app .m-xl-1, .multiple-react-app .m-xl-1, .bs4-popover .m-xl-1, .bs4-modal .m-xl-1, .bs4 .m-xl-1 {
    margin: 0.5rem !important;
  }
  .react-app .mt-xl-1,
  .react-app .my-xl-1, .multiple-react-app .mt-xl-1,
  .multiple-react-app .my-xl-1, .bs4-popover .mt-xl-1,
  .bs4-popover .my-xl-1, .bs4-modal .mt-xl-1,
  .bs4-modal .my-xl-1, .bs4 .mt-xl-1,
  .bs4 .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .react-app .mr-xl-1,
  .react-app .mx-xl-1, .multiple-react-app .mr-xl-1,
  .multiple-react-app .mx-xl-1, .bs4-popover .mr-xl-1,
  .bs4-popover .mx-xl-1, .bs4-modal .mr-xl-1,
  .bs4-modal .mx-xl-1, .bs4 .mr-xl-1,
  .bs4 .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .react-app .mb-xl-1,
  .react-app .my-xl-1, .multiple-react-app .mb-xl-1,
  .multiple-react-app .my-xl-1, .bs4-popover .mb-xl-1,
  .bs4-popover .my-xl-1, .bs4-modal .mb-xl-1,
  .bs4-modal .my-xl-1, .bs4 .mb-xl-1,
  .bs4 .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .react-app .ml-xl-1,
  .react-app .mx-xl-1, .multiple-react-app .ml-xl-1,
  .multiple-react-app .mx-xl-1, .bs4-popover .ml-xl-1,
  .bs4-popover .mx-xl-1, .bs4-modal .ml-xl-1,
  .bs4-modal .mx-xl-1, .bs4 .ml-xl-1,
  .bs4 .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .react-app .m-xl-2, .multiple-react-app .m-xl-2, .bs4-popover .m-xl-2, .bs4-modal .m-xl-2, .bs4 .m-xl-2 {
    margin: 1rem !important;
  }
  .react-app .mt-xl-2,
  .react-app .my-xl-2, .multiple-react-app .mt-xl-2,
  .multiple-react-app .my-xl-2, .bs4-popover .mt-xl-2,
  .bs4-popover .my-xl-2, .bs4-modal .mt-xl-2,
  .bs4-modal .my-xl-2, .bs4 .mt-xl-2,
  .bs4 .my-xl-2 {
    margin-top: 1rem !important;
  }
  .react-app .mr-xl-2,
  .react-app .mx-xl-2, .multiple-react-app .mr-xl-2,
  .multiple-react-app .mx-xl-2, .bs4-popover .mr-xl-2,
  .bs4-popover .mx-xl-2, .bs4-modal .mr-xl-2,
  .bs4-modal .mx-xl-2, .bs4 .mr-xl-2,
  .bs4 .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .react-app .mb-xl-2,
  .react-app .my-xl-2, .multiple-react-app .mb-xl-2,
  .multiple-react-app .my-xl-2, .bs4-popover .mb-xl-2,
  .bs4-popover .my-xl-2, .bs4-modal .mb-xl-2,
  .bs4-modal .my-xl-2, .bs4 .mb-xl-2,
  .bs4 .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .react-app .ml-xl-2,
  .react-app .mx-xl-2, .multiple-react-app .ml-xl-2,
  .multiple-react-app .mx-xl-2, .bs4-popover .ml-xl-2,
  .bs4-popover .mx-xl-2, .bs4-modal .ml-xl-2,
  .bs4-modal .mx-xl-2, .bs4 .ml-xl-2,
  .bs4 .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .react-app .m-xl-3, .multiple-react-app .m-xl-3, .bs4-popover .m-xl-3, .bs4-modal .m-xl-3, .bs4 .m-xl-3 {
    margin: 1.5rem !important;
  }
  .react-app .mt-xl-3,
  .react-app .my-xl-3, .multiple-react-app .mt-xl-3,
  .multiple-react-app .my-xl-3, .bs4-popover .mt-xl-3,
  .bs4-popover .my-xl-3, .bs4-modal .mt-xl-3,
  .bs4-modal .my-xl-3, .bs4 .mt-xl-3,
  .bs4 .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .react-app .mr-xl-3,
  .react-app .mx-xl-3, .multiple-react-app .mr-xl-3,
  .multiple-react-app .mx-xl-3, .bs4-popover .mr-xl-3,
  .bs4-popover .mx-xl-3, .bs4-modal .mr-xl-3,
  .bs4-modal .mx-xl-3, .bs4 .mr-xl-3,
  .bs4 .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .react-app .mb-xl-3,
  .react-app .my-xl-3, .multiple-react-app .mb-xl-3,
  .multiple-react-app .my-xl-3, .bs4-popover .mb-xl-3,
  .bs4-popover .my-xl-3, .bs4-modal .mb-xl-3,
  .bs4-modal .my-xl-3, .bs4 .mb-xl-3,
  .bs4 .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .react-app .ml-xl-3,
  .react-app .mx-xl-3, .multiple-react-app .ml-xl-3,
  .multiple-react-app .mx-xl-3, .bs4-popover .ml-xl-3,
  .bs4-popover .mx-xl-3, .bs4-modal .ml-xl-3,
  .bs4-modal .mx-xl-3, .bs4 .ml-xl-3,
  .bs4 .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .react-app .m-xl-4, .multiple-react-app .m-xl-4, .bs4-popover .m-xl-4, .bs4-modal .m-xl-4, .bs4 .m-xl-4 {
    margin: 2rem !important;
  }
  .react-app .mt-xl-4,
  .react-app .my-xl-4, .multiple-react-app .mt-xl-4,
  .multiple-react-app .my-xl-4, .bs4-popover .mt-xl-4,
  .bs4-popover .my-xl-4, .bs4-modal .mt-xl-4,
  .bs4-modal .my-xl-4, .bs4 .mt-xl-4,
  .bs4 .my-xl-4 {
    margin-top: 2rem !important;
  }
  .react-app .mr-xl-4,
  .react-app .mx-xl-4, .multiple-react-app .mr-xl-4,
  .multiple-react-app .mx-xl-4, .bs4-popover .mr-xl-4,
  .bs4-popover .mx-xl-4, .bs4-modal .mr-xl-4,
  .bs4-modal .mx-xl-4, .bs4 .mr-xl-4,
  .bs4 .mx-xl-4 {
    margin-right: 2rem !important;
  }
  .react-app .mb-xl-4,
  .react-app .my-xl-4, .multiple-react-app .mb-xl-4,
  .multiple-react-app .my-xl-4, .bs4-popover .mb-xl-4,
  .bs4-popover .my-xl-4, .bs4-modal .mb-xl-4,
  .bs4-modal .my-xl-4, .bs4 .mb-xl-4,
  .bs4 .my-xl-4 {
    margin-bottom: 2rem !important;
  }
  .react-app .ml-xl-4,
  .react-app .mx-xl-4, .multiple-react-app .ml-xl-4,
  .multiple-react-app .mx-xl-4, .bs4-popover .ml-xl-4,
  .bs4-popover .mx-xl-4, .bs4-modal .ml-xl-4,
  .bs4-modal .mx-xl-4, .bs4 .ml-xl-4,
  .bs4 .mx-xl-4 {
    margin-left: 2rem !important;
  }
  .react-app .m-xl-5, .multiple-react-app .m-xl-5, .bs4-popover .m-xl-5, .bs4-modal .m-xl-5, .bs4 .m-xl-5 {
    margin: 3rem !important;
  }
  .react-app .mt-xl-5,
  .react-app .my-xl-5, .multiple-react-app .mt-xl-5,
  .multiple-react-app .my-xl-5, .bs4-popover .mt-xl-5,
  .bs4-popover .my-xl-5, .bs4-modal .mt-xl-5,
  .bs4-modal .my-xl-5, .bs4 .mt-xl-5,
  .bs4 .my-xl-5 {
    margin-top: 3rem !important;
  }
  .react-app .mr-xl-5,
  .react-app .mx-xl-5, .multiple-react-app .mr-xl-5,
  .multiple-react-app .mx-xl-5, .bs4-popover .mr-xl-5,
  .bs4-popover .mx-xl-5, .bs4-modal .mr-xl-5,
  .bs4-modal .mx-xl-5, .bs4 .mr-xl-5,
  .bs4 .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .react-app .mb-xl-5,
  .react-app .my-xl-5, .multiple-react-app .mb-xl-5,
  .multiple-react-app .my-xl-5, .bs4-popover .mb-xl-5,
  .bs4-popover .my-xl-5, .bs4-modal .mb-xl-5,
  .bs4-modal .my-xl-5, .bs4 .mb-xl-5,
  .bs4 .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .react-app .ml-xl-5,
  .react-app .mx-xl-5, .multiple-react-app .ml-xl-5,
  .multiple-react-app .mx-xl-5, .bs4-popover .ml-xl-5,
  .bs4-popover .mx-xl-5, .bs4-modal .ml-xl-5,
  .bs4-modal .mx-xl-5, .bs4 .ml-xl-5,
  .bs4 .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .react-app .m-xl-6, .multiple-react-app .m-xl-6, .bs4-popover .m-xl-6, .bs4-modal .m-xl-6, .bs4 .m-xl-6 {
    margin: 4rem !important;
  }
  .react-app .mt-xl-6,
  .react-app .my-xl-6, .multiple-react-app .mt-xl-6,
  .multiple-react-app .my-xl-6, .bs4-popover .mt-xl-6,
  .bs4-popover .my-xl-6, .bs4-modal .mt-xl-6,
  .bs4-modal .my-xl-6, .bs4 .mt-xl-6,
  .bs4 .my-xl-6 {
    margin-top: 4rem !important;
  }
  .react-app .mr-xl-6,
  .react-app .mx-xl-6, .multiple-react-app .mr-xl-6,
  .multiple-react-app .mx-xl-6, .bs4-popover .mr-xl-6,
  .bs4-popover .mx-xl-6, .bs4-modal .mr-xl-6,
  .bs4-modal .mx-xl-6, .bs4 .mr-xl-6,
  .bs4 .mx-xl-6 {
    margin-right: 4rem !important;
  }
  .react-app .mb-xl-6,
  .react-app .my-xl-6, .multiple-react-app .mb-xl-6,
  .multiple-react-app .my-xl-6, .bs4-popover .mb-xl-6,
  .bs4-popover .my-xl-6, .bs4-modal .mb-xl-6,
  .bs4-modal .my-xl-6, .bs4 .mb-xl-6,
  .bs4 .my-xl-6 {
    margin-bottom: 4rem !important;
  }
  .react-app .ml-xl-6,
  .react-app .mx-xl-6, .multiple-react-app .ml-xl-6,
  .multiple-react-app .mx-xl-6, .bs4-popover .ml-xl-6,
  .bs4-popover .mx-xl-6, .bs4-modal .ml-xl-6,
  .bs4-modal .mx-xl-6, .bs4 .ml-xl-6,
  .bs4 .mx-xl-6 {
    margin-left: 4rem !important;
  }
  .react-app .m-xl-7, .multiple-react-app .m-xl-7, .bs4-popover .m-xl-7, .bs4-modal .m-xl-7, .bs4 .m-xl-7 {
    margin: 5rem !important;
  }
  .react-app .mt-xl-7,
  .react-app .my-xl-7, .multiple-react-app .mt-xl-7,
  .multiple-react-app .my-xl-7, .bs4-popover .mt-xl-7,
  .bs4-popover .my-xl-7, .bs4-modal .mt-xl-7,
  .bs4-modal .my-xl-7, .bs4 .mt-xl-7,
  .bs4 .my-xl-7 {
    margin-top: 5rem !important;
  }
  .react-app .mr-xl-7,
  .react-app .mx-xl-7, .multiple-react-app .mr-xl-7,
  .multiple-react-app .mx-xl-7, .bs4-popover .mr-xl-7,
  .bs4-popover .mx-xl-7, .bs4-modal .mr-xl-7,
  .bs4-modal .mx-xl-7, .bs4 .mr-xl-7,
  .bs4 .mx-xl-7 {
    margin-right: 5rem !important;
  }
  .react-app .mb-xl-7,
  .react-app .my-xl-7, .multiple-react-app .mb-xl-7,
  .multiple-react-app .my-xl-7, .bs4-popover .mb-xl-7,
  .bs4-popover .my-xl-7, .bs4-modal .mb-xl-7,
  .bs4-modal .my-xl-7, .bs4 .mb-xl-7,
  .bs4 .my-xl-7 {
    margin-bottom: 5rem !important;
  }
  .react-app .ml-xl-7,
  .react-app .mx-xl-7, .multiple-react-app .ml-xl-7,
  .multiple-react-app .mx-xl-7, .bs4-popover .ml-xl-7,
  .bs4-popover .mx-xl-7, .bs4-modal .ml-xl-7,
  .bs4-modal .mx-xl-7, .bs4 .ml-xl-7,
  .bs4 .mx-xl-7 {
    margin-left: 5rem !important;
  }
  .react-app .m-xl-8, .multiple-react-app .m-xl-8, .bs4-popover .m-xl-8, .bs4-modal .m-xl-8, .bs4 .m-xl-8 {
    margin: 6rem !important;
  }
  .react-app .mt-xl-8,
  .react-app .my-xl-8, .multiple-react-app .mt-xl-8,
  .multiple-react-app .my-xl-8, .bs4-popover .mt-xl-8,
  .bs4-popover .my-xl-8, .bs4-modal .mt-xl-8,
  .bs4-modal .my-xl-8, .bs4 .mt-xl-8,
  .bs4 .my-xl-8 {
    margin-top: 6rem !important;
  }
  .react-app .mr-xl-8,
  .react-app .mx-xl-8, .multiple-react-app .mr-xl-8,
  .multiple-react-app .mx-xl-8, .bs4-popover .mr-xl-8,
  .bs4-popover .mx-xl-8, .bs4-modal .mr-xl-8,
  .bs4-modal .mx-xl-8, .bs4 .mr-xl-8,
  .bs4 .mx-xl-8 {
    margin-right: 6rem !important;
  }
  .react-app .mb-xl-8,
  .react-app .my-xl-8, .multiple-react-app .mb-xl-8,
  .multiple-react-app .my-xl-8, .bs4-popover .mb-xl-8,
  .bs4-popover .my-xl-8, .bs4-modal .mb-xl-8,
  .bs4-modal .my-xl-8, .bs4 .mb-xl-8,
  .bs4 .my-xl-8 {
    margin-bottom: 6rem !important;
  }
  .react-app .ml-xl-8,
  .react-app .mx-xl-8, .multiple-react-app .ml-xl-8,
  .multiple-react-app .mx-xl-8, .bs4-popover .ml-xl-8,
  .bs4-popover .mx-xl-8, .bs4-modal .ml-xl-8,
  .bs4-modal .mx-xl-8, .bs4 .ml-xl-8,
  .bs4 .mx-xl-8 {
    margin-left: 6rem !important;
  }
  .react-app .p-xl-0, .multiple-react-app .p-xl-0, .bs4-popover .p-xl-0, .bs4-modal .p-xl-0, .bs4 .p-xl-0 {
    padding: 0 !important;
  }
  .react-app .pt-xl-0,
  .react-app .py-xl-0, .multiple-react-app .pt-xl-0,
  .multiple-react-app .py-xl-0, .bs4-popover .pt-xl-0,
  .bs4-popover .py-xl-0, .bs4-modal .pt-xl-0,
  .bs4-modal .py-xl-0, .bs4 .pt-xl-0,
  .bs4 .py-xl-0 {
    padding-top: 0 !important;
  }
  .react-app .pr-xl-0,
  .react-app .px-xl-0, .multiple-react-app .pr-xl-0,
  .multiple-react-app .px-xl-0, .bs4-popover .pr-xl-0,
  .bs4-popover .px-xl-0, .bs4-modal .pr-xl-0,
  .bs4-modal .px-xl-0, .bs4 .pr-xl-0,
  .bs4 .px-xl-0 {
    padding-right: 0 !important;
  }
  .react-app .pb-xl-0,
  .react-app .py-xl-0, .multiple-react-app .pb-xl-0,
  .multiple-react-app .py-xl-0, .bs4-popover .pb-xl-0,
  .bs4-popover .py-xl-0, .bs4-modal .pb-xl-0,
  .bs4-modal .py-xl-0, .bs4 .pb-xl-0,
  .bs4 .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .react-app .pl-xl-0,
  .react-app .px-xl-0, .multiple-react-app .pl-xl-0,
  .multiple-react-app .px-xl-0, .bs4-popover .pl-xl-0,
  .bs4-popover .px-xl-0, .bs4-modal .pl-xl-0,
  .bs4-modal .px-xl-0, .bs4 .pl-xl-0,
  .bs4 .px-xl-0 {
    padding-left: 0 !important;
  }
  .react-app .p-xl-1, .multiple-react-app .p-xl-1, .bs4-popover .p-xl-1, .bs4-modal .p-xl-1, .bs4 .p-xl-1 {
    padding: 0.5rem !important;
  }
  .react-app .pt-xl-1,
  .react-app .py-xl-1, .multiple-react-app .pt-xl-1,
  .multiple-react-app .py-xl-1, .bs4-popover .pt-xl-1,
  .bs4-popover .py-xl-1, .bs4-modal .pt-xl-1,
  .bs4-modal .py-xl-1, .bs4 .pt-xl-1,
  .bs4 .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .react-app .pr-xl-1,
  .react-app .px-xl-1, .multiple-react-app .pr-xl-1,
  .multiple-react-app .px-xl-1, .bs4-popover .pr-xl-1,
  .bs4-popover .px-xl-1, .bs4-modal .pr-xl-1,
  .bs4-modal .px-xl-1, .bs4 .pr-xl-1,
  .bs4 .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .react-app .pb-xl-1,
  .react-app .py-xl-1, .multiple-react-app .pb-xl-1,
  .multiple-react-app .py-xl-1, .bs4-popover .pb-xl-1,
  .bs4-popover .py-xl-1, .bs4-modal .pb-xl-1,
  .bs4-modal .py-xl-1, .bs4 .pb-xl-1,
  .bs4 .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .react-app .pl-xl-1,
  .react-app .px-xl-1, .multiple-react-app .pl-xl-1,
  .multiple-react-app .px-xl-1, .bs4-popover .pl-xl-1,
  .bs4-popover .px-xl-1, .bs4-modal .pl-xl-1,
  .bs4-modal .px-xl-1, .bs4 .pl-xl-1,
  .bs4 .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .react-app .p-xl-2, .multiple-react-app .p-xl-2, .bs4-popover .p-xl-2, .bs4-modal .p-xl-2, .bs4 .p-xl-2 {
    padding: 1rem !important;
  }
  .react-app .pt-xl-2,
  .react-app .py-xl-2, .multiple-react-app .pt-xl-2,
  .multiple-react-app .py-xl-2, .bs4-popover .pt-xl-2,
  .bs4-popover .py-xl-2, .bs4-modal .pt-xl-2,
  .bs4-modal .py-xl-2, .bs4 .pt-xl-2,
  .bs4 .py-xl-2 {
    padding-top: 1rem !important;
  }
  .react-app .pr-xl-2,
  .react-app .px-xl-2, .multiple-react-app .pr-xl-2,
  .multiple-react-app .px-xl-2, .bs4-popover .pr-xl-2,
  .bs4-popover .px-xl-2, .bs4-modal .pr-xl-2,
  .bs4-modal .px-xl-2, .bs4 .pr-xl-2,
  .bs4 .px-xl-2 {
    padding-right: 1rem !important;
  }
  .react-app .pb-xl-2,
  .react-app .py-xl-2, .multiple-react-app .pb-xl-2,
  .multiple-react-app .py-xl-2, .bs4-popover .pb-xl-2,
  .bs4-popover .py-xl-2, .bs4-modal .pb-xl-2,
  .bs4-modal .py-xl-2, .bs4 .pb-xl-2,
  .bs4 .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .react-app .pl-xl-2,
  .react-app .px-xl-2, .multiple-react-app .pl-xl-2,
  .multiple-react-app .px-xl-2, .bs4-popover .pl-xl-2,
  .bs4-popover .px-xl-2, .bs4-modal .pl-xl-2,
  .bs4-modal .px-xl-2, .bs4 .pl-xl-2,
  .bs4 .px-xl-2 {
    padding-left: 1rem !important;
  }
  .react-app .p-xl-3, .multiple-react-app .p-xl-3, .bs4-popover .p-xl-3, .bs4-modal .p-xl-3, .bs4 .p-xl-3 {
    padding: 1.5rem !important;
  }
  .react-app .pt-xl-3,
  .react-app .py-xl-3, .multiple-react-app .pt-xl-3,
  .multiple-react-app .py-xl-3, .bs4-popover .pt-xl-3,
  .bs4-popover .py-xl-3, .bs4-modal .pt-xl-3,
  .bs4-modal .py-xl-3, .bs4 .pt-xl-3,
  .bs4 .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .react-app .pr-xl-3,
  .react-app .px-xl-3, .multiple-react-app .pr-xl-3,
  .multiple-react-app .px-xl-3, .bs4-popover .pr-xl-3,
  .bs4-popover .px-xl-3, .bs4-modal .pr-xl-3,
  .bs4-modal .px-xl-3, .bs4 .pr-xl-3,
  .bs4 .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .react-app .pb-xl-3,
  .react-app .py-xl-3, .multiple-react-app .pb-xl-3,
  .multiple-react-app .py-xl-3, .bs4-popover .pb-xl-3,
  .bs4-popover .py-xl-3, .bs4-modal .pb-xl-3,
  .bs4-modal .py-xl-3, .bs4 .pb-xl-3,
  .bs4 .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .react-app .pl-xl-3,
  .react-app .px-xl-3, .multiple-react-app .pl-xl-3,
  .multiple-react-app .px-xl-3, .bs4-popover .pl-xl-3,
  .bs4-popover .px-xl-3, .bs4-modal .pl-xl-3,
  .bs4-modal .px-xl-3, .bs4 .pl-xl-3,
  .bs4 .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .react-app .p-xl-4, .multiple-react-app .p-xl-4, .bs4-popover .p-xl-4, .bs4-modal .p-xl-4, .bs4 .p-xl-4 {
    padding: 2rem !important;
  }
  .react-app .pt-xl-4,
  .react-app .py-xl-4, .multiple-react-app .pt-xl-4,
  .multiple-react-app .py-xl-4, .bs4-popover .pt-xl-4,
  .bs4-popover .py-xl-4, .bs4-modal .pt-xl-4,
  .bs4-modal .py-xl-4, .bs4 .pt-xl-4,
  .bs4 .py-xl-4 {
    padding-top: 2rem !important;
  }
  .react-app .pr-xl-4,
  .react-app .px-xl-4, .multiple-react-app .pr-xl-4,
  .multiple-react-app .px-xl-4, .bs4-popover .pr-xl-4,
  .bs4-popover .px-xl-4, .bs4-modal .pr-xl-4,
  .bs4-modal .px-xl-4, .bs4 .pr-xl-4,
  .bs4 .px-xl-4 {
    padding-right: 2rem !important;
  }
  .react-app .pb-xl-4,
  .react-app .py-xl-4, .multiple-react-app .pb-xl-4,
  .multiple-react-app .py-xl-4, .bs4-popover .pb-xl-4,
  .bs4-popover .py-xl-4, .bs4-modal .pb-xl-4,
  .bs4-modal .py-xl-4, .bs4 .pb-xl-4,
  .bs4 .py-xl-4 {
    padding-bottom: 2rem !important;
  }
  .react-app .pl-xl-4,
  .react-app .px-xl-4, .multiple-react-app .pl-xl-4,
  .multiple-react-app .px-xl-4, .bs4-popover .pl-xl-4,
  .bs4-popover .px-xl-4, .bs4-modal .pl-xl-4,
  .bs4-modal .px-xl-4, .bs4 .pl-xl-4,
  .bs4 .px-xl-4 {
    padding-left: 2rem !important;
  }
  .react-app .p-xl-5, .multiple-react-app .p-xl-5, .bs4-popover .p-xl-5, .bs4-modal .p-xl-5, .bs4 .p-xl-5 {
    padding: 3rem !important;
  }
  .react-app .pt-xl-5,
  .react-app .py-xl-5, .multiple-react-app .pt-xl-5,
  .multiple-react-app .py-xl-5, .bs4-popover .pt-xl-5,
  .bs4-popover .py-xl-5, .bs4-modal .pt-xl-5,
  .bs4-modal .py-xl-5, .bs4 .pt-xl-5,
  .bs4 .py-xl-5 {
    padding-top: 3rem !important;
  }
  .react-app .pr-xl-5,
  .react-app .px-xl-5, .multiple-react-app .pr-xl-5,
  .multiple-react-app .px-xl-5, .bs4-popover .pr-xl-5,
  .bs4-popover .px-xl-5, .bs4-modal .pr-xl-5,
  .bs4-modal .px-xl-5, .bs4 .pr-xl-5,
  .bs4 .px-xl-5 {
    padding-right: 3rem !important;
  }
  .react-app .pb-xl-5,
  .react-app .py-xl-5, .multiple-react-app .pb-xl-5,
  .multiple-react-app .py-xl-5, .bs4-popover .pb-xl-5,
  .bs4-popover .py-xl-5, .bs4-modal .pb-xl-5,
  .bs4-modal .py-xl-5, .bs4 .pb-xl-5,
  .bs4 .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .react-app .pl-xl-5,
  .react-app .px-xl-5, .multiple-react-app .pl-xl-5,
  .multiple-react-app .px-xl-5, .bs4-popover .pl-xl-5,
  .bs4-popover .px-xl-5, .bs4-modal .pl-xl-5,
  .bs4-modal .px-xl-5, .bs4 .pl-xl-5,
  .bs4 .px-xl-5 {
    padding-left: 3rem !important;
  }
  .react-app .p-xl-6, .multiple-react-app .p-xl-6, .bs4-popover .p-xl-6, .bs4-modal .p-xl-6, .bs4 .p-xl-6 {
    padding: 4rem !important;
  }
  .react-app .pt-xl-6,
  .react-app .py-xl-6, .multiple-react-app .pt-xl-6,
  .multiple-react-app .py-xl-6, .bs4-popover .pt-xl-6,
  .bs4-popover .py-xl-6, .bs4-modal .pt-xl-6,
  .bs4-modal .py-xl-6, .bs4 .pt-xl-6,
  .bs4 .py-xl-6 {
    padding-top: 4rem !important;
  }
  .react-app .pr-xl-6,
  .react-app .px-xl-6, .multiple-react-app .pr-xl-6,
  .multiple-react-app .px-xl-6, .bs4-popover .pr-xl-6,
  .bs4-popover .px-xl-6, .bs4-modal .pr-xl-6,
  .bs4-modal .px-xl-6, .bs4 .pr-xl-6,
  .bs4 .px-xl-6 {
    padding-right: 4rem !important;
  }
  .react-app .pb-xl-6,
  .react-app .py-xl-6, .multiple-react-app .pb-xl-6,
  .multiple-react-app .py-xl-6, .bs4-popover .pb-xl-6,
  .bs4-popover .py-xl-6, .bs4-modal .pb-xl-6,
  .bs4-modal .py-xl-6, .bs4 .pb-xl-6,
  .bs4 .py-xl-6 {
    padding-bottom: 4rem !important;
  }
  .react-app .pl-xl-6,
  .react-app .px-xl-6, .multiple-react-app .pl-xl-6,
  .multiple-react-app .px-xl-6, .bs4-popover .pl-xl-6,
  .bs4-popover .px-xl-6, .bs4-modal .pl-xl-6,
  .bs4-modal .px-xl-6, .bs4 .pl-xl-6,
  .bs4 .px-xl-6 {
    padding-left: 4rem !important;
  }
  .react-app .p-xl-7, .multiple-react-app .p-xl-7, .bs4-popover .p-xl-7, .bs4-modal .p-xl-7, .bs4 .p-xl-7 {
    padding: 5rem !important;
  }
  .react-app .pt-xl-7,
  .react-app .py-xl-7, .multiple-react-app .pt-xl-7,
  .multiple-react-app .py-xl-7, .bs4-popover .pt-xl-7,
  .bs4-popover .py-xl-7, .bs4-modal .pt-xl-7,
  .bs4-modal .py-xl-7, .bs4 .pt-xl-7,
  .bs4 .py-xl-7 {
    padding-top: 5rem !important;
  }
  .react-app .pr-xl-7,
  .react-app .px-xl-7, .multiple-react-app .pr-xl-7,
  .multiple-react-app .px-xl-7, .bs4-popover .pr-xl-7,
  .bs4-popover .px-xl-7, .bs4-modal .pr-xl-7,
  .bs4-modal .px-xl-7, .bs4 .pr-xl-7,
  .bs4 .px-xl-7 {
    padding-right: 5rem !important;
  }
  .react-app .pb-xl-7,
  .react-app .py-xl-7, .multiple-react-app .pb-xl-7,
  .multiple-react-app .py-xl-7, .bs4-popover .pb-xl-7,
  .bs4-popover .py-xl-7, .bs4-modal .pb-xl-7,
  .bs4-modal .py-xl-7, .bs4 .pb-xl-7,
  .bs4 .py-xl-7 {
    padding-bottom: 5rem !important;
  }
  .react-app .pl-xl-7,
  .react-app .px-xl-7, .multiple-react-app .pl-xl-7,
  .multiple-react-app .px-xl-7, .bs4-popover .pl-xl-7,
  .bs4-popover .px-xl-7, .bs4-modal .pl-xl-7,
  .bs4-modal .px-xl-7, .bs4 .pl-xl-7,
  .bs4 .px-xl-7 {
    padding-left: 5rem !important;
  }
  .react-app .p-xl-8, .multiple-react-app .p-xl-8, .bs4-popover .p-xl-8, .bs4-modal .p-xl-8, .bs4 .p-xl-8 {
    padding: 6rem !important;
  }
  .react-app .pt-xl-8,
  .react-app .py-xl-8, .multiple-react-app .pt-xl-8,
  .multiple-react-app .py-xl-8, .bs4-popover .pt-xl-8,
  .bs4-popover .py-xl-8, .bs4-modal .pt-xl-8,
  .bs4-modal .py-xl-8, .bs4 .pt-xl-8,
  .bs4 .py-xl-8 {
    padding-top: 6rem !important;
  }
  .react-app .pr-xl-8,
  .react-app .px-xl-8, .multiple-react-app .pr-xl-8,
  .multiple-react-app .px-xl-8, .bs4-popover .pr-xl-8,
  .bs4-popover .px-xl-8, .bs4-modal .pr-xl-8,
  .bs4-modal .px-xl-8, .bs4 .pr-xl-8,
  .bs4 .px-xl-8 {
    padding-right: 6rem !important;
  }
  .react-app .pb-xl-8,
  .react-app .py-xl-8, .multiple-react-app .pb-xl-8,
  .multiple-react-app .py-xl-8, .bs4-popover .pb-xl-8,
  .bs4-popover .py-xl-8, .bs4-modal .pb-xl-8,
  .bs4-modal .py-xl-8, .bs4 .pb-xl-8,
  .bs4 .py-xl-8 {
    padding-bottom: 6rem !important;
  }
  .react-app .pl-xl-8,
  .react-app .px-xl-8, .multiple-react-app .pl-xl-8,
  .multiple-react-app .px-xl-8, .bs4-popover .pl-xl-8,
  .bs4-popover .px-xl-8, .bs4-modal .pl-xl-8,
  .bs4-modal .px-xl-8, .bs4 .pl-xl-8,
  .bs4 .px-xl-8 {
    padding-left: 6rem !important;
  }
  .react-app .m-xl-n1, .multiple-react-app .m-xl-n1, .bs4-popover .m-xl-n1, .bs4-modal .m-xl-n1, .bs4 .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .react-app .mt-xl-n1,
  .react-app .my-xl-n1, .multiple-react-app .mt-xl-n1,
  .multiple-react-app .my-xl-n1, .bs4-popover .mt-xl-n1,
  .bs4-popover .my-xl-n1, .bs4-modal .mt-xl-n1,
  .bs4-modal .my-xl-n1, .bs4 .mt-xl-n1,
  .bs4 .my-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .react-app .mr-xl-n1,
  .react-app .mx-xl-n1, .multiple-react-app .mr-xl-n1,
  .multiple-react-app .mx-xl-n1, .bs4-popover .mr-xl-n1,
  .bs4-popover .mx-xl-n1, .bs4-modal .mr-xl-n1,
  .bs4-modal .mx-xl-n1, .bs4 .mr-xl-n1,
  .bs4 .mx-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .react-app .mb-xl-n1,
  .react-app .my-xl-n1, .multiple-react-app .mb-xl-n1,
  .multiple-react-app .my-xl-n1, .bs4-popover .mb-xl-n1,
  .bs4-popover .my-xl-n1, .bs4-modal .mb-xl-n1,
  .bs4-modal .my-xl-n1, .bs4 .mb-xl-n1,
  .bs4 .my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .react-app .ml-xl-n1,
  .react-app .mx-xl-n1, .multiple-react-app .ml-xl-n1,
  .multiple-react-app .mx-xl-n1, .bs4-popover .ml-xl-n1,
  .bs4-popover .mx-xl-n1, .bs4-modal .ml-xl-n1,
  .bs4-modal .mx-xl-n1, .bs4 .ml-xl-n1,
  .bs4 .mx-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .react-app .m-xl-n2, .multiple-react-app .m-xl-n2, .bs4-popover .m-xl-n2, .bs4-modal .m-xl-n2, .bs4 .m-xl-n2 {
    margin: -1rem !important;
  }
  .react-app .mt-xl-n2,
  .react-app .my-xl-n2, .multiple-react-app .mt-xl-n2,
  .multiple-react-app .my-xl-n2, .bs4-popover .mt-xl-n2,
  .bs4-popover .my-xl-n2, .bs4-modal .mt-xl-n2,
  .bs4-modal .my-xl-n2, .bs4 .mt-xl-n2,
  .bs4 .my-xl-n2 {
    margin-top: -1rem !important;
  }
  .react-app .mr-xl-n2,
  .react-app .mx-xl-n2, .multiple-react-app .mr-xl-n2,
  .multiple-react-app .mx-xl-n2, .bs4-popover .mr-xl-n2,
  .bs4-popover .mx-xl-n2, .bs4-modal .mr-xl-n2,
  .bs4-modal .mx-xl-n2, .bs4 .mr-xl-n2,
  .bs4 .mx-xl-n2 {
    margin-right: -1rem !important;
  }
  .react-app .mb-xl-n2,
  .react-app .my-xl-n2, .multiple-react-app .mb-xl-n2,
  .multiple-react-app .my-xl-n2, .bs4-popover .mb-xl-n2,
  .bs4-popover .my-xl-n2, .bs4-modal .mb-xl-n2,
  .bs4-modal .my-xl-n2, .bs4 .mb-xl-n2,
  .bs4 .my-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .react-app .ml-xl-n2,
  .react-app .mx-xl-n2, .multiple-react-app .ml-xl-n2,
  .multiple-react-app .mx-xl-n2, .bs4-popover .ml-xl-n2,
  .bs4-popover .mx-xl-n2, .bs4-modal .ml-xl-n2,
  .bs4-modal .mx-xl-n2, .bs4 .ml-xl-n2,
  .bs4 .mx-xl-n2 {
    margin-left: -1rem !important;
  }
  .react-app .m-xl-n3, .multiple-react-app .m-xl-n3, .bs4-popover .m-xl-n3, .bs4-modal .m-xl-n3, .bs4 .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .react-app .mt-xl-n3,
  .react-app .my-xl-n3, .multiple-react-app .mt-xl-n3,
  .multiple-react-app .my-xl-n3, .bs4-popover .mt-xl-n3,
  .bs4-popover .my-xl-n3, .bs4-modal .mt-xl-n3,
  .bs4-modal .my-xl-n3, .bs4 .mt-xl-n3,
  .bs4 .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .react-app .mr-xl-n3,
  .react-app .mx-xl-n3, .multiple-react-app .mr-xl-n3,
  .multiple-react-app .mx-xl-n3, .bs4-popover .mr-xl-n3,
  .bs4-popover .mx-xl-n3, .bs4-modal .mr-xl-n3,
  .bs4-modal .mx-xl-n3, .bs4 .mr-xl-n3,
  .bs4 .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .react-app .mb-xl-n3,
  .react-app .my-xl-n3, .multiple-react-app .mb-xl-n3,
  .multiple-react-app .my-xl-n3, .bs4-popover .mb-xl-n3,
  .bs4-popover .my-xl-n3, .bs4-modal .mb-xl-n3,
  .bs4-modal .my-xl-n3, .bs4 .mb-xl-n3,
  .bs4 .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .react-app .ml-xl-n3,
  .react-app .mx-xl-n3, .multiple-react-app .ml-xl-n3,
  .multiple-react-app .mx-xl-n3, .bs4-popover .ml-xl-n3,
  .bs4-popover .mx-xl-n3, .bs4-modal .ml-xl-n3,
  .bs4-modal .mx-xl-n3, .bs4 .ml-xl-n3,
  .bs4 .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .react-app .m-xl-n4, .multiple-react-app .m-xl-n4, .bs4-popover .m-xl-n4, .bs4-modal .m-xl-n4, .bs4 .m-xl-n4 {
    margin: -2rem !important;
  }
  .react-app .mt-xl-n4,
  .react-app .my-xl-n4, .multiple-react-app .mt-xl-n4,
  .multiple-react-app .my-xl-n4, .bs4-popover .mt-xl-n4,
  .bs4-popover .my-xl-n4, .bs4-modal .mt-xl-n4,
  .bs4-modal .my-xl-n4, .bs4 .mt-xl-n4,
  .bs4 .my-xl-n4 {
    margin-top: -2rem !important;
  }
  .react-app .mr-xl-n4,
  .react-app .mx-xl-n4, .multiple-react-app .mr-xl-n4,
  .multiple-react-app .mx-xl-n4, .bs4-popover .mr-xl-n4,
  .bs4-popover .mx-xl-n4, .bs4-modal .mr-xl-n4,
  .bs4-modal .mx-xl-n4, .bs4 .mr-xl-n4,
  .bs4 .mx-xl-n4 {
    margin-right: -2rem !important;
  }
  .react-app .mb-xl-n4,
  .react-app .my-xl-n4, .multiple-react-app .mb-xl-n4,
  .multiple-react-app .my-xl-n4, .bs4-popover .mb-xl-n4,
  .bs4-popover .my-xl-n4, .bs4-modal .mb-xl-n4,
  .bs4-modal .my-xl-n4, .bs4 .mb-xl-n4,
  .bs4 .my-xl-n4 {
    margin-bottom: -2rem !important;
  }
  .react-app .ml-xl-n4,
  .react-app .mx-xl-n4, .multiple-react-app .ml-xl-n4,
  .multiple-react-app .mx-xl-n4, .bs4-popover .ml-xl-n4,
  .bs4-popover .mx-xl-n4, .bs4-modal .ml-xl-n4,
  .bs4-modal .mx-xl-n4, .bs4 .ml-xl-n4,
  .bs4 .mx-xl-n4 {
    margin-left: -2rem !important;
  }
  .react-app .m-xl-n5, .multiple-react-app .m-xl-n5, .bs4-popover .m-xl-n5, .bs4-modal .m-xl-n5, .bs4 .m-xl-n5 {
    margin: -3rem !important;
  }
  .react-app .mt-xl-n5,
  .react-app .my-xl-n5, .multiple-react-app .mt-xl-n5,
  .multiple-react-app .my-xl-n5, .bs4-popover .mt-xl-n5,
  .bs4-popover .my-xl-n5, .bs4-modal .mt-xl-n5,
  .bs4-modal .my-xl-n5, .bs4 .mt-xl-n5,
  .bs4 .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .react-app .mr-xl-n5,
  .react-app .mx-xl-n5, .multiple-react-app .mr-xl-n5,
  .multiple-react-app .mx-xl-n5, .bs4-popover .mr-xl-n5,
  .bs4-popover .mx-xl-n5, .bs4-modal .mr-xl-n5,
  .bs4-modal .mx-xl-n5, .bs4 .mr-xl-n5,
  .bs4 .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .react-app .mb-xl-n5,
  .react-app .my-xl-n5, .multiple-react-app .mb-xl-n5,
  .multiple-react-app .my-xl-n5, .bs4-popover .mb-xl-n5,
  .bs4-popover .my-xl-n5, .bs4-modal .mb-xl-n5,
  .bs4-modal .my-xl-n5, .bs4 .mb-xl-n5,
  .bs4 .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .react-app .ml-xl-n5,
  .react-app .mx-xl-n5, .multiple-react-app .ml-xl-n5,
  .multiple-react-app .mx-xl-n5, .bs4-popover .ml-xl-n5,
  .bs4-popover .mx-xl-n5, .bs4-modal .ml-xl-n5,
  .bs4-modal .mx-xl-n5, .bs4 .ml-xl-n5,
  .bs4 .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .react-app .m-xl-n6, .multiple-react-app .m-xl-n6, .bs4-popover .m-xl-n6, .bs4-modal .m-xl-n6, .bs4 .m-xl-n6 {
    margin: -4rem !important;
  }
  .react-app .mt-xl-n6,
  .react-app .my-xl-n6, .multiple-react-app .mt-xl-n6,
  .multiple-react-app .my-xl-n6, .bs4-popover .mt-xl-n6,
  .bs4-popover .my-xl-n6, .bs4-modal .mt-xl-n6,
  .bs4-modal .my-xl-n6, .bs4 .mt-xl-n6,
  .bs4 .my-xl-n6 {
    margin-top: -4rem !important;
  }
  .react-app .mr-xl-n6,
  .react-app .mx-xl-n6, .multiple-react-app .mr-xl-n6,
  .multiple-react-app .mx-xl-n6, .bs4-popover .mr-xl-n6,
  .bs4-popover .mx-xl-n6, .bs4-modal .mr-xl-n6,
  .bs4-modal .mx-xl-n6, .bs4 .mr-xl-n6,
  .bs4 .mx-xl-n6 {
    margin-right: -4rem !important;
  }
  .react-app .mb-xl-n6,
  .react-app .my-xl-n6, .multiple-react-app .mb-xl-n6,
  .multiple-react-app .my-xl-n6, .bs4-popover .mb-xl-n6,
  .bs4-popover .my-xl-n6, .bs4-modal .mb-xl-n6,
  .bs4-modal .my-xl-n6, .bs4 .mb-xl-n6,
  .bs4 .my-xl-n6 {
    margin-bottom: -4rem !important;
  }
  .react-app .ml-xl-n6,
  .react-app .mx-xl-n6, .multiple-react-app .ml-xl-n6,
  .multiple-react-app .mx-xl-n6, .bs4-popover .ml-xl-n6,
  .bs4-popover .mx-xl-n6, .bs4-modal .ml-xl-n6,
  .bs4-modal .mx-xl-n6, .bs4 .ml-xl-n6,
  .bs4 .mx-xl-n6 {
    margin-left: -4rem !important;
  }
  .react-app .m-xl-n7, .multiple-react-app .m-xl-n7, .bs4-popover .m-xl-n7, .bs4-modal .m-xl-n7, .bs4 .m-xl-n7 {
    margin: -5rem !important;
  }
  .react-app .mt-xl-n7,
  .react-app .my-xl-n7, .multiple-react-app .mt-xl-n7,
  .multiple-react-app .my-xl-n7, .bs4-popover .mt-xl-n7,
  .bs4-popover .my-xl-n7, .bs4-modal .mt-xl-n7,
  .bs4-modal .my-xl-n7, .bs4 .mt-xl-n7,
  .bs4 .my-xl-n7 {
    margin-top: -5rem !important;
  }
  .react-app .mr-xl-n7,
  .react-app .mx-xl-n7, .multiple-react-app .mr-xl-n7,
  .multiple-react-app .mx-xl-n7, .bs4-popover .mr-xl-n7,
  .bs4-popover .mx-xl-n7, .bs4-modal .mr-xl-n7,
  .bs4-modal .mx-xl-n7, .bs4 .mr-xl-n7,
  .bs4 .mx-xl-n7 {
    margin-right: -5rem !important;
  }
  .react-app .mb-xl-n7,
  .react-app .my-xl-n7, .multiple-react-app .mb-xl-n7,
  .multiple-react-app .my-xl-n7, .bs4-popover .mb-xl-n7,
  .bs4-popover .my-xl-n7, .bs4-modal .mb-xl-n7,
  .bs4-modal .my-xl-n7, .bs4 .mb-xl-n7,
  .bs4 .my-xl-n7 {
    margin-bottom: -5rem !important;
  }
  .react-app .ml-xl-n7,
  .react-app .mx-xl-n7, .multiple-react-app .ml-xl-n7,
  .multiple-react-app .mx-xl-n7, .bs4-popover .ml-xl-n7,
  .bs4-popover .mx-xl-n7, .bs4-modal .ml-xl-n7,
  .bs4-modal .mx-xl-n7, .bs4 .ml-xl-n7,
  .bs4 .mx-xl-n7 {
    margin-left: -5rem !important;
  }
  .react-app .m-xl-n8, .multiple-react-app .m-xl-n8, .bs4-popover .m-xl-n8, .bs4-modal .m-xl-n8, .bs4 .m-xl-n8 {
    margin: -6rem !important;
  }
  .react-app .mt-xl-n8,
  .react-app .my-xl-n8, .multiple-react-app .mt-xl-n8,
  .multiple-react-app .my-xl-n8, .bs4-popover .mt-xl-n8,
  .bs4-popover .my-xl-n8, .bs4-modal .mt-xl-n8,
  .bs4-modal .my-xl-n8, .bs4 .mt-xl-n8,
  .bs4 .my-xl-n8 {
    margin-top: -6rem !important;
  }
  .react-app .mr-xl-n8,
  .react-app .mx-xl-n8, .multiple-react-app .mr-xl-n8,
  .multiple-react-app .mx-xl-n8, .bs4-popover .mr-xl-n8,
  .bs4-popover .mx-xl-n8, .bs4-modal .mr-xl-n8,
  .bs4-modal .mx-xl-n8, .bs4 .mr-xl-n8,
  .bs4 .mx-xl-n8 {
    margin-right: -6rem !important;
  }
  .react-app .mb-xl-n8,
  .react-app .my-xl-n8, .multiple-react-app .mb-xl-n8,
  .multiple-react-app .my-xl-n8, .bs4-popover .mb-xl-n8,
  .bs4-popover .my-xl-n8, .bs4-modal .mb-xl-n8,
  .bs4-modal .my-xl-n8, .bs4 .mb-xl-n8,
  .bs4 .my-xl-n8 {
    margin-bottom: -6rem !important;
  }
  .react-app .ml-xl-n8,
  .react-app .mx-xl-n8, .multiple-react-app .ml-xl-n8,
  .multiple-react-app .mx-xl-n8, .bs4-popover .ml-xl-n8,
  .bs4-popover .mx-xl-n8, .bs4-modal .ml-xl-n8,
  .bs4-modal .mx-xl-n8, .bs4 .ml-xl-n8,
  .bs4 .mx-xl-n8 {
    margin-left: -6rem !important;
  }
  .react-app .m-xl-auto, .multiple-react-app .m-xl-auto, .bs4-popover .m-xl-auto, .bs4-modal .m-xl-auto, .bs4 .m-xl-auto {
    margin: auto !important;
  }
  .react-app .mt-xl-auto,
  .react-app .my-xl-auto, .multiple-react-app .mt-xl-auto,
  .multiple-react-app .my-xl-auto, .bs4-popover .mt-xl-auto,
  .bs4-popover .my-xl-auto, .bs4-modal .mt-xl-auto,
  .bs4-modal .my-xl-auto, .bs4 .mt-xl-auto,
  .bs4 .my-xl-auto {
    margin-top: auto !important;
  }
  .react-app .mr-xl-auto,
  .react-app .mx-xl-auto, .multiple-react-app .mr-xl-auto,
  .multiple-react-app .mx-xl-auto, .bs4-popover .mr-xl-auto,
  .bs4-popover .mx-xl-auto, .bs4-modal .mr-xl-auto,
  .bs4-modal .mx-xl-auto, .bs4 .mr-xl-auto,
  .bs4 .mx-xl-auto {
    margin-right: auto !important;
  }
  .react-app .mb-xl-auto,
  .react-app .my-xl-auto, .multiple-react-app .mb-xl-auto,
  .multiple-react-app .my-xl-auto, .bs4-popover .mb-xl-auto,
  .bs4-popover .my-xl-auto, .bs4-modal .mb-xl-auto,
  .bs4-modal .my-xl-auto, .bs4 .mb-xl-auto,
  .bs4 .my-xl-auto {
    margin-bottom: auto !important;
  }
  .react-app .ml-xl-auto,
  .react-app .mx-xl-auto, .multiple-react-app .ml-xl-auto,
  .multiple-react-app .mx-xl-auto, .bs4-popover .ml-xl-auto,
  .bs4-popover .mx-xl-auto, .bs4-modal .ml-xl-auto,
  .bs4-modal .mx-xl-auto, .bs4 .ml-xl-auto,
  .bs4 .mx-xl-auto {
    margin-left: auto !important;
  }
}
.react-app .stretched-link::after, .multiple-react-app .stretched-link::after, .bs4-popover .stretched-link::after, .bs4-modal .stretched-link::after, .bs4 .stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}
.react-app .text-monospace, .multiple-react-app .text-monospace, .bs4-popover .text-monospace, .bs4-modal .text-monospace, .bs4 .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}
.react-app .text-justify, .multiple-react-app .text-justify, .bs4-popover .text-justify, .bs4-modal .text-justify, .bs4 .text-justify {
  text-align: justify !important;
}
.react-app .text-wrap, .multiple-react-app .text-wrap, .bs4-popover .text-wrap, .bs4-modal .text-wrap, .bs4 .text-wrap {
  white-space: normal !important;
}
.react-app .text-nowrap, .multiple-react-app .text-nowrap, .bs4-popover .text-nowrap, .bs4-modal .text-nowrap, .bs4 .text-nowrap {
  white-space: nowrap !important;
}
.react-app .text-truncate, .multiple-react-app .text-truncate, .bs4-popover .text-truncate, .bs4-modal .text-truncate, .bs4 .text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.react-app .text-left, .multiple-react-app .text-left, .bs4-popover .text-left, .bs4-modal .text-left, .bs4 .text-left {
  text-align: left !important;
}
.react-app .text-right, .multiple-react-app .text-right, .bs4-popover .text-right, .bs4-modal .text-right, .bs4 .text-right {
  text-align: right !important;
}
.react-app .text-center, .multiple-react-app .text-center, .bs4-popover .text-center, .bs4-modal .text-center, .bs4 .text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .react-app .text-sm-left, .multiple-react-app .text-sm-left, .bs4-popover .text-sm-left, .bs4-modal .text-sm-left, .bs4 .text-sm-left {
    text-align: left !important;
  }
  .react-app .text-sm-right, .multiple-react-app .text-sm-right, .bs4-popover .text-sm-right, .bs4-modal .text-sm-right, .bs4 .text-sm-right {
    text-align: right !important;
  }
  .react-app .text-sm-center, .multiple-react-app .text-sm-center, .bs4-popover .text-sm-center, .bs4-modal .text-sm-center, .bs4 .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .react-app .text-md-left, .multiple-react-app .text-md-left, .bs4-popover .text-md-left, .bs4-modal .text-md-left, .bs4 .text-md-left {
    text-align: left !important;
  }
  .react-app .text-md-right, .multiple-react-app .text-md-right, .bs4-popover .text-md-right, .bs4-modal .text-md-right, .bs4 .text-md-right {
    text-align: right !important;
  }
  .react-app .text-md-center, .multiple-react-app .text-md-center, .bs4-popover .text-md-center, .bs4-modal .text-md-center, .bs4 .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .react-app .text-lg-left, .multiple-react-app .text-lg-left, .bs4-popover .text-lg-left, .bs4-modal .text-lg-left, .bs4 .text-lg-left {
    text-align: left !important;
  }
  .react-app .text-lg-right, .multiple-react-app .text-lg-right, .bs4-popover .text-lg-right, .bs4-modal .text-lg-right, .bs4 .text-lg-right {
    text-align: right !important;
  }
  .react-app .text-lg-center, .multiple-react-app .text-lg-center, .bs4-popover .text-lg-center, .bs4-modal .text-lg-center, .bs4 .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .react-app .text-xl-left, .multiple-react-app .text-xl-left, .bs4-popover .text-xl-left, .bs4-modal .text-xl-left, .bs4 .text-xl-left {
    text-align: left !important;
  }
  .react-app .text-xl-right, .multiple-react-app .text-xl-right, .bs4-popover .text-xl-right, .bs4-modal .text-xl-right, .bs4 .text-xl-right {
    text-align: right !important;
  }
  .react-app .text-xl-center, .multiple-react-app .text-xl-center, .bs4-popover .text-xl-center, .bs4-modal .text-xl-center, .bs4 .text-xl-center {
    text-align: center !important;
  }
}
.react-app .text-lowercase, .multiple-react-app .text-lowercase, .bs4-popover .text-lowercase, .bs4-modal .text-lowercase, .bs4 .text-lowercase {
  text-transform: lowercase !important;
}
.react-app .text-uppercase, .multiple-react-app .text-uppercase, .bs4-popover .text-uppercase, .bs4-modal .text-uppercase, .bs4 .text-uppercase {
  text-transform: uppercase !important;
}
.react-app .text-capitalize, .multiple-react-app .text-capitalize, .bs4-popover .text-capitalize, .bs4-modal .text-capitalize, .bs4 .text-capitalize {
  text-transform: capitalize !important;
}
.react-app .font-weight-light, .multiple-react-app .font-weight-light, .bs4-popover .font-weight-light, .bs4-modal .font-weight-light, .bs4 .font-weight-light {
  font-weight: 300 !important;
}
.react-app .font-weight-lighter, .multiple-react-app .font-weight-lighter, .bs4-popover .font-weight-lighter, .bs4-modal .font-weight-lighter, .bs4 .font-weight-lighter {
  font-weight: lighter !important;
}
.react-app .font-weight-normal, .multiple-react-app .font-weight-normal, .bs4-popover .font-weight-normal, .bs4-modal .font-weight-normal, .bs4 .font-weight-normal {
  font-weight: 400 !important;
}
.react-app .font-weight-bold, .multiple-react-app .font-weight-bold, .bs4-popover .font-weight-bold, .bs4-modal .font-weight-bold, .bs4 .font-weight-bold {
  font-weight: 600 !important;
}
.react-app .font-weight-bolder, .multiple-react-app .font-weight-bolder, .bs4-popover .font-weight-bolder, .bs4-modal .font-weight-bolder, .bs4 .font-weight-bolder {
  font-weight: 700 !important;
}
.react-app .font-italic, .multiple-react-app .font-italic, .bs4-popover .font-italic, .bs4-modal .font-italic, .bs4 .font-italic {
  font-style: italic !important;
}
.react-app .text-white, .multiple-react-app .text-white, .bs4-popover .text-white, .bs4-modal .text-white, .bs4 .text-white {
  color: #fff !important;
}
.react-app .text-primary, .multiple-react-app .text-primary, .bs4-popover .text-primary, .bs4-modal .text-primary, .bs4 .text-primary {
  color: #0E72ED !important;
}
.react-app a.text-primary:hover, .react-app a.text-primary:focus, .multiple-react-app a.text-primary:hover, .multiple-react-app a.text-primary:focus, .bs4-popover a.text-primary:hover, .bs4-popover a.text-primary:focus, .bs4-modal a.text-primary:hover, .bs4-modal a.text-primary:focus, .bs4 a.text-primary:hover, .bs4 a.text-primary:focus {
  color: #0a4fa5 !important;
}
.react-app .text-secondary, .multiple-react-app .text-secondary, .bs4-popover .text-secondary, .bs4-modal .text-secondary, .bs4 .text-secondary {
  color: #6c757d !important;
}
.react-app a.text-secondary:hover, .react-app a.text-secondary:focus, .multiple-react-app a.text-secondary:hover, .multiple-react-app a.text-secondary:focus, .bs4-popover a.text-secondary:hover, .bs4-popover a.text-secondary:focus, .bs4-modal a.text-secondary:hover, .bs4-modal a.text-secondary:focus, .bs4 a.text-secondary:hover, .bs4 a.text-secondary:focus {
  color: #494f54 !important;
}
.react-app .text-success, .multiple-react-app .text-success, .bs4-popover .text-success, .bs4-modal .text-success, .bs4 .text-success {
  color: #00843C !important;
}
.react-app a.text-success:hover, .react-app a.text-success:focus, .multiple-react-app a.text-success:hover, .multiple-react-app a.text-success:focus, .bs4-popover a.text-success:hover, .bs4-popover a.text-success:focus, .bs4-modal a.text-success:hover, .bs4-modal a.text-success:focus, .bs4 a.text-success:hover, .bs4 a.text-success:focus {
  color: #003819 !important;
}
.react-app .text-info, .multiple-react-app .text-info, .bs4-popover .text-info, .bs4-modal .text-info, .bs4 .text-info {
  color: #eaf7f7 !important;
}
.react-app a.text-info:hover, .react-app a.text-info:focus, .multiple-react-app a.text-info:hover, .multiple-react-app a.text-info:focus, .bs4-popover a.text-info:hover, .bs4-popover a.text-info:focus, .bs4-modal a.text-info:hover, .bs4-modal a.text-info:focus, .bs4 a.text-info:hover, .bs4 a.text-info:focus {
  color: #b3e2e2 !important;
}
.react-app .text-warning, .multiple-react-app .text-warning, .bs4-popover .text-warning, .bs4-modal .text-warning, .bs4 .text-warning {
  color: #fa6625 !important;
}
.react-app a.text-warning:hover, .react-app a.text-warning:focus, .multiple-react-app a.text-warning:hover, .multiple-react-app a.text-warning:focus, .bs4-popover a.text-warning:hover, .bs4-popover a.text-warning:focus, .bs4-modal a.text-warning:hover, .bs4-modal a.text-warning:focus, .bs4 a.text-warning:hover, .bs4 a.text-warning:focus {
  color: #ce4205 !important;
}
.react-app .text-danger, .multiple-react-app .text-danger, .bs4-popover .text-danger, .bs4-modal .text-danger, .bs4 .text-danger {
  color: #eb0c3b !important;
}
.react-app a.text-danger:hover, .react-app a.text-danger:focus, .multiple-react-app a.text-danger:hover, .multiple-react-app a.text-danger:focus, .bs4-popover a.text-danger:hover, .bs4-popover a.text-danger:focus, .bs4-modal a.text-danger:hover, .bs4-modal a.text-danger:focus, .bs4 a.text-danger:hover, .bs4 a.text-danger:focus {
  color: #a20829 !important;
}
.react-app .text-light, .multiple-react-app .text-light, .bs4-popover .text-light, .bs4-modal .text-light, .bs4 .text-light {
  color: #f8f9fa !important;
}
.react-app a.text-light:hover, .react-app a.text-light:focus, .multiple-react-app a.text-light:hover, .multiple-react-app a.text-light:focus, .bs4-popover a.text-light:hover, .bs4-popover a.text-light:focus, .bs4-modal a.text-light:hover, .bs4-modal a.text-light:focus, .bs4 a.text-light:hover, .bs4 a.text-light:focus {
  color: #cbd3da !important;
}
.react-app .text-dark, .multiple-react-app .text-dark, .bs4-popover .text-dark, .bs4-modal .text-dark, .bs4 .text-dark {
  color: #343a40 !important;
}
.react-app a.text-dark:hover, .react-app a.text-dark:focus, .multiple-react-app a.text-dark:hover, .multiple-react-app a.text-dark:focus, .bs4-popover a.text-dark:hover, .bs4-popover a.text-dark:focus, .bs4-modal a.text-dark:hover, .bs4-modal a.text-dark:focus, .bs4 a.text-dark:hover, .bs4 a.text-dark:focus {
  color: #121416 !important;
}
.react-app .text-logo, .multiple-react-app .text-logo, .bs4-popover .text-logo, .bs4-modal .text-logo, .bs4 .text-logo {
  color: #467b8c !important;
}
.react-app a.text-logo:hover, .react-app a.text-logo:focus, .multiple-react-app a.text-logo:hover, .multiple-react-app a.text-logo:focus, .bs4-popover a.text-logo:hover, .bs4-popover a.text-logo:focus, .bs4-modal a.text-logo:hover, .bs4-modal a.text-logo:focus, .bs4 a.text-logo:hover, .bs4 a.text-logo:focus {
  color: #2d4e59 !important;
}
.react-app .text-black, .multiple-react-app .text-black, .bs4-popover .text-black, .bs4-modal .text-black, .bs4 .text-black {
  color: #1d2126 !important;
}
.react-app a.text-black:hover, .react-app a.text-black:focus, .multiple-react-app a.text-black:hover, .multiple-react-app a.text-black:focus, .bs4-popover a.text-black:hover, .bs4-popover a.text-black:focus, .bs4-modal a.text-black:hover, .bs4-modal a.text-black:focus, .bs4 a.text-black:hover, .bs4 a.text-black:focus {
  color: black !important;
}
.react-app .text-gray-1, .multiple-react-app .text-gray-1, .bs4-popover .text-gray-1, .bs4-modal .text-gray-1, .bs4 .text-gray-1 {
  color: #3a424d !important;
}
.react-app a.text-gray-1:hover, .react-app a.text-gray-1:focus, .multiple-react-app a.text-gray-1:hover, .multiple-react-app a.text-gray-1:focus, .bs4-popover a.text-gray-1:hover, .bs4-popover a.text-gray-1:focus, .bs4-modal a.text-gray-1:hover, .bs4-modal a.text-gray-1:focus, .bs4 a.text-gray-1:hover, .bs4 a.text-gray-1:focus {
  color: #191d21 !important;
}
.react-app .text-gray-2, .multiple-react-app .text-gray-2, .bs4-popover .text-gray-2, .bs4-modal .text-gray-2, .bs4 .text-gray-2 {
  color: #576373 !important;
}
.react-app a.text-gray-2:hover, .react-app a.text-gray-2:focus, .multiple-react-app a.text-gray-2:hover, .multiple-react-app a.text-gray-2:focus, .bs4-popover a.text-gray-2:hover, .bs4-popover a.text-gray-2:focus, .bs4-modal a.text-gray-2:hover, .bs4-modal a.text-gray-2:focus, .bs4 a.text-gray-2:hover, .bs4 a.text-gray-2:focus {
  color: #363e47 !important;
}
.react-app .text-gray-3, .multiple-react-app .text-gray-3, .bs4-popover .text-gray-3, .bs4-modal .text-gray-3, .bs4 .text-gray-3 {
  color: #748499 !important;
}
.react-app a.text-gray-3:hover, .react-app a.text-gray-3:focus, .multiple-react-app a.text-gray-3:hover, .multiple-react-app a.text-gray-3:focus, .bs4-popover a.text-gray-3:hover, .bs4-popover a.text-gray-3:focus, .bs4-modal a.text-gray-3:hover, .bs4-modal a.text-gray-3:focus, .bs4 a.text-gray-3:hover, .bs4 a.text-gray-3:focus {
  color: #515e6f !important;
}
.react-app .text-gray-4, .multiple-react-app .text-gray-4, .bs4-popover .text-gray-4, .bs4-modal .text-gray-4, .bs4 .text-gray-4 {
  color: #acb5c2 !important;
}
.react-app a.text-gray-4:hover, .react-app a.text-gray-4:focus, .multiple-react-app a.text-gray-4:hover, .multiple-react-app a.text-gray-4:focus, .bs4-popover a.text-gray-4:hover, .bs4-popover a.text-gray-4:focus, .bs4-modal a.text-gray-4:hover, .bs4-modal a.text-gray-4:focus, .bs4 a.text-gray-4:hover, .bs4 a.text-gray-4:focus {
  color: #808ea2 !important;
}
.react-app .text-gray-5, .multiple-react-app .text-gray-5, .bs4-popover .text-gray-5, .bs4-modal .text-gray-5, .bs4 .text-gray-5 {
  color: #d4dae1 !important;
}
.react-app a.text-gray-5:hover, .react-app a.text-gray-5:focus, .multiple-react-app a.text-gray-5:hover, .multiple-react-app a.text-gray-5:focus, .bs4-popover a.text-gray-5:hover, .bs4-popover a.text-gray-5:focus, .bs4-modal a.text-gray-5:hover, .bs4-modal a.text-gray-5:focus, .bs4 a.text-gray-5:hover, .bs4 a.text-gray-5:focus {
  color: #a7b3c2 !important;
}
.react-app .text-gray-6, .multiple-react-app .text-gray-6, .bs4-popover .text-gray-6, .bs4-modal .text-gray-6, .bs4 .text-gray-6 {
  color: #e4e7ec !important;
}
.react-app a.text-gray-6:hover, .react-app a.text-gray-6:focus, .multiple-react-app a.text-gray-6:hover, .multiple-react-app a.text-gray-6:focus, .bs4-popover a.text-gray-6:hover, .bs4-popover a.text-gray-6:focus, .bs4-modal a.text-gray-6:hover, .bs4-modal a.text-gray-6:focus, .bs4 a.text-gray-6:hover, .bs4 a.text-gray-6:focus {
  color: #b7bfcc !important;
}
.react-app .text-gray-7, .multiple-react-app .text-gray-7, .bs4-popover .text-gray-7, .bs4-modal .text-gray-7, .bs4 .text-gray-7 {
  color: #f7f8fa !important;
}
.react-app a.text-gray-7:hover, .react-app a.text-gray-7:focus, .multiple-react-app a.text-gray-7:hover, .multiple-react-app a.text-gray-7:focus, .bs4-popover a.text-gray-7:hover, .bs4-popover a.text-gray-7:focus, .bs4-modal a.text-gray-7:hover, .bs4-modal a.text-gray-7:focus, .bs4 a.text-gray-7:hover, .bs4 a.text-gray-7:focus {
  color: #c8cfdd !important;
}
.react-app .text-yellow, .multiple-react-app .text-yellow, .bs4-popover .text-yellow, .bs4-modal .text-yellow, .bs4 .text-yellow {
  color: #fdce00 !important;
}
.react-app a.text-yellow:hover, .react-app a.text-yellow:focus, .multiple-react-app a.text-yellow:hover, .multiple-react-app a.text-yellow:focus, .bs4-popover a.text-yellow:hover, .bs4-popover a.text-yellow:focus, .bs4-modal a.text-yellow:hover, .bs4-modal a.text-yellow:focus, .bs4 a.text-yellow:hover, .bs4 a.text-yellow:focus {
  color: #b19000 !important;
}
.react-app .text-kelp, .multiple-react-app .text-kelp, .bs4-popover .text-kelp, .bs4-modal .text-kelp, .bs4 .text-kelp {
  color: #03a569 !important;
}
.react-app a.text-kelp:hover, .react-app a.text-kelp:focus, .multiple-react-app a.text-kelp:hover, .multiple-react-app a.text-kelp:focus, .bs4-popover a.text-kelp:hover, .bs4-popover a.text-kelp:focus, .bs4-modal a.text-kelp:hover, .bs4-modal a.text-kelp:focus, .bs4 a.text-kelp:hover, .bs4 a.text-kelp:focus {
  color: #025a39 !important;
}
.react-app .text-high-tide, .multiple-react-app .text-high-tide, .bs4-popover .text-high-tide, .bs4-modal .text-high-tide, .bs4 .text-high-tide {
  color: #0b4dc2 !important;
}
.react-app a.text-high-tide:hover, .react-app a.text-high-tide:focus, .multiple-react-app a.text-high-tide:hover, .multiple-react-app a.text-high-tide:focus, .bs4-popover a.text-high-tide:hover, .bs4-popover a.text-high-tide:focus, .bs4-modal a.text-high-tide:hover, .bs4-modal a.text-high-tide:focus, .bs4 a.text-high-tide:hover, .bs4 a.text-high-tide:focus {
  color: #07307a !important;
}
.react-app .text-body, .multiple-react-app .text-body, .bs4-popover .text-body, .bs4-modal .text-body, .bs4 .text-body {
  color: #212529 !important;
}
.react-app .text-muted, .multiple-react-app .text-muted, .bs4-popover .text-muted, .bs4-modal .text-muted, .bs4 .text-muted {
  color: #6c757d !important;
}
.react-app .text-black-50, .multiple-react-app .text-black-50, .bs4-popover .text-black-50, .bs4-modal .text-black-50, .bs4 .text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.react-app .text-white-50, .multiple-react-app .text-white-50, .bs4-popover .text-white-50, .bs4-modal .text-white-50, .bs4 .text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.react-app .text-hide, .multiple-react-app .text-hide, .bs4-popover .text-hide, .bs4-modal .text-hide, .bs4 .text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.react-app .text-decoration-none, .multiple-react-app .text-decoration-none, .bs4-popover .text-decoration-none, .bs4-modal .text-decoration-none, .bs4 .text-decoration-none {
  text-decoration: none !important;
}
.react-app .text-break, .multiple-react-app .text-break, .bs4-popover .text-break, .bs4-modal .text-break, .bs4 .text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}
.react-app .text-reset, .multiple-react-app .text-reset, .bs4-popover .text-reset, .bs4-modal .text-reset, .bs4 .text-reset {
  color: inherit !important;
}
.react-app .visible, .multiple-react-app .visible, .bs4-popover .visible, .bs4-modal .visible, .bs4 .visible {
  visibility: visible !important;
}
.react-app .invisible, .multiple-react-app .invisible, .bs4-popover .invisible, .bs4-modal .invisible, .bs4 .invisible {
  visibility: hidden !important;
}
.react-app body, .multiple-react-app body, .bs4-popover body, .bs4-modal body, .bs4 body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.react-app .header-xl, .multiple-react-app .header-xl, .bs4-popover .header-xl, .bs4-modal .header-xl, .bs4 .header-xl {
  font-size: 48px;
  line-height: 65px;
}
.react-app .header-lg, .multiple-react-app .header-lg, .bs4-popover .header-lg, .bs4-modal .header-lg, .bs4 .header-lg {
  font-size: 36px;
  line-height: 50px;
}
.react-app .text-xl, .multiple-react-app .text-xl, .bs4-popover .text-xl, .bs4-modal .text-xl, .bs4 .text-xl {
  font-size: 24px;
  line-height: 35px;
}
.react-app .text-large-regular, .multiple-react-app .text-large-regular, .bs4-popover .text-large-regular, .bs4-modal .text-large-regular, .bs4 .text-large-regular {
  font-size: 18px;
  line-height: 25px;
}
.react-app .text-medium, .multiple-react-app .text-medium, .bs4-popover .text-medium, .bs4-modal .text-medium, .bs4 .text-medium {
  font-size: 14px;
  line-height: 20px;
}
.react-app .text-small, .multiple-react-app .text-small, .bs4-popover .text-small, .bs4-modal .text-small, .bs4 .text-small {
  font-size: 12px;
  line-height: 15px;
}
.react-app .text-xs, .multiple-react-app .text-xs, .bs4-popover .text-xs, .bs4-modal .text-xs, .bs4 .text-xs {
  font-size: 10px;
}
.react-app .text-body, .multiple-react-app .text-body, .bs4-popover .text-body, .bs4-modal .text-body, .bs4 .text-body {
  font-size: 14px;
  line-height: 25px;
}
.react-app .text-regular, .multiple-react-app .text-regular, .bs4-popover .text-regular, .bs4-modal .text-regular, .bs4 .text-regular {
  font-size: 14px;
  line-height: 20px;
}
.react-app .text-date, .multiple-react-app .text-date, .bs4-popover .text-date, .bs4-modal .text-date, .bs4 .text-date {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  line-height: 20px;
}
.react-app .text-large-body, .multiple-react-app .text-large-body, .bs4-popover .text-large-body, .bs4-modal .text-large-body, .bs4 .text-large-body {
  font-size: 16px;
  line-height: 30px;
}
.react-app .text-cta, .multiple-react-app .text-cta, .bs4-popover .text-cta, .bs4-modal .text-cta, .bs4 .text-cta {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.react-app .warning-title, .multiple-react-app .warning-title, .bs4-popover .warning-title, .bs4-modal .warning-title, .bs4 .warning-title {
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}
.react-app .placeholder-text, .multiple-react-app .placeholder-text, .bs4-popover .placeholder-text, .bs4-modal .placeholder-text, .bs4 .placeholder-text {
  color: #748499;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}
.react-app .label, .multiple-react-app .label, .bs4-popover .label, .bs4-modal .label, .bs4 .label {
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}
.react-app .page-title-xl, .multiple-react-app .page-title-xl, .bs4-popover .page-title-xl, .bs4-modal .page-title-xl, .bs4 .page-title-xl {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 50px;
}
.react-app .page-title-l, .multiple-react-app .page-title-l, .bs4-popover .page-title-l, .bs4-modal .page-title-l, .bs4 .page-title-l {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
}
.react-app .page-title, .multiple-react-app .page-title, .bs4-popover .page-title, .bs4-modal .page-title, .bs4-modal .h4, .bs4 .page-title {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}
.react-app .page-title-small, .multiple-react-app .page-title-small, .bs4-popover .page-title-small, .bs4-modal .page-title-small, .bs4 .page-title-small {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
}
.react-app .page-title-xs, .multiple-react-app .page-title-xs, .bs4-popover .page-title-xs, .bs4-modal .page-title-xs, .bs4 .page-title-xs {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}
.react-app .page-title-xxs, .multiple-react-app .page-title-xxs, .bs4-popover .page-title-xxs, .bs4-modal .page-title-xxs, .bs4 .page-title-xxs {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}
.react-app .page-title-xxxs, .multiple-react-app .page-title-xxxs, .bs4-popover .page-title-xxxs, .bs4-modal .page-title-xxxs, .bs4 .page-title-xxxs {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
}
.react-app .card-title-xl, .multiple-react-app .card-title-xl, .bs4-popover .card-title-xl, .bs4-modal .card-title-xl, .bs4 .card-title-xl {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-weight: 300;
  line-height: 50px;
}
.react-app .card-title-l, .multiple-react-app .card-title-l, .bs4-popover .card-title-l, .bs4-modal .card-title-l, .bs4 .card-title-l {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 50px;
}
.react-app .card-title, .multiple-react-app .card-title, .bs4-popover .card-title, .bs4-modal .card-title, .bs4 .card-title {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 35px;
}
.react-app .card-title-small, .multiple-react-app .card-title-small, .bs4-popover .card-title-small, .bs4-modal .card-title-small, .bs4 .card-title-small {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
}
.react-app .card-title-xs, .multiple-react-app .card-title-xs, .bs4-popover .card-title-xs, .bs4-modal .card-title-xs, .bs4 .card-title-xs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}
.react-app .card-title-xxs, .multiple-react-app .card-title-xxs, .bs4-popover .card-title-xxs, .bs4-modal .card-title-xxs, .bs4 .card-title-xxs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}
.react-app .card-title-xxxs, .multiple-react-app .card-title-xxxs, .bs4-popover .card-title-xxxs, .bs4-modal .card-title-xxxs, .bs4 .card-title-xxxs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 15px;
}
.react-app .course-title-xl, .multiple-react-app .course-title-xl, .bs4-popover .course-title-xl, .bs4-modal .course-title-xl, .bs4 .course-title-xl {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 50px;
}
.react-app .course-title-l, .multiple-react-app .course-title-l, .bs4-popover .course-title-l, .bs4-modal .course-title-l, .bs4 .course-title-l {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 50px;
}
.react-app .course-title, .multiple-react-app .course-title, .bs4-popover .course-title, .bs4-modal .course-title, .bs4 .course-title {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
}
.react-app .course-title-small, .multiple-react-app .course-title-small, .bs4-popover .course-title-small, .bs4-modal .course-title-small, .bs4 .course-title-small {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}
.react-app .course-title-xs, .multiple-react-app .course-title-xs, .bs4-popover .course-title-xs, .bs4-modal .course-title-xs, .bs4 .course-title-xs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.react-app .course-title-xxs, .multiple-react-app .course-title-xxs, .bs4-popover .course-title-xxs, .bs4-modal .course-title-xxs, .bs4 .course-title-xxs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
}
.react-app .course-title-xxxs, .multiple-react-app .course-title-xxxs, .bs4-popover .course-title-xxxs, .bs4-modal .course-title-xxxs, .bs4 .course-title-xxxs {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
}
.react-app .safari-hardware-fix, .multiple-react-app .safari-hardware-fix, .bs4-popover .safari-hardware-fix, .bs4-modal .safari-hardware-fix, .bs4 .safari-hardware-fix {
  transform: translate3d(0, 0, 0);
}
@font-face {
  font-family: "novoed-icons";
  src: url("./fonts/novoed-icons/fonts/novoed-icons.eot");
  src: url("./fonts/novoed-icons/fonts/novoed-icons.eot?#iefix") format("embedded-opentype"), url("./fonts/novoed-icons/fonts/novoed-icons.woff") format("woff"), url("./fonts/novoed-icons/fonts/novoed-icons.ttf") format("truetype"), url("./fonts/novoed-icons/fonts/novoed-icons.svg#novoed-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
.react-app [data-icon], .multiple-react-app [data-icon], .bs4-popover [data-icon], .bs4-modal [data-icon], .bs4 [data-icon] {
  font-family: "novoed-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-app [class^=icon-],
.react-app [class*=" icon-"], .multiple-react-app [class^=icon-],
.multiple-react-app [class*=" icon-"], .bs4-popover [class^=icon-],
.bs4-popover [class*=" icon-"], .bs4-modal [class^=icon-],
.bs4-modal [class*=" icon-"], .bs4 [class^=icon-],
.bs4 [class*=" icon-"] {
  font-family: "novoed-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.react-app .icon-3rd-party-settings:before, .multiple-react-app .icon-3rd-party-settings:before, .bs4-popover .icon-3rd-party-settings:before, .bs4-modal .icon-3rd-party-settings:before, .bs4 .icon-3rd-party-settings:before {
  content: "\e000";
}
.react-app .icon-acrobat:before, .multiple-react-app .icon-acrobat:before, .bs4-popover .icon-acrobat:before, .bs4-modal .icon-acrobat:before, .bs4 .icon-acrobat:before {
  content: "\e001";
}
.react-app .icon-add:before, .multiple-react-app .icon-add:before, .bs4-popover .icon-add:before, .bs4-modal .icon-add:before, .bs4 .icon-add:before {
  content: "\e002";
}
.react-app .icon-add-square:before, .multiple-react-app .icon-add-square:before, .bs4-popover .icon-add-square:before, .bs4-modal .icon-add-square:before, .bs4 .icon-add-square:before {
  content: "\e003";
}
.react-app .icon-admin-credits:before, .multiple-react-app .icon-admin-credits:before, .bs4-popover .icon-admin-credits:before, .bs4-modal .icon-admin-credits:before, .bs4 .icon-admin-credits:before {
  content: "\e004";
}
.react-app .icon-admin-dashboard:before, .multiple-react-app .icon-admin-dashboard:before, .bs4-popover .icon-admin-dashboard:before, .bs4-modal .icon-admin-dashboard:before, .bs4 .icon-admin-dashboard:before {
  content: "\e005";
}
.react-app .icon-admin-embeds:before, .multiple-react-app .icon-admin-embeds:before, .bs4-popover .icon-admin-embeds:before, .bs4-modal .icon-admin-embeds:before, .bs4 .icon-admin-embeds:before {
  content: "\e006";
}
.react-app .icon-admin-folder:before, .multiple-react-app .icon-admin-folder:before, .bs4-popover .icon-admin-folder:before, .bs4-modal .icon-admin-folder:before, .bs4 .icon-admin-folder:before {
  content: "\e007";
}
.react-app .icon-admin-image-left:before, .multiple-react-app .icon-admin-image-left:before, .bs4-popover .icon-admin-image-left:before, .bs4-modal .icon-admin-image-left:before, .bs4 .icon-admin-image-left:before {
  content: "\e008";
}
.react-app .icon-admin-image-middel:before, .multiple-react-app .icon-admin-image-middel:before, .bs4-popover .icon-admin-image-middel:before, .bs4-modal .icon-admin-image-middel:before, .bs4 .icon-admin-image-middel:before {
  content: "\e009";
}
.react-app .icon-admin-image-right:before, .multiple-react-app .icon-admin-image-right:before, .bs4-popover .icon-admin-image-right:before, .bs4-modal .icon-admin-image-right:before, .bs4 .icon-admin-image-right:before {
  content: "\e00a";
}
.react-app .icon-admin-lti-tools:before, .multiple-react-app .icon-admin-lti-tools:before, .bs4-popover .icon-admin-lti-tools:before, .bs4-modal .icon-admin-lti-tools:before, .bs4 .icon-admin-lti-tools:before {
  content: "\e00b";
}
.react-app .icon-admin-rich-text:before, .multiple-react-app .icon-admin-rich-text:before, .bs4-popover .icon-admin-rich-text:before, .bs4-modal .icon-admin-rich-text:before, .bs4 .icon-admin-rich-text:before {
  content: "\e00c";
}
.react-app .icon-admin-scorm:before, .multiple-react-app .icon-admin-scorm:before, .bs4-popover .icon-admin-scorm:before, .bs4-modal .icon-admin-scorm:before, .bs4 .icon-admin-scorm:before {
  content: "\e00d";
}
.react-app .icon-admin-template:before, .multiple-react-app .icon-admin-template:before, .bs4-popover .icon-admin-template:before, .bs4-modal .icon-admin-template:before, .bs4 .icon-admin-template:before {
  content: "\e00e";
}
.react-app .icon-admin-text-bottom:before, .multiple-react-app .icon-admin-text-bottom:before, .bs4-popover .icon-admin-text-bottom:before, .bs4-modal .icon-admin-text-bottom:before, .bs4 .icon-admin-text-bottom:before {
  content: "\e00f";
}
.react-app .icon-admin-text-middle:before, .multiple-react-app .icon-admin-text-middle:before, .bs4-popover .icon-admin-text-middle:before, .bs4-modal .icon-admin-text-middle:before, .bs4 .icon-admin-text-middle:before {
  content: "\e010";
}
.react-app .icon-admin-text-top:before, .multiple-react-app .icon-admin-text-top:before, .bs4-popover .icon-admin-text-top:before, .bs4-modal .icon-admin-text-top:before, .bs4 .icon-admin-text-top:before {
  content: "\e011";
}
.react-app .icon-admin-tools:before, .multiple-react-app .icon-admin-tools:before, .bs4-popover .icon-admin-tools:before, .bs4-modal .icon-admin-tools:before, .bs4 .icon-admin-tools:before {
  content: "\e012";
}
.react-app .icon-announcements:before, .multiple-react-app .icon-announcements:before, .bs4-popover .icon-announcements:before, .bs4-modal .icon-announcements:before, .bs4 .icon-announcements:before {
  content: "\e013";
}
.react-app .icon-arrow-down:before, .multiple-react-app .icon-arrow-down:before, .bs4-popover .icon-arrow-down:before, .bs4-modal .icon-arrow-down:before, .bs4 .icon-arrow-down:before {
  content: "\e014";
}
.react-app .icon-arrow-left:before, .multiple-react-app .icon-arrow-left:before, .bs4-popover .icon-arrow-left:before, .bs4-modal .icon-arrow-left:before, .bs4 .icon-arrow-left:before {
  content: "\e015";
}
.react-app .icon-arrow-right:before, .multiple-react-app .icon-arrow-right:before, .bs4-popover .icon-arrow-right:before, .bs4-modal .icon-arrow-right:before, .bs4 .icon-arrow-right:before {
  content: "\e016";
}
.react-app .icon-arrow-up:before, .multiple-react-app .icon-arrow-up:before, .bs4-popover .icon-arrow-up:before, .bs4-modal .icon-arrow-up:before, .bs4 .icon-arrow-up:before {
  content: "\e017";
}
.react-app .icon-assignments:before, .multiple-react-app .icon-assignments:before, .bs4-popover .icon-assignments:before, .bs4-modal .icon-assignments:before, .bs4 .icon-assignments:before {
  content: "\e018";
}
.react-app .icon-attempt:before, .multiple-react-app .icon-attempt:before, .bs4-popover .icon-attempt:before, .bs4-modal .icon-attempt:before, .bs4 .icon-attempt:before {
  content: "\e019";
}
.react-app .icon-audio:before, .multiple-react-app .icon-audio:before, .bs4-popover .icon-audio:before, .bs4-modal .icon-audio:before, .bs4 .icon-audio:before {
  content: "\e01a";
}
.react-app .icon-audio-practice-on-cards:before, .multiple-react-app .icon-audio-practice-on-cards:before, .bs4-popover .icon-audio-practice-on-cards:before, .bs4-modal .icon-audio-practice-on-cards:before, .bs4 .icon-audio-practice-on-cards:before {
  content: "\e01b";
}
.react-app .icon-audio-recording:before, .multiple-react-app .icon-audio-recording:before, .bs4-popover .icon-audio-recording:before, .bs4-modal .icon-audio-recording:before, .bs4 .icon-audio-recording:before {
  content: "\e01c";
}
.react-app .icon-auto-connection:before, .multiple-react-app .icon-auto-connection:before, .bs4-popover .icon-auto-connection:before, .bs4-modal .icon-auto-connection:before, .bs4 .icon-auto-connection:before {
  content: "\e01d";
}
.react-app .icon-auto-connection-off:before, .multiple-react-app .icon-auto-connection-off:before, .bs4-popover .icon-auto-connection-off:before, .bs4-modal .icon-auto-connection-off:before, .bs4 .icon-auto-connection-off:before {
  content: "\e01e";
}
.react-app .icon-back:before, .multiple-react-app .icon-back:before, .bs4-popover .icon-back:before, .bs4-modal .icon-back:before, .bs4 .icon-back:before {
  content: "\e01f";
}
.react-app .icon-badge:before, .multiple-react-app .icon-badge:before, .bs4-popover .icon-badge:before, .bs4-modal .icon-badge:before, .bs4 .icon-badge:before {
  content: "\e020";
}
.react-app .icon-badge-cohort:before, .multiple-react-app .icon-badge-cohort:before, .bs4-popover .icon-badge-cohort:before, .bs4-modal .icon-badge-cohort:before, .bs4 .icon-badge-cohort:before {
  content: "\e021";
}
.react-app .icon-badge-primary:before, .multiple-react-app .icon-badge-primary:before, .bs4-popover .icon-badge-primary:before, .bs4-modal .icon-badge-primary:before, .bs4 .icon-badge-primary:before {
  content: "\e022";
}
.react-app .icon-ban:before, .multiple-react-app .icon-ban:before, .bs4-popover .icon-ban:before, .bs4-modal .icon-ban:before, .bs4 .icon-ban:before {
  content: "\e023";
}
.react-app .icon-bookmark:before, .multiple-react-app .icon-bookmark:before, .bs4-popover .icon-bookmark:before, .bs4-modal .icon-bookmark:before, .bs4 .icon-bookmark:before {
  content: "\e024";
}
.react-app .icon-bookmark-and-notes:before, .multiple-react-app .icon-bookmark-and-notes:before, .bs4-popover .icon-bookmark-and-notes:before, .bs4-modal .icon-bookmark-and-notes:before, .bs4 .icon-bookmark-and-notes:before {
  content: "\e025";
}
.react-app .icon-bookmarked:before, .multiple-react-app .icon-bookmarked:before, .bs4-popover .icon-bookmarked:before, .bs4-modal .icon-bookmarked:before, .bs4 .icon-bookmarked:before {
  content: "\e026";
}
.react-app .icon-calendar:before, .multiple-react-app .icon-calendar:before, .bs4-popover .icon-calendar:before, .bs4-modal .icon-calendar:before, .bs4 .icon-calendar:before {
  content: "\e027";
}
.react-app .icon-card-view:before, .multiple-react-app .icon-card-view:before, .bs4-popover .icon-card-view:before, .bs4-modal .icon-card-view:before, .bs4 .icon-card-view:before {
  content: "\e028";
}
.react-app .icon-check:before, .multiple-react-app .icon-check:before, .bs4-popover .icon-check:before, .bs4-modal .icon-check:before, .bs4 .icon-check:before {
  content: "\e029";
}
.react-app .icon-close:before, .multiple-react-app .icon-close:before, .bs4-popover .icon-close:before, .bs4-modal .icon-close:before, .bs4 .icon-close:before {
  content: "\e02a";
}
.react-app .icon-collapse:before, .multiple-react-app .icon-collapse:before, .bs4-popover .icon-collapse:before, .bs4-modal .icon-collapse:before, .bs4 .icon-collapse:before {
  content: "\e02b";
}
.react-app .icon-comments:before, .multiple-react-app .icon-comments:before, .bs4-popover .icon-comments:before, .bs4-modal .icon-comments:before, .bs4 .icon-comments:before {
  content: "\e02c";
}
.react-app .icon-community:before, .multiple-react-app .icon-community:before, .bs4-popover .icon-community:before, .bs4-modal .icon-community:before, .bs4 .icon-community:before {
  content: "\e02d";
}
.react-app .icon-complete:before, .multiple-react-app .icon-complete:before, .bs4-popover .icon-complete:before, .bs4-modal .icon-complete:before, .bs4 .icon-complete:before {
  content: "\e02e";
}
.react-app .icon-content:before, .multiple-react-app .icon-content:before, .bs4-popover .icon-content:before, .bs4-modal .icon-content:before, .bs4 .icon-content:before {
  content: "\e02f";
}
.react-app .icon-conversations:before, .multiple-react-app .icon-conversations:before, .bs4-popover .icon-conversations:before, .bs4-modal .icon-conversations:before, .bs4 .icon-conversations:before {
  content: "\e030";
}
.react-app .icon-course:before, .multiple-react-app .icon-course:before, .bs4-popover .icon-course:before, .bs4-modal .icon-course:before, .bs4 .icon-course:before {
  content: "\e031";
}
.react-app .icon-course-completion:before, .multiple-react-app .icon-course-completion:before, .bs4-popover .icon-course-completion:before, .bs4-modal .icon-course-completion:before, .bs4 .icon-course-completion:before {
  content: "\e032";
}
.react-app .icon-course-mentees:before, .multiple-react-app .icon-course-mentees:before, .bs4-popover .icon-course-mentees:before, .bs4-modal .icon-course-mentees:before, .bs4 .icon-course-mentees:before {
  content: "\e033";
}
.react-app .icon-course-menu:before, .multiple-react-app .icon-course-menu:before, .bs4-popover .icon-course-menu:before, .bs4-modal .icon-course-menu:before, .bs4 .icon-course-menu:before {
  content: "\e034";
}
.react-app .icon-create-new-post:before, .multiple-react-app .icon-create-new-post:before, .bs4-popover .icon-create-new-post:before, .bs4-modal .icon-create-new-post:before, .bs4 .icon-create-new-post:before {
  content: "\e035";
}
.react-app .icon-crop:before, .multiple-react-app .icon-crop:before, .bs4-popover .icon-crop:before, .bs4-modal .icon-crop:before, .bs4 .icon-crop:before {
  content: "\e036";
}
.react-app .icon-dash:before, .multiple-react-app .icon-dash:before, .bs4-popover .icon-dash:before, .bs4-modal .icon-dash:before, .bs4 .icon-dash:before {
  content: "\e037";
}
.react-app .icon-dashboard:before, .multiple-react-app .icon-dashboard:before, .bs4-popover .icon-dashboard:before, .bs4-modal .icon-dashboard:before, .bs4 .icon-dashboard:before {
  content: "\e038";
}
.react-app .icon-data:before, .multiple-react-app .icon-data:before, .bs4-popover .icon-data:before, .bs4-modal .icon-data:before, .bs4 .icon-data:before {
  content: "\e039";
}
.react-app .icon-download:before, .multiple-react-app .icon-download:before, .bs4-popover .icon-download:before, .bs4-modal .icon-download:before, .bs4 .icon-download:before {
  content: "\e03a";
}
.react-app .icon-drafts:before, .multiple-react-app .icon-drafts:before, .bs4-popover .icon-drafts:before, .bs4-modal .icon-drafts:before, .bs4 .icon-drafts:before {
  content: "\e03b";
}
.react-app .icon-drag-handle:before, .multiple-react-app .icon-drag-handle:before, .bs4-popover .icon-drag-handle:before, .bs4-modal .icon-drag-handle:before, .bs4 .icon-drag-handle:before {
  content: "\e03c";
}
.react-app .icon-dropdown-arrow:before, .multiple-react-app .icon-dropdown-arrow:before, .bs4-popover .icon-dropdown-arrow:before, .bs4-modal .icon-dropdown-arrow:before, .bs4 .icon-dropdown-arrow:before {
  content: "\e03d";
}
.react-app .icon-dueday:before, .multiple-react-app .icon-dueday:before, .bs4-popover .icon-dueday:before, .bs4-modal .icon-dueday:before, .bs4 .icon-dueday:before {
  content: "\e03e";
}
.react-app .icon-duplicate:before, .multiple-react-app .icon-duplicate:before, .bs4-popover .icon-duplicate:before, .bs4-modal .icon-duplicate:before, .bs4 .icon-duplicate:before {
  content: "\e03f";
}
.react-app .icon-edit:before, .multiple-react-app .icon-edit:before, .bs4-popover .icon-edit:before, .bs4-modal .icon-edit:before, .bs4 .icon-edit:before {
  content: "\e040";
}
.react-app .icon-editable:before, .multiple-react-app .icon-editable:before, .bs4-popover .icon-editable:before, .bs4-modal .icon-editable:before, .bs4 .icon-editable:before {
  content: "\e041";
}
.react-app .icon-education:before, .multiple-react-app .icon-education:before, .bs4-popover .icon-education:before, .bs4-modal .icon-education:before, .bs4 .icon-education:before {
  content: "\e042";
}
.react-app .icon-email-template:before, .multiple-react-app .icon-email-template:before, .bs4-popover .icon-email-template:before, .bs4-modal .icon-email-template:before, .bs4 .icon-email-template:before {
  content: "\e043";
}
.react-app .icon-enrollment:before, .multiple-react-app .icon-enrollment:before, .bs4-popover .icon-enrollment:before, .bs4-modal .icon-enrollment:before, .bs4 .icon-enrollment:before {
  content: "\e044";
}
.react-app .icon-error:before, .multiple-react-app .icon-error:before, .bs4-popover .icon-error:before, .bs4-modal .icon-error:before, .bs4 .icon-error:before {
  content: "\e045";
}
.react-app .icon-evaluation:before, .multiple-react-app .icon-evaluation:before, .bs4-popover .icon-evaluation:before, .bs4-modal .icon-evaluation:before, .bs4 .icon-evaluation:before {
  content: "\e046";
}
.react-app .icon-events:before, .multiple-react-app .icon-events:before, .bs4-popover .icon-events:before, .bs4-modal .icon-events:before, .bs4 .icon-events:before {
  content: "\e047";
}
.react-app .icon-expand:before, .multiple-react-app .icon-expand:before, .bs4-popover .icon-expand:before, .bs4-modal .icon-expand:before, .bs4 .icon-expand:before {
  content: "\e048";
}
.react-app .icon-export:before, .multiple-react-app .icon-export:before, .bs4-popover .icon-export:before, .bs4-modal .icon-export:before, .bs4 .icon-export:before {
  content: "\e049";
}
.react-app .icon-facebook:before, .multiple-react-app .icon-facebook:before, .bs4-popover .icon-facebook:before, .bs4-modal .icon-facebook:before, .bs4 .icon-facebook:before {
  content: "\e04a";
}
.react-app .icon-files:before, .multiple-react-app .icon-files:before, .bs4-popover .icon-files:before, .bs4-modal .icon-files:before, .bs4 .icon-files:before {
  content: "\e04b";
}
.react-app .icon-filter:before, .multiple-react-app .icon-filter:before, .bs4-popover .icon-filter:before, .bs4-modal .icon-filter:before, .bs4 .icon-filter:before {
  content: "\e04c";
}
.react-app .icon-flag:before, .multiple-react-app .icon-flag:before, .bs4-popover .icon-flag:before, .bs4-modal .icon-flag:before, .bs4 .icon-flag:before {
  content: "\e04d";
}
.react-app .icon-format-aligncenter:before, .multiple-react-app .icon-format-aligncenter:before, .bs4-popover .icon-format-aligncenter:before, .bs4-modal .icon-format-aligncenter:before, .bs4 .icon-format-aligncenter:before {
  content: "\e04e";
}
.react-app .icon-format-alignleft:before, .multiple-react-app .icon-format-alignleft:before, .bs4-popover .icon-format-alignleft:before, .bs4-modal .icon-format-alignleft:before, .bs4 .icon-format-alignleft:before {
  content: "\e04f";
}
.react-app .icon-format-alignright:before, .multiple-react-app .icon-format-alignright:before, .bs4-popover .icon-format-alignright:before, .bs4-modal .icon-format-alignright:before, .bs4 .icon-format-alignright:before {
  content: "\e050";
}
.react-app .icon-format-bold:before, .multiple-react-app .icon-format-bold:before, .bs4-popover .icon-format-bold:before, .bs4-modal .icon-format-bold:before, .bs4 .icon-format-bold:before {
  content: "\e051";
}
.react-app .icon-format-bulletlist:before, .multiple-react-app .icon-format-bulletlist:before, .bs4-popover .icon-format-bulletlist:before, .bs4-modal .icon-format-bulletlist:before, .bs4 .icon-format-bulletlist:before {
  content: "\e052";
}
.react-app .icon-format-fontstyle:before, .multiple-react-app .icon-format-fontstyle:before, .bs4-popover .icon-format-fontstyle:before, .bs4-modal .icon-format-fontstyle:before, .bs4 .icon-format-fontstyle:before {
  content: "\e053";
}
.react-app .icon-format-italic:before, .multiple-react-app .icon-format-italic:before, .bs4-popover .icon-format-italic:before, .bs4-modal .icon-format-italic:before, .bs4 .icon-format-italic:before {
  content: "\e054";
}
.react-app .icon-format-makelink:before, .multiple-react-app .icon-format-makelink:before, .bs4-popover .icon-format-makelink:before, .bs4-modal .icon-format-makelink:before, .bs4 .icon-format-makelink:before {
  content: "\e055";
}
.react-app .icon-format-numberedlist:before, .multiple-react-app .icon-format-numberedlist:before, .bs4-popover .icon-format-numberedlist:before, .bs4-modal .icon-format-numberedlist:before, .bs4 .icon-format-numberedlist:before {
  content: "\e056";
}
.react-app .icon-format-underline:before, .multiple-react-app .icon-format-underline:before, .bs4-popover .icon-format-underline:before, .bs4-modal .icon-format-underline:before, .bs4 .icon-format-underline:before {
  content: "\e057";
}
.react-app .icon-format-unlink:before, .multiple-react-app .icon-format-unlink:before, .bs4-popover .icon-format-unlink:before, .bs4-modal .icon-format-unlink:before, .bs4 .icon-format-unlink:before {
  content: "\e058";
}
.react-app .icon-fullscreen:before, .multiple-react-app .icon-fullscreen:before, .bs4-popover .icon-fullscreen:before, .bs4-modal .icon-fullscreen:before, .bs4 .icon-fullscreen:before {
  content: "\e059";
}
.react-app .icon-g-groups:before, .multiple-react-app .icon-g-groups:before, .bs4-popover .icon-g-groups:before, .bs4-modal .icon-g-groups:before, .bs4 .icon-g-groups:before {
  content: "\e05a";
}
.react-app .icon-g-learners:before, .multiple-react-app .icon-g-learners:before, .bs4-popover .icon-g-learners:before, .bs4-modal .icon-g-learners:before, .bs4 .icon-g-learners:before {
  content: "\e05b";
}
.react-app .icon-g-mentors:before, .multiple-react-app .icon-g-mentors:before, .bs4-popover .icon-g-mentors:before, .bs4-modal .icon-g-mentors:before, .bs4 .icon-g-mentors:before {
  content: "\e05c";
}
.react-app .icon-g-teachers:before, .multiple-react-app .icon-g-teachers:before, .bs4-popover .icon-g-teachers:before, .bs4-modal .icon-g-teachers:before, .bs4 .icon-g-teachers:before {
  content: "\e05d";
}
.react-app .icon-g-teams:before, .multiple-react-app .icon-g-teams:before, .bs4-popover .icon-g-teams:before, .bs4-modal .icon-g-teams:before, .bs4 .icon-g-teams:before {
  content: "\e05e";
}
.react-app .icon-gallery:before, .multiple-react-app .icon-gallery:before, .bs4-popover .icon-gallery:before, .bs4-modal .icon-gallery:before, .bs4 .icon-gallery:before {
  content: "\e05f";
}
.react-app .icon-gen-ai:before, .multiple-react-app .icon-gen-ai:before, .bs4-popover .icon-gen-ai:before, .bs4-modal .icon-gen-ai:before, .bs4 .icon-gen-ai:before {
  content: "\e060";
}
.react-app .icon-group-invitation:before, .multiple-react-app .icon-group-invitation:before, .bs4-popover .icon-group-invitation:before, .bs4-modal .icon-group-invitation:before, .bs4 .icon-group-invitation:before {
  content: "\e061";
}
.react-app .icon-groups:before, .multiple-react-app .icon-groups:before, .bs4-popover .icon-groups:before, .bs4-modal .icon-groups:before, .bs4 .icon-groups:before {
  content: "\e062";
}
.react-app .icon-groups-lhs:before, .multiple-react-app .icon-groups-lhs:before, .bs4-popover .icon-groups-lhs:before, .bs4-modal .icon-groups-lhs:before, .bs4 .icon-groups-lhs:before {
  content: "\e063";
}
.react-app .icon-half-star:before, .multiple-react-app .icon-half-star:before, .bs4-popover .icon-half-star:before, .bs4-modal .icon-half-star:before, .bs4 .icon-half-star:before {
  content: "\e064";
}
.react-app .icon-harddeadline:before, .multiple-react-app .icon-harddeadline:before, .bs4-popover .icon-harddeadline:before, .bs4-modal .icon-harddeadline:before, .bs4 .icon-harddeadline:before {
  content: "\e065";
}
.react-app .icon-heart-outline:before, .multiple-react-app .icon-heart-outline:before, .bs4-popover .icon-heart-outline:before, .bs4-modal .icon-heart-outline:before, .bs4 .icon-heart-outline:before {
  content: "\e066";
}
.react-app .icon-help:before, .multiple-react-app .icon-help:before, .bs4-popover .icon-help:before, .bs4-modal .icon-help:before, .bs4 .icon-help:before {
  content: "\e067";
}
.react-app .icon-hide:before, .multiple-react-app .icon-hide:before, .bs4-popover .icon-hide:before, .bs4-modal .icon-hide:before, .bs4 .icon-hide:before {
  content: "\e068";
}
.react-app .icon-highlight:before, .multiple-react-app .icon-highlight:before, .bs4-popover .icon-highlight:before, .bs4-modal .icon-highlight:before, .bs4 .icon-highlight:before {
  content: "\e069";
}
.react-app .icon-home:before, .multiple-react-app .icon-home:before, .bs4-popover .icon-home:before, .bs4-modal .icon-home:before, .bs4 .icon-home:before {
  content: "\e06a";
}
.react-app .icon-html:before, .multiple-react-app .icon-html:before, .bs4-popover .icon-html:before, .bs4-modal .icon-html:before, .bs4 .icon-html:before {
  content: "\e06b";
}
.react-app .icon-length:before, .multiple-react-app .icon-length:before, .bs4-popover .icon-length:before, .bs4-modal .icon-length:before, .bs4 .icon-length:before {
  content: "\e06c";
}
.react-app .icon-length-long:before, .multiple-react-app .icon-length-long:before, .bs4-popover .icon-length-long:before, .bs4-modal .icon-length-long:before, .bs4 .icon-length-long:before {
  content: "\e06d";
}
.react-app .icon-length-medium:before, .multiple-react-app .icon-length-medium:before, .bs4-popover .icon-length-medium:before, .bs4-modal .icon-length-medium:before, .bs4 .icon-length-medium:before {
  content: "\e06e";
}
.react-app .icon-ideas:before, .multiple-react-app .icon-ideas:before, .bs4-popover .icon-ideas:before, .bs4-modal .icon-ideas:before, .bs4 .icon-ideas:before {
  content: "\e06f";
}
.react-app .icon-images:before, .multiple-react-app .icon-images:before, .bs4-popover .icon-images:before, .bs4-modal .icon-images:before, .bs4 .icon-images:before {
  content: "\e070";
}
.react-app .icon-improvement:before, .multiple-react-app .icon-improvement:before, .bs4-popover .icon-improvement:before, .bs4-modal .icon-improvement:before, .bs4 .icon-improvement:before {
  content: "\e071";
}
.react-app .icon-in-progress:before, .multiple-react-app .icon-in-progress:before, .bs4-popover .icon-in-progress:before, .bs4-modal .icon-in-progress:before, .bs4 .icon-in-progress:before {
  content: "\e072";
}
.react-app .icon-info:before, .multiple-react-app .icon-info:before, .bs4-popover .icon-info:before, .bs4-modal .icon-info:before, .bs4 .icon-info:before {
  content: "\e073";
}
.react-app .icon-insert:before, .multiple-react-app .icon-insert:before, .bs4-popover .icon-insert:before, .bs4-modal .icon-insert:before, .bs4 .icon-insert:before {
  content: "\e074";
}
.react-app .icon-invitations:before, .multiple-react-app .icon-invitations:before, .bs4-popover .icon-invitations:before, .bs4-modal .icon-invitations:before, .bs4 .icon-invitations:before {
  content: "\e075";
}
.react-app .icon-job-title:before, .multiple-react-app .icon-job-title:before, .bs4-popover .icon-job-title:before, .bs4-modal .icon-job-title:before, .bs4 .icon-job-title:before {
  content: "\e076";
}
.react-app .icon-language:before, .multiple-react-app .icon-language:before, .bs4-popover .icon-language:before, .bs4-modal .icon-language:before, .bs4 .icon-language:before {
  content: "\e077";
}
.react-app .icon-learning-catalog:before, .multiple-react-app .icon-learning-catalog:before, .bs4-popover .icon-learning-catalog:before, .bs4-modal .icon-learning-catalog:before, .bs4 .icon-learning-catalog:before {
  content: "\e078";
}
.react-app .icon-link:before, .multiple-react-app .icon-link:before, .bs4-popover .icon-link:before, .bs4-modal .icon-link:before, .bs4 .icon-link:before {
  content: "\e079";
}
.react-app .icon-linkedin:before, .multiple-react-app .icon-linkedin:before, .bs4-popover .icon-linkedin:before, .bs4-modal .icon-linkedin:before, .bs4 .icon-linkedin:before {
  content: "\e07a";
}
.react-app .icon-location:before, .multiple-react-app .icon-location:before, .bs4-popover .icon-location:before, .bs4-modal .icon-location:before, .bs4 .icon-location:before {
  content: "\e07b";
}
.react-app .icon-managing-roles:before, .multiple-react-app .icon-managing-roles:before, .bs4-popover .icon-managing-roles:before, .bs4-modal .icon-managing-roles:before, .bs4 .icon-managing-roles:before {
  content: "\e07c";
}
.react-app .icon-manual-connection:before, .multiple-react-app .icon-manual-connection:before, .bs4-popover .icon-manual-connection:before, .bs4-modal .icon-manual-connection:before, .bs4 .icon-manual-connection:before {
  content: "\e07d";
}
.react-app .icon-media-practice:before, .multiple-react-app .icon-media-practice:before, .bs4-popover .icon-media-practice:before, .bs4-modal .icon-media-practice:before, .bs4 .icon-media-practice:before {
  content: "\e07e";
}
.react-app .icon-meet-your-peers:before, .multiple-react-app .icon-meet-your-peers:before, .bs4-popover .icon-meet-your-peers:before, .bs4-modal .icon-meet-your-peers:before, .bs4 .icon-meet-your-peers:before {
  content: "\e07f";
}
.react-app .icon-mentees:before, .multiple-react-app .icon-mentees:before, .bs4-popover .icon-mentees:before, .bs4-modal .icon-mentees:before, .bs4 .icon-mentees:before {
  content: "\e080";
}
.react-app .icon-menu:before, .multiple-react-app .icon-menu:before, .bs4-popover .icon-menu:before, .bs4-modal .icon-menu:before, .bs4 .icon-menu:before {
  content: "\e081";
}
.react-app .icon-messages:before, .multiple-react-app .icon-messages:before, .bs4-popover .icon-messages:before, .bs4-modal .icon-messages:before, .bs4 .icon-messages:before {
  content: "\e082";
}
.react-app .icon-minus:before, .multiple-react-app .icon-minus:before, .bs4-popover .icon-minus:before, .bs4-modal .icon-minus:before, .bs4 .icon-minus:before {
  content: "\e083";
}
.react-app .icon-mobile:before, .multiple-react-app .icon-mobile:before, .bs4-popover .icon-mobile:before, .bs4-modal .icon-mobile:before, .bs4 .icon-mobile:before {
  content: "\e084";
}
.react-app .icon-more:before, .multiple-react-app .icon-more:before, .bs4-popover .icon-more:before, .bs4-modal .icon-more:before, .bs4 .icon-more:before {
  content: "\e085";
}
.react-app .icon-move-down:before, .multiple-react-app .icon-move-down:before, .bs4-popover .icon-move-down:before, .bs4-modal .icon-move-down:before, .bs4 .icon-move-down:before {
  content: "\e086";
}
.react-app .icon-move-up:before, .multiple-react-app .icon-move-up:before, .bs4-popover .icon-move-up:before, .bs4-modal .icon-move-up:before, .bs4 .icon-move-up:before {
  content: "\e087";
}
.react-app .icon-multiple-choice:before, .multiple-react-app .icon-multiple-choice:before, .bs4-popover .icon-multiple-choice:before, .bs4-modal .icon-multiple-choice:before, .bs4 .icon-multiple-choice:before {
  content: "\e088";
}
.react-app .icon-new-course:before, .multiple-react-app .icon-new-course:before, .bs4-popover .icon-new-course:before, .bs4-modal .icon-new-course:before, .bs4 .icon-new-course:before {
  content: "\e089";
}
.react-app .icon-new-note:before, .multiple-react-app .icon-new-note:before, .bs4-popover .icon-new-note:before, .bs4-modal .icon-new-note:before, .bs4 .icon-new-note:before {
  content: "\e08a";
}
.react-app .icon-next:before, .multiple-react-app .icon-next:before, .bs4-popover .icon-next:before, .bs4-modal .icon-next:before, .bs4 .icon-next:before {
  content: "\e08b";
}
.react-app .icon-not-editable:before, .multiple-react-app .icon-not-editable:before, .bs4-popover .icon-not-editable:before, .bs4-modal .icon-not-editable:before, .bs4 .icon-not-editable:before {
  content: "\e08c";
}
.react-app .icon-notifications:before, .multiple-react-app .icon-notifications:before, .bs4-popover .icon-notifications:before, .bs4-modal .icon-notifications:before, .bs4 .icon-notifications:before {
  content: "\e08d";
}
.react-app .icon-number:before, .multiple-react-app .icon-number:before, .bs4-popover .icon-number:before, .bs4-modal .icon-number:before, .bs4 .icon-number:before {
  content: "\e08e";
}
.react-app .icon-open-external:before, .multiple-react-app .icon-open-external:before, .bs4-popover .icon-open-external:before, .bs4-modal .icon-open-external:before, .bs4 .icon-open-external:before {
  content: "\e08f";
}
.react-app .icon-path:before, .multiple-react-app .icon-path:before, .bs4-popover .icon-path:before, .bs4-modal .icon-path:before, .bs4 .icon-path:before {
  content: "\e090";
}
.react-app .icon-peer-feedback:before, .multiple-react-app .icon-peer-feedback:before, .bs4-popover .icon-peer-feedback:before, .bs4-modal .icon-peer-feedback:before, .bs4 .icon-peer-feedback:before {
  content: "\e091";
}
.react-app .icon-play:before, .multiple-react-app .icon-play:before, .bs4-popover .icon-play:before, .bs4-modal .icon-play:before, .bs4 .icon-play:before {
  content: "\e092";
}
.react-app .icon-points:before, .multiple-react-app .icon-points:before, .bs4-popover .icon-points:before, .bs4-modal .icon-points:before, .bs4 .icon-points:before {
  content: "\e093";
}
.react-app .icon-polls:before, .multiple-react-app .icon-polls:before, .bs4-popover .icon-polls:before, .bs4-modal .icon-polls:before, .bs4 .icon-polls:before {
  content: "\e094";
}
.react-app .icon-ppt:before, .multiple-react-app .icon-ppt:before, .bs4-popover .icon-ppt:before, .bs4-modal .icon-ppt:before, .bs4 .icon-ppt:before {
  content: "\e095";
}
.react-app .icon-practice-feedback:before, .multiple-react-app .icon-practice-feedback:before, .bs4-popover .icon-practice-feedback:before, .bs4-modal .icon-practice-feedback:before, .bs4 .icon-practice-feedback:before {
  content: "\e096";
}
.react-app .icon-profile:before, .multiple-react-app .icon-profile:before, .bs4-popover .icon-profile:before, .bs4-modal .icon-profile:before, .bs4 .icon-profile:before {
  content: "\e097";
}
.react-app .icon-camera-only:before, .multiple-react-app .icon-camera-only:before, .bs4-popover .icon-camera-only:before, .bs4-modal .icon-camera-only:before, .bs4 .icon-camera-only:before {
  content: "\e098";
}
.react-app .icon-like:before, .multiple-react-app .icon-like:before, .bs4-popover .icon-like:before, .bs4-modal .icon-like:before, .bs4 .icon-like:before {
  content: "\e099";
}
.react-app .icon-like-filled:before, .multiple-react-app .icon-like-filled:before, .bs4-popover .icon-like-filled:before, .bs4-modal .icon-like-filled:before, .bs4 .icon-like-filled:before {
  content: "\e09a";
}
.react-app .icon-locked:before, .multiple-react-app .icon-locked:before, .bs4-popover .icon-locked:before, .bs4-modal .icon-locked:before, .bs4 .icon-locked:before {
  content: "\e09b";
}
.react-app .icon-screen:before, .multiple-react-app .icon-screen:before, .bs4-popover .icon-screen:before, .bs4-modal .icon-screen:before, .bs4 .icon-screen:before {
  content: "\e09c";
}
.react-app .icon-unlocked:before, .multiple-react-app .icon-unlocked:before, .bs4-popover .icon-unlocked:before, .bs4-modal .icon-unlocked:before, .bs4 .icon-unlocked:before {
  content: "\e09d";
}
.react-app .icon-question:before, .multiple-react-app .icon-question:before, .bs4-popover .icon-question:before, .bs4-modal .icon-question:before, .bs4 .icon-question:before {
  content: "\e09e";
}
.react-app .icon-quiz:before, .multiple-react-app .icon-quiz:before, .bs4-popover .icon-quiz:before, .bs4-modal .icon-quiz:before, .bs4 .icon-quiz:before {
  content: "\e09f";
}
.react-app .icon-read:before, .multiple-react-app .icon-read:before, .bs4-popover .icon-read:before, .bs4-modal .icon-read:before, .bs4 .icon-read:before {
  content: "\e0a0";
}
.react-app .icon-refresh:before, .multiple-react-app .icon-refresh:before, .bs4-popover .icon-refresh:before, .bs4-modal .icon-refresh:before, .bs4 .icon-refresh:before {
  content: "\e0a1";
}
.react-app .icon-reorder:before, .multiple-react-app .icon-reorder:before, .bs4-popover .icon-reorder:before, .bs4-modal .icon-reorder:before, .bs4 .icon-reorder:before {
  content: "\e0a2";
}
.react-app .icon-reply:before, .multiple-react-app .icon-reply:before, .bs4-popover .icon-reply:before, .bs4-modal .icon-reply:before, .bs4 .icon-reply:before {
  content: "\e0a3";
}
.react-app .icon-rotate:before, .multiple-react-app .icon-rotate:before, .bs4-popover .icon-rotate:before, .bs4-modal .icon-rotate:before, .bs4 .icon-rotate:before {
  content: "\e0a4";
}
.react-app .icon-saved-items:before, .multiple-react-app .icon-saved-items:before, .bs4-popover .icon-saved-items:before, .bs4-modal .icon-saved-items:before, .bs4 .icon-saved-items:before {
  content: "\e0a5";
}
.react-app .icon-search:before, .multiple-react-app .icon-search:before, .bs4-popover .icon-search:before, .bs4-modal .icon-search:before, .bs4 .icon-search:before {
  content: "\e0a6";
}
.react-app .icon-settings:before, .multiple-react-app .icon-settings:before, .bs4-popover .icon-settings:before, .bs4-modal .icon-settings:before, .bs4 .icon-settings:before {
  content: "\e0a7";
}
.react-app .icon-sharable:before, .multiple-react-app .icon-sharable:before, .bs4-popover .icon-sharable:before, .bs4-modal .icon-sharable:before, .bs4 .icon-sharable:before {
  content: "\e0a8";
}
.react-app .icon-share:before, .multiple-react-app .icon-share:before, .bs4-popover .icon-share:before, .bs4-modal .icon-share:before, .bs4 .icon-share:before {
  content: "\e0a9";
}
.react-app .icon-signout:before, .multiple-react-app .icon-signout:before, .bs4-popover .icon-signout:before, .bs4-modal .icon-signout:before, .bs4 .icon-signout:before {
  content: "\e0aa";
}
.react-app .icon-skill-tag:before, .multiple-react-app .icon-skill-tag:before, .bs4-popover .icon-skill-tag:before, .bs4-modal .icon-skill-tag:before, .bs4 .icon-skill-tag:before {
  content: "\e0ab";
}
.react-app .icon-skills-feedback:before, .multiple-react-app .icon-skills-feedback:before, .bs4-popover .icon-skills-feedback:before, .bs4-modal .icon-skills-feedback:before, .bs4 .icon-skills-feedback:before {
  content: "\e0ac";
}
.react-app .icon-skills-feedback-activity:before, .multiple-react-app .icon-skills-feedback-activity:before, .bs4-popover .icon-skills-feedback-activity:before, .bs4-modal .icon-skills-feedback-activity:before, .bs4 .icon-skills-feedback-activity:before {
  content: "\e0ad";
}
.react-app .icon-sorting-down-active:before, .multiple-react-app .icon-sorting-down-active:before, .bs4-popover .icon-sorting-down-active:before, .bs4-modal .icon-sorting-down-active:before, .bs4 .icon-sorting-down-active:before {
  content: "\e0ae";
}
.react-app .icon-sorting-inactive:before, .multiple-react-app .icon-sorting-inactive:before, .bs4-popover .icon-sorting-inactive:before, .bs4-modal .icon-sorting-inactive:before, .bs4 .icon-sorting-inactive:before {
  content: "\e0af";
}
.react-app .icon-sorting-up-active:before, .multiple-react-app .icon-sorting-up-active:before, .bs4-popover .icon-sorting-up-active:before, .bs4-modal .icon-sorting-up-active:before, .bs4 .icon-sorting-up-active:before {
  content: "\e0b0";
}
.react-app .icon-spacer:before, .multiple-react-app .icon-spacer:before, .bs4-popover .icon-spacer:before, .bs4-modal .icon-spacer:before, .bs4 .icon-spacer:before {
  content: "\e0b1";
}
.react-app .icon-step-record:before, .multiple-react-app .icon-step-record:before, .bs4-popover .icon-step-record:before, .bs4-modal .icon-step-record:before, .bs4 .icon-step-record:before {
  content: "\e0b2";
}
.react-app .icon-step-review:before, .multiple-react-app .icon-step-review:before, .bs4-popover .icon-step-review:before, .bs4-modal .icon-step-review:before, .bs4 .icon-step-review:before {
  content: "\e0b3";
}
.react-app .icon-step-view-prompt:before, .multiple-react-app .icon-step-view-prompt:before, .bs4-popover .icon-step-view-prompt:before, .bs4-modal .icon-step-view-prompt:before, .bs4 .icon-step-view-prompt:before {
  content: "\e0b4";
}
.react-app .icon-success:before, .multiple-react-app .icon-success:before, .bs4-popover .icon-success:before, .bs4-modal .icon-success:before, .bs4 .icon-success:before {
  content: "\e0b5";
}
.react-app .icon-survey:before, .multiple-react-app .icon-survey:before, .bs4-popover .icon-survey:before, .bs4-modal .icon-survey:before, .bs4 .icon-survey:before {
  content: "\e0b6";
}
.react-app .icon-switch:before, .multiple-react-app .icon-switch:before, .bs4-popover .icon-switch:before, .bs4-modal .icon-switch:before, .bs4 .icon-switch:before {
  content: "\e0b7";
}
.react-app .icon-team:before, .multiple-react-app .icon-team:before, .bs4-popover .icon-team:before, .bs4-modal .icon-team:before, .bs4 .icon-team:before {
  content: "\e0b8";
}
.react-app .icon-team-delete:before, .multiple-react-app .icon-team-delete:before, .bs4-popover .icon-team-delete:before, .bs4-modal .icon-team-delete:before, .bs4 .icon-team-delete:before {
  content: "\e0b9";
}
.react-app .icon-team-update:before, .multiple-react-app .icon-team-update:before, .bs4-popover .icon-team-update:before, .bs4-modal .icon-team-update:before, .bs4 .icon-team-update:before {
  content: "\e0ba";
}
.react-app .icon-timedexam:before, .multiple-react-app .icon-timedexam:before, .bs4-popover .icon-timedexam:before, .bs4-modal .icon-timedexam:before, .bs4 .icon-timedexam:before {
  content: "\e0bb";
}
.react-app .icon-translate:before, .multiple-react-app .icon-translate:before, .bs4-popover .icon-translate:before, .bs4-modal .icon-translate:before, .bs4 .icon-translate:before {
  content: "\e0bc";
}
.react-app .icon-trash:before, .multiple-react-app .icon-trash:before, .bs4-popover .icon-trash:before, .bs4-modal .icon-trash:before, .bs4 .icon-trash:before {
  content: "\e0bd";
}
.react-app .icon-upload:before, .multiple-react-app .icon-upload:before, .bs4-popover .icon-upload:before, .bs4-modal .icon-upload:before, .bs4 .icon-upload:before {
  content: "\e0be";
}
.react-app .icon-video:before, .multiple-react-app .icon-video:before, .bs4-popover .icon-video:before, .bs4-modal .icon-video:before, .bs4 .icon-video:before {
  content: "\e0bf";
}
.react-app .icon-video-practice-on-cards:before, .multiple-react-app .icon-video-practice-on-cards:before, .bs4-popover .icon-video-practice-on-cards:before, .bs4-modal .icon-video-practice-on-cards:before, .bs4 .icon-video-practice-on-cards:before {
  content: "\e0c0";
}
.react-app .icon-videolist:before, .multiple-react-app .icon-videolist:before, .bs4-popover .icon-videolist:before, .bs4-modal .icon-videolist:before, .bs4 .icon-videolist:before {
  content: "\e0c1";
}
.react-app .icon-view:before, .multiple-react-app .icon-view:before, .bs4-popover .icon-view:before, .bs4-modal .icon-view:before, .bs4 .icon-view:before {
  content: "\e0c2";
}
.react-app .icon-warning:before, .multiple-react-app .icon-warning:before, .bs4-popover .icon-warning:before, .bs4-modal .icon-warning:before, .bs4 .icon-warning:before {
  content: "\e0c3";
}
.react-app .icon-web-embeds:before, .multiple-react-app .icon-web-embeds:before, .bs4-popover .icon-web-embeds:before, .bs4-modal .icon-web-embeds:before, .bs4 .icon-web-embeds:before {
  content: "\e0c4";
}
.react-app .icon-work:before, .multiple-react-app .icon-work:before, .bs4-popover .icon-work:before, .bs4-modal .icon-work:before, .bs4 .icon-work:before {
  content: "\e0c5";
}
.react-app .icon-xls:before, .multiple-react-app .icon-xls:before, .bs4-popover .icon-xls:before, .bs4-modal .icon-xls:before, .bs4 .icon-xls:before {
  content: "\e0c6";
}
.react-app .icon-zip:before, .multiple-react-app .icon-zip:before, .bs4-popover .icon-zip:before, .bs4-modal .icon-zip:before, .bs4 .icon-zip:before {
  content: "\e0c7";
}
.react-app .icon-zoom:before, .multiple-react-app .icon-zoom:before, .bs4-popover .icon-zoom:before, .bs4-modal .icon-zoom:before, .bs4 .icon-zoom:before {
  content: "\e0c8";
}
.react-app .icon-skill:before, .multiple-react-app .icon-skill:before, .bs4-popover .icon-skill:before, .bs4-modal .icon-skill:before, .bs4 .icon-skill:before {
  content: "\e0c9";
}
.react-app .icon-mentor-plus:before, .multiple-react-app .icon-mentor-plus:before, .bs4-popover .icon-mentor-plus:before, .bs4-modal .icon-mentor-plus:before, .bs4 .icon-mentor-plus:before {
  content: "\e0ca";
}
.react-app .icon-recurring:before, .multiple-react-app .icon-recurring:before, .bs4-popover .icon-recurring:before, .bs4-modal .icon-recurring:before, .bs4 .icon-recurring:before {
  content: "\e0cb";
}
.react-app .icon-xss-smallest, .multiple-react-app .icon-xss-smallest, .bs4-popover .icon-xss-smallest, .bs4-modal .icon-xss-smallest, .bs4 .icon-xss-smallest {
  font-size: 12px;
}
.react-app .icon-smallest, .multiple-react-app .icon-smallest, .bs4-popover .icon-smallest, .bs4-modal .icon-smallest, .bs4 .icon-smallest {
  font-size: 16px;
}
.react-app .icon-small, .multiple-react-app .icon-small, .bs4-popover .icon-small, .bs4-modal .icon-small, .bs4 .icon-small {
  font-size: 20px;
}
.react-app .icon-medium, .multiple-react-app .icon-medium, .bs4-popover .icon-medium, .bs4-modal .icon-medium, .bs4 .icon-medium {
  font-size: 24px;
}
.react-app .icon-medium-large, .multiple-react-app .icon-medium-large, .bs4-popover .icon-medium-large, .bs4-modal .icon-medium-large, .bs4 .icon-medium-large {
  font-size: 28px;
}
.react-app .icon-medium-larger, .multiple-react-app .icon-medium-larger, .bs4-popover .icon-medium-larger, .bs4-modal .icon-medium-larger, .bs4 .icon-medium-larger {
  font-size: 32px;
}
.react-app .icon-large, .multiple-react-app .icon-large, .bs4-popover .icon-large, .bs4-modal .icon-large, .bs4 .icon-large {
  font-size: 36px;
}
.react-app .icon-larger, .multiple-react-app .icon-larger, .bs4-popover .icon-larger, .bs4-modal .icon-larger, .bs4 .icon-larger {
  font-size: 48px;
}
.react-app .icon-largest, .multiple-react-app .icon-largest, .bs4-popover .icon-largest, .bs4-modal .icon-largest, .bs4 .icon-largest {
  font-size: 60px;
}
.react-app .icon-super-large, .multiple-react-app .icon-super-large, .bs4-popover .icon-super-large, .bs4-modal .icon-super-large, .bs4 .icon-super-large {
  font-size: 80px;
}
.react-app .icon-ultra-large, .multiple-react-app .icon-ultra-large, .bs4-popover .icon-ultra-large, .bs4-modal .icon-ultra-large, .bs4 .icon-ultra-large {
  font-size: 100px;
}
.react-app .icon-button:hover, .multiple-react-app .icon-button:hover, .bs4-popover .icon-button:hover, .bs4-modal .icon-button:hover, .bs4 .icon-button:hover {
  color: #0E72ED;
  cursor: pointer;
}
.react-app .icon, .multiple-react-app .icon, .bs4-popover .icon, .bs4-modal .icon, .bs4 .icon {
  display: block;
}
.react-app .fade, .multiple-react-app .fade, .bs4-popover .fade, .bs4-modal .fade, .bs4 .fade {
  opacity: initial;
  transition: initial;
}
.react-app .froala-style-small, .react-app .froala-style-regular, .react-app .froala-style-medium, .react-app .froala-style-big, .multiple-react-app .froala-style-small, .multiple-react-app .froala-style-regular, .multiple-react-app .froala-style-medium, .multiple-react-app .froala-style-big, .bs4-popover .froala-style-small, .bs4-popover .froala-style-regular, .bs4-popover .froala-style-medium, .bs4-popover .froala-style-big, .bs4-modal .froala-style-small, .bs4-modal .froala-style-regular, .bs4-modal .froala-style-medium, .bs4-modal .froala-style-big, .bs4 .froala-style-small, .bs4 .froala-style-regular, .bs4 .froala-style-medium, .bs4 .froala-style-big {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  /* all of the specific languages must be spelled out because
    1) variable($variable) names cannot be generated dyanmicly
    2) placeholders(%placholder) dont' work because you can't extend them in media queries which we do in some cases
  */
}
body.lang-zh_CN .react-app .froala-style-small, body.lang-zh_CN .react-app .froala-style-regular, body.lang-zh_CN .react-app .froala-style-medium, body.lang-zh_CN .react-app .froala-style-big, body.lang-zh_CN .multiple-react-app .froala-style-small, body.lang-zh_CN .multiple-react-app .froala-style-regular, body.lang-zh_CN .multiple-react-app .froala-style-medium, body.lang-zh_CN .multiple-react-app .froala-style-big, body.lang-zh_CN .bs4-popover .froala-style-small, body.lang-zh_CN .bs4-popover .froala-style-regular, body.lang-zh_CN .bs4-popover .froala-style-medium, body.lang-zh_CN .bs4-popover .froala-style-big, body.lang-zh_CN .bs4-modal .froala-style-small, body.lang-zh_CN .bs4-modal .froala-style-regular, body.lang-zh_CN .bs4-modal .froala-style-medium, body.lang-zh_CN .bs4-modal .froala-style-big, body.lang-zh_CN .bs4 .froala-style-small, body.lang-zh_CN .bs4 .froala-style-regular, body.lang-zh_CN .bs4 .froala-style-medium, body.lang-zh_CN .bs4 .froala-style-big {
  font-family: "Open Sans", "微软雅黑", "Microsoft YaHei", "华文细黑", "STXihei", "PingFang TC", "宋体", "SimSun", "Helvetica", "Arial", "sans-serif";
}

body.lang-ja_JP .react-app .froala-style-small, body.lang-ja_JP .react-app .froala-style-regular, body.lang-ja_JP .react-app .froala-style-medium, body.lang-ja_JP .react-app .froala-style-big, body.lang-ja_JP .multiple-react-app .froala-style-small, body.lang-ja_JP .multiple-react-app .froala-style-regular, body.lang-ja_JP .multiple-react-app .froala-style-medium, body.lang-ja_JP .multiple-react-app .froala-style-big, body.lang-ja_JP .bs4-popover .froala-style-small, body.lang-ja_JP .bs4-popover .froala-style-regular, body.lang-ja_JP .bs4-popover .froala-style-medium, body.lang-ja_JP .bs4-popover .froala-style-big, body.lang-ja_JP .bs4-modal .froala-style-small, body.lang-ja_JP .bs4-modal .froala-style-regular, body.lang-ja_JP .bs4-modal .froala-style-medium, body.lang-ja_JP .bs4-modal .froala-style-big, body.lang-ja_JP .bs4 .froala-style-small, body.lang-ja_JP .bs4 .froala-style-regular, body.lang-ja_JP .bs4 .froala-style-medium, body.lang-ja_JP .bs4 .froala-style-big {
  font-family: "Open Sans", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", "Helvetica", "Arial", "sans-serif";
}

body.lang-ko_KP .react-app .froala-style-small, body.lang-ko_KP .react-app .froala-style-regular, body.lang-ko_KP .react-app .froala-style-medium, body.lang-ko_KP .react-app .froala-style-big, body.lang-ko_KP .multiple-react-app .froala-style-small, body.lang-ko_KP .multiple-react-app .froala-style-regular, body.lang-ko_KP .multiple-react-app .froala-style-medium, body.lang-ko_KP .multiple-react-app .froala-style-big, body.lang-ko_KP .bs4-popover .froala-style-small, body.lang-ko_KP .bs4-popover .froala-style-regular, body.lang-ko_KP .bs4-popover .froala-style-medium, body.lang-ko_KP .bs4-popover .froala-style-big, body.lang-ko_KP .bs4-modal .froala-style-small, body.lang-ko_KP .bs4-modal .froala-style-regular, body.lang-ko_KP .bs4-modal .froala-style-medium, body.lang-ko_KP .bs4-modal .froala-style-big, body.lang-ko_KP .bs4 .froala-style-small, body.lang-ko_KP .bs4 .froala-style-regular, body.lang-ko_KP .bs4 .froala-style-medium, body.lang-ko_KP .bs4 .froala-style-big {
  font-family: "Open Sans", "NanumBarunGothic", "맑은 고딕", "Malgun Gothic", "굴림", "Gulim", "돋움", "Dotum", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

body.lang-ar_SA .react-app .froala-style-small, body.lang-ar_SA .react-app .froala-style-regular, body.lang-ar_SA .react-app .froala-style-medium, body.lang-ar_SA .react-app .froala-style-big, body.lang-ar_SA .multiple-react-app .froala-style-small, body.lang-ar_SA .multiple-react-app .froala-style-regular, body.lang-ar_SA .multiple-react-app .froala-style-medium, body.lang-ar_SA .multiple-react-app .froala-style-big, body.lang-ar_SA .bs4-popover .froala-style-small, body.lang-ar_SA .bs4-popover .froala-style-regular, body.lang-ar_SA .bs4-popover .froala-style-medium, body.lang-ar_SA .bs4-popover .froala-style-big, body.lang-ar_SA .bs4-modal .froala-style-small, body.lang-ar_SA .bs4-modal .froala-style-regular, body.lang-ar_SA .bs4-modal .froala-style-medium, body.lang-ar_SA .bs4-modal .froala-style-big, body.lang-ar_SA .bs4 .froala-style-small, body.lang-ar_SA .bs4 .froala-style-regular, body.lang-ar_SA .bs4 .froala-style-medium, body.lang-ar_SA .bs4 .froala-style-big {
  font-family: "Open Sans", "Geeza Pro", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

.react-app .froala-style-title-small, .react-app .froala-style-subtitle, .react-app .froala-style-title, .multiple-react-app .froala-style-title-small, .multiple-react-app .froala-style-subtitle, .multiple-react-app .froala-style-title, .bs4-popover .froala-style-title-small, .bs4-popover .froala-style-subtitle, .bs4-popover .froala-style-title, .bs4-modal .froala-style-title-small, .bs4-modal .froala-style-subtitle, .bs4-modal .froala-style-title, .bs4 .froala-style-title-small, .bs4 .froala-style-subtitle, .bs4 .froala-style-title {
  font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
}
body.lang-zh_CN .react-app .froala-style-title-small, body.lang-zh_CN .react-app .froala-style-subtitle, body.lang-zh_CN .react-app .froala-style-title, body.lang-zh_CN .multiple-react-app .froala-style-title-small, body.lang-zh_CN .multiple-react-app .froala-style-subtitle, body.lang-zh_CN .multiple-react-app .froala-style-title, body.lang-zh_CN .bs4-popover .froala-style-title-small, body.lang-zh_CN .bs4-popover .froala-style-subtitle, body.lang-zh_CN .bs4-popover .froala-style-title, body.lang-zh_CN .bs4-modal .froala-style-title-small, body.lang-zh_CN .bs4-modal .froala-style-subtitle, body.lang-zh_CN .bs4-modal .froala-style-title, body.lang-zh_CN .bs4 .froala-style-title-small, body.lang-zh_CN .bs4 .froala-style-subtitle, body.lang-zh_CN .bs4 .froala-style-title {
  font-family: "Open Sans Condensed", "微软雅黑", "Microsoft YaHei", "华文细黑", "STXihei", "PingFang TC", "宋体", "SimSun", "Helvetica", "Arial", "sans-serif";
}

body.lang-ja_JP .react-app .froala-style-title-small, body.lang-ja_JP .react-app .froala-style-subtitle, body.lang-ja_JP .react-app .froala-style-title, body.lang-ja_JP .multiple-react-app .froala-style-title-small, body.lang-ja_JP .multiple-react-app .froala-style-subtitle, body.lang-ja_JP .multiple-react-app .froala-style-title, body.lang-ja_JP .bs4-popover .froala-style-title-small, body.lang-ja_JP .bs4-popover .froala-style-subtitle, body.lang-ja_JP .bs4-popover .froala-style-title, body.lang-ja_JP .bs4-modal .froala-style-title-small, body.lang-ja_JP .bs4-modal .froala-style-subtitle, body.lang-ja_JP .bs4-modal .froala-style-title, body.lang-ja_JP .bs4 .froala-style-title-small, body.lang-ja_JP .bs4 .froala-style-subtitle, body.lang-ja_JP .bs4 .froala-style-title {
  font-family: "Open Sans Condensed", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", "Helvetica", "Arial", "sans-serif";
}

body.lang-ko_KP .react-app .froala-style-title-small, body.lang-ko_KP .react-app .froala-style-subtitle, body.lang-ko_KP .react-app .froala-style-title, body.lang-ko_KP .multiple-react-app .froala-style-title-small, body.lang-ko_KP .multiple-react-app .froala-style-subtitle, body.lang-ko_KP .multiple-react-app .froala-style-title, body.lang-ko_KP .bs4-popover .froala-style-title-small, body.lang-ko_KP .bs4-popover .froala-style-subtitle, body.lang-ko_KP .bs4-popover .froala-style-title, body.lang-ko_KP .bs4-modal .froala-style-title-small, body.lang-ko_KP .bs4-modal .froala-style-subtitle, body.lang-ko_KP .bs4-modal .froala-style-title, body.lang-ko_KP .bs4 .froala-style-title-small, body.lang-ko_KP .bs4 .froala-style-subtitle, body.lang-ko_KP .bs4 .froala-style-title {
  font-family: "Open Sans Condensed", "NanumBarunGothic", "맑은 고딕", "Malgun Gothic", "굴림", "Gulim", "돋움", "Dotum", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

body.lang-ar_SA .react-app .froala-style-title-small, body.lang-ar_SA .react-app .froala-style-subtitle, body.lang-ar_SA .react-app .froala-style-title, body.lang-ar_SA .multiple-react-app .froala-style-title-small, body.lang-ar_SA .multiple-react-app .froala-style-subtitle, body.lang-ar_SA .multiple-react-app .froala-style-title, body.lang-ar_SA .bs4-popover .froala-style-title-small, body.lang-ar_SA .bs4-popover .froala-style-subtitle, body.lang-ar_SA .bs4-popover .froala-style-title, body.lang-ar_SA .bs4-modal .froala-style-title-small, body.lang-ar_SA .bs4-modal .froala-style-subtitle, body.lang-ar_SA .bs4-modal .froala-style-title, body.lang-ar_SA .bs4 .froala-style-title-small, body.lang-ar_SA .bs4 .froala-style-subtitle, body.lang-ar_SA .bs4 .froala-style-title {
  font-family: "Open Sans Condensed", "Geeza Pro", "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
}

.react-app .froala-style-big, .react-app .froala-style-title-small, .react-app .froala-style-subtitle, .multiple-react-app .froala-style-big, .multiple-react-app .froala-style-title-small, .multiple-react-app .froala-style-subtitle, .bs4-popover .froala-style-big, .bs4-popover .froala-style-title-small, .bs4-popover .froala-style-subtitle, .bs4-modal .froala-style-big, .bs4-modal .froala-style-title-small, .bs4-modal .froala-style-subtitle, .bs4 .froala-style-big, .bs4 .froala-style-title-small, .bs4 .froala-style-subtitle {
  font-weight: 300;
}
.react-app .froala-style-title, .multiple-react-app .froala-style-title, .bs4-popover .froala-style-title, .bs4-modal .froala-style-title, .bs4 .froala-style-title {
  font-weight: 700;
}
.react-app .froala-style-small, .multiple-react-app .froala-style-small, .bs4-popover .froala-style-small, .bs4-modal .froala-style-small, .bs4 .froala-style-small {
  font-weight: 600;
}
.react-app .froala-style-title, .multiple-react-app .froala-style-title, .bs4-popover .froala-style-title, .bs4-modal .froala-style-title, .bs4 .froala-style-title {
  font-size: 36px;
  line-height: 50px;
}
.react-app .froala-style-subtitle, .multiple-react-app .froala-style-subtitle, .bs4-popover .froala-style-subtitle, .bs4-modal .froala-style-subtitle, .bs4 .froala-style-subtitle {
  font-size: 24px;
  line-height: 30px;
}
.react-app .froala-style-title-small, .multiple-react-app .froala-style-title-small, .bs4-popover .froala-style-title-small, .bs4-modal .froala-style-title-small, .bs4 .froala-style-title-small {
  font-size: 18px;
  line-height: 25px;
}
.react-app .froala-style-big, .multiple-react-app .froala-style-big, .bs4-popover .froala-style-big, .bs4-modal .froala-style-big, .bs4 .froala-style-big {
  font-size: 24px;
  line-height: 35px;
}
.react-app .froala-style-medium, .multiple-react-app .froala-style-medium, .bs4-popover .froala-style-medium, .bs4-modal .froala-style-medium, .bs4 .froala-style-medium {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
}
.react-app .froala-style-regular, .multiple-react-app .froala-style-regular, .bs4-popover .froala-style-regular, .bs4-modal .froala-style-regular, .bs4 .froala-style-regular {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.react-app .froala-style-small, .multiple-react-app .froala-style-small, .bs4-popover .froala-style-small, .bs4-modal .froala-style-small, .bs4 .froala-style-small {
  font-size: 12px;
  line-height: 15px;
}
.react-app .froala-text-align-left, .multiple-react-app .froala-text-align-left, .bs4-popover .froala-text-align-left, .bs4-modal .froala-text-align-left, .bs4 .froala-text-align-left {
  /*rtl:ignore*/
  text-align: left;
}
.react-app .froala-text-align-center, .multiple-react-app .froala-text-align-center, .bs4-popover .froala-text-align-center, .bs4-modal .froala-text-align-center, .bs4 .froala-text-align-center {
  text-align: center;
}
.react-app .froala-text-align-right, .multiple-react-app .froala-text-align-right, .bs4-popover .froala-text-align-right, .bs4-modal .froala-text-align-right, .bs4 .froala-text-align-right {
  /*rtl:ignore*/
  text-align: right;
}

.bs4-modal {
  /** This is a spot-fix for https://novoed.atlassian.net/browse/NOV-68253. See comments for details */
}
.bs4-modal .h4 {
  margin: 0 !important;
  width: 100%;
}
.bs4-modal h3.popover-title {
  font-size: unset;
}
.bs4-modal .close {
  position: absolute;
  right: 20px;
  top: 15px;
  margin: 0;
  padding: 0;
}

.nv-dropdown .bs4-dropdown-menu {
  margin: 0 !important;
}

.highlight-react-components nv-react-component {
  z-index: 0;
  position: relative;
}
.highlight-react-components nv-react-component .react-component-highlight {
  display: block;
}